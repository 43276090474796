<template>
  <section
    class="insights-wrapper"
  >
    <div
      :class="[
        'chart-section',
        $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
      ]"
    >
      <am2-card-container
        class="cell"
        :style="{ padding: $arMediaQuery.pageContent.minWidth('sm') ? '30px' : '30px 10px' }"
        layout="soft"
      >
        <am2-multiple-column-charts
          v-if="mostPopularDayData"
          hide-switch-buttons
          title="Most popular day"
          :loading="isFetchingEventTicketStats"
          description="This is the most popular day of week that people are buying tickets for this event."
          :topics="mostPopularDayData.topics"
          :chart-datas="mostPopularDayData.chartDatas"
          :chart-height="400"
          :topic-index="0"
          :style="{ height: '376px' }"
        />

      </am2-card-container>

      <am2-card-container
        class="cell"
        :style="{ padding:  $arMediaQuery.pageContent.minWidth('sm') ? '30px' : '30px 10px' }"
        layout="soft"
      >
        <am2-multiple-column-charts
          v-if="mostPopularTimeData"
          hide-switch-buttons
          title="Most popular time"
          :loading="isFetchingEventTicketStats"
          description="This is the most popular time of day that people are buying tickets for this event."
          :topics="mostPopularTimeData.topics"
          :chart-datas="mostPopularTimeData.chartDatas"
          :chart-height="400"
          :topic-index="0"
          :style="{ height: '376px' }"
        />
      </am2-card-container>
      <RegistrationsChartSection v-if="arEnableEventRegistrationChart" />
      <PageViewsChartSection v-if="arEnablePageViewsGraphs" />
      <ZoomAttendanceChartSection v-if="arEnableZoomGraphs && zoomUrlFromCurrentEvent" />

    </div>
  </section>
</template>

<script>
  import accounting from 'accounting';
  import moment from 'moment';
  import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
  import RegistrationsChartSection from './RegistrationsChartSection';
  import PageViewsChartSection from './PageViewsChartSection';
  import ZoomAttendanceChartSection from './ZoomAttendanceChartSection';

  const dataKeyTitleMap = {
    highestValueCustomers: 'Top Spending Ticket Buyers',
    mostLoyalCustomers: 'Repeat Ticket Buyers',
    topReferrers: 'Top Referrers',
    topInfluencers: 'Top Influencers',
    topArtists: 'Top Artists',
    topTracks: 'Top Tracks',
    topPodcasts: 'Top Podcast Shows',
  };

  const chartPageSize = 10;

  export default {
  name: 'EventInsightsPage',
  components: {
    RegistrationsChartSection,
    PageViewsChartSection,
    ZoomAttendanceChartSection,
  },

  props: {
    filter: {
      type: Object,
      default: null,
    },
    featureType: {
      type: String,
      default: 'audience-insights',
    },
    enableSeeAllTableRouteChange: {
      type: Boolean,
      default: false,
    },
    eventOid: {
      type: String,
      default: null,
    },
    campaignOid: {
      type: String,
      default: null,
    },
    insightType: {
      type: String,
      default: 'filter',
      validator: (val) => ['filter', 'campaign', 'event'].indexOf(val) > -1,
    },
  },

  data() {
    return {
      failedToFetchInsights: false,
      insightsTopFans: [],
      // seeAllTableDataKey: null,
      locationChartTopic: 'City',
      locationChartMaxSize: chartPageSize,
      isLoadingSeeMore: false,
      arEnableZoomGraphs: process.env.arEnableZoomGraphs,
      arEnableEventRegistrationChart: process.env.arEnableEventRegistrationChart,
      arEnablePageViewsGraphs: process.env.arEnablePageViewsGraphs,
    };
  },

  async mounted() {
    this.fetchAndInitEventTicketStats(this.oid)

    this.checkIfEventIsSyncing()
  },

  created() {
    this.$store.registerModule("event-view-child", {
      actions: {
        FORCE_REFETCH_DATA: (state) => {
          this.pollNewStats();
        },
      }
    })
  },

  computed: {
    ...mapState({
      oid: state => state.route.params.oid,
      currentEvent: state => state.event.currentEvent,
      eventTicketStats: state => state.event.eventTicketStats,
      isFetchingEventTicketStats: state => state.event.isFetchingEventTicketStats,
    }),
    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled',
      prunedScratchSegment: 'segment/prunedScratchSegment',
      zoomUrlFromCurrentEvent: 'event/zoomUrlFromCurrentEvent',
      regionSettingsCurrencySymbol: 'auth/regionSettingsCurrencySymbol',
    }),

    mostPopularDayData() {
      if (!this.eventTicketStats) {
        return {};
      }

      const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
      const daysNice = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
      let data = [];
      let percentageSumedUp = 0;

      const timezone = this.currentEvent?.timeZone;
      const timezoneCode = timezone ? moment.tz(moment(), timezone).format('z') : null;

      days.forEach( (day, idx) => {
        let relatedData = this.eventTicketStats && this.eventTicketStats.revenuePerDay.find(revenueDay => revenueDay.day === day);
        let dayNice = daysNice[idx];
        relatedData = relatedData ? { day, dayNice, value: relatedData.value, percentage: relatedData.percentage } : { day, dayNice, percentage: 0, value: 0 };
        percentageSumedUp += relatedData.percentage;
        data.push(relatedData);
      });
      const hasChartDatas = percentageSumedUp !== 0;
      const currencySymbol = this.regionSettingsCurrencySymbol

      return {
        topics: [
          {
            name: 'Percentage',
            valueGetters: [{
              name: 'Default',
              getter: ({ day, percentage }) => [day, percentage],
            }],
            columnWidth: 25,
            format: '{value}%',
            hasYLabel: true,
            yAxisMinRange: hasChartDatas ? 10 : 100,
            xAxisRotation: 0,
            hasDataLabels: false,
            tooltipLayout: 2,
            tooltipValueFormatter({ value }) {
              return `${currencySymbol}${accounting.formatNumber(value/100)}`;
            },
            tooltipPercentageFormatter({ percentage }) {
              let cleanCount = parseFloat(percentage);
              if (cleanCount > 0 && cleanCount < 1) {
                return "<1%";
              } else if (cleanCount > 99 && cleanCount !== 100) {
                return ">99%";
              } else {
                return `${Math.round(cleanCount)}%`;
              }
            },
            tooltipLabelFormatter({ dayNice }) {
              return timezoneCode ? `Sales on a ${dayNice} (${timezoneCode})` : `Sales on a ${dayNice}`;
            },
            hasTooltip: true,
          }
        ],
        chartDatas: data, // You will still get 7 days of data with all 0 if there's no data.
      };
    },


    mostPopularTimeData() {
      if (!this.eventTicketStats) {
        return {};
      }

      const timePeriods = [];
      const data = [];
      let percentageSumedUp = 0;

      // The server is responsible for converting the day/hour data to the event timezone before returning it.
      for (let i = 0; i < 24; i += 2) {
        timePeriods.push(`${i}-${i + 2}`);

        const currentHourData = this.eventTicketStats.revenuePerHour.find(revenueHour => revenueHour.hour === i);
        const nextHourData = this.eventTicketStats.revenuePerHour.find(revenueHour => revenueHour.hour === i + 1);
        const value = (currentHourData ? currentHourData.value : 0) + (nextHourData ? nextHourData.value : 0);
        const percentage = (currentHourData ? currentHourData.percentage : 0) + (nextHourData ? nextHourData.percentage : 0);
        percentageSumedUp += percentage;

        const timezone = this.currentEvent?.timeZone;
        const timezoneCode = timezone ? moment.tz(moment(), timezone).format('z') : null;

        let timePeriodNice = '';
        timePeriodNice += i < 12 ? `${i == 0 ? 12 : i}AM - ` : `${(i - 12 == 0) ? 12 : i - 12}PM - `;
        const timePlus2 = i + 2
        timePeriodNice += timePlus2 >= 12 && timePlus2 < 24 ? `${timePlus2 - 12 == 0 ? 12 : timePlus2 - 12}PM` : `${timePlus2 == 24 ? 12 : timePlus2}AM`;
        timePeriodNice += timezoneCode ? ` (${timezoneCode})` : '';

        data.push({
          timePeriod: `${i}-${i + 2}`,
          timePeriodNice,
          value,
          percentage,
        });
      }
      const hasChartDatas = percentageSumedUp !== 0;
      const currencySymbol = this.regionSettingsCurrencySymbol

      return {
        topics: [
          {
            name: 'Percentage',
            valueGetters:[{
              name: 'Default',
              getter: ({ timePeriod, percentage }) => [timePeriod, percentage],
            }],
            columnWidth: 25,
            format: '{value}%',
            hasYLabel: true,
            yAxisMinRange: hasChartDatas ? 10 : 100,
            xAxisRotation: 0,
            hasDataLabels: false,
            tooltipLayout: 2,
            tooltipValueFormatter({ value }) {
              return `${currencySymbol}${accounting.formatNumber(value/100)}`;
            },
            tooltipPercentageFormatter({ percentage }) {
              let cleanCount = parseFloat(percentage);
              if (cleanCount > 0 && cleanCount < 1) {
                return "<1%";
              } else if (cleanCount > 99 && cleanCount !== 100) {
                return ">99%";
              } else {
                return `${Math.round(cleanCount)}%`;
              }
            },
            tooltipLabelFormatter({ timePeriodNice }) {
              return `Sales between ${timePeriodNice}`;
            },
            hasTooltip: true,
          }
        ],
        chartDatas: data, // You will still get 7 days of data with all 0 if there's no data.
      };
    },

    useInsightsSnapshot() {
      return [
        'topInfluencers',
        'topArtists',
        'topTracks',
        'topPodcasts',
      ].findIndex(s => s === this.seeAllTableDataKey) >= 0;
    }
  },

  created() {
    if (this.enableSeeAllTableRouteChange) {
      const allowedKeys = Object.keys(dataKeyTitleMap);
      const seeAllVal = this.$route.query.seeAll;
      if (seeAllVal && allowedKeys.indexOf(seeAllVal) > -1) {
        this.seeAllTableDataKey = seeAllVal;
      }
    }
  },

  methods: {
    ...mapActions({
      fetchEventTicketStats: 'event/FETCH_EVENT_TICKET_STATS',
    }),

    checkIfEventIsSyncing() {
      if (this.isEventSyncing) {
        this.startEventSyncPoller();
      }
    },

    startEventSyncPoller() {
      if (!this.syncPoller) {
        this.syncPoller = setInterval(this.pollNewStats, 4000);
      }
    },

    async pollNewStats() {
      this.fetchAndInitEventTicketStats(this.oid)

      if (!isSyncing(this.currentEvent)) {
        clearInterval(this.syncPoller);
      }
    },

    async fetchAndInitEventTicketStats(oid) {
      await this.fetchEventTicketStats({
        eventOid: oid
      })
    }
  },

  beforeDestroy() {
    if (this.syncPoller) {
      clearInterval(this.syncPoller);
    }

    // this.$store.unregisterModule("event-view-child");
  },
};
</script>

<style lang="scss" scoped>
.insights-wrapper {
  .chart-section {
    display: grid;
    grid-template-columns: calc(50% - 15px) calc(50% - 15px);
    grid-gap: 30px;

    .chart-container {
      padding: 30px;
      overflow: hidden;

      &.full-width {
        grid-column: span 2;
      }
    }

    &.md-max {
      .chart-container {
        padding: 24px 12px;
      }
    }
  }
}
</style>
