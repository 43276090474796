import { NuxtAxiosInstance } from "@nuxtjs/axios";
import { clone } from '@/utils/helpers';
import { isErrorStatus } from '../utils';
import { CustomApiError } from '../types';
import { NewUser } from './types';

export default (axios: NuxtAxiosInstance) => ({
  async registerToWaveApp(user: NewUser): Promise<string> {
    const body = clone(user);
    body.waveRegistration = true;
    const { headers, status } = await axios.post('/signup', body);
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Register to Wave error',
        message: `Error registering Wave app.`,
        status,
      };
      throw apiError;
    }
    return headers['success-redirect-uri'];
  },

  async fetchPromoterAccountPermissions(promoterOid: number): Promise<string> {
    const { data, status } = await axios.get(`/promoter/${promoterOid}/account-permissions`);
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Fetch User Permissions error',
        message: `Error fetching User permissions `,
        status,
      };
      throw apiError;
    }
    return data;
  },

  async sendUserInvite(userData: object): Promise <any> {
    const { promoterOid, emailAddress, defaultUserRoleOid, promoterUserRoleOid }: any = userData
    const res = await axios.post(`promoter/${promoterOid}/user-invite`, {
      emailAddress,
      defaultUserRoleOid,
      promoterUserRoleOid
    })
    if (isErrorStatus(res.status)) {
      const apiError: CustomApiError = {
        name: 'Failed to generate invite!',
        message: `The server could not generate an invite. Please try again.`,
        status: res.status,
      };
      throw apiError;
    }
    return res
  },

  async deleteUserInvite(promoterOid: number, inviteOid: number): Promise <any> {
    if (!promoterOid || !inviteOid) {
      return;
    }
    const { status } = await axios.delete(`promoter/${promoterOid}/user-invite/${inviteOid}`)
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Failed to delete invite!',
        message: `The server could not revoke the selected invite. Please try again.`,
        status,
      };
      throw apiError;
    }
    return true
  },

  async validateInvite(userData: object): Promise <any> {
    const { promoterOid, hash, id}: any = userData
    const { data, status } = await axios.post(`validate-user-invite`, {
      promoterOid,
      inviteCode: hash,
      inviteOid: id,
    })

    if (!data || data.length <= 0 || isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Invite is not valid',
        message: `Invite is not valid for promoter ${promoterOid} with invite code '${hash}' and invite id ${id}`,
        status,
      };
      throw apiError;
    }

    return data
  },

  async finishInvite(userData: object): Promise <any> {
    const { firstName, lastName, promoterOid, email, hash, password, id}: any = userData
    const { data, status } = await axios.post(`promoter/${promoterOid}/register-to-company`, {
      firstName,
      lastName,
      emailAddress: email,
      promoterOid,
      code: hash,
      password,
      id
    })

    if (!data || data.length <= 0 || isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Cannot complete invited user registration to company',
        message: `${firstName} ${lastName} (${email}) cannot finish registration to ${promoterOid}`,
        status,
      };
      throw apiError;
    }

    return data
  },

  async fetchInvitees(promoterOid: number): Promise <any> {
    const { data, status } = await axios.get(`/promoter/${promoterOid}/user-invite`);
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Fetch User Permissions error',
        message: `Error fetching User permissions `,
        status,
      };
      throw apiError;
    }
    return {data, status};
  },

  async resendInvite(email: string): Promise <any> {
    const response = await axios.get(`resend-account-invite`, {
      params: {
        emailAddress: email
      }
    })

    if (isErrorStatus(response.status)) {
      const apiError: CustomApiError = {
        name: 'Invite cannot be resent',
        message: `Invite cannot be resent for ${email}`,
        status: response.status,
      };
      throw apiError;
    }

    return response
  },
});
