var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('am2-card-container',{style:({ padding: _vm.$arMediaQuery.pageContent.maxWidth('sm') ? '20px' : '24px 30px' }),attrs:{"layout":"soft"}},[_c('section',{class:[
    'integration',
    _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
    _vm.$arMediaQuery.pageContent.minWidth('sm') && 'sm-min' ]},[_c('div',{class:[
      'left-section',
      _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
      _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
      _vm.$arMediaQuery.pageContent.minWidth('sm') && 'sm-min' ]},[_c('div',{staticClass:"left-section-item u-align-items-center"},[_c('div',{staticClass:"icon"},[_vm._t("logo-icon",function(){return [_c('am2-provider-icon',{attrs:{"name":_vm.logoIconName,"color":_vm.logoColor,"width":"40px","height":"40px"}})]})],2),_vm._v(" "),_c('div',{staticClass:"context",style:({
            height: _vm.$arMediaQuery.pageContent.minWidth('lg') && '44px',
          })},[_c('am2-heading',{attrs:{"type":"h1","size":"sm","title":_vm.title,"weight":"bold","align":_vm.$arMediaQuery.pageContent.minWidth('sm') ? 'left' : 'center'}}),_vm._v(" "),_c('ar-text',{staticClass:"description",attrs:{"size":"xs","text":_vm.description,"align":_vm.$arMediaQuery.pageContent.maxWidth('xs') ? 'center' : 'left',"multiple-lines":""}})],1)]),_vm._v(" "),(_vm.smallImages)?_c('div',{staticClass:"left-section-item"},[_c('div',{staticClass:"icon"}),_vm._v(" "),_c('div',{staticClass:"context"},[_c('div',{staticClass:"display-images-section"},[_vm._l((_vm.displayedImages),function(img,idx){return _c('div',{key:idx,staticClass:"overlap-image-wrapper",style:({ zIndex: _vm.displayedImages.length - idx })},[_c('img',{staticClass:"overlap-image",attrs:{"src":img.src}})])}),_vm._v(" "),_c('am2-heading',{class:[
              'images-section-subtitle',
              _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'u-display-none' ],attrs:{"type":"h2","size":"xs","title":_vm.smallImagesSubtitle}})],2)])]):_vm._e()]),_vm._v(" "),_c('div',{class:[
      'right-section',
      _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
      _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ]},[(_vm.failed && !_vm.loading)?_c('am2-tag',{class:[
          'failed-tag',
          _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'u-display-none' ],attrs:{"text":"Deauthorised","type":"red"}}):_vm._e(),_vm._v(" "),_c('ar-simple-button',{attrs:{"outlined":_vm.connected,"text":_vm.connected ? 'Manage'
                : _vm.failed ? 'Reconnect' : 'Connect',"loading":_vm.loading,"disabled":_vm.disabled},on:{"click":_vm.handleButtonClick}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }