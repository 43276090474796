<template>
  <div class="ar-hero-wrapper">
    <div class="ar-content">
      <a class="back-link" @click="handleBackClick">
        <ar-icon
          name="arrow"
          :rotate="90"
        />
        <span class="text" :style="{ top: isFirefox ? '0' : '-1px' }">{{stepBackText}}</span>
      </a>

      <div class="message-title-wrapper">
        <ar-icon
          v-if="providerIcon"
          :name="providerIcon.name"
          :color="providerIcon.color"
          :height="providerIcon.height"
          :width="providerIcon.width"
          :wrapper-style="{
            backgroundColor: '#FFF',
            padding: providerIcon.padding,
            borderRadius: '50%',
            marginRight: '12px',
            boxShadow: `0px 0px 6px 2px ${rgbaColor($arStyle.color.blueGrey400, 0.2)}`
          }"
        />
        <am2-heading
          class="message-heading"
          type="h1"
          size="xl"
          :title="title"
          :style="{ color: 'white' }"
          v-tooltip.top="{
                content: title,
                classes: ['longTooltip'],
              }"
        />
      </div>

      <div class="message-hero-stats-wrapper" :class="statsLoadedClass">
        <div
          class="message-hero-stat"
          v-tooltip.top="{
            content: 'Date sent'
          }"
          :style="{
            cursor: 'default',
          }"
        >
          <ar-icon
            height="16px"
            name="clock"
            :color="$arStyle.color.skyBlueGrey500"
            :style="{
              marginRight: '7px',
            }"
          />
          <ar-text
            :text="scheduledAtText"
            size="xs"
            :style="{
              color: `${$arStyle.color.skyBlueGrey500}`,
            }"
          />
        </div>
        <div
          class="message-hero-stat"
          v-if="!isSms"
          v-tooltip.top="{
            content: 'Sent from',
          }"
          :style="{
            cursor: 'default',
          }"
        >
          <ar-icon
            height="16px"
            name="flag"
            :color="$arStyle.color.skyBlueGrey500"
            :style="{
              marginRight: '7px'
            }"
          />
          <ar-text
            :text="messageProviderText"
            size="xs"
            :style="{
              color: `${$arStyle.color.skyBlueGrey500}`,
            }"
          />
        </div>
        <div
          class="message-hero-stat"
          v-tooltip.top="{
            content: messageListTooltip,
          }"
          :style="{
            cursor: 'pointer',
          }"
          @click="handleListClick"
        >
          <ar-icon
            height="16px"
            name="user"
            :color="$arStyle.color.skyBlueGrey500"
            :style="{
              marginRight: '7px'
            }"
          />
          <ar-text
            :text="`${messageListName} • ${fansTotal}`"
            size="xs"
            :style="{
              color: `${$arStyle.color.skyBlueGrey500}`,
            }"
          />
        </div>
        <div
          class="message-hero-stat"
          v-if="costOfMessage"
          v-tooltip.top="{
            content: 'This is how much it cost to send your SMS message.',
          }"
          :style="{
            cursor: 'default',
          }"
        >
          <ar-icon
            name="money"
            height="17px"
            :color="$arStyle.color.skyBlueGrey500"
          />
          <ar-text
            size="xs"
            :text="costOfMessage"
            :style="{
              marginLeft: '7px',
              color: `${$arStyle.color.skyBlueGrey500}`
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { hexToRGBA } from '@/utils/helpers';
import { backToText } from '@/utils/route';
import accounting from 'accounting';

export default {
  name: 'MessageViewHero',
  props: {
    providerIcon: {
      type: Object,
      default: null
    },
    message: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    scheduledAtText: {
      type: String,
      default: null
    },
    messageProviderText: {
      type: String,
      default: null
    },
    messageListName: {
      type: String,
      default: null
    },
    messageListFansTotal: {
      type: Number,
      default: 0,
    },
    costOfMessage: {
      type: String,
      default: null
    },
    isSms: {
      type: Boolean,
      default: false
    },
    isFirefox: {
      type: Boolean,
      default: false
    },
    targetingString: {
      type: String,
      default: null
    },
    backPath: {
      type: String,
      default: '/message-center'
    }
  },
  data() {
    return {
      statsReady: false
    }
  },
  computed: {
    statsLoadedClass() {
      return this.statsReady && 'stats-loaded'
    },
    messageListTooltip() {
      if (!this.targetingString) return 'Sent to list';
      return `Sent to list with targeting - ${this.targetingString}`;
    },
    stepBackText() {
      return backToText(this.backPath);
    },
    fansTotal() {
      return accounting.formatNumber(this.messageListFansTotal);
    },
  },
  methods: {
    handleBackClick() {
      this.$emit('back-click')
    },
    handleListClick() {
      this.$emit('list-click')
    },
    rgbaColor(color, opacity) {
      return hexToRGBA(color, opacity)
    },
  },
  mounted() {
    this.statsReady = true
  }
}
</script>
<style lang="scss" scoped>
.ar-hero-wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  background: $purple800;
  padding: 50px 0;

  .ar-content {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 90%;
    height: 100%;
    max-width: 1200px;

    .back-link {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      color: #fff;
      line-height: 1;
      align-items: center;
      cursor: pointer;

      .text {
        margin-bottom: 2px;
        line-height: 1;
        margin-left: 9px;
      }
    }

    .message-hero-stats-wrapper {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      margin-top: 36px;
      opacity: 0;
      transition: opacity 0.5s ease;

      &.stats-loaded {
        opacity: 1;
      }

      .message-hero-stat {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        margin-right: 20px;
        padding: 5px 0;
      }
    }

    .message-title-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      margin-top: 70px;

      .message-heading {
        width: calc(100% - 80px);
        min-width: 295px;

        @media only screen and (min-width: $sm-min) {
          width: calc(100% - 80px);
        }
        @media only screen and (min-width: $md-min) {
          width: calc(100% - 270px);
          min-width: 550px;
        }
        @media only screen and (min-width: $xl-min) {
          width: calc(100% - 490px);
          min-width: 710px;
        }
      }
    }
  }
}
</style>
