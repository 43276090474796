<template>
  <portal
    to="modal"
    :disabled="!isShow"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="isShow"
      class="message-sender-modal"
      :header="header"
      width="600px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
      v-click-outside="handleOutsideClick"
      @click="handleClickingOnModal"
    >
      <div slot="body" class="body">
        <div :class="[
          'modal-content',
            $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]">

          <!-- Email -->
          <div class="settings-card">
            <section
              v-if="!emailVerified && !isMessageSenderCreation"
              class="email-unverified"
            >
              <!-- Your From Email is awaiting verification, you Rebel scum -->
              <ar-snackbar
                type="error"
                message="Please verify this email address. <a>Resend verification email</a>."
                @anchorClick="retryEmailVerification"
                :style="{ width: '100%' }"
              />
            </section>
            <div class="additional-info-section">
              <ar-field
                class="u-margin-bottom-6"
                label="From Email"
                :error-messages="veeErrors.first('emailAddress')"
              >
                <ar-input
                  id="senderEmail"
                  :value="scratchEmailSender.property"
                  @input="(val) => { handleScratchEmailSenderPatch({ property: val }) }"
                  placeholder="example@email-provider.com"
                  :disabled="!isMessageSenderCreation"
                  v-validate="{
                    required: true,
                    email: true,
                  }"
                  autocomplete="email"
                  data-vv-name="emailAddress"
                  data-vv-as="email address"
                />
              </ar-field>
              <ar-field
                class="u-margin-bottom-6"
                label="From Name"
                :error-messages="veeErrors.first('senderName')"
              >
                <ar-input
                  id="senderName"
                  :value="scratchEmailSender.additionalInfo.senderName"
                  @input="(val) => { handleScratchEmailSenderPatch({ additionalInfo: { senderName: val } }) }"
                  placeholder=""
                  v-validate="`required`"
                  autocomplete="organization"
                  :auto-focus="autoFocusOn === 'sender-name'"
                  data-vv-name="senderName"
                  data-vv-as="sender name"
                />
              </ar-field>
              <ar-field
                class="u-margin-bottom-6"
                label="Business Address"
                :error-messages="veeErrors.first('businessAddress')"
              >
                <ar-input
                  id="senderBusinessAddress"
                  :value="scratchEmailSender.additionalInfo.businessAddress"
                  @input="(val) => { handleScratchEmailSenderPatch({ additionalInfo: { businessAddress: val } }) }"
                  placeholder=""
                  v-validate="`required`"
                  autocomplete="address"
                  :auto-focus="autoFocusOn === 'business-address'"
                  data-vv-name="businessAddress"
                  data-vv-as="business address"
                />
              </ar-field>
            </div>
            <ar-state-message
              v-if="messageSender"
              class="u-margin-y-5"
              :text-props="{
                weight: 'bold',
              }"
              has-icon
              disable-text-color
              type="information"
              text="Note: Updates are applied to all lists where email address is used."
            />
          </div>
        </div>
      </div>
      <div slot="footer" :class="[
        'footer',
        $arMediaQuery.window.maxWidth('xs') && 'u-padding-3',
      ]">
        <div
          v-if="!isDeletingMessageSender && !isCreatingMessageSender && !isPatchingMessageSenders"
          class="sender-cancel-button-wrapper"
          @click="handleCloseClick"
        >
          <ar-link-button            
            text="Cancel"
            class="u-margin-right-5"
          />
        </div>

        <ar-simple-button
          v-if="!isMessageSenderCreation && enableDelete"
          text="Delete"
          type="red"
          outlined
          :style="{ width: '117px', height: '42px', }"
          text-weight="normal"
          class="u-margin-right-3"
          :disabled="isPatchingMessageSenders"
          :loading="isDeletingMessageSender"
          @click="handleDeleteClick"
        />
        <div>
          <ar-simple-button   
            v-if="isMessageSenderCreation"   
            text="Create"
            :disabled="!isScratchEmailSenderComplete"
            :loading="isCreatingMessageSender"
            :style="{ width: '117px', height: '42px', }"
            @click="handleCreateClick"
          />
          <ar-simple-button
            v-else
            text="Update"
            :disabled="!isScratchEmailSenderComplete"
            :loading="isPatchingMessageSenders"
            @click="handleUpdateClick"
            :style="{ width: '117px', height: '42px', }"
          />
        </div>

      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';

export default {
  name: 'MessageSenderModal',
  components: {},
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    messageSender: {
      type: Object,
      default: () => null,
    },
    mode: {
      type: String,
      default: 'edit',
      validator: (val) => ['create', 'edit'].indexOf(val) > -1,
    },
    enableDelete: {
      type: Boolean,
      default: true,
    },
    autoFocusOn: {
      type: String,
      default: null,
      validator: (val) => ['sender-name', 'business-address'].indexOf(val) > -1,
    },
    header: {
      type: String,
      default: 'Email Settings',
    }
  },

  computed: {
    ...mapState({
      scratchEmailSender: state => state.messageSender.scratchEmailSender,
      isCreatingMessageSender: state => state.messageSender.isCreatingMessageSender,
      isPatchingMessageSenders: state => state.messageSender.isPatchingMessageSenders,
      isDeletingMessageSender: state => state.messageSender.isDeletingMessageSender,
    }),
    ...mapGetters({
      isScratchEmailSenderComplete: 'messageSender/isScratchEmailSenderComplete'
    }),
    isMessageSenderCreation() {
      return this.mode === 'create';
    },
    emailVerified() {
      if (!this.messageSender) { return false; }
      else return this.messageSender.verified;
    },
  },

  watch: {
    isShow(val) {
      if (val) {
        this.initializeEditValues();
      }
    },
  },

  methods: {
    ...mapActions([
      'messageSender/UPDATE_MESSAGE_SENDER',
      'messageSender/DELETE_MESSAGE_SENDER',
      'messageSender/ADD_MESSAGE_SENDER',
      'messageSender/RESEND_EMAIL_VERIFICATION',
    ]),
    ...mapMutations([
      'messageSender/SET_SCRATCH_EMAIL_SENDER',
      'messageSender/RESET_SCRATCH_EMAIL_SENDER',
      'messageSender/PATCH_SCRATCH_EMAIL_SENDER',
    ]),
    initializeEditValues() {
      // For email setting
      if (this.messageSender) {
        this['messageSender/SET_SCRATCH_EMAIL_SENDER'](this.messageSender);
      } else {
        this['messageSender/RESET_SCRATCH_EMAIL_SENDER']();
      }
    },

    retryEmailVerification() {
      if (!this.messageSender) return;
      this['messageSender/RESEND_EMAIL_VERIFICATION'](this.messageSender.oid);
    },

    handleScratchEmailSenderPatch(changes) {
      this['messageSender/PATCH_SCRATCH_EMAIL_SENDER'](changes);
    },

    async handleCreateClick() {
      const isValid = await this.$validator.validateAll();
      if (!isValid) {
        return;
      }
      const newSender = await this['messageSender/ADD_MESSAGE_SENDER'](this.scratchEmailSender);
      if (newSender) {
        this.$emit('create', newSender);
      }
    },

    async handleUpdateClick() {
      const isValid = await this.$validator.validateAll();
      if (!isValid) {
        return;
      }
      const updatedSender = await this['messageSender/UPDATE_MESSAGE_SENDER'](this.scratchEmailSender);
      if (updatedSender) {
        this.$emit('update', updatedSender);
      }
    },

    async handleDeleteClick() {
      const succeed = await this['messageSender/DELETE_MESSAGE_SENDER'](this.messageSender.oid);
      if (succeed) {
        this.$emit('delete');
      }
    },

    handleCloseClick() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
.message-sender-modal {
  .header {
    font-size: 20px;
  }
  .body {
    .modal-content {
       padding: 29px 30px;

      .header-section {
        display: flex;
        justify-content: space-between;
        flex-wrap:wrap;
        align-items: center;

        .title-section {
          display: inline-flex;
          align-items: center;
        }
      }

      .additional-info-section {
        margin: 0 0 12px;
      }

      .email-unverified {
        padding-bottom: 39px;
      }

      .settings-card {
        border-bottom: 1px solid $skyBlueGrey500;
        &:last-child {
          border-bottom: none;
        }
      }

    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px 25px;

    .sender-cancel-button-wrapper {
      cursor: pointer;
      align-self: center;
    }

    .sender-create-button-wrapper {
      cursor: pointer;
    }
  }
}
</style>
