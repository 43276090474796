<template>
  <section
    :class="[
      'wrapper',
      'content',
      'content--centered',
      $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
      $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
    ]">
    <am2-ask-memberful-client-credentials-modal
      :is-show="showAskMemberfulCredentialsModal"
      :loading="isCreatingMemberfulIntegration"
      @confirm="handleMemberfulClientCredentialsConfirm"
      @cancel="showAskMemberfulCredentialsModal = false"
    />
    <am2-step-link
      class="step-back-link"
      text="Back to integrations"
      @click="handleBackLinkClick" />
    <div
      :class="[
        'title-section',
        $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
      ]">
      <div class="title-section-left">
        <am2-icon-title-section
          title="Memberful"
          description="Automatically sync your members from Memberful"
          :icon-props="{
            name: 'memberful',
            color: $arStyle.color.memberful,
            height: '46px',
            width: '40px',
          }"
        />
      </div>
      <div class="title-section-right" v-if="!isFetchingMemberfulIntegration && integrationsSummary">
        <am2-integration-account-select
          v-if="!isFetchingMemberfulIntegration && memberfulIntegration && currentIntegration"
          :default-selected-integration-oid="currentIntegration.oid"
          :available-integrations="integrationsSummary"
          @select="handleIntegrationChange"
          @new-account="handleAddNewIntegration"
          :style="{ marginRight: '10px', maxWidth: 'calc(100vw - 80px)', }"
        />
        <am2-expand-button-dropdown
          align="left"
          :button-props="{ iconName: 'settings' }"
          :items="[
            {
              name: 'Open Memberful',
              value: 'goToMemberfulSite',
            },
            {
              name: 'Reconnect Integration',
              value: 'reconnectIntegration',
            },
            {
              name: 'Remove Account',
              value: 'removeIntegration',
            },
          ]"
          @select="handleSettingOptionSelect" />
      </div>
    </div>


    <section v-if="isFetchingMemberfulIntegration" class="integration-loading-container">
      <am2-loading-bubble />
    </section>
    <section v-else>
      <am2-tabs
        data-test-id="memberful-tabs"
        :items="[
          { title: 'Webhook URL' },
          { title: 'Status' },
        ]"
        :selected-tab-index="selectedTabIndex"
        class="tab-options"
        @select="(selected, idx) => selectedTabIndex = idx"
      />
      <section v-if="integrationFailed" class="integration-failed-message">
        <ar-snackbar
          type="warning"
          message="Your Memberful connection is no longer valid. This means we can't sync your member data. <a>Please reconnect your account</a>"
          @anchorClick="handleReconnectIntegration"
          :style="{ width: '100%' }"
        />
      </section>
      <section v-else>
        <div v-if="selectedTabIndex === 0">
          <am2-card-container class="webhook-container">
            <ar-text
              class="u-margin-bottom-6"
              size="xs"
              text="Copy the URL below to add a new Webhook inside your Memberful Account Settings."
              align="center"
              allow-html
              multiple-lines
              line-height="28px"
              :style="{
                color: $arStyle.color.blueGrey700,
                maxWidth: '590px',
                marginBottom: '50px',
              }"
            />

            <am2-new-slug-input
              data-test-id="memberful-tab-webhook-url-input"
              :value="webhookUrl"
              readonly
              :style="{
                maxWidth: '708px',
              }"
            />

            <div class="read-more-container u-margin-bottom-6">
              <ar-text
                size="xxxs"
                text="Read more about "
                :style="{
                  color: $arStyle.color.skyBlueGrey700,
                }"
              />
              <ar-link-button
                data-test-id="memberful-tab-setup-support-link"
                class="u-margin-left-1"
                text="how to add a webhook to your Memberful account"
                :has-underline="true"
                @click="handleSetupClick"
                :text-props="{
                  size: 'xxxs'
                }"
              />
            </div>
          </am2-card-container>
        </div>
        <div v-else>
          <SyncBlockContainer
            data-test-id="memberful-status-tab-sync"
            class="sync-block-container"
            :is-fetching-sync-task="isFetchingSyncTask"
            :sync-status="syncStatus"
            :sync-time="syncTime"
            :sync-copies="{
              'in-progress': 'Syncing data from Memberful',
              'pending': 'The data sync is still pending',
              'completed': 'Your Memberful account is currently connected',
              'cancelled': 'Your Memberful account connection is cancelled',
              'failed': this.syncErrorMessage,
              'default': 'Syncing tasks not found',
            }"
            @startSync="handleStartSync"
            @stopSync="handleStopSync"
          />
        </div>
      </section>
    </section>

  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import SyncBlockContainer from '../components/sync-block-container';

export default {
  name: 'MemberfulIntegration',
  layout: 'default',
  components: {
    SyncBlockContainer,
  },

  data: () => ({
    currentlySelectedIntegrationIndex: 0,
    syncStatusPoller: null,
    showAskMemberfulCredentialsModal: false,
    selectedTabIndex: 0,
    arCampaignApiDomain: process.env.arCampaignApiDomain,
    arCampaignApiBaseUriPrefix: process.env.arCampaignApiBaseUriPrefix,
    setupUrl: "https://support.audiencerepublic.com/en/articles/5722323-create-an-api-key-for-memberful",
  }),

  computed: {
    ...mapState({
      memberfulIntegration: state => state.memberfulIntegration.integration,
      isFetchingMemberfulIntegration: state => state.memberfulIntegration.isFetchingIntegration,
      isCreatingMemberfulIntegration: state => state.memberfulIntegration.isCreatingIntegration,
      isDeletingMemberfulIntegration: state => state.memberfulIntegration.isDeletingIntegration,
      isFetchingSyncTask: state => state.memberfulIntegration.isFetchingSyncTask,
      syncTask: state => state.memberfulIntegration.syncTask,
      syncStatus: state => state.memberfulIntegration.syncStatus,
    }),

    memberfulIntegrationEnabled() {
      return process.env.arEnableMemberfulIntegration;
    },

    syncErrorMessage() {
      return this.syncTask?.statusDetails?.lastError || null;
    },

    syncTime() {
      return this.syncTask?.sysMtime || null;
    },

    integrationsSummary() {
      if (!this.memberfulIntegration) return null;
      return this.memberfulIntegration
        .filter(i => i.status !== 'failed')
        .map(i => ({
          oid: i.oid,
          name: i.integration?.subdomain,
          email: i.integration?.subdomain,
        }));
    },

    currentIntegration() {
      if (this.currentlySelectedIntegrationIndex === null) return null;
      if (!this.memberfulIntegration || this.memberfulIntegration.length < 1 || !this.memberfulIntegration[this.currentlySelectedIntegrationIndex]) return null;
      return this.memberfulIntegration[this.currentlySelectedIntegrationIndex];
    },

    integrationFailed() {
      if (!this.currentIntegration) return false;
      return this.currentIntegration.status === 'failed';
    },

    webhookUrl() {
      if (!this.currentIntegration || !this.currentIntegration.integration) return null;
      const encodedEncryptedPromoterIntegrationOid = encodeURIComponent(this.currentIntegration.integration.encryptedPromoterIntegrationOid)
      return `${this.arCampaignApiDomain}${this.arCampaignApiBaseUriPrefix}/memberful-events-webhook?piref=${encodedEncryptedPromoterIntegrationOid}`
    }
  },

  async mounted() {
    if (!this.memberfulIntegrationEnabled) {
      this.handleBackLinkClick();
    }
    await this.fetchIntegration();
    this.startFetchTasksPolling();
  },

  beforeDestroy() {
    this['memberfulIntegration/RESET_INTEGRATION']();

    if (this.syncStatusPoller) {
      clearInterval(this.syncStatusPoller);
    }
  },

  methods: {
    ...mapActions([
      'SHOW_CONFIRM',
      'memberfulIntegration/FETCH_INTEGRATION',
      'memberfulIntegration/DELETE_INTEGRATION',
      'memberfulIntegration/SYNC_START',
      'memberfulIntegration/SYNC_STOP',
      'memberfulIntegration/FETCH_SYNC_TASK',
      'memberfulIntegration/CREATE_MEMBERFUL_INTEGRATION',
      'promoterTasks/START_POLLING_PENDING_TASKS',
    ]),
    ...mapMutations([
      'memberfulIntegration/RESET_INTEGRATION',
    ]),
    startFetchTasksPolling() {
      if (this.currentIntegration?.oid && !this.isFetchingSyncTask) {
        this.fetchTasks();
        this.syncStatusPoller = setInterval(this.fetchTasks, 3000);
      }
    },
    async fetchIntegration() {
      await this['memberfulIntegration/FETCH_INTEGRATION']();
      if (this.memberfulIntegration.length === 0) {
        this.$router.push({
          path: '/settings/integrations',
        });
      }
    },

    async fetchTasks() {
      await this['memberfulIntegration/FETCH_SYNC_TASK'](this.currentIntegration.oid);

      if (this.currentIntegration && this.hasExistingTask && this.syncStatus !== "in-progress") {
        window.clearInterval(this.syncStatusPoller);
        this.syncStatusPoller = null;
        this['promoterTasks/START_POLLING_PENDING_TASKS']({});
      }
    },

    hasExistingTask() {
      return this.syncTask !== null;
    },

    handleBackLinkClick() {
      this.$router.push({ path: '/settings/integrations' });
    },

    handleIntegrationChange(integration, index) {
      this.currentlySelectedIntegrationIndex = index;
      this.fetchTasks();
    },

    async handleMemberfulClientCredentialsConfirm({ apiKey, siteName }) {
      const succeed = await this['memberfulIntegration/CREATE_MEMBERFUL_INTEGRATION']({
        apiKey,
        siteName,
      });
      if (succeed) {
        this.$router.push({
          path: `/settings/integrations/memberful`,
        });
        this.showAskMemberfulCredentialsModal = false;
      }
    },

    async handleAddNewIntegration() {
      this.showAskMemberfulCredentialsModal = true;
    },

    async handleStartSync() {
      setTimeout(() => {
        this['promoterTasks/START_POLLING_PENDING_TASKS']({});
      }, 750);
      await this['memberfulIntegration/SYNC_START']({ oid: this.currentIntegration.oid });
      if (this.syncStatus !== 'completed') {
        this.syncStatusPoller = setInterval(this.fetchTasks, 3000);
      }
    },

    handleStopSync() {
      this['memberfulIntegration/SYNC_STOP']({oid: this.currentIntegration.oid});
      setTimeout(() => {
        this['promoterTasks/START_POLLING_PENDING_TASKS']({});
      }, 750);
    },

    async handleReconnectIntegration() {
      const agreed = await this.SHOW_CONFIRM({
        messageHtml: `Make sure you are logging in with the Memberful account <strong>${this.currentIntegration.integration.user.attributes.email}</strong> on Memberful before reconnecting it.`,
        confirmButtonText: 'Reconnect account',
      });
      if (!agreed) {
        return;
      }

      const succeed = await this['memberfulIntegration/DELETE_INTEGRATION'](this.currentIntegration.oid);
      if (!succeed) {
        return;
      }

      this.showAskMemberfulCredentialsModal = true;
    },

    async handleSettingOptionSelect(item) {
      if (item.value === 'removeIntegration') {
        const answer = await this.SHOW_CONFIRM({
          messageHtml: 'Removing the Memberful integration will stop syncing your orders and customer data from Memberful.',
          confirmButtonText: 'Remove integration',
          iconName: 'alert-question-mark',
          title: 'Are you sure?',
        });
        if (answer) {
          try {
            await this['memberfulIntegration/DELETE_INTEGRATION'](this.currentIntegration.oid);
            if (this.memberfulIntegration.length > 0) {
              this.currentlySelectedIntegrationIndex = 0;
              await this.fetchIntegration();
              this.startFetchTasksPolling();
            } else {
              this.$router.push({ path: '/settings/integrations' });
            }
          } catch (e) {
            this.$arNotification.push({ type: 'error', message: 'Failed to remove integration' });
          }
        }
      } else if (item.value === 'reconnectIntegration') {
        this.handleReconnectIntegration();
      } else if (item.value === 'goToMemberfulSite') {
        if (!this.currentIntegration) return;
        var win = window.open('https://www.memberful.com/creator-home', '_blank');
        win.focus();
      }
    },

    handleSetupClick() {
      window.open(this.setupUrl, '_blank');
    },
  }
};
</script>

<style lang="scss" scoped>
.wrapper {

  &.sm-max {
    padding: 0 24px;
  }
  &.xs-max {
    padding: 0 12px;
  }

  .integration-loading-container {
    display: flex;
    justify-content: center;
    margin-top: 65px;
  }

  .tab-options {
    margin: 60px 0 20px 0;
    border-bottom: 1px $skyBlueGrey500 solid;
  }


  .step-back-link {
    margin-top: 48px;
  }

  .title-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 48px;

    .title-section-left {
      display: inline-flex;
      align-items: flex-start;

      .tag {
        position: relative;
        margin-left: 10px;
        top: 4px;
      }
    }

    .title-section-right {
      display: inline-flex;
      align-items: center;

      .select {
        width: 300px;
        margin-right: 10px;
      }
    }

    &.md-max {
      flex-direction: column;
      align-items: flex-start;

      .title-section-right {
        margin-top:16px;
      }
    }
  }


  .sync-block-container {
    margin-top:64px;
  }

  .webhook-container {
    margin-top:64px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 85px;
    padding-bottom: 70px;
    flex-direction: column;
    width: 100%;

    .read-more-container {
      margin-top: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
