<template>
  <portal to="modal" :disabled="!opened" :style="{ position: 'absolute' }">
    <ar-modal
      :is-open="opened"
      header="Design your email"
      :width="`${restricted ? '728' : '1068'}px`"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleModalClose"
      class="design-email-modal"
      :data-test-id="templateType"
    >
      <div slot="body">
        <div  class="body">
          <div 
            v-for="card in designCards" 
            :key="card.title" 
            class="design-email-card" 
            :class="card.class" 
            @click="card.clickHandler"
          >
            <img :src="card.image" />
            <ar-text class="title" size="sm" weight="bold" :text="card.title" />
            <ar-text class="subtitle" multiple-lines size="xs" :text="card.subtitle" />
          </div>
        </div>
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import {mapGetters} from 'vuex'
import htmlEmailImg from '@/assets/images/message-center/html-email.png'
import designFromScratchImg from '@/assets/images/message-center/design-from-scratch.png'
import useANewTemplateImg from '@/assets/images/message-center/use-a-new-template.png'
import useATemplateImg from '@/assets/images/message-center/use-a-template.png'

export default {
  name: 'DesignEmailModal',
  components: {},
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    showHtmlEmail: {
      type: Boolean,
      default: false,
    },
    templateType: {
      type: String,
      default: 'beefree',
    }
  },
  data() {
    return {
      template: null,
      emailHtml: null,
      showSelectEmailTemplateSection: false,
    };
  },

  computed: {
    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled',
    }),

    isAbEnabled() {
      return this.isFeatureEnabled(['messages', 'abEmails'])
    },

    designCards() {
      const base = [
        {
          class: 'design-from-scratch',
          image: designFromScratchImg,
          title: 'Design from scratch',
          subtitle: 'Start with a blank canvas if you already have a design in mind.',
          clickHandler: this.handleDesignFromScratchClick
        },
        {
          class: 'template-section',
          image: this.templateType === 'beefree' ? useANewTemplateImg : useATemplateImg,
          title: this.templateType === 'beefree' ? 
            'Use a NEW template' : 
            'Use a template',
          subtitle: this.templateType === 'beefree' ? 
            'Select a template to open in the new email builder.' : 
            'Select from your saved templates or get start with one of ours.',
          clickHandler: this.handleTemplateClick
        }
      ];

      if (!this.showHtmlEmail) {
        return base
      }

      return [
        ...base,
        {
          class: 'html-email',
          image: htmlEmailImg,
          title: 'Import HTML',
          subtitle: 'Add your custom HTML email with externally served assets.',
          clickHandler: this.handleImportHtmlClick
        },
      ];
    },

    restricted() {
      return this.designCards.length === 2;
    }
  },

  watch: {
    opened(newVal) {
      if (newVal) {
        this.initEmailEditor();
      }
    }
  },

  methods: {
    initEmailEditor() {},
    handleModalClose() {
      this.$emit('close');
    },
    handleDesignFromScratchClick() {
      this.$emit('designFromScratch');
    },
    handleTemplateClick() {
      this.$emit('template');
    },
    handleImportHtmlClick() {
      this.$emit('importHtml');
    }
  }
};
</script>

<style lang="scss" scoped>
.design-email-modal {
  .body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    margin: 48px;

    .title {
      margin-bottom: 6px;
    }
    .subtitle {
      color: $skyBlueGrey700;
    }

    .design-email-card {
      cursor: pointer;
      border: 1px solid $blueGrey500;
      border-radius: 4px;
      background-color: #FFF;

      width: 292px;
      padding: 24px;

      img {
        margin-bottom: 16px;
      }

      &:hover {
        color: $purple500;
        border: 1px solid $purple500;
        box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.07);
        .title {
          color: $purple500;
        }
        .subtitle {
          color: $blueGrey800;
        }
      }
    }

    .new-email-builder-tag {
      position: relative;
      top: 12px;
      left: 12px;
      height: 0px !important;

      &-text {
        background-color: $purple500;
        font-size: 12px;
        color: #FFF;
        padding: 2px 6px 2px 6px;
        font-weight: 500;
        border-radius: 3px;
      }
    }
  }

  .notification {
    margin: 0 48px 48px 48px;
  }
}
</style>
