<template>
  <section class="events-view">
    <!-- Viewing the event -->
    <template>
      <am2-hero-skeleton
        v-if="isFetchingEvent"
        type="hero"
        :back-path="sanitizedReferringPage"
        is-events
      />
      <am2-hero
        v-else-if="currentEvent"
        :image="eventImage"
        :title="currentEvent.name"
        :event-name="currentEvent.name"
        :timeframe="eventTimeframe"
        :location="currentEvent.location"
        :back-path="sanitizedReferringPage"
        is-events
        :urlSlug="getCurrentUrlSlug"
      />
      <am2-navbar
        :menu="menu"
        class="navigation-events">
        <div
          slot="controls"
          :class="[
            'buttons-section',
            $arMediaQuery.pageContent.maxWidth('sm') && 'md-max',
            $arMediaQuery.pageContent.minWidth('md') && 'md-min',
            $arMediaQuery.pageContent.minWidth('lg') && 'lg-min'
          ]">
          <div :class="[
          'buttons-section__main-buttons-group',
          ($arMediaQuery.pageContent.minWidth('lg') || optionsPaneVisible) && 'is-visible',
          ]">
            <am2-create-campaign-button
              :text="$arMediaQuery.pageContent.minWidth('lg') ? 'Create campaign' : 'New campaign'"
              outlined
              :style="{ marginRight: '8px' }"
              :campaignArgs="{
                eventOid: currentEvent && currentEvent.oid,
                skipEventSelection: true,
              }"
              data-test-id="create-campaign-button-events-nav"
            />
            <ar-simple-button
              v-if="hasEventbriteIntegration && isPermissionEnabled(['view-sales'])"
              :text="$arMediaQuery.pageContent.minWidth('lg') ? 'Sync ticket sales' : 'Sync sales'"
              :loading="isSyncing"
              @click="syncWithIntegrations"
              :style="{ marginRight: '8px' }"
            />
          </div>
          <div class="buttons-section__secondary-buttons-group">
            <ar-simple-button
              v-if="$arMediaQuery.pageContent.maxWidth('md')"
              :icon-name="optionsPaneVisible ? 'arrow' : 'edit'"
              :text="showOptionsText"
              :iconProps="{
                rotate: optionsPaneVisible && $arMediaQuery.pageContent.minWidth('md') ? -90 : null,
              }"
              :style="{
                marginRight: optionsPaneVisible && $arMediaQuery.pageContent.minWidth('md') ? '0' : '8px',
                height: '50px',
                transition: '0.3s',
                padding: '0 18px',
              }"
              type="grey"
              @click="handleOptionsClick"
            />
            <am2-split-dropdown
              v-if="arEnableMessageFromEvent"
              text="New Message"
              class="campaign-button"
              toggle-dropdown-on-button-click
              :items="messageButtonOptions"
              icon-name="dialog"
              icon-distance="12px"
              :style="{
                width: '196px',
              }"
              @select="(item) => item.action()"
            />
            <am2-event-dropdown
              v-if="currentEvent && currentEvent.oid && (!optionsPaneVisible || $arMediaQuery.pageContent.maxWidth('sm') || $arMediaQuery.pageContent.minWidth('lg'))"
              :oid="currentEvent.oid"
              :event="currentEvent"
              :name="currentEvent.name"
              title="Settings"
              kind="expand-icon-button"
              :preventExpand="!$arMediaQuery.pageContent.minWidth('md')"
              @ticketSalesDelete="refreshEventData"
              @delete="handleEventDelete"
              :style="{ marginLeft: '0' }"
            />
          </div>
        </div>
      </am2-navbar>

      <section
        v-if="hasPendingTasks"
        :class="['content', $arMediaQuery.pageContent.minWidth('md') && 'md-min']"
        :style="{paddingBottom: 0}">
        <ar-snackbar
          type="warning"
          :message="pendingTasksString"
          :style="{ marginTop: '10px' }"
        />
      </section>


      <nuxt-child
        :class="['content', $arMediaQuery.pageContent.minWidth('md') && 'md-min']"
        :event="currentEvent"
        :is-fetching-event="isFetchingEvent"
        @navbarAction="runActionFromChild"
      />
    </template>

  </section>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';

import { displayDateRangeUS } from '@/utils/date/';
import {
  isSyncing,
} from '@/utils/event';

export default {
  name: 'Event',
  layout: 'default',

  scrollToTop: true,

  data: () => ({
    dontShowEventWarningAgain: false,
    syncStatusPoller: null,
    optionsPaneVisible: false,
    arEnableMessageFromEvent: process.env.arEnableMessageFromEvent,
  }),

  computed: {
    ...mapState({
      oid: state => parseInt(state.route.params.oid, 10),
      currentEvent: state => state.event.currentEvent,
      isFetchingEvent: state => state.event.isFetchingEvent,
    }),
    ...mapGetters({
      eventHasIntegration: 'event/eventHasIntegration',
      pendingTasksForEvent: 'promoterTasks/pendingTasksForEvent',
      pendingImportTasksForEvent: 'promoterTasks/pendingImportTasksForEvent',
      pendingExportTasksForEvent: 'promoterTasks/pendingExportTasksForEvent',
      pendingDeletePosOrderTasksForEvent: 'promoterTasks/pendingDeletePosOrderTasksForEvent',
      pendingDeleteEventTicketSalesTasksForEvent: 'promoterTasks/pendingDeleteEventTicketSalesTasksForEvent',
      historicalPreviousPagePath: 'application/historicalPreviousPagePath',
      isPermissionEnabled: 'auth/isPermissionEnabled',
    }),
    sanitizedReferringPage() {
      return this.historicalPreviousPagePath('/events');
    },
    hasPendingTasks() {
      return this.pendingTasksForEvent(this.oid).length > 0;
    },
    pendingTasksString() {
      if (this.pendingExportTasksForEvent(this.oid).length === 0 &&
        this.pendingImportTasksForEvent(this.oid).length > 0) {
        return "Import in progress. Your sales will be added to this event soon.";
      }
      if (this.pendingExportTasksForEvent(this.oid).length > 0 &&
        this.pendingImportTasksForEvent(this.oid).length === 0) {
        return "Export in progress. You'll receive an email soon.";
      }
      const tasks = [];
      if (this.pendingImportTasksForEvent(this.oid).length > 0) tasks.push('CSV import');
      if (this.pendingExportTasksForEvent(this.oid).length > 0) tasks.push('CSV export');
      if (this.pendingDeleteEventTicketSalesTasksForEvent(this.oid).length > 0) tasks.push('Clearing event ticket sales');
      if (this.pendingDeletePosOrderTasksForEvent(this.oid).length > 0) tasks.push('Clearing POS Orders');

      let taskString = ""

      if (tasks.length === 0) {
        return null
      } else {
        if (tasks.length > 1) {
          const exceptLastTask = tasks.slice(0, -1)
          taskString = `${exceptLastTask.join(', ')} and `
        }

        taskString += tasks[tasks.length - 1] // Might be last or only task
      }

      return `${taskString} currently in progress`
    },
    menu() {
      let menu = {};

      if (this.isPermissionEnabled(['view-sales'])) {
        menu['ticket-sales'] = {
          name: 'Ticket Sales',
          to: `/events/${this.$route.params.oid}/view/sales`,
          featureKeys: null,
        }
      }

      if (this.getCurrentUrlSlug != null) {
        menu.attendees = {
          name: 'Attendees',
          to: `/events/${this.$route.params.oid}/view/attendees`,
          featureKeys: null,
        }
      } else if (this.isPermissionEnabled(['view-sales'])) {
        menu.orders = {
          name: 'Orders',
          to: `/events/${this.$route.params.oid}/view/orders`,
          featureKeys: ['orders'],
        };
      }

      return {
        ...menu,
        campaigns: {
          name: 'Campaigns',
          to: `/events/${this.$route.params.oid}/view/campaigns`,
          featureKeys: null,
        },
        insights: {
          name: 'Insights',
          to: `/events/${this.$route.params.oid}/view/insights`,
          featureKeys: ['insights'],
        }
      }
    },

    showOptionsText() {
      if (this.optionsPaneVisible && this.$arMediaQuery.pageContent.minWidth('md')) {
        return '';
      }

      if(this.optionsPaneVisible && this.$arMediaQuery.pageContent.maxWidth('sm')) {
        return 'Hide options'
      }

      return 'Show options';
    },

    eventImage() {
      if (!this.currentEvent) {
        return null;
      }
      const image =
        this.currentEvent.resources &&
        this.currentEvent.resources.find(resource => resource.assetType === 'event-image');

      return image && image.url;
    },

    eventTimeframe() {
      return displayDateRangeUS(this.currentEvent.startDate, this.currentEvent.endDate, this.currentEvent.timeZone)
    },

    hasEventbriteIntegration() {
      return this.eventHasIntegration(this.currentEvent, 'eventbrite');
    },

    isSyncing() {
      if (!this.currentEvent) {
        return false;
      }
      return isSyncing(this.currentEvent);
    },

    getCurrentUrlSlug() {
      if (!this.currentEvent || !this.currentEvent.campaigns) {
        return null;
      }

      const rsvpCampaign = this.currentEvent.campaigns.find((event) => {
        return event.type == "rsvp"
      })

      if (rsvpCampaign) {
        return rsvpCampaign.urlSlug
      } else {
        return null
      }
    },


    messageButtonOptions() {
      const options = [];
      const emailEnabled = true;

      if (emailEnabled) {
        options.push({
          name: 'Email',
          icon: {
            name: 'email',
            color: this.$arStyle.color.email,
            height: '14px',
            width: '19px',
            wrapperStyle: {
              width: '19px',
              marginRight: '12px',
            }
          },
          action: this.handleNewEmailMessageClick,
        });
      }

      options.push({
        name: 'SMS',
        icon: {
          name: 'sms',
          color: this.$arStyle.color.sms,
          height: '19px',
          width: '18px',
          wrapperStyle: {
            width: '19px',
            marginRight: '12px',
          }
        },
        action: this.handleNewSmsMessageClick,
      });

      return options;
    },

  },

  async mounted() {
    this['auth/FETCH_PERMISSIONS']()
    await this.fetchEvent();

    // Do it after we fecthEvent
    if　(this.isSyncing && !this.syncStatusPoller) {
      this.syncStatusPoller = setInterval(this.updateSyncObject, 3000);
    }
    this.checkPendingTasks();

  },

  beforeDestroy() {
    if (this.syncStatusPoller !== null) {
      clearInterval(this.syncStatusPoller);
      this.syncPoller = null;
    }
    this['event/RESET_CURRENT_EVENT']();
  },

  methods: {
    ...mapActions([
      // 'OPEN_IMPORT_EVENT_DATA_MODAL',
      'START_EVENT_SYNC',
      'FORCE_REFETCH_DATA',
      'event/FETCH_CURRENT_EVENT',
      'promoterTasks/START_POLLING_PENDING_TASKS',
      'auth/FETCH_PERMISSIONS',
    ]),

    ...mapMutations([
      'FORCED_EVENT_UPDATE',
      'event/RESET_CURRENT_EVENT',
    ]),

    runActionFromChild(action, key) {
      if (action === 'SYNC_EVENT') {
        this.syncWithIntegrations();
      }
    },

    async fetchEvent() {
      await this['event/FETCH_CURRENT_EVENT'](this.$route.params.oid);
    },

    importTicketSales() {
      this.OPEN_IMPORT_EVENT_DATA_MODAL({
        eventOid: this.currentEvent.oid,
        eventTimeZone: this.currentEvent.timeZone,
      });
    },

    async updateSyncObject() {
      const succeed = await this['event/FETCH_CURRENT_EVENT'](this.currentEvent.oid);
      if (!succeed) {
        return;
      }

      if (!this.isSyncing) {
        window.clearInterval(this.syncStatusPoller);
        this.syncStatusPoller = null;

        // to update stats/audience data in child components
        this.FORCE_REFETCH_DATA();
      }
    },

    async refreshEventData() {
      const succeed = await this['event/FETCH_CURRENT_EVENT'](this.currentEvent.oid);
      if (!succeed) {
        return;
      }

      // using this in-lieu of using Vuex
      // to send mutations to update the children
      this.FORCE_REFETCH_DATA();
    },

    async syncWithIntegrations() {
      const result = await this.START_EVENT_SYNC(
        this.currentEvent.oid,
      );

      if (result.accepted) {
        this.currentEvent = result.event;
        this['promoterTasks/START_POLLING_PENDING_TASKS']({});

        if (!this.syncStatusPoller) {
          this.syncStatusPoller = setInterval(this.updateSyncObject, 3000);
        }
      } else {
        this.$arNotification.push({
          type: 'error',
          message: result.message,
        })
      }
    },

    handleEventDelete() {
      this.$router.push({
        path: `/events`,
      });
    },

    handleOptionsClick() {
      this.optionsPaneVisible = !this.optionsPaneVisible;
    },


    async handleNewEmailMessageClick() {
      if (this.$arMediaQuery.window.maxWidth('xs')) {
        const response = await this.SHOW_CONFIRM({
          messageHtml: `You are able to create and edit emails using your desktop or tablet device.`,
          hideConfirmButton: true,
          cancelButtonText: 'Back',
        });
      } else {
        this.$router.push({
          path: `/message-center/messages/email/new/basic`,
          query: {
            eventOid: this.currentEvent.oid,
          },
        });
      }
    },
    handleNewSmsMessageClick() {
      this.$router.push({
        path: `/message-center/messages/sms/new`,
        query: {
          eventOid: this.currentEvent.oid,
        },
      });
    },

    checkPendingTasks() {
      this['promoterTasks/START_POLLING_PENDING_TASKS']({
        reload: true,
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.events-view {
  .buttons-section {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-flow: column-reverse;
    position:relative;

    button,
    section {
      margin-bottom: 8px;
    }

    &.md-max {
      min-height:72px;

      .buttons-section__main-buttons-group {
        z-index: $zIndexHigh;
        min-width:300px;
        overflow:hidden;
        transition: 0.25s;
        width: 100%;
        opacity: 0;
        max-height:0;
        &.is-visible {
          opacity:1;
          max-height:100px;
        }

      }
    }

    &.md-min {
      align-items: flex-end;

      button,
      section {
        margin-bottom: 0;
      }

      .buttons-section__main-buttons-group {
        position: absolute;
        right: 50px;
        top: 0;
        display: flex;
        justify-content: flex-end;
        z-index: $zIndexHigh;
        overflow:hidden;
        width: 100%;
        max-width:0;
        opacity: 0.5;
        min-width:0;
        transition: 0.3s;
        &.is-visible {
          opacity:1;
          min-width:300px;
          max-width:none;
        }
      }
    }
    &.lg-min {
      flex-flow: row;

      .buttons-section__main-buttons-group {
        position: static;
        min-width: 0;
      }
    }


    &__secondary-buttons-group {
      z-index: $zIndexRegular;
      display:flex;
      min-height:50px;
    }
  }
}
.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px 12px;

  &.md-min {
    padding: 30px;
  }
}
.navigation-events {
  &__controls-container {
    display: flex;
    flex-direction: row;
  }
}

</style>
