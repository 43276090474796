<template>
  <section class="wrapper content content--centered">
    <am2-step-link
      class="step-back-link"
      text="Back to integrations"
      @click="handleBackLinkClick"
    />
    <div :class="[
      'title-section',
      $arMediaQuery.pageContent.minWidth('lg') && 'lg-min',
      $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
    ]">
      <div class="title-section-left">
        <am2-icon-title-section
          title="Facebook Custom Audiences"
          description="Create custom audiences to target with Facebook Ads"
          :text-size="$arMediaQuery.pageContent.minWidth('md') ? 'lg' : 'md'"
          :multiple-lines-title="$arMediaQuery.pageContent.minWidth('md') ? false : true"
          :icon-props="{
            name: 'facebook',
          }"
        />
        <!-- temporarily comment it out <am2-tag
          class="tag"
          :text="`${syncedFiltersCount} of ${filters.length} linked`"
          type="purple" /> -->
      </div>
      <div class="title-section-right" v-if="!integrationFailed">
        <ar-simple-select
          class="select"
          enable-filter
          placeholder="Select ad accounts"
          :items="adAccountItems"
          :default-select-index="selectedAdAccountIndex"
          @select="handleAdAccountSelect"
          :style="{ maxWidth: 'calc(100vw - 80px)', }" />
        <am2-expand-button-dropdown
          align="left"
          :button-props="{ iconName: 'settings' }"
          :items="[
            {
              name: 'Open Facebook',
              value: 'GO_TO_FACEBOOK',
            },
            {
              name: 'Refresh Ad Accounts',
              value: 'REFRESH_INTEGRATION',
            },
            {
              name: 'Reconnect Account',
              value: 'RECONNECT_INTEGRATION',
            },
            {
              name: 'Remove Account',
              value: 'DELETE_CUSTOM_INTEGRATION',
            },
          ]"
          @select="handleSettingsOptionSelect"
        />
      </div>
    </div>
    <section
      v-if="integrationFailed"
      :style="{ paddingTop: '55px' }"
    >
      <ar-snackbar
        type="warning"
        message="Your Facebook Custom Audience connection is no longer valid. This means you won't be able to sync your Custom Audiences with filters <a>Please reconnect your account</a>"
        @anchorClick="reconnectIntegration"
        :style="{ width: '100%' }"
      />
    </section>
    <section v-else>
      <am2-tabs
        class="tabs"
        :items="[
          {
            iconName: 'hourglass',
            title: 'Segments',
          },
        ]"
      />
      <ar-divider :style="{ margin: '0 0 39px' }" />
      <section :class="[
        'search-section',
        $arMediaQuery.pageContent.minWidth('md') && 'md-min',
      ]">
        <span class="display-filter-count">Showing <b>{{ filtersByName.length }} segments</b></span>
        <am2-search
          class="search"
          v-model="searchString"
          placeholder="Search segments"
          @blur="searchString = searchString.trim()" />
      </section>
      <!-- Remember, we only want to show the status of Custom Audiences with the active Ad Account -->
      <section class="filter-cards-section">
        <FilterIntegrationCard
          v-for="filter of filtersByName"
          class="filter-card"
          :key="filter.oid"
          :title="filter.name"
          :action-items="getFilterActionItems(filter.oid)"
          :sync-status="getCustomAudienceSyncStatus(filter.oid)"
          :synced-date="getCustomAudienceAttribute(filter.oid, 'lastSyncDate') && generateTimeInformation(getCustomAudienceAttribute(filter.oid, 'lastSyncDate'))"
          :synced-error="getCustomAudienceAttribute(filter.oid, 'error') && getCustomAudienceAttribute(filter.oid, 'error').errorUserTitle"
          :synced-error-details="getCustomAudienceAttribute(filter.oid, 'error') && getCustomAudienceAttribute(filter.oid, 'error').errorUserMsg"
          :disabled="selectedAdAccount ? false : true"
          :card-size="$arMediaQuery.pageContent.minWidth('sm') ? 'regular' : 'xs'"
          @buttonClick="handleSyncFilterIntegrationButtonClick(filter)"
          @itemSelect="handleFilterIntegrationItemSelect(
            filter.oid,
            getCustomAudienceAttribute(filter.oid, 'id'),
            ...arguments
          )"
          :data-test-id="`filter card - ${filter.name}`"
        />
      </section>
    </section>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import checkoutPageAccessibility from '@/mixins/checkoutPageAccessibility';
import moment from 'moment';
import { clone } from '@/utils/helpers/';
import { urlify } from '@/utils/html-element/';

import FilterIntegrationCard from './components/filter-integration-card';

// CONSTANTS
const INDEX_ONLY = true;
// const TAB_OPTIONS = ;

export default {
  name: 'FacebookCustomAudiences',
  layout: 'default',
  mixins: [
    checkoutPageAccessibility({ featureKeys: ['integrations', 'facebookCustomAudience'], featureName: 'Facebook Custom Audience' }),
  ],

  components: {
    FilterIntegrationCard,
  },

  data: () => ({
    allIntegrations: [],
    integration: null,
    filters: [],
    searchString: '',
    selectedAdAccountIndex: 0,
    isPollingIntegrationResource: true,
  }),

  computed: {
    ...mapState({
      savedSegmentList: state => state.segment.savedSegmentList,
    }),
    // Filters by searchstring
    filtersByName() {
      if (this.searchString) {
        return this.savedSegmentList.filter((filter) =>
          filter.name.toLowerCase().indexOf(this.searchString.trim().toLowerCase()) > -1
        );
      } else {
        return this.savedSegmentList;
      }
    },
    // AdAccount items for ar-select
    adAccountItems() {
      if (!this.integration || !this.integration.integration || !this.integration.integration.adAccounts) {
        return [];
      }
      return this.integration.integration.adAccounts.map(adAccount => ({
        name: `Ad account:\n${adAccount.name} (${adAccount.accountId})`,
        adAccount,
      }));
    },
    // AdAccount selected
    selectedAdAccount() {
      if (!this.adAccountItems[this.selectedAdAccountIndex]) {
        return null;
      }
      return this.adAccountItems[this.selectedAdAccountIndex].adAccount;
    },
    selectedAdAccountId() {
      return this.selectedAdAccount ? this.selectedAdAccount.id : null;
    },
    customAudiences() {
      if (!this.integration || !this.integration.integration || !this.integration.integration.customAudiences) {
        return [];
      } else {
        return this.integration.integration.customAudiences;
      }
    },
    syncingCustomAudiences() {
      return this.customAudiences.filter(ca => ca.syncing);
    },
    removingCustomAudiences() {
      return this.customAudiences.filter(ca => ca.removing);
    },
    customAudiencesNeedsPolling() {
      return this.syncingCustomAudiences.length > 0 || this.removingCustomAudiences.length > 0;
    },
    syncedFiltersCount() {
      return this.customAudiences.filter(ca =>
        !!ca.lastSyncDate && ca.adAccountId === this.selectedAdAccountId
      ).length;
    },
    integrationFailed() {
      // Don't prematurely mark the integration as
      // 'failed' if it hasn't finished 'loading' in yet.
      return this.integration ? this.integration.status === 'failed' : false;
    },
  },

  async mounted() {
    this['segment/FETCH_SEGMENTS']({});
    await this.fetchIntegrations();

    // Keep checking if there is any syncing or removing Custom Audiences
    if (this.customAudiencesNeedsPolling) {
      this.customAudiencesPolling = setInterval(this.fetchCustomAudiences, 3000);
    }

    // It may take a while to get initial integration information after you connect
    this.startPollingCompleteIntegration();
  },

  beforeDestroy() {
    clearInterval(this.customAudiencesPolling);
    clearInterval(this.fetchCompleteIntegrationInterval);
  },

  methods: {
    ...mapActions([
      'SHOW_CONFIRM',
      'FETCH_INTEGRATIONS',
      'FETCH_INTEGRATION',
      'UPDATE_INTEGRATION',
      'DELETE_INTEGRATION',
      'segment/FETCH_SEGMENTS',
      'CREATE_CUSTOM_AUDIENCE',
      'DELETE_CUSTOM_AUDIENCE',
      'CONNECT_TO_INTEGRATION',
    ]),

    async fetchIntegrations() {
      try {
        const facebookCustomAudiencesIntegrations = await this.FETCH_INTEGRATIONS({
          top: 1,
          filters: {
            expressions: [{
              key: 'provider',
              value: 'facebook',
              operator: '=',
            }, {
              key: 'app',
              value: 'custom-audiences',
              operator: '=',
            }],
            logicalOperators: ['AND'],
          },
          orderby: "sysMtime desc",
        });

        this.allIntegrations = facebookCustomAudiencesIntegrations;
        this.integration = this.allIntegrations[0];
      } catch(e) {
        this.$arNotification.push({ type: 'error', message: 'Failed to fetch Custom Audiences' });
      }
    },

    startPollingCompleteIntegration() {
      this.isPollingIntegrationResource = true;

      this.fetchCompleteIntegrationInterval = setInterval(async () => {
        // If status says 'completed', it's completed
        if (this.integration.status !== 'in-progress') {
          clearInterval(this.fetchCompleteIntegrationInterval);
          this.isPollingIntegrationResource = false;

          // If there's message from Facebook, display it
          if (this.integration.integration.message) {
            this.displayBusinessMessage();
            this.isPollingIntegrationResource = false;
            return;
          }

          return;
        }

        this.integration = await this.FETCH_INTEGRATION(this.integration.oid);
      }, 1000);
    },

    getCustomAudienceSyncStatus(filterOid) {
      const customAudience = this.getCustomAudienceByFilterOid(filterOid);
      if (!customAudience) {
        return 'not-synced';
      } else if (customAudience.removing) {
        return 'removing';
      } else if (customAudience.lastSyncDate) {
        return 'synced';
      } else if (customAudience.failed) {
        return 'failed';
      } else if (customAudience.syncing) {
        return 'syncing';
      }
      return 'not-synced';
    },

    getCustomAudienceAttribute(filterOid, key) {
      const customAudience = this.getCustomAudienceByFilterOid(filterOid);
      return customAudience && customAudience[key];
    },

    getCustomAudienceByFilterOidAndAdAccountId(filterOid, adAccountId, justIndex = false) {
      const predicate = ca => ca.filterGroupOid === filterOid && ca.adAccountId === adAccountId;
      if (justIndex) {
        return this.customAudiences.findIndex(predicate);
      } else {
        return this.customAudiences.find(predicate);
      }
    },

    getCustomAudienceByFilterOid(filterOid, justIndex = false) {
      const predicate = ca => ca.filterGroupOid === filterOid && ca.adAccountId === this.selectedAdAccountId;
      if (justIndex) {
        return this.customAudiences.findIndex(predicate);
      } else {
        return this.customAudiences.find(predicate);
      }
    },

    getCustomAudienceById(customAudienceId, justIndex = false) {
      const predicate = ca => ca.id === customAudienceId;
      if (justIndex) {
        return this.customAudiences.findIndex(predicate);
      } else {
        return this.customAudiences.find(predicate);
      }
    },

    getFilterActionItems(filterGroupOid) {
      if (this.getCustomAudienceAttribute(filterGroupOid, 'lastSyncDate')) {
        return [
          {
            name: 'Remove',
            value: 'REMOVE',
          },
          {
            name: 'View Audience',
            value: 'VIEW_AUDIENCE',
          },
        ];
      } else {
        return [
          {
            name: 'View Audience',
            value: 'VIEW_AUDIENCE',
          },
        ];
      }
    },

    generateTimeInformation(date) {
      const secDiff = moment().diff(moment(date), 'seconds');

      if (secDiff < 60) {
        return 'Last synced a few seconds ago';
      } else if (secDiff < 900) {
        return 'Last synced a few minutes ago';
      } else if (secDiff < 2800) {
        return 'Last synced half an hour ago';
      } else if (secDiff < 4500) {
        return 'Last synced one hour ago';
      } else if (secDiff < 86400) {
        return 'Last synced a few hours ago';
      } else if (secDiff < 864000) {
        return 'Last synced a few days ago';
      } else {
        return `Last synced at ${moment(date).format('D MMM YYYY')}`
      }

      return secDiff;
    },

    async displayBusinessMessage() {
      const answer = await this.SHOW_CONFIRM({
        messageHtml: this.integration.integration.message,
        iconName: 'facebook',
        confirmButtonText: 'Go to Facebook Business',
      });
      if (answer) {
        window.open('https://business.facebook.com', '_blank');
      }
    },

    async fetchCustomAudiences() {
      if (
        !this.integration.integration ||
        (
          this.syncingCustomAudiences.length === 0 &&
          this.removingCustomAudiences.length === 0
        )
      ) {
        if (this.customAudiencesPolling) {
          clearInterval(this.customAudiencesPolling);
        }
        return;
      }
      const newIntegration = await this.FETCH_INTEGRATION(this.integration.oid);
      const newCustomAudiences = newIntegration.integration.customAudiences;

      newCustomAudiences.forEach(newCustomAudience => {
        const idx = this.getCustomAudienceByFilterOidAndAdAccountId(
          newCustomAudience.filterGroupOid,
          newCustomAudience.adAccountId,
          INDEX_ONLY
        );

        if (idx > -1) {
          this.$set(
            this.integration.integration.customAudiences,
            idx,
            newCustomAudience,
          );

          // Explain why CA sync failed if possible
          if (newCustomAudience.failed) {

            // If user didn't tick Terms & Conditions on Audience Manager AdAccount, guide them to
            if (newCustomAudience.error.errorSubcode === 1870034) {
              let messageHtml = newCustomAudience.error.errorUserMsg;
              // Annoying issue, remove the last '.' to avoid the wrong URL parsing
              if (messageHtml.lastIndexOf('.') === messageHtml.length - 1) {
                messageHtml = messageHtml.substring(0, messageHtml.length - 1);
              }
              this.SHOW_CONFIRM({
                messageHtml: urlify(messageHtml, {}),
                iconName: 'facebook',
                confirmButtonText: 'Confirm',
              });
            } else if (newCustomAudience.error.errorSubcode === 1870054) {
              this.SHOW_CONFIRM({
                title: 'You got an error from Facebook Custom Audience',
                messageHtml: `${newCustomAudience.error.message}<br/>Facebook error traceId: <b>${newCustomAudience.error.fbtraceId}</b>`,
                iconName: 'facebook',
                confirmButtonText: 'Confirm',
              });
            }
          }
        }
      });

      // Then we check if the customAudience has been removed
      this.removingCustomAudiences.forEach(caToRemove => {
        let predicate = ca => ca.id === caToRemove.id;
        if (newCustomAudiences.find(predicate) === undefined) {
          this.$delete(
            this.integration.integration.customAudiences,
            this.getCustomAudienceById(caToRemove.id, INDEX_ONLY),
          );
        }
      });
    },

    async refreshIntegration() {
      try {
        await this.UPDATE_INTEGRATION({
          oid: this.integration.oid,
          data: {
            /**
             * CAUTION!!!!!
             * Really important, we only want to keep `CustomAudiences` when we refresh
             * */
            integration: {
              account: null,
              adAccounts: null,
              customAudiences: this.integration.integration.customAudiences,
            },
          },
        });

        // Remember to fetch integrations again and also have a poll to check if it's ready
        await this.fetchIntegrations();
        this.startPollingCompleteIntegration();
        this.$arNotification.push({ type: 'success', message: 'Facebook Custom Audience Ad Accounts successfully refreshed!' });
      } catch (e) {
        this.$arNotification.push({ type: 'error', message: 'Failed to refresh ad accounts' });
      }
    },

    async handleSyncFilterIntegrationButtonClick(filter) {
      const filterOid = filter.oid;

      // In case it's undefined
      if (!this.integration.integration.customAudiences) {
        this.$set(this.integration.integration, 'customAudiences', []);
      }

      const existingCustomAudienceIdx = this.getCustomAudienceByFilterOid(filterOid, INDEX_ONLY);

      try {
        await this.CREATE_CUSTOM_AUDIENCE({
          adAccountId: this.selectedAdAccount.id,
          filterGroupOid: filterOid,
          integrationOid: this.integration.oid,
        });

        // Add CustomAudience with status 'syncing'
        // Or replace existing one if the custom audience with 'filterOid' already exitsts
        this.$set(
          this.integration.integration.customAudiences,
          existingCustomAudienceIdx > -1 ? existingCustomAudienceIdx : this.integration.integration.customAudiences.length,
          {
            adAccountId: this.selectedAdAccount.id,
            filterGroupOid: filterOid,
            syncing: true,
          }
        );

        clearInterval(this.customAudiencesPolling);
        this.customAudiencesPolling = setInterval(this.fetchCustomAudiences, 3000);
      } catch (e) {
        this.$arNotification.push({ type: 'error', message: 'Failed to sync custom audience' });
      }
    },

    async handleFilterIntegrationItemSelect(filterOid, customAudienceId, item) {
      if (item.value === 'VIEW_AUDIENCE') {
        this.$router.push({ path: '/audience', query: { filterOid } });
      } else if (item.value === 'REMOVE') {
        // Flag the customAudience to removing
        const customAudienceIdx = this.getCustomAudienceById(customAudienceId, INDEX_ONLY);

        try {
          await this.DELETE_CUSTOM_AUDIENCE({ integrationOid: this.integration.oid, customAudienceId });
          this.$set(this.integration.integration.customAudiences[customAudienceIdx], 'removing', true);
          clearInterval(this.customAudiencesPolling);
          this.customAudiencesPolling = setInterval(this.fetchCustomAudiences, 3000);
        } catch (e) {
          this.$arNotification.push({ type: 'error', message: 'Failed to remove custom audience' });
        }
      }
    },

    handleAdAccountSelect(item, index) {
      this.selectedAdAccountIndex = index;

      if (this.customAudiencesNeedsPolling) {
        clearInterval(this.customAudiencesPolling);
        this.customAudiencesPolling = setInterval(this.fetchCustomAudiences, 3000);
      }
    },

    async handleSettingsOptionSelect(item) {
      if (item.value === 'GO_TO_FACEBOOK') {
        if (this.adAccountItems && this.selectedAdAccountIndex >= 0 && this.adAccountItems[this.selectedAdAccountIndex] &&
            this.adAccountItems[this.selectedAdAccountIndex].adAccount.accountId) {
          window.open(`https://business.facebook.com/asset-library/audience/${this.adAccountItems[this.selectedAdAccountIndex].adAccount.accountId}/?business_id=${this.adAccountItems[this.selectedAdAccountIndex].adAccount.business.id}` , '_blank');
        } else {
          window.open('https://business.facebook.com', '_blank');
        }
      } else if (item.value === 'DELETE_CUSTOM_INTEGRATION') {
        const answer = await this.SHOW_CONFIRM({
          messageHtml: 'Removing the Facebook Custom Audiences integration will stop you from messaging fans on Facebook through Audience Republic',
          confirmButtonText: 'Remove integration',
          iconName: 'alert-question-mark',
          title: 'Are you sure?',
        });
        if (answer) {
          try {
            await this.DELETE_INTEGRATION(this.integration.oid);
            this.$router.push({ path: '/settings/integrations' });
          } catch (e) {
            this.$arNotification.push({ type: 'error', message: 'Failed to remove Facebook Custom Audience account' });
          }
        }
      } else if (item.value === 'REFRESH_INTEGRATION') {
        this.refreshIntegration();
      } else if (item.value === 'RECONNECT_INTEGRATION') {
        this.reconnectIntegration();
      }
    },

    async reconnectIntegration() {
      await this.DELETE_INTEGRATION(this.integration.oid);
      const res = await this.CONNECT_TO_INTEGRATION({
        provider: 'facebook',
        app: 'custom-audiences',
      });
      this.fetchIntegrations();
      this.startPollingCompleteIntegration();
      this.$arNotification.push({ type: 'success', message: 'Facebook Custom Audience account successfully reconnected!' });
    },

    handleBackLinkClick() {
      this.$router.push({ path: '/settings/integrations' });
    },
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  .step-back-link {
    margin-top: 48px;
  }

  .title-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 48px;

    &.md-max {
      flex-direction: column;
      align-items: flex-start;

      .title-section-right {
        margin-top: 20px;
      }
    }

    .title-section-left {
      display: inline-flex;
      align-items: flex-start;
      max-width: 100%;

      .tag {
        position: relative;
        margin-left: 10px;
        top: 4px;
      }
    }

    .title-section-right {
      display: inline-flex;
      align-items: center;

      .select {
        width: 300px;
        margin-right: 10px;
      }
    }

    &.lg-min {
      flex-direction:row;

      .title-section-right {
        align-self: center;
        padding-top:0;
      }
    }
  }

  .tabs {
    margin-top: 60px;
  }

  .search-section {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;

    .display-filter-count {
      font-size: 14px;
      line-height: 1;
    }

    .search {
      width: 381px;
      max-width: 100%;
      margin-top: 12px;
    }

    &.md-min {
      flex-direction: row;
      align-items: center;

      .search {
        margin-top: 0;
      }
    }
  }

  .filter-cards-section {
    margin-top: 20px;

    .filter-card {
      margin-bottom: 10px;
    }
  }
}
</style>
