<template>
  <article class="actionblock-container">
    <header class="actionblock-header">
      <div class="actionblock-title-container">
        <am2-heading type="h1" size="md" :title="title" />
        <ar-icon
          v-if="help"
          name="tooltip-question-mark"
          class="u-margin-top-1 u-margin-left-2 tooltip-icon"
          :color="$arStyle.color.blueGrey500"
          height="16px"
          width="16px"
          :data-test-id="`action-block-title-icon-${titleInKebabCase}`"
          v-tooltip.top="{
            content: help,
          }"
        />
      </div>
      <ar-text class="h4-actionvalue" size="md" :text="formatNumber(channels.total) || 0" />
    </header>
    <ul class="actions-list">
      <li v-for="(item, key) in orderedChannels()" :key="key">
        <ActionField
          :name="item.name"
          :target="item.target"
          :identifier="item.identifier"
          :amount="formatNumber(item.total)"
          :link="item.link"
          :class="{ 'has-identifier': item.identifier !== undefined }"
        />
      </li>
    </ul>
    <ar-modal
      :is-open="showFullChannelListModal"
      :header="title"
      hide-footer
      @close="showFullChannelListModal = false"
      width="590px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
    >
      <div slot="body">
        <ul class="actions-list modal">
          <li v-for="(item, key) in orderedChannels(true)" :key="key">
            <ActionField
              :name="item.name"
              :pretty-name="item.prettyName"
              :target="item.target"
              :identifier="item.identifier"
              :amount="formatNumber(item.total)"
              :link="item.link"
            />
          </li>
        </ul>
      </div>
    </ar-modal>
    <div v-if="showSeeAllOption">
      <ar-divider
        :style="{
          margin: '39px 0 8px 0',
        }"
      />
      <ar-link-button
        text="See all platforms"
        :style="{
          marginTop: '4px',
        }"
        @click="showFullChannelList"
      />
    </div>
  </article>
</template>

<script>
import accounting from 'accounting';
import ActionField from '@/components/ui/molecules/field/actionfield/ActionField';
import {clone, isURL} from '@/utils/helpers';

export default {
  name: 'ActionBlock',
  components: {
    ActionField,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    help: {
      type: String,
      default: null,
    },
    channels: {
      type: Object,
      default: () => ({
        channels: {},
      }),
    },
    defaultOrder: {
      type: Array,
      default: () => [],
    },
    showItemsCutOffThreshold: {
      type: Number,
      default: 5,
    },
  },

  data() {
    return {
      showFullChannelListModal: false,
    };
  },

  computed: {
    showSeeAllOption() {
      if (this.title === "Shares") return true;
      return this.channels.targets ? this.channels.targets.length > this.showItemsCutOffThreshold : false;
    },
    titleInKebabCase() {
      if (!this.title) {
        return '';
      }
      return this.$arUtils.general.generateDataTestPrettyName(this.title);
    },
  },

  methods: {
    formatNumber(number) {
      return accounting.formatNumber(number);
    },

    extractGeneric(channelNode) {
      const targetsSeparated = channelNode.target.split(':');
      let identifier = targetsSeparated[targetsSeparated.length - 1];
      let maybeUrl = decodeURIComponent(identifier);
      let name = channelNode.channel;
      return {
        targetsSeparated,
        identifier,
        maybeUrl,
        name
      }
    },

    // Facebook channel can include pages, events or messenger
    extractFacebook(channelNode) {
      let {targetsSeparated, identifier, maybeUrl, name} = this.extractGeneric(channelNode);
      const type = targetsSeparated[2];

      // sometimes its a url,
      // sometimes its an id
      // (fb events always url)
      if (type === 'event') {
        identifier = type;

        // Facebook Events must be presented as "Facebook event" not as just "Facebook"
        // Add an exceptoin for this, it's a bit barbaric this code and will probably need
        // to be corrected in the back-end at some point. Some kind of UI presentation feature.
        name = 'facebook event';
        identifier = channelNode.additionalInfo.title || 'Link to event';
      } else if (type === 'messenger') {
        name = 'messenger';

        // JNM: This is really ugly, but the idea for messenger is the server will put a href
        // field into the additional info JSON blob which we can use as URL target for the facebook action.
        if (channelNode.additionalInfo) {
          identifier = channelNode.additionalInfo.title || targetsSeparated[3];
          maybeUrl = channelNode.additionalInfo.href;
        } else {
          identifier = targetsSeparated[3];
          maybeUrl = `https://www.facebook.com/${identifier}`;
        }
      } else {
        if (isURL(maybeUrl)) {
          // strip out everything, except for
          // whats after the last '/' (greedy)
          identifier = maybeUrl.replace(/^.*\//, '');
        } else {
          identifier = maybeUrl;
        }
      }
      return {
        name: name,
        prettyName: name,
        identifier: this.channels.type === 'follow' ? identifier : null,
        target: channelNode.target,
        total: channelNode.total || 0,
        link: isURL(maybeUrl) ? maybeUrl : null,
      }
    },

    extractSpotify(channelNode) {
      let {targetsSeparated, identifier, maybeUrl, name} = this.extractGeneric(channelNode);
      identifier = (channelNode.additionalInfo && channelNode.additionalInfo.title) || targetsSeparated[2];
      return {
        name: name,
        prettyName: name,
        identifier: this.channels.type === 'follow' ? identifier : null,
        target: channelNode.target,
        total: channelNode.total || 0,
        link: isURL(maybeUrl) ? maybeUrl : null,
      }
    },

    extractTwitter(channelNode) {
      let {targetsSeparated, identifier, maybeUrl, name} = this.extractGeneric(channelNode);
      if (isURL(maybeUrl) && decodeURIComponent(identifier) === maybeUrl) {
        identifier = 'Link to user';
      } else {
        // assume url that fits template string {channel-name}.com/{identifier}
        maybeUrl = `https://${channelNode.channel}.com/${identifier}`;
      }
      const isInvite = targetsSeparated[1] === 'invite';
      const isShare = targetsSeparated[1] === 'share' || channelNode.type === 'share';

      let prettyName;
      if (isInvite) {
        prettyName = `${name} (invite)`
      } else if (isShare) {
        prettyName = `${name} (share)`
      } else {
        prettyName = name;
      }

      return {
        name: name,
        prettyName,
        identifier: this.channels.type === 'follow' ? identifier : null,
        target: channelNode.target,
        total: channelNode.total || 0,
        link: isURL(maybeUrl) ? maybeUrl : null,
      }
    },

    extractOther(channelNode) {
      let {targetsSeparated, identifier, maybeUrl, name} = this.extractGeneric(channelNode);
      if (isURL(maybeUrl) && decodeURIComponent(identifier) === maybeUrl) {
        identifier = 'Link to user';
      } else {
        // assume url that fits template string {channel-name}.com/{identifier}
        maybeUrl =
          channelNode.channel === 'youtube'
            ? `https://${channelNode.channel}.com/channel/${identifier}`
            : `https://${channelNode.channel}.com/${identifier}`;
      }
      return {
        name: name,
        prettyName: name,
        identifier: this.channels.type === 'follow' ? identifier : null,
        target: channelNode.target,
        total: channelNode.total || 0,
        link: isURL(maybeUrl) ? maybeUrl : null,
      }
    },


    calculateResultForFullList(targetGroup) {
      const channel = targetGroup.channel;
      let name, prettyName, identifier, target, total, link;
      if (channel === 'facebook') {
        ({name, prettyName, identifier, target, total, link} = this.extractFacebook(targetGroup))
      } else if (channel === 'spotify') {
        ({name, prettyName, identifier, target, total, link} = this.extractSpotify(targetGroup))
      } else if (channel === 'twitter') {
        ({name, prettyName, identifier, target, total, link} = this.extractTwitter(targetGroup))
      } else {
        ({name, prettyName, identifier, target, total, link} = this.extractOther(targetGroup))
      }
      return {
        name,
        prettyName,
        identifier,
        target,
        total,
        link,
      }
    },

    orderedChannels(showAll) {
      const priorityList = this.defaultOrder;
      let resultsList = [];

      // Fallback for when targets not exists
      // Early exit so that we don't have gigantic if/else statements
      if (!this.channels.targets) {
        priorityList.forEach((channel) => {
          resultsList.push({
            name: channel,
            target: channel.target,
            total: 0,
          });
        });
        return showAll ? resultsList : resultsList.slice(0, this.showItemsCutOffThreshold);
      }

      // Otherwise when showAll is true, show a list sorted by popularity of channel
      const clonedTargets = clone(this.channels.targets)
      const sortedTargets = clonedTargets.sort( (a, b) => {
        return a.total > b.total ? -1 : 1;
      });

      sortedTargets.forEach( item => {
        resultsList.push(this.calculateResultForFullList(item));
      })

      // Fill in remainder from priorityList
      priorityList.forEach( channel => {
        const alreadyAdded = resultsList.some((r) => r.name.includes(channel));
        if (alreadyAdded) return;
        resultsList.push({
          name: channel,
          target: channel.target,
          total: 0,
        });
      })

      return showAll ? resultsList : resultsList.slice(0, this.showItemsCutOffThreshold);
    },
    showFullChannelList() {
      this.showFullChannelListModal = true;
    },
  },
};
</script>

<style lang="scss">
.actionblock-container {
  padding: 30px 30px 10px 30px;
  .actions-list {
    list-style: none;
    // border: 1px solid red;
    padding: 0;
    // text-align: left;
    li {
      & > div {
        margin: 0 0 19px;
      }
    }
  }
  .actionblock-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;

    .h4-actionvalue {
      color: $purple500;
    }
  }
  .actionblock-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .modal {
    padding: 30px;
  }
}
</style>
