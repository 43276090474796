<template>
  <EditableFrame
    class="send-email-wrapper"
    title="Edit Action"
    :subtitle="item.name"
    has-save-button
    :save-button-disabled="savedButtonDisabled"
    @close="$emit('close')"
    @save="handleSave"
  >
    <am2-email-editor-modal
      :opened="displayEmailEditorModal"
      location="automation"
      :template-type="emailEditorModalTemplateType"
      :default-template="emailTemplate"
      @update="handleEmailEditingUpdate"
      @cancel="handleEmailEditingCancel"
      @select="handleSelectEmailTemplateSelect"
      @setEmailEditor="setEmailEditorModalTemplateType"
    />
    <am2-email-preview-modal
      :opened="displayEmailPreviewModal"
      has-mode-tabs
      :subject="subject"
      :preview-text="previewText"
      :sender-name="senderName"
      :business-address="businessAddress"
      :email-html="emailHtmlWithInfo"
      @close="handleEmailPreviewClose"
    />
    <!-- Subject -->
    <ar-field
      :error-messages="[
        veeErrors.first('messageSender'),
      ]"
      data-test-id="automation-email-sender-field"
    >
      <div class="sender-section">
        <ar-text
          class="label"
          size="13px"
          text="SENDER"
          weight="bold"
          multiple-lines
          :max-lines="1"
          letter-spacing="1px"
        />
        <am2-message-sender-select
          class="sender-select"
          :value="messageSenderOid"
          v-validate="'required'"
          data-vv-name="messageSender"
          data-vv-as="message sender"
          @select="handleMessageSenderSelect"
        />
        <ar-snackbar
          v-if="messageSenderNotVerified"
          type="general"
          message="Please verify this email address. <a>Resend verification email</a>."
          @anchorClick="retryEmailVerification"
          :style="{
            marginTop: '10px',
            width: '100%',
          }"
        />
      </div>
    </ar-field>
    <!-- Subject -->
    <ar-field
      :error-messages="[
        veeErrors.first('subject'),
      ]"
      data-test-id="automation-email-subject-field"
    >
      <div class="subject-section">
        <div class="label-section">
          <ar-text
            class="label"
            size="13px"
            text="SUBJECT"
            weight="bold"
            multiple-lines
            :max-lines="1"
            letter-spacing="1px"
          />
          <ar-link-button
            v-if="!displayPreviewLine"
            text="Add preview line"
            @click="handleAddPreviewLineClick"
          />
        </div>
        <ar-input
          class="input"
          v-model="subject"
          v-validate="'required'"
          data-vv-name="subject"
          data-vv-as="subject"
          placeholder="Email subject"
          data-test-id="automation-email-subject-input-field"
        />
      </div>
    </ar-field>
    <!-- Preview Line -->
    <div
      v-if="displayPreviewLine"
      class="preview-line-section"
    >
      <div class="label-section">
        <ar-text
          class="label"
          size="13px"
          text="PREVIEW LINE"
          weight="bold"
          multiple-lines
          :max-lines="1"
          letter-spacing="1px"
        />
        <ar-link-button
          text="Remove preview line"
          @click="handleRemovePreviewLineClick"
          data-test-id="automation-email-remove-preview-link"
        />
      </div>
      <ar-input
        class="input"
        v-model="previewText"
        placeholder="Email preview line"
        data-test-id="automation-email-preview-input"
      />
    </div>
    <!-- Template -->
    <ar-field
      :error-messages="[
        veeErrors.first('emailTemplate'),
      ]"
      data-test-id="automation-email-design-field"
    >
      <div
        v-validate:emailHtmlBucketFieldsIsComplete="'required:true'"
        data-vv-name="emailTemplate"
        data-vv-as="email design"
      />
      <div
        class="design-section"
      >
        <div class="label-section">
          <ar-text
            class="label"
            size="13px"
            text="DESIGN"
            weight="bold"
            multiple-lines
            :max-lines="1"
            letter-spacing="1px"
          />
        </div>
        <template>
          <ar-skeleton
            v-if="isGeneratingEmailHtmlFromTemplate"
            class="html-preview"
            :style="{
              borderRadius: '3px',
            }"
          />
          <am2-html-preview
            v-else-if="emailHtml"
            class="html-preview"
            :html="emailHtmlWithInfo"
            enable-scroll
          />
        </template>
        <div class="action-buttons-section">
          <ar-simple-button
            class="launch-email-button"
            text="Launch email builder"
            outlined
            @click="handleLaunchEmailBuilderClick"
            data-test-id="automation-email-launch-builder-button"
          />
          <ar-simple-button
            v-if="emailHtml"
            class="review-email-button"
            icon-name="preview"
            text="Preview"
            type="grey"
            @click="handlePreviewEmailClick"
            data-test-id="automation-email-preview-button"
          />
        </div>
      </div>
    </ar-field>
  </EditableFrame>
</template>
<script>
import EditableFrame from '../utils/EditableFrame.vue'
import { mapActions, mapState, mapMutations } from 'vuex'
export default {
  name: 'SendEmail',
  components: {
    EditableFrame,
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      emailTemplate: null,
      emailTemplateType: null,
      emailHtml: null,
      subject: null,
      previewText: null,
      messageSender: null,
      isGeneratingEmailHtmlFromTemplate: false,
      displayPreviewLine: false,
      displayEmailEditorModal: false,
      displayEmailPreviewModal: false,
      emailEditorModalTemplateType: null,
      beefreeGeneratedHtml: null,
      savingEmailSettings: false,
    }
  },
  computed: {
    ...mapState({
      promoterOid: state => state.auth.account.promoterOid,
      automationId: state => state.automation.id,
    }),
    savedButtonDisabled() {
      if (this.savingEmailSettings) return true;
      return !(this.messageSender && this.subject && this.emailHtml)
    },
    senderName() {
      return this.messageSender?.additionalInfo.senderName || null;
    },
    businessAddress() {
      return this.messageSender?.additionalInfo.businessAddress || null;
    },
    messageSenderNotVerified() {
      if (!this.messageSender) {
        return false;
      }
      return !this.messageSender.verified;
    },
    messageSenderOid() {
      return this.messageSender?.oid;
    },
    saveButtonDisabled() {
      return this.messageSenderNotVerified;
    },
    emailHtmlBucketFieldsIsComplete() {
      return !!this.emailTemplate
        && !!this.emailTemplateType
        && !!this.emailHtml;
    },
    emailHtmlWithInfo() {
      if (!this.emailHtml) {
        return null;
      }
      return this.generateEmailHtmlWithInfo(
        this.emailTemplateType,
        this.emailHtml,
        this.previewText,
        this.senderName,
        this.businessAddress,
      );
    },
    blockOid() {
      return this.item?.id;
    },
  },
  watch: {
    'item.id'() {
      this.setItemVals()
    },
  },
  methods: {
    ...mapActions({
      updateActionConfig: 'automation/UPDATE_ACTION_CONFIG',
      resendEmailVerification: 'messageSender/RESEND_EMAIL_VERIFICATION',
      createBeefreeTemplate: 'emailTemplate/CREATE_BEEFREE_EMAIL_TEMPLATE',
      updateBucketEmailTemplate: 'emailTemplate/UPDATE_BUCKET_EMAIL_TEMPLATE',
      downloadBeefreeHtml: 'emailTemplate/DOWNLOAD_BEEFREE_HTML_FROM_TEMPLATE',
      fetchBeefreeTemplate: 'emailTemplate/FETCH_BEEFREE_TEMPLATE'
    }),
    ...mapMutations([
      'emailTemplate/RESET_EMAIL_TEMPLATES',
    ]),
    generateEmailHtmlWithInfo(templateType, emailHtml, previewText, senderName, businessAddress) {
      if (templateType === 'unlayer') {
        return this.$arUtils.email.injectInformationToUnlayerHtml(
          emailHtml,
          previewText,
          senderName,
          businessAddress,
          true,
        );
      } else if (templateType === 'beefree') {
        return emailHtml;
      } else {
        return this.$arUtils.email.injectInformationToRichTextHtml(
          emailHtml,
          previewText,
          senderName,
          businessAddress,
          true,
        );
      }
    },
    async generateEmailHtmlFromTemplate(templateType, template, htmlUrl = null) {
      try {
        this.isGeneratingEmailHtmlFromTemplate = true;
        if (templateType === 'unlayer') {
          const unlayerOutput = await this.$api.unlayer.exportHtml(template);
          this.emailHtml = unlayerOutput.html;
        } else if (templateType === 'beefree') {
          const beefreeOutput = await this.$api.beefree.exportHtml(htmlUrl);
          this.emailHtml = beefreeOutput;
        } else if (templateType === 'rich-text') {
          this.emailHtml = template;
        }
      } catch (e) {
        this.$arNotification.push({
          type: 'error',
          message: 'Failed to download email html',
        });
      } finally {
        this.isGeneratingEmailHtmlFromTemplate = false;
      }
    },
    async createEmailHtmlBucket(template, templateType, emailHtmlInfo) {
      // Save the template into the bucket

      const config = this.item?.config;
      const bucketEmailTemplateOid = !!config ? config['bucket-email-template-oid'] : null;

      if (templateType === 'beefree') {
        if (bucketEmailTemplateOid) {
          const update = await this.updateBucketEmailTemplate(
            {
              oid: bucketEmailTemplateOid,
              meta: {
                name: `${this.subject} - Automation ${this.automationId}-${this.item.id}` || `Automation Template ${this.automationId} - ${this.item.id}`,
                template: typeof template === 'string' ? template : JSON.stringify(template),
                templateType: templateType,
                version: 1,
                previewHtml: this.beefreeGeneratedHtml?.previewHtml,
                footerHtml: this.beefreeGeneratedHtml?.footerHtml,
              }
            });
          return await this.fetchBeefreeTemplate(bucketEmailTemplateOid)
        } else {
          return await this.createBeefreeTemplate({
            meta: {
              name: this.subject || `Automation Template ${this.automationId} - ${this.item.id}`,
              template: typeof template === 'string' ? template : JSON.stringify(template),
              templateType: templateType,
              version: 1,
              previewHtml: this.beefreeGeneratedHtml?.previewHtml,
              footerHtml: this.beefreeGeneratedHtml?.footerHtml,
            },
            automationBlockOid: this.blockOid,
          })
        }
      } else {
        return await this.$api.buckets.createLocalBucket(
          this.promoterOid,
          'email-html',
          'text/html',
          {
            template,
            templateType,
            emailHtml: emailHtmlInfo,
          },
        );
      }
    },
    async handleSave() {
      const valid = await this.validateInputs();
      if (!valid) {
        return;
      }

      // Update the injected preview and footer html
      await this.updateInjection();

      this.savingEmailSettings = true;
      const newBucket = await this.createEmailHtmlBucket(
        this.emailTemplate,
        this.emailTemplateType,
        this.emailHtmlWithInfo,
      );

      const contextData = {
        config: {
          subject: this.subject,
          previewText: this.previewText,
          promoterPropertyOid: this.messageSender.oid,
          templateType: this.emailTemplateType,
        },
        meta: {
          messageSender: this.messageSender,
          bucket: newBucket,
        },
      };
      if (this.emailTemplateType === 'beefree') {
        contextData.config.bucketEmailTemplateOid = newBucket.oid;
      } else {
        contextData.config.bucketOid = newBucket.oid;
      }

      this.updateActionConfig({
        id: this.item.id,
        data: contextData,
      });
      this.savingEmailSettings = false;
      this.$emit('close');
    },
    async validateInputs() {
      const isValid = await this.$validator.validateAll();
      return isValid;
    },
    async setItemVals() {
      const {
        config,
        meta,
      } = this.item;

      if (config?.['promoter-property-oid']) {
        this.messageSender = meta.messageSender;
      }

      if (config?.['template-type'] === 'beefree') {
        // If the current bucket is empty and the template type is beefree, we need to retrieve the html from the beefree bucket
        const beefree_template = await this.fetchBeefreeTemplate(config?.['bucket-email-template-oid'])
        this.emailTemplate = JSON.parse(beefree_template?.meta?.template);
        this.emailTemplateType = 'beefree';
        this.emailEditorModalTemplateType = 'beefree';
        this.generateEmailHtmlFromTemplate('beefree', this.emailTemplate, beefree_template?.meta?.url);
      } else if (config?.['bucket-oid'] && meta.bucket.meta) {
        const { template, templateType, url } = meta.bucket.meta;
        this.emailTemplate = template;
        this.emailTemplateType = templateType;
        this.emailEditorModalTemplateType = templateType;
        this.generateEmailHtmlFromTemplate(templateType, template, url);
      }

      if (!this.emailEditorModalTemplateType) {
        this.emailEditorModalTemplateType = 'beefree'
      }

      this.subject = config?.subject || null;
      this.previewText = config?.['preview-text'] || null;

      if (this.previewText) {
        this.displayPreviewLine = true;
      }
    },
    retryEmailVerification() {
      this.resendEmailVerification(this.messageSender.oid);
    },
    handleMessageSenderSelect(messageSender) {
      this.messageSender = messageSender;
    },
    handleAddPreviewLineClick() {
      this.displayPreviewLine = true;
    },
    handleRemovePreviewLineClick() {
      this.displayPreviewLine = false;
    },
    handleLaunchEmailBuilderClick() {
      this.displayEmailEditorModal = true;
    },
    async getBeefreeHtml(template) {
      try {
        await this.updateInjection();
        return await this.downloadBeefreeHtml({
          design: template,
          previewHtml: this.beefreeGeneratedHtml?.previewHtml || null,
          footerHtml: this.beefreeGeneratedHtml?.footerHtml || null,
        })
      } catch (error) {
        console.error("failed to download beefree html", error)
        return null
      }
    },
    async handleEmailEditingUpdate(template, emailHtml) {
      this.displayEmailEditorModal = false;
      let htmlString = emailHtml

      if (this.emailEditorModalTemplateType === 'beefree') {
        htmlString = await this.getBeefreeHtml(template)
      }

      this.emailTemplate = template;
      this.emailTemplateType = this.emailEditorModalTemplateType;
      this.emailHtml = htmlString;
    },
    handleEmailEditingCancel() {
      this.displayEmailEditorModal = false;
    },
    handlePreviewEmailClick() {
      this.displayEmailPreviewModal = true;
    },
    handleEmailPreviewClose() {
      this.displayEmailPreviewModal = false;
    },
    async handleSelectEmailTemplateSelect(template, templateType, emailHtml) {
      let htmlString = emailHtml

      if (this.emailEditorModalTemplateType === 'beefree') {
        htmlString = await this.getBeefreeHtml(template)
      }

      this.emailTemplate = template;
      this.emailTemplateType = templateType;
      this.emailHtml = htmlString;
    },
    setEmailEditorModalTemplateType(templateType) {
      this.emailEditorModalTemplateType = templateType
    },
    async updateInjection() {
      this.beefreeGeneratedHtml = await this.$arUtils.email.getPreviewAndFooterHtml(
        this.previewText,
        this.senderName,
        this.businessAddress,
        true
      )
    }
  },
  mounted() {
    this.setItemVals()

    this['emailTemplate/RESET_EMAIL_TEMPLATES']()
  }
}
</script>
<style lang="scss" scoped>
.send-email-wrapper {
  width: 100%;
  height: 100%;
  .sender-section {
    .sender-select {
      margin-top: 14px;
      width: 100%;
    }
  }
  .subject-section {
    margin-top: 31px;
    .label-section {
      display: flex;
      align-items: center;
      .label {
        flex-grow: 1;
      }
    }
    .input {
      margin-top: 14px;
    }
  }
  .preview-line-section {
    margin-top: 31px;
    .label-section {
      display: flex;
      align-items: center;
      .label {
        flex-grow: 1;
      }
    }
    .input {
      margin-top: 14px;
    }
  }
  .design-section {
    margin-top: 31px;
    .label-section {
      display: flex;
      align-items: center;
      .label {
        flex-grow: 1;
      }
    }
    .html-preview {
      margin-top: 14px;
      height: 200px;
    }
    .action-buttons-section {
      display: flex;
      flex-flow: column;
      align-items: center;
      margin-top: 20px;
      .review-email-button {
        width: 100%;
        height: 40px;
      }
      .launch-email-button {
        margin-bottom: 10px;
        width: 100%;
        height: 40px;
      }
    }
  }
}
</style>
