<template>
    <portal
      to="modal"
      :disabled="!isShow"
      :style="{ position: 'absolute' }"
    >
      <ar-modal
        :is-open="isShow"
        class="ticketmaster-modal"
        header="Connect to Ticketmaster"
        width="590px"
        :mobile="$arMediaQuery.window.maxWidth('xs')"
        @close="handleCloseClick"
      >
        <div
          slot="body"
          :class="[
            'ticketmaster-modal-body',
            $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
          ]"
        >
          <ar-field
            label="Provider Name"
            class="u-margin-top-5"
          >
            <ar-input
              v-model="providerName"
              name="providerName"
              placeholder="Please enter your Ticketmaster Provider Name"
              id="ticketmaster-provider-name-input"
              type="text"
              data-test-id="ticketmaster-provider-name-input"
            />
          </ar-field>
        </div>
          
        <div
          slot="footer"
          :class="[
            'ticketmaster-modal-footer',
            $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
          ]">
          <ar-simple-button
            text="Confirm"
            :loading="loading"
            :disabled="confirmButtonDisabled"
            :style="{ width: '108px' }"
            @click="handleConfirmClick"
            id="ticketmaster-confirm-button"
            data-test-id="ticketmaster-confirm-button"
          />
        </div>
      </ar-modal>
    </portal>
  </template>
  
  <script>
  import { mapActions } from 'vuex';
  
  export default {
    name: 'AskInputModal',
    components: {},
    props: {
      isShow: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        providerName: ""
      };
    },
  
    computed: {
      confirmButtonDisabled() {
       
        return !this.providerName;
      }
    },
  
    watch: {
      isShow(val) {
        if (val) {
          this.providerName = null;
        }
      },
    },
  
    methods: {
      ...mapActions([
        'ticketmasterIntegration/FETCH_INTEGRATION',
        'ticketmasterIntegration/CREATE_TICKETMASTER_INTEGRATION',
      ]),
      async fetchIntegration() {
        const success = await this['ticketmasterIntegration/FETCH_INTEGRATION']()
        return success;
      },
      async handleConfirmClick() {
        this.errorMessage = null;
        this.httpStatus = null;
        const providerName = this.providerName;
        const response = await this['ticketmasterIntegration/CREATE_TICKETMASTER_INTEGRATION']({
          providerName
        });
        if (response) {
          if (this.$route.path != '/settings/integrations/ticketmaster') {
            await this.$router.push({ path: '/settings/integrations/ticketmaster' });;
          }
          else {
            this.fetchIntegration();
          }
          this.$emit('cancel');
        }
        else {
          this.errorMessage = response.message;
          this.httpStatus = response.httpStatusCode;
        }
      },
      handleCloseClick() {
        this.$emit('cancel');
      },
      toggleApplyPrefix() {
        this.applyPrefix = !this.applyPrefix;
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .ticketmaster-modal {
    .ticketmaster-modal-body {
      padding: 20px 30px 30px;

      &:nth-child(2) {
        border-top: 1px solid $blueGrey500;
      }
    }
    .ticketmaster-modal-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 25px 30px;
      height: 100px;
    }
  }
  </style>
  