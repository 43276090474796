<template>
  <section class="template-page">
    <am2-import-dynamic-tags-modal
      :is-show="showImportDynamicTagsModal"
      :message-list-oid="scratchEmailMessage.meta.messageListOid"
      channel="email"
      @import="handleDynamicTagsImport"
      @cancel="showImportDynamicTagsModal = false"
    />
    <div
      :class="[
        'email-editor-wrapper',
        scratchEmailMessage.meta.presentation.templateType === 'rich-text' && 'for-rich-text'
      ]"
    >
      <EmailEditor
        ref="email-editor-component"
        :dynamic-tags="availableDynamicTagsInEmailMessage"
        :type="scratchEmailMessage.meta.presentation.templateType"
        @templateUpdate="handleDesignOutput"
        @emailHtmlUpdate="handleHtmlOutput"
        @addDynamicTagsToolClick="addImportDynamicTagsToolClick"
        @addDynamicTagsToolNotFound="addImportDynamicTagsToolNotFound"
        @setBeefreeShowPreview="setBeefreeShowPreview"
        @setBeefreeShowStructure="setBeefreeShowStructure"
        @setBeefreeShowPreviewDynamicTags="setBeefreeShowPreviewDynamicTags"
      />
    </div>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import EmailEditor from '@/components/editors/email-editor';

export default {
  name: 'Template',

  components: {
    EmailEditor
  },

  props: {
    messageInitializationIsComplete: {
      type: Boolean,
      default: false
    },
    beefreeShowPreview: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      showImportDynamicTagsModal: false
    };
  },

  computed: {
    ...mapState({
      scratchEmailMessage: state => state.message.scratchEmailMessage,
      selectedVariant: state => state.message.selectedVariant,
    }),
    ...mapGetters({
      availableDynamicTagsInEmailMessage: 'message/availableDynamicTagsInEmailMessage'
    })
  },

  watch: {
    showImportDynamicTagsModal(val) {
      this.toggleUnlayerEditorPointEvents(!val);
    },
    messageInitializationIsComplete(newVal) {
      if (newVal) {
        this.setEmailTemplate();
      }
    },
    selectedVariant() {
      this.setEmailTemplate().then(() => {
        if (this.beefreeShowPreview) {
          this.preview()
        }
      })
    },
  },

  methods: {
    ...mapActions([
      'SHOW_CONFIRM',
      'message/RENEW_AVAILABLE_FALLBACK_DYNAMIC_TAGS_FOR_EMAIL_MESSAGE'
    ]),
    ...mapMutations([
      'message/PATCH_SCRATCH_EMAIL_MESSAGE',
      'message/SET_SCRATCH_EMAIL_TEMPLATE',
      'message/SET_CSV_PREVIEW_CONTACTS',
      'message/PATCH_AB_EMAIL_MESSAGE_TEMPLATE',
      'message/PATCH_AB_EMAIL_MESSAGE_TEMPLATE_TYPE',
    ]),
    toggleUnlayerEditorPointEvents(enabled) {
      if (!this.$refs['email-editor-component']) {
        return;
      }
      if (enabled) {
        this.$refs['email-editor-component'].$el.style.pointerEvents = 'all';
      } else {
        this.$refs['email-editor-component'].$el.style.pointerEvents = 'none';
      }
    },
    // This method is being used by parent component
    async setEmailTemplate() {
      await this.$nextTick(() => {
        if (!this.$refs['email-editor-component']) {
          return;
        }

        // 15 Feb 2023 ADR
        // We may not know where the template came from or when it's updated, so as a last line of defense, we need to parse the string into JSON
        // So that the template will not display as empty
        const jsonTemplate =
          typeof this.scratchEmailMessage.meta.presentation.template === 'string'
            ? JSON.parse(this.scratchEmailMessage.meta.presentation.template)
            : this.scratchEmailMessage.meta.presentation.template;

        this.$refs['email-editor-component'].setDesign(jsonTemplate);
      });
    },
    preview() {
      if (!this.$refs['email-editor-component']) {
        return;
      }
      this.$refs['email-editor-component'].preview();
    },
    toggleStructureOutlines() {
      if (!this.$refs['email-editor-component']) {
        return;
      }
      this.$refs['email-editor-component'].toggleStructureOutlines();
    },
    previewDynamicTags() {
      if (!this.$refs['email-editor-component']) {
        return;
      }
      this.$refs['email-editor-component'].previewDynamicTags();
    },
    // This method is being used by parent component
    async importDynamicTags() {
      this.showImportDynamicTagsModal = true;
    },
    handleDesignOutput(design) {
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        meta: {
          presentation: {
            template: design
          }
        }
      });

      if (this.scratchEmailMessage.abEmail) {
        const templateType = this.scratchEmailMessage?.meta?.presentation?.templateType || 'beefree';
        this['message/PATCH_AB_EMAIL_MESSAGE_TEMPLATE'](design);
        this['message/PATCH_AB_EMAIL_MESSAGE_TEMPLATE_TYPE'](templateType);
        this['message/RENEW_AVAILABLE_FALLBACK_DYNAMIC_TAGS_FOR_EMAIL_MESSAGE']('')
      }
    },
    handleHtmlOutput(template) {
      this['message/SET_SCRATCH_EMAIL_TEMPLATE'](template);
    },
    async handleDynamicTagsImport({ dynamicTagHeaders, dynamicTagRows, tagsResourceOid, tagMaxWidthMap }) {
      this['message/SET_CSV_PREVIEW_CONTACTS']({
        headers: dynamicTagHeaders,
        rows: dynamicTagRows
      });
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        meta: {
          dynamicTagHeaders,
          tagsResourceOid,
          tagMaxWidthMap
        }
      });
      await this.$nextTick();
      this.showImportDynamicTagsModal = false;
    },
    async addImportDynamicTagsToolClick() {
      if (!this.scratchEmailMessage.meta.messageListOid) {
        this.SHOW_CONFIRM({
          messageHtml: 'Please go back and select a list before uploading dynamic tags file.',
          confirmButtonText: 'Done',
          hideCancelButton: true
        });
        return;
      }
      this.importDynamicTags();
    },
    addImportDynamicTagsToolNotFound() {
      this.$emit('showImportDynamicTagsLink');
    },
    setBeefreeShowPreview(showPreview) {
      this.$emit('setBeefreeShowPreview', showPreview);
    },
    setBeefreeShowPreviewDynamicTags(showPreviewDynamicTags) {
      this.$emit('setBeefreeShowPreviewDynamicTags', showPreviewDynamicTags);
    },
    setBeefreeShowStructure(showStructure) {
      this.$emit('setBeefreeShowStructure', showStructure);
    }
  },
  mounted() {
    if (this.messageInitializationIsComplete) {
      this.setEmailTemplate();
    }
  }
};
</script>
<style lang="scss" scoped>
.template-page {
  width: 100%;
  height: 100%;
  background: $skyBlueGrey300;

  .email-editor-wrapper {
    width: 100%;
    height: 100%;

    &.for-rich-text {
      padding: 32px;
      max-width: 909px;
      margin: 0 auto;
    }
  }
}
</style>
