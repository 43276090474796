var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"conversions-table"},[_c('div',{staticClass:"conversions-table__count"},[_c('ar-text',{attrs:{"text":_vm.formattedTableCount,"weight":"bold","size":"sm"}}),_vm._v(" "),_c('ar-text',{style:({ marginLeft: '4px' }),attrs:{"text":"recipients","size":"sm"}})],1),_vm._v(" "),_c('div',{staticClass:"conversions-table__top"},[_c('am2-table-control-section',{directives:[{name:"ar-sticky-top",rawName:"v-ar-sticky-top",value:({
        priority: 1,
      }),expression:"{\n        priority: 1,\n      }"}],attrs:{"show-filter-and-search-bar":"","show-checked-rows-options-bar":"","show-filter-button":false,"show-count-and-options-bar":false,"columnSettings":{
        selectedColumnKeys: _vm.selectedColumnKeys,
        availableColumns: _vm.availableColumns,
        action: _vm.handleSelectedColumnKeysUpdate,
      },"fans-search":{
        action: _vm.handleFanSearch,
      }}})],1),_vm._v(" "),_c('div',{staticClass:"conversions-table__main"},[_c('am2-table',{attrs:{"heads":_vm.displayedTableHeadings,"rows":_vm.isLoading || !_vm.isCalculationsFinished ? [] : _vm.conversionTableData,"loading":_vm.isLoading || !_vm.isCalculationsFinished,"empty-text":"No messages available","has-control-section":""},scopedSlots:_vm._u([{key:"name",fn:function(ref){
      var data = ref.data;
return _c('div',{staticClass:"conversions-table__cell left"},[_c('ar-avatar',{style:({
          marginRight: '10px',
        }),attrs:{"name":_vm.determineName(data.firstName, data.lastName, data.emailAddress),"color":'grey'}}),_vm._v(" "),_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({content: _vm.determineName(data.firstName, data.lastName, data.emailAddress)}),expression:"{content: determineName(data.firstName, data.lastName, data.emailAddress)}",modifiers:{"top":true}}],staticClass:"name-text",style:({
        }),attrs:{"size":"xs","text":_vm.determineName(data.firstName, data.lastName, data.emailAddress),"multiple-lines":"","max-lines":1}})],1)}},{key:"eventName",fn:function(ref){
        var data = ref.data;
return _c('div',{staticClass:"conversions-table__cell left"},[_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({content: data.eventName }),expression:"{content: data.eventName }",modifiers:{"top":true}}],attrs:{"size":"xs","text":data.eventName}})],1)}},{key:"email",fn:function(ref){
        var data = ref.data;
return _c('div',{staticClass:"conversions-table__cell left"},[_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({content: data.emailAddress }),expression:"{content: data.emailAddress }",modifiers:{"top":true}}],attrs:{"size":"xs","text":data.emailAddress}})],1)}},{key:"mobileNumber",fn:function(ref){
        var data = ref.data;
return _c('div',{staticClass:"conversions-table__cell left"},[_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({content: data.mobileNumber }),expression:"{content: data.mobileNumber }",modifiers:{"top":true}}],attrs:{"size":"xs","text":data.mobileNumber}})],1)}},{key:"ticketType",fn:function(ref){
        var data = ref.data;
return _c('div',{staticClass:"conversions-table__cell left"},[_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({content: data.name }),expression:"{content: data.name }",modifiers:{"top":true}}],attrs:{"size":"xs","text":data.name}})],1)}},{key:"quantity",fn:function(ref){
        var data = ref.data;
return _c('div',{staticClass:"conversions-table__cell conversions-table__cell--right"},[_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({content: data.totalTickets }),expression:"{content: data.totalTickets }",modifiers:{"top":true}}],attrs:{"size":"xs","text":data.totalTickets}})],1)}},{key:"orderTotal",fn:function(ref){
        var data = ref.data;
return _c('div',{staticClass:"conversions-table__cell conversions-table__cell--right"},[_c('ar-text',{attrs:{"size":"xs","text":_vm.formatPriceNumber(data.gross.value)}})],1)}},{key:"orderDate",fn:function(ref){
        var data = ref.data;
return _c('div',{staticClass:"conversions-table__cell conversions-table__cell--right"},[_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({content: _vm.convertTime(data.orderDate) }),expression:"{content: convertTime(data.orderDate) }",modifiers:{"top":true}}],attrs:{"size":"xs","text":_vm.convertTime(data.orderDate)}})],1)}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }