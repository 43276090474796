<template>
  <am2-card-container
    class="sync-block-container"
  >
    <div v-if="syncStatus === 'in-progress'" class="sync-block">
      <am2-loading-bubble
        class="sync-animation"
      />
      <div class="text-block">
        <am2-heading
          class="header"
          :size="$arMediaQuery.pageContent.minWidth('sm') ? 'lg' : 'md'"
          type="h2"
          :title="syncCopies[syncStatus || 'default']"/>
        <div class="button-block">
          <ar-simple-button
            text="Stop sync"
            outlined
            class="button"
            @click="handleStopSyncClick" />
        </div>
      </div>
    </div>
    <div v-else class="sync-block">
      <ar-skeleton
        v-if="isFetchingSyncTask"
        :style="{
          width: '44px',
          height: '44px',
          borderRadius: '50%',
        }"
      />
      <div v-else class="tick-container">
        <div
          v-if="syncStatus === 'completed' || syncStatus === 'connected'"
          class="tick-container"
          :style="{
            backgroundColor: $arStyle.color.green500,
          }"
        >
          <ar-icon
            name="check"
            class="tick-icon"
            width="18"
            height="17"
          />
        </div>
        <div
          v-else
          class="tick-container"
          :style="{
            backgroundColor: $arStyle.color.purple500,
          }"
        >
          <ar-icon
            name="exclamation-mark"
            class="tick-icon"
            height="25"
          />
        </div>
      </div>
      <div class="text-block u-margin-top-8">
        <ar-skeleton
          v-if="isFetchingSyncTask"
          :style="{
            width: '100%',
            height: '26px',
            borderRadius: '3px',
          }"
        />
        <am2-heading
          v-else
          class="header"
          :size="copySize"
          type="h2"
          :title="syncCopies[syncStatus || 'default']"/>
        <div class="button-block u-margin-top-9">
          <ar-skeleton
            v-if="isFetchingSyncTask"
            :style="{
              width: '300px',
              height: '50px',
              borderRadius: '3px',
            }"
          />
          <ar-simple-button
            v-else-if="syncStatus === 'completed'"
            text="Sync now"
            class="button"
            :loading="isFetchingSyncTask"
            @click="handleSyncNowClick" />
        </div>
        <div v-if="timeSinceLastSync" class="last-sync-time">
          <ar-text
            :text="`Last synced ${timeSinceLastSync}`"
            size="xxs"
            :style="{
              color: $arStyle.color.blueGrey700,
            }"
          />
        </div>
      </div>
    </div>
  </am2-card-container>
</template>

<script>
import moment from 'moment';

export default {
  name: 'SyncBlockContainer',

  props: {
    isFetchingSyncTask: {
      type: Boolean,
      default: false,
    },
    syncStatus: {
      type: String,
      default: false,
    },
    syncTime: {
      type: String,
      default: false,
    },
    syncCopies: {
      type: Object,
      default: () => ({}),
    },
    copySize: {
      type: String,
      default: 'md'
    }
  },
  computed: {
    timeSinceLastSync() {
      if (this.syncStatus === 'completed' && this.syncTime) {
        return moment.utc(
          this.syncTime
        ).fromNow();
      }
      return null;
    }
  },
  methods: {
    handleSyncNowClick() {
      this.$emit('startSync');
    },
    handleStopSyncClick() {
      this.$emit('stopSync');
    }
  },
};
</script>

<style lang="scss" scoped>
.sync-block-container {
  background-color: #FFF;

  .sync-block {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 85px;
    padding-bottom: 70px;
    flex-direction: column;

    .tick-container {
      height: 44px;
      width: 44px;
      border-radius: 50%;
      background-color: $green500;
      display: flex;
      justify-content: center;
      align-items: center;
      .tick-icon {
        color: #FFF;
      }
    }

    .sync-animation {
      color: $green500;
      margin-bottom: 63px;
    }

    .button-block {
      display: flex;
      flex-direction: column;
      max-width: 100%;
      padding: 0 12px;
      margin-top: 44px;

      .button {
        width: 300px;
        max-width:100%;
      }
    }

    .last-sync-time {
      margin-top: 12px;
      font-size: 14px;
      color: $blueGrey700;
      line-height: 25px;
    }

    .text-block {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      max-width: 100%;

      .header {
        color: $blueGrey800;
        margin-bottom: 3px;
      }
    }
  }
}
</style>
