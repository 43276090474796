<template>
  <EditableFrame
    class="purchased-ticket-wrapper"
    title="Edit Trigger"
    :subtitle="item.name"
    has-save-button
    :save-button-disabled="savedButtonDisabled"
    @close="$emit('close')"
    @save="handleSave"
    @click.native="handleClickOutside"
  >
    <ar-text
      class="purchased-ticket-heading"
      size="14px"
      text="To event"
      weight="bold"
      multiple-lines
      :max-lines="1"
      letter-spacing="1px"
    />
    <div class="purchased-ticket-container">
      <div class="purchased-ticket-dropdown-wrapper" @click="toggleOptions" data-test-id="purchased-ticket-toggle">
        <ar-text
          class="purchased-ticket-copy"
          size="14px"
          :text="selectedEventCopy"
          multiple-lines
          :max-lines="1"
          line-height="20px"
        />
        <ArrowHead
          class="purchased-ticket-dropdown-arrowhead"
          :class="listOpen && 'rotate'"
          height="8px"
          width="10px"
          :fill="$arStyle.color.skyBlueGrey700"
        />
      </div>
      <div v-if="listOpen" class="purchased-ticket-search-wrapper">
        <am2-search
          class="purchased-ticket-search"
          v-model="listSearchText"
          placeholder="Search events..."
          data-test-id="purchased-ticket-search"
        />
        <div class="purchased-ticket-wrapper">
          <div v-if="noMatchingEvent" class="event-link no-event-found-wrapper">
            <div class="item-wrapper">
              <ar-text
                class="item-name"
                :style="{ marginRight: '4px' }"
                size="xs"
                text="No matching event found"
                multiple-lines
                :max-lines="1"
                line-height="20px"
                weight="normal"
              />
            </div>
          </div>
          <template v-for="event in filteredEventsList">
            <a
              :key="event.oid"
              class="event-link"
              @click="() => setSelectedEvent(event)"
              :data-test-id="`purchased-ticket-anchor-${event.oid}`"
            >
              <div class="item-wrapper">
                <ar-text
                  :class="[
                    'item-name',
                    selectedEvent && event.oid === selectedEvent.oid && 'selected'
                  ]"
                  size="xs"
                  :text="event.name"
                  multiple-lines
                  :max-lines="1"
                  line-height="20px"
                  :weight="selectedEvent && event.oid === selectedEvent.oid ? 'bold' : 'normal'"
                />
                <ar-text
                  v-if="event['date-string']"
                  :class="[
                    'purchased-ticket-date-string',
                    selectedEvent && event.oid === selectedEvent.oid && 'selected'
                  ]"
                  size="xs"
                  :text="event['date-string']"
                  multiple-lines
                  :max-lines="1"
                  line-height="20px"
                />
                <ar-text
                  v-if="event.location"
                  :class="[
                    'purchased-ticket-location',
                    selectedEvent && event.oid === selectedEvent.oid && 'selected'
                  ]"
                  size="xs"
                  :text="event.location"
                  multiple-lines
                  :max-lines="1"
                  line-height="20px"
                />
              </div>
            </a>
          </template>
        </div>
      </div>
      <div v-if="ticketTypesOpen" class="purchased-ticket-type-container">
        <ar-text
          class="purchased-ticket-type-title"
          size="14px"
          text="Ticket types"
          weight="bold"
          v-tooltip.top="{
            content: 'Unknown ticket types are generated based on ticket prices where ticket type did not exist in your data source.',
          }"
          multiple-lines
          :max-lines="1"
          letter-spacing="1px"
        />
        <ar-text
          class="purchased-ticket-type-subtitle"
          size="13px"
          text="If you do not select a ticket type all ticket types will be considered"
          multiple-lines
          letter-spacing="1px"
        />
        <div v-for="(ticketType, index) in ticketTypes" :key="ticketType.oid">
          <ar-checkbox
            class="purchased-ticket-type-checkbox"
            @input="(value) => handleCheckboxClick(ticketType.oid, value)"
            :value="isChecked(ticketType.oid)"
            :label="ticketType.name"
            :label-typography-props="{
              size: 'xs',
            }"
            :data-test-id="`automation-ticket-type-checkbox-${ticketType.oid}`"
          />
        </div>
      </div>
    </div>
  </EditableFrame>
</template>
<script>
import EditableFrame from '../utils/EditableFrame.vue'
import ArrowHead from '~/assets/icons/arrow-head.svg?inline'
import { mapActions } from 'vuex'
import { displayDateRangeUSNoDay } from '@/utils/date/'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(timezone)
export default {
  name: 'PurchasedTicket',
  components: {
    EditableFrame,
    ArrowHead,
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      listOpen: false,
      listSearchText: '',
      selectedEvent: {oid: 'any', name: 'Any event'},
      filteredEventsList: [],
      initialEventsList: [],
      ticketTypesOpen: false,
      ticketTypes: [],
      ticketTypeSelections: [],
    }
  },
  watch: {
    'item.id'() {
      this.setItemVals()
    },
    listSearchText() {
      this.searchEventsOnBackend(this.listSearchText)
    }
  },
  computed: {
    noMatchingEvent() {
      return !this.filteredEventsList.length
    },
    savedButtonDisabled() {
      return !this.selectedEvent
    },
    selectedEventCopy() {
      return this.selectedEvent.name || ''
    },
  },
  methods: {
    async searchEventsOnBackend(text) {
      let { rows } = await this.fetchEvents({ orderBy: 'datecreated', top: '200', selectString: 'name,location,startDate', searchString: text })
      this.filteredEventsList = rows
    },
    ...mapActions({
      fetchEvents: 'FETCH_EVENTS',
      fetchTicketClasses: 'automation/FETCH_TICKET_CLASSES',
      updateTriggerContextData: 'automation/UPDATE_TRIGGER_CONTEXT_DATA'
    }),
    handleCheckboxClick(oid, checked) {
      if (checked) {
        this.ticketTypeSelections.push(oid)
      } else {
        this.ticketTypeSelections = this.ticketTypeSelections.filter(item => item !== oid)
      }
    },
    isChecked(oid) {
      return  !!this.ticketTypeSelections.includes(oid)
    },
    handleClickOutside(e) {
      if (!this.listOpen) return

      let clickOutsideClasses = ['content-wrapper', 'header-wrapper', 'heading-close-icon-wrapper', 'footer-wrapper', 'purchased-ticket-container']
      if (clickOutsideClasses.includes(e.target.className)) {
        this.listOpen = false
        this.listSearchText = ''
      }
    },
    async setSelectedEvent(event) {
      this.selectedEvent = event
      this.listOpen = false
      this.listSearchText = ''

      if (this.item.config?.['event-oid'] == event.oid) {
        this.ticketTypeSelections = this.item.config['ticket-types'] || []
      } else {
        this.ticketTypeSelections = []
      }

      this.ticketTypes = event.oid === 'any' ? [] : await this.fetchTicketClasses({ eventOid: event.oid })
      this.ticketTypesOpen = this.ticketTypes.length > 0
    },
    filterLists(text) {
      let filteredList = []
      let i = 0
      while (i < this.initialEventsList.length) {
        if (this.$arFuzzySearch(this.initialEventsList[i].name, text) || !!(this.initialEventsList[i]?.location && this.$arFuzzySearch(this.initialEventsList[i].location, text))) {
          filteredList.push(this.initialEventsList[i])
        }

        i++
      }

      this.filteredEventsList = filteredList
    },
    toggleOptions() {
      if (this.listOpen) {
        this.listSearchText = ''
      }
      this.listOpen = !this.listOpen
    },
    handleSave() {
      let contextData = this.selectedEvent.oid === 'any' ?
        {
          config: {},
          meta: {}
        } :
        {
          config: {
            'event-oid': this.selectedEvent.oid,
            'should-match': 'at-least-one-of',
            'ticket-types': this.ticketTypeSelections,
          },
          meta: {
            name: this.selectedEvent.name,
            location: this.selectedEvent.location,
          }
        }

      this.updateTriggerContextData({
        id: this.item.id,
        data: contextData
      })
      this.$emit('close')
    },
    async fetchDropdownEvents() {
      let { rows } = await this.fetchEvents({ orderBy: 'datecreated', top: '200', selectString: 'name,location,startDate' })

      let localTimezone = dayjs.tz.guess()

      let events = rows.map(item => {
        return {
          oid: item.oid,
          name: item.name,
          location: item.location,
          'date-string': displayDateRangeUSNoDay(dayjs(item.startDate).tz(localTimezone).valueOf(), null, localTimezone),
        }
      })
      events.unshift({oid: 'any', name: 'Any event'})
      this.initialEventsList = events
      this.filteredEventsList = events
    },
    resetDefaults() {
      this.selectedEvent = ''
      this.listOpen = false
      this.listSearchText = ''
    },
    async setItemVals() {
      this.resetDefaults()
      if (!this.initialEventsList.length) await this.fetchDropdownEvents()

      if (this.item.config?.['event-oid']) {
        const selectedEvent = this.initialEventsList.find(item => item.oid === this.item.config['event-oid'])
        this.setSelectedEvent(selectedEvent)
      } else {
        this.selectedEvent = {oid: 'any', name: 'Any event'}
      }
    },
  },
  mounted() {
    this.setItemVals()
  }
}
</script>
<style lang="scss" scoped>
.purchased-ticket-wrapper {
  width: 100%;
  height: 100%;

  .purchased-ticket-heading {
    margin-bottom: 14px;
    pointer-events: none;
  }

  .purchased-ticket-container {
    .purchased-ticket-dropdown-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: ui-px2em(14) ui-px2em(18) ui-px2em(16) ui-px2em(16);
      border-radius: 4px;
      border: solid ui-px2em(1) #dcdee4;
      width: 100%;
      margin-bottom: 10px;
      min-height: 52px;
      cursor: pointer;

      .purchased-ticket-dropdown-arrowhead {
        transition: all 0.2s ease;

        &.rotate {
          transform: rotate(540deg);
        }
      }
    }
    .purchased-ticket-search-wrapper {
      width: 100%;
      position: absolute;
      border-radius: 5px;
      box-shadow: 0 2px 6px 0 #e4e5e7;
      border: solid 1px #d4d8e1;
      background-color: #fff;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      z-index: 10;
      margin-bottom: 10px;

      .purchased-ticket-search {
        width: 100%;
        height: 60px;
        padding: 9px 12px 0px 9px;
      }

      .purchased-ticket-wrapper {
        width: 100%;
        margin-top: 9px;
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        max-height: 360px;
        overflow: auto;
        padding-bottom: 10px;

        .event-link {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          padding-left: 8px;
          cursor: pointer;

          &:hover {
            background: #f2edff;
          }

          &.no-event-found-wrapper:hover {
            background: unset;
            cursor: default;
          }

          .item-wrapper {
            display: flex;
            flex-flow: column nowrap;
            align-items: flex-start;
            width: 100%;
            padding: 9px;

            .item-name {
              &.selected {
                color: $purple500;
              }
            }

            .purchased-ticket-date-string, .purchased-ticket-location {
              color: #c1c6cf;

              &.selected {
                color: #ad8adf;
              }
            }
          }
        }
      }
    }
  }

  .purchased-ticket-type-container {
    .purchased-ticket-type-title {
      font-weight: 500;
      margin-top: 32px;
    }
    .purchased-ticket-type-subtitle {

      margin-top: 12px;
      color: $blueGrey700;
    }
    .purchased-ticket-type-checkbox {
      margin-top: 16px;
    }
  }
}

.purchased-ticket-options-wrapper {
  width: 100%;

  .option-heading {
    margin-bottom: 14px;
  }

  .options-heading {
    margin-top: 52px;
    margin-bottom: 14px;
  }
}
</style>
