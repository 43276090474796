<template>
  <section>
    <div
      v-if="isMessageScheduled"
      class="message-strip-wrapper"
      :style="{
        background: $arStyle.color.purple100,
        border: `1px solid ${$arStyle.color.purple200}`
      }"
    >
      <div :class="['message-strip-inner complete']">
        <div :class="['icon-wrapper edit-icon-wrapper']"><CalendarIcon class="icon" /></div>
        <div class="message-content-wrapper">
          <div class="message-content-top complete">
            <ar-text class="content-title" multiple-lines :text="scheduleMessageCopy" weight="bold" size="16px" />
            <ar-link-button
              class="content-link cancel"
              text="Cancel"
              has-underline
              @click="handleCancel"
              :data-test-id="`message-content-cancel-button`"
            />
            <ar-link-button
              class="content-link edit"
              text="Edit"
              has-underline
              @click="handleEdit"
              :data-test-id="`message-content-edit-button`"
            />
          </div>
          <div class="message-content-middle">
            <div :class="['message-content-middle-container complete']">
              <div class="complete-details-wrapper">
                <div class="complete-left">
                  <ar-text class="left-subheading" multiple-lines text="Date and time" weight="normal" size="14px" />
                  <ar-text class="left-copy" :text="displayedTime" weight="normal" size="16px" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { generateMessageCenterDateCopy } from '@/utils/date/';
import CalendarIcon from '~/assets/icons/calendar-schedule.svg?inline';

dayjs.extend(timezone);
export default {
  name: 'MessageDetailsFromBlock',
  components: {
    CalendarIcon
  },

  props: {
    isFieldExpanded: Boolean
  },

  watch: {},
  computed: {
    ...mapState({
      scratchEmailMessage: state => state.message.scratchEmailMessage
    }),
    ...mapGetters({
      countdownTime: 'message/currentScheduledMessageCountdownTime',
      historicalCurrentPagePath: 'application/historicalCurrentPagePath',
    }),

    sanitizedReferringPage() {
      return this.historicalCurrentPagePath('/message-center/messages');
    },

    scheduleMessageCopy() {
      return `Sending In ${this.countdownTime}`;
    },
    isMessageScheduled() {
      return this.scratchEmailMessage.scheduledAt;
    },
    displayedTime() {
      return generateMessageCenterDateCopy(
        this.scratchEmailMessage.scheduledAt,
        this.scratchEmailMessage.meta.presentation.timeZone
      );
    }
  },

  methods: {
    ...mapActions([
      'OPEN_CANCELLATION_SCHEDULED_MODAL',
    ]),
    ...mapMutations({
      patchScratchEmail: 'message/PATCH_SCRATCH_EMAIL_MESSAGE'
    }),

    async handleCancel() {
      this.$emit('cancel');

      const resp = await this.OPEN_CANCELLATION_SCHEDULED_MODAL();
      if (resp) this.handleCancelSchedule();
    },

    handleEdit() {
      this.$emit('edit');
    },

    handleCancelSchedule() {
      this.patchScratchEmail({
        meta: {
          presentation: {
            timeZone: null
          }
        },
        scheduledAt: null
      });

      this.$router.push({
        path: this.sanitizedReferringPage,
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.message-strip-wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 24px;
  width: 100%;
  margin-top: 30px;
  border: 1px solid $skyBlueGrey500;
  border-radius: 4px;
  &.disabled {
    pointer-events: none; // disable all pointer events
    opacity: 0.5;
  }
  .message-strip-inner {
    min-height: 40px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: auto;

    &.complete {
      align-items: flex-start;
    }

    .icon-wrapper {
      margin-right: 24px;

      &.new-icon-wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
      }
      &.edit-icon-wrapper {
        margin-bottom: auto;
      }
    }

    .message-content-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      flex-grow: 1;
      width: 35rem;
    }

    .message-content-top {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      position: relative;

      &.complete {
        margin-bottom: 21px;
      }
      .content-title {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }

      .content-button {
        padding: 10px 16px 11px;
        border: 1px solid $purple500;
        border-radius: 4px;
        position: absolute;
        right: 0;
        top: 5px;
      }
      .content-link.edit {
        margin-left: 32px;
      }
      .content-link.cancel {
        color: #fc4349;
      }
    }

    .message-content-middle {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      width: 100%;

      .message-content-middle-container {
        &.complete {
          width: 100%;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
        }

        .left-subheading {
          color: $skyBlueGrey700;
        }

        .complete-details-wrapper {
          width: 100%;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-start;

          .complete-left {
            width: 70%;
            padding-right: 15px;
          }

          .complete-right {
            margin-left: auto;

            .left-copy {
              color: $blueGrey800;
            }
          }

          .left-subheading {
            color: $skyBlueGrey700;
            margin-bottom: 6px;
          }
        }
      }
    }
  }
}
</style>
