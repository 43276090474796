<template>
  <section class="email-edit-page">
    <am2-advanced-settings-modal
      title="Advanced Settings"
      :is-show="showAdvancedSettingsModal"
      @close="closeAdvancedSettingsClick"
      @input="handleAdvancedSettingsModalConfirm"
      type="signup-form"
    />
    <div
      :class="[
        'header-section',
        displayMobilePreview && 'now-visible',
      ]"
    >
      <am2-top-create
        :type="isEditSignupForm ? `purple` : `white`"
        :total-steps="isEditSignupForm ? null : totalStep"
        :current-step="currentStep"
        :hasAdvancedSettings="true"
        :handleAdvancedSettingsClick="handleAdvancedSettingsClick"
        :exit-path="`/message-center/forms`"
        :title="pageTitle"
        :style="{
          position: 'relative',
        }"
      />
    </div>
    <div class="body-section">
      <div
        :class="[
          'edit-section',
          hasNoSpaceForRightPreview && 'full-size',
          $arMediaQuery.window.maxWidth('sm') && 'small-window-size',
        ]"
      >
        <div
          :class="[
            'edit-top-section',
            $arMediaQuery.window.maxWidth('sm') && 'small-window-size',
          ]"
          ref="content"
        >
          <div class="page-content">
            <nuxt-child
              ref="modify-signup-form-page"
            />
          </div>
        </div>
        <div
          :class="[
            'edit-navigation-section',
            $arMediaQuery.window.maxWidth('sm') && 'small-window-size',
          ]"
        >
          <ar-simple-button
            v-if="currentStep === 1"
            text="Cancel"
            outlined
            :style="{ width: $arMediaQuery.window.minWidth('sm') ? '100px' : '90px' }"
            @click="handleCancelClick"
          />
          <ar-simple-button
            v-else-if="currentStep > 1"
            text="Back"
            outlined
            :style="{ width: $arMediaQuery.window.minWidth('sm') ? '100px' : '90px' }"
            @click="handlePreviousStepClick"
          />


          <div class="u-display-flex">
            <ar-simple-button
              v-if="hasNoSpaceForRightPreview"
              :text="rightPreviewIsToggledUp ? 'Edit' : 'Preview'"
              class="u-margin-right-3"
              @click="handlePreviewClick"
              outlined
              :icon-name="(rightPreviewIsToggledUp || $arMediaQuery.window.maxWidth('xs')) ? null : 'mobile-preview'"
              icon-distance="10px"
              :icon-props="{
                height: '26px',
                color: $arStyle.color.purple200,
              }"
              :style="{
                width: $arMediaQuery.window.minWidth('sm') ? '135px' : '90px',
              }"
            />
            <ar-simple-button
              v-if="currentStep < totalStep"
              text="Next"
              :style="{ width: $arMediaQuery.window.minWidth('sm') ? '135px' : '90px' }"
              v-ar-hotkey="{
                general: ['Control', 'Enter'],
                mac: ['Meta', 'Enter'],
                disabled: false,
                action: handleNextStepClick,
              }"
              @click="handleNextStepClick"
            />
            <ar-simple-button
              v-else-if="currentStep === totalStep"
              :text="'Finish'"
              :style="{ width: $arMediaQuery.window.minWidth('sm') ? '120px' : '90px' }"
              :loading="isPatchingSignupForm || isCreatingSignupForm"
              v-ar-hotkey="{
                general: ['Control', 'Enter'],
                mac: ['Meta', 'Enter'],
                disabled: false,
                action: handleSaveOptInFormClick,
              }"
              @click="handleSaveOptInFormClick"
            />
          </div>
        </div>
      </div>
      <div :class="[
        'right-preview-section',
        hasNoSpaceForRightPreview && 'go-on-top-of-edit-section',
        $arMediaQuery.window.maxWidth('sm') && 'small-window-size',
        displayMobilePreview && 'is-visible'
      ]">
        <am2-campaign-preview
          ref="campaign-preview"
          :has-account="false"
          :has-fan="false"
          :has-footer="!hasNoSpaceForRightPreview"
          :preview-page="'/'"
          :campaign="prunedScratchSignupFormForPreview"
          size="fullHeight"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import { clone } from '@/utils/helpers';

export default {
  name: 'EmailEdit',
  layout: 'edit',

  data() {
    return {
      hasBeenUpdated: false,
      totalStep: 2,
      steps: [
        'basic',
        'details',
      ],
      rightPreviewIsToggledUp: false,
      windowHeight: window.innerHeight,
      showAdvancedSettingsModal: false,
    };
  },

  computed: {
    ...mapState({
      step: (state) => state.route.path.substring(state.route.path.lastIndexOf('/') + 1),
      signupFormOid: (state) => state.route.params.signupFormOid,
      currentMessageList: state => state.messageList.currentMessageList,
      currentSignupForm: state => state.signupForm.currentSignupForm,
      scratchSignupForm: state => state.signupForm.scratchSignupForm,
      isFetchingSignupForm: state => state.signupForm.isFetchingSignupForm,
      isPatchingSignupForm: state => state.signupForm.isPatchingSignupForm,
      isCreatingSignupForm: state => state.signupForm.isCreatingSignupForm,
      isCreatingScratchSignupForm: state => state.signupForm.isCreatingScratchSignupForm,
      createdSignupForm: state => state.signupForm.createdSignupForm,
    }),

    ...mapGetters([
      'signupForm/prunedScratchSignupFormForServer',
      'signupForm/prunedScratchSignupFormForPreview',
    ]),

    hasNoSpaceForRightPreview() {
      return this.windowHeight < 600 || this.$arMediaQuery.window.maxWidth('md');
    },

    displayMobilePreview() {
      return this.hasNoSpaceForRightPreview && this.rightPreviewIsToggledUp;
    },

    prunedScratchSignupFormForServer() {
      return this['signupForm/prunedScratchSignupFormForServer'];
    },

    prunedScratchSignupFormForPreview() {
      return this['signupForm/prunedScratchSignupFormForPreview'];
    },

    currentStepIndex() {
     return this.steps.findIndex(step => step === this.step);
    },

    currentStep() {
      return this.currentStepIndex + 1;
    },

    isInitializingPage() {
      return this.isFetchingSignupForm || this.isCreatingScratchSignupForm;
    },

    isEditSignupForm() {
      return !!this.scratchSignupForm.oid;
    },

    pageTitle() {
      return this.isEditSignupForm ? 'Edit signup form' : 'Create a signup form'
    },
  },

  watch: {
    scratchSignupForm(val) {
      this.hasBeenUpdated = true;
      sessionStorage.setItem('_ar_promoter_new_signup_form_', JSON.stringify(val));
    },
  },

  async created() {
    // If this signup form is already created, let's fetch the original signup form
    if (this.signupFormOid) {
      await this['signupForm/FETCH_CURRENT_SIGNUP_FORM'](this.signupFormOid);
    }

    if (sessionStorage.getItem('_ar_promoter_new_signup_form_')) {
      const scratchSignupForm = JSON.parse(sessionStorage.getItem('_ar_promoter_new_signup_form_'));
      if (!scratchSignupForm.messageListOid) {
        await this['signupForm/FETCH_MOCKUP_MESSAGE_LIST_FOR_SCRATCH_SIGNUP_FORM']();
      }
      this['signupForm/SET_SCRATCH_SIGNUP_FORM'](scratchSignupForm);
    } else if (this.signupFormOid) {
      this['signupForm/SET_SCRATCH_SIGNUP_FORM'](this.currentSignupForm);
    } else {
      await this['signupForm/FETCH_MOCKUP_MESSAGE_LIST_FOR_SCRATCH_SIGNUP_FORM']();
      await this['signupForm/CREATE_SCRATCH_SIGNUP_FORM']();
    }

    // Let's setup message list for our scratch signup form
    if (this.scratchSignupForm.messageListOid) {
      await this['messageList/FETCH_CURRENT_MESSAGE_LIST']({oid: this.scratchSignupForm.messageListOid});
      this['signupForm/SET_MESSAGE_LIST_IN_SCRATCH_SIGNUP_FORM'](this.currentMessageList);
    }

    // TODO - get rid of this, preview should know when data is ready
    this.$nextTick(() => {
      if (this.$refs['campaign-preview']) {
        this.$refs['campaign-preview'].setupInitialPreviewData();
      }
    });
  },

  mounted() {
    window.addEventListener('resize', this.handleWindowResize);
  },

  async beforeRouteLeave (to, from, next) {
    if (this.hasBeenUpdated && to.path === `/message-center/forms`) {
      const response = await this.SHOW_CONFIRM({ messageHtml: 'Are you sure you want to leave?<br />Unsaved changes will be lost.' });
      if (response) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleWindowResize);
    sessionStorage.removeItem('_ar_promoter_new_signup_form_');
    this['signupForm/RESET_CURRENT_SIGNUP_FORM']();
    this['signupForm/RESET_SCRATCH_SIGNUP_FORM']();
  },

  methods: {
    ...mapActions([
      'messageList/FETCH_CURRENT_MESSAGE_LIST',
      'SHOW_CONFIRM',
      'signupForm/CREATE_SIGNUP_FORM',
      'signupForm/PATCH_SIGNUP_FORM',
      'signupForm/FETCH_MOCKUP_MESSAGE_LIST_FOR_SCRATCH_SIGNUP_FORM',
      'signupForm/CREATE_SCRATCH_SIGNUP_FORM',
      'signupForm/FETCH_CURRENT_SIGNUP_FORM',
    ]),
    ...mapMutations([
      'signupForm/RESET_SCRATCH_SIGNUP_FORM',
      'signupForm/RESET_CURRENT_SIGNUP_FORM',
      'signupForm/SET_SCRATCH_SIGNUP_FORM',
      'signupForm/PUT_SCRATCH_SIGNUP_FORM',
      'signupForm/SET_MESSAGE_LIST_IN_SCRATCH_SIGNUP_FORM',
    ]),
    goToPreviousStep() {
      this.$router.push({
        path: `${this.steps[this.currentStepIndex - 1]}`,
      });
    },
    goToNextStep() {
      this.$router.push({
        path: `${this.steps[this.currentStepIndex + 1]}`,
      });
    },
    async validateInputs() {
      if (!this.$refs['modify-signup-form-page']) { return; }
      await this.$refs['modify-signup-form-page'].$validator.reset();
      const valid = await this.$refs['modify-signup-form-page'].$validator.validateAll();
      if (!valid) {
        this.$arNotification.push({ type: 'error', message: 'Could not save details, please review error messages' });
      }
      return valid;
    },
    handleCancelClick() {
      this.$router.push({
        path: `/message-center/forms`,
      });
    },
    handlePreviousStepClick() {
      this.goToPreviousStep();

      setTimeout(() => {
        this.$refs['content'].scrollTop = 0;
      }, 100)
    },
    handlePreviewClick() {
      this.rightPreviewIsToggledUp = !this.rightPreviewIsToggledUp;
    },
    async handleNextStepClick() {
      const valid = await this.validateInputs();
      if (valid) {
        this.goToNextStep();

        setTimeout(() => {
          this.$refs['content'].scrollTop = 0;
        }, 100)
      }
    },
    async handleSaveOptInFormClick() {
      const valid = await this.validateInputs();
      if (!valid) {
        return;
      }
      let isSuccess;
      if (this.signupFormOid) {
        isSuccess = await this['signupForm/PATCH_SIGNUP_FORM'](this.prunedScratchSignupFormForServer);
      } else {
        isSuccess = await this['signupForm/CREATE_SIGNUP_FORM'](this.prunedScratchSignupFormForServer);
      }
      if (!!isSuccess) {
        this.hasBeenUpdated = false;
        this.$router.push({ path: `/message-center/forms` });
      }
    },
    handleWindowResize() {
      this.windowHeight = window.innerHeight;
    },

    closeAdvancedSettingsClick() {
      this.showAdvancedSettingsModal = false;
    },

    handleAdvancedSettingsModalConfirm(item) {
      this.showAdvancedSettingsModal = false;
    },

    handleAdvancedSettingsClick() {
      this.showAdvancedSettingsModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.email-edit-page {
  display: flex;
  flex-flow: column;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  .header-section {
    flex-shrink: 0;

    &.not-visible {
      display: none;
    }
  }
  .body-section {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    > .edit-section {
      position: relative;
      z-index: $zIndexRegular;
      flex: 1;
      display: flex;
      flex-direction: column;
      height: 100%;
      min-width: 700px;
      max-width: 768px;
      background: #fff;
      overflow: hidden;

      &.full-size {
        min-width: unset;
        max-width: unset;
      }

      > .edit-top-section {
        flex: 1;
        overflow: scroll;
        -webkit-overflow-scrolling: touch;
        padding: 0 80px;

        .page-content {
          max-width: 760px;
          margin: 50px auto;
        }

        &.small-window-size {
          padding: 0 20px;
          .page-content {
            margin: 50px auto 80px;
          }
        }
      }

      .edit-navigation-section {
        flex-shrink: 0;
        height: 110px;
        border-top: 1px solid $skyBlueGrey500;
        background-color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px 80px;

        &.small-window-size {
          padding: 0 12px;
          height: 75px;
        }
      }
    }
    .right-preview-section {
      display: flex;
      align-items: center;
      height: 100%;
      max-height: 780px;
      padding: 45px;

      &.go-on-top-of-edit-section {
        justify-content: center;
        position: fixed;
        padding: 12px;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100vh - 110px);
        background-color:#FFF;
        z-index: $zIndexLow;
        &.small-window-size {
          height: calc(100vh - 75px);
        }
        &.is-visible {
          z-index: $zIndexHigh;
        }
      }
    }
  }
}
</style>
