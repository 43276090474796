<template>
  <portal
    to="modal"
    :disabled="!isShow"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="isShow"
      class="humanitix-modal"
      header="Connect To Humanitix"
      width="590px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
    >
      <div
        slot="body"
        :class="[
          'humanitix-modal-body',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]"
      >
        <ar-field
          label="Account Name"
          class="u-margin-top-5"
        >
          <ar-input
            v-model="accountName"
            name="accountName"
            placeholder="Please enter your Humanitix account name"
            id="humanitix-account-name-input"
            type="text"
            data-test-id="humanitix-account-name-input"
          />
        </ar-field>
        <ar-field
          label="API Key"
          class="u-margin-top-5"
        >
          <ar-input
            v-model="apiKey"
            name="apiKey"
            placeholder="Please enter your Humanitix API key"
            id="humanitix-api-key-input"
            type="password"
            data-test-id="humanitix-api-key-input"
          />
        </ar-field>
      </div>
      <div
        slot="body"
        :class="[
          'humanitix-modal-body',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]"
      >
      </div>
      <div
        slot="footer"
        :class="[
          'humanitix-modal-footer',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]">
        <ar-simple-button
          text="Confirm"
          :loading="loading"
          :disabled="confirmButtonDisabled"
          :style="{ width: '108px' }"
          @click="handleConfirmClick"
          id="humanitix-modal-confirm-button"
          data-test-id="humanitix-modal-confirm-button"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'HumanitixModal',
  components: {},
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      apiKey: null,
      accountName: null
    };
  },

  computed: {
    confirmButtonDisabled() {
      return !this.apiKey || !this.accountName;;
    }
  },

  watch: {
    isShow(val) {
      if (val) {
        this.apiKey = null;
        this.accountName = null;
      }
    },
  },

  methods: {
    ...mapActions([
      'humanitixIntegration/CREATE_HUMANITIX_INTEGRATION',
      'humanitixIntegration/FETCH_INTEGRATION'
    ]),
    async fetchIntegration() {
      const success = await this['humanitixIntegration/FETCH_INTEGRATION']()
      return success;
    },

    async handleConfirmClick() {
      this.errorMessage = null;
      this.httpStatus = null;
      const apiKey = this.apiKey;
      const accountName = this.accountName;
      const response = await this['humanitixIntegration/CREATE_HUMANITIX_INTEGRATION']({
        apiKey,
        accountName
      });
      if (response) {
        if (this.$route.path != '/settings/integrations/humanitix') {
          await this.$router.push({ path: '/settings/integrations/humanitix' });;
        }
        else {
          this.fetchIntegration();
        }
        this.$emit('cancel');
      }
      else {
        this.errorMessage = response.message;
        this.httpStatus = response.httpStatusCode;
      }
    },
    handleCloseClick() {
      this.$emit('cancel');
    }
  }
};
</script>

<style lang="scss" scoped>
.humanitix-modal {
  .humanitix-modal-body {
    padding: 20px 30px 30px;

    &:nth-child(2) {
      border-top: 1px solid $blueGrey500;
    }
  }
  .humanitix-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 25px 30px;
    height: 100px;
  }
}
</style>
