<template>
  <div class="conversions-table">
    <div class="conversions-table__count">
      <ar-text
        :text="formattedTableCount"
        weight="bold"
        size="sm"
      />
      <ar-text
        text="recipients"
        size="sm"
        :style="{ marginLeft: '4px' }"
      />
    </div>
    <div class="conversions-table__top">
      <am2-table-control-section
        v-ar-sticky-top="{
          priority: 1,
        }"
        show-filter-and-search-bar
        show-checked-rows-options-bar
        :show-filter-button="false"
        :show-count-and-options-bar="false"
        :columnSettings="{
          selectedColumnKeys,
          availableColumns,
          action: handleSelectedColumnKeysUpdate,
        }"
        :fans-search="{
          action: handleFanSearch,
        }"
      />
    </div>

    <div class="conversions-table__main">
      <am2-table
        :heads="displayedTableHeadings"
        :rows="isLoading || !isCalculationsFinished ? [] : conversionTableData"
        :loading="isLoading || !isCalculationsFinished"
        empty-text="No messages available"
        has-control-section
      >
        <div
          slot="name"
          slot-scope="{ data }"
          class="conversions-table__cell left"
        >
          <ar-avatar
            :name="determineName(data.firstName, data.lastName, data.emailAddress)"
            :style="{
            marginRight: '10px',
          }"
            :color="'grey'"
          />
          <ar-text
            class="name-text"
            size="xs"
            :text="determineName(data.firstName, data.lastName, data.emailAddress)"
            :style="{
          }"
            multiple-lines
            :max-lines="1"
            v-tooltip.top="{content: determineName(data.firstName, data.lastName, data.emailAddress)}"
          />
        </div>
        <div
          slot="eventName"
          slot-scope="{ data }"
          class="conversions-table__cell left"
        >
          <ar-text
            size="xs"
            :text="data.eventName"
            v-tooltip.top="{content: data.eventName }"
          />
        </div>
        <div
          slot="email"
          slot-scope="{ data }"
          class="conversions-table__cell left"
        >
          <ar-text
            size="xs"
            :text="data.emailAddress"
            v-tooltip.top="{content: data.emailAddress }"
          />
        </div>
        <div
          slot="mobileNumber"
          slot-scope="{ data }"
          class="conversions-table__cell left"
        >
          <ar-text
            size="xs"
            :text="data.mobileNumber"
            v-tooltip.top="{content: data.mobileNumber }"
          />
        </div>
        <div
          slot="ticketType"
          slot-scope="{ data }"
          class="conversions-table__cell left"
        >
          <ar-text
            size="xs"
            :text="data.name"
            v-tooltip.top="{content: data.name }"
          />
        </div>
        <div
          slot="quantity"
          slot-scope="{ data }"
          class="conversions-table__cell conversions-table__cell--right"
        >
          <ar-text
            size="xs"
            :text="data.totalTickets"
            v-tooltip.top="{content: data.totalTickets }"
          />
        </div>
        <div
          slot="orderTotal"
          slot-scope="{ data }"
          class="conversions-table__cell conversions-table__cell--right"
        >
          <ar-text
            size="xs"
            :text="formatPriceNumber(data.gross.value)"
          />
        </div>
        <div
          slot="orderDate"
          slot-scope="{ data }"
          class="conversions-table__cell conversions-table__cell--right"
        >
          <ar-text
            size="xs"
            :text="convertTime(data.orderDate)"
            v-tooltip.top="{content: convertTime(data.orderDate) }"
          />
        </div>
      </am2-table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { sanitizeHtmlChild } from '~/utils/html-element';
import { formatNumberWithCommas } from '~/utils/helpers';
import dayjs from 'dayjs';

const tableHeadings = [
  {
    name: 'Name',
    key: 'name',
    format: 'name',
    sortKey: 'name',
    attributeKeys: 'name',
    align: 'left',
  },
  {
    name: 'Email',
    key: 'email',
    format: 'email',
    attributeKeys: 'email',
    align: 'left',
  },
  {
    name: 'Mobile',
    key: 'mobileNumber',
    format: 'mobileNumber',
    attributeKeys: 'mobileNumber',
    align: 'left',
  },
  {
    name: 'Event name',
    key: 'eventName',
    format: 'eventName',
    attributeKeys: 'eventName',
    align: 'left',
  },
  {
    name: 'Ticket type',
    key: 'ticketType',
    format: 'ticketType',
    attributeKeys: 'ticketType',
    align: 'left',
  },
  {
    name: 'Quantity',
    key: 'quantity',
    sortKey: 'quantity',
    format: 'quantity',
    attributeKeys: 'quantity',
  },
  {
    name: 'Order total',
    key: 'orderTotal',
    sortKey: 'orderTotal',
    format: 'orderTotal',
    attributeKeys: 'orderTotal',
  },
  {
    name: 'Order date',
    key: 'orderDate',
    sortKey: 'orderDate',
    format: 'orderDate',
    attributeKeys: 'orderDate',
  },
];

export default {
  name: 'ConversionTable',
  data() {
    return {
      tableHeadings,
      selectedColumnKeys: [],
      availableColumns: [
        {
          name: 'Name',
          value: 'name',
          disabled: true,
        },
        {
          name: 'Email',
          value: 'email',
          disabled: true,
        },
        {
          name: 'Mobile',
          value: 'mobileNumber',
        },
        {
          name: 'Event name',
          value: 'eventName',
        },
        {
          name: 'Ticket type',
          value: 'ticketType',
        },
        {
          name: 'Quantity',
          value: 'quantity',
        },
        {
          name: 'Order total',
          value: 'orderTotal',
        },
        {
          name: 'Order date',
          value: 'orderDate',
        },
      ]
    }
  },

  mounted() {
    this.setSelectedColumnKeys()
  },

  computed: {
    ...mapState({
      conversionSelectedEvents: state => state.message.conversionSelectedEvents,
      conversionTableCount: state => state.message.conversionTableCount,
      conversionTableData: state => state.message.conversionTableData,
      currentSelectedMessage: state => state.message.currentSelectedMessage,
      isLoading: state => state.message.isFetchingConversionTableData,
      isCalculationsFinished: state => state.message.isCalculationsFinished,
      oid: state => state.route.params.oid,
    }),

    displayedTableHeadings() {
      return this.tableHeadings.filter((heading) => {
        return this.selectedColumnKeys && this.selectedColumnKeys.includes(heading.attributeKeys) ? heading : false
      });
    },

    formattedTableCount() {
      return formatNumberWithCommas(this.conversionTableCount)
    },

    isSms() {
      return this.currentSelectedMessage?.provider === 'sms'
    },
  },

  watch: {
    conversionSelectedEvents: function () {
      this.setSelectedColumnKeys()
    }
  },

  methods: {
    ...mapActions({
      getConversionTableData: 'message/FETCH_CONVERSION_RECIPIENTS',
    }),

    ...mapMutations({
      setConversionTableSearch: 'message/SET_CONVERSION_TABLE_SEARCH',
    }),

    determineName(fName, lName, email) {
      let finalName = fName ? `${fName} ${lName}` : email;
      return sanitizeHtmlChild(finalName || '');
    },

    handleSelectedColumnKeysUpdate(columnKeys) {
      this.selectedColumnKeys = columnKeys;
    },

    async handleFanSearch(search) {
      this.setConversionTableSearch(search);
      await this.getConversionTableData(this.oid)
    },

    formatPriceNumber(number) {
      return '$' + formatNumberWithCommas(number / 100);
    },

    convertTime(timeString) {
      return dayjs(timeString).format("YYYY-MM-DD hh:mm");
    },

    setSelectedColumnKeys() {
      this.selectedColumnKeys = [];
      this.availableColumns.forEach(({ value }) => {
        if (!['eventName', 'mobileNumber', 'ticketType'].includes(value)) {
          this.selectedColumnKeys.push(value)
        }
      })

      if (this.conversionSelectedEvents.length > 1) {
        this.selectedColumnKeys.push('eventName');
      } else if (this.isSms) {
        this.selectedColumnKeys.push('mobileNumber');
        this.selectedColumnKeys.push('ticketType');
      } else {
        this.selectedColumnKeys.push('ticketType');
      }
    }
  },

  beforeDestroy() {
    this.setConversionTableSearch('');
  },
}
</script>

<style lang="scss">
.conversions-table {
  &__cell {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 12px;
  }

  &__cell--right {
    justify-content: flex-end;
  }

  &__count {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }
}

</style>
