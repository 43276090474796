import { NuxtAxiosInstance } from "@nuxtjs/axios";
import { isErrorStatus } from '../utils';
import { CustomApiError } from '../types';

export default (axios: NuxtAxiosInstance) => ({
  async getCustomField(
    promoterOid: number,
    oid: number,
  ): Promise<any> {
    try {
      const { data, status } = await axios.get(`/promoter/${promoterOid}/custom-field/${oid}`);
      if (!status || isErrorStatus(status)) {
        const apiError: CustomApiError = {
          name: 'Fetch Custom Fields error',
          message: `Error fetching custom fields`,
          status,
        };
        throw apiError;
      }
      return data
    } catch (error) {
      throw error
    }
  },

  async getCustomFields(
    promoterOid: number,
    type: string,
    searchFilter: string,
    filter: string,
  ): Promise<any> {
    try {
      const iLikeString = !!searchFilter && searchFilter != '' ? ` AND name ILIKE "%${searchFilter}%"` : ''
      const filterString = `${!!filter && filter != '' ? ' AND ' + filter : ''}`
      const { data, status } = await axios.get(`/promoter/${promoterOid}/custom-field`, {
        params: {
          $filter: `visibility=${type}${iLikeString}${filterString}`
        }
      });

      if (!status || isErrorStatus(status)) {
        const apiError: CustomApiError = {
          name: 'Fetch Custom Fields error',
          message: `Error fetching custom fields`,
          status,
        };
        throw apiError;
      }

      return data
    } catch (error) {
      throw error
    }
  },
  async createCustomField(
    paramOptions: {
      promoterOid: number,
      name: string,
      fieldType: string,
      options: any | null,
      type: string,
      description: string,
      readOnly: boolean,
    }
  ): Promise<any> {
    const {
      promoterOid,
      type,
      name,
      fieldType,
      options,
      description,
      readOnly,
    } = paramOptions;
    try {
      const { data, status} = await axios.post(`/promoter/${promoterOid}/custom-field`, {
        source: "platform",
        promoterOid,
        name,
        fieldType,
        options,
        description,
        readOnly,
        visibility: type,
      })

      if (isErrorStatus(status)) {
        const apiError: CustomApiError = {
          name: 'Create custom field error',
          message: `Error creating custom field`,
          status,
        };
        throw apiError;
      }

      return data
    } catch (error: any) {
      throw error?.response || error
    }
  },
  async updateCustomField(
    paramOptions: {
      promoterOid: number,
      name: string,
      fieldType: string,
      options: any | null,
      type: string,
      description: string,
      readOnly: boolean,
      oid: number,
    }
  ): Promise<any> {
    const {
      oid,
      promoterOid,
      type,
      name,
      fieldType,
      options,
      description,
      readOnly,
    } = paramOptions;
    try {
      const { data, status} = await axios.patch(`/promoter/${promoterOid}/custom-field/${oid}`, {
        source: "platform",
        promoterOid,
        name,
        fieldType,
        options,
        description,
        readOnly,
        visibility: type,
      })

      if (isErrorStatus(status)) {
        const apiError: CustomApiError = {
          name: 'Update custom field error',
          message: `Error updating custom field`,
          status,
        };
        throw apiError;
      }

      return data
    } catch (error) {
      throw error
    }
  },
  async deleteCustomField(
    promoterOid: number,
    oid: number,
  ): Promise<any> {
    try {
      const { data, status} = await axios.delete(`/promoter/${promoterOid}/custom-field/${oid}`)

      if (isErrorStatus(status)) {
        const apiError: CustomApiError = {
          name: 'Delete custom field error',
          message: `Error deleting custom field`,
          status,
        };
        throw apiError;
      }

      return data
    } catch (error) {
      throw error
    }
  },
  async exportCustomFieldCsv(
    promoterOid: number,
    oid: number,
    customFieldProperties: any,
    audienceFilter: string,
  ): Promise<any> {
    try {
      const { type, name } = customFieldProperties

      const customFieldAlias = `${type}[${oid}] as cf${oid}${name}`

      const { data, status} = await axios.get(`/promoter/${promoterOid}/filter-fan-export/`, {
        params: {
          $top: 'all',
          $select: `emailAddress,firstName,lastName,gender,streetAddress,city,state,country,postcode,dob,mobileNumber,${customFieldAlias}`,
          $audienceFilter: audienceFilter
        }
      })

      if (isErrorStatus(status)) {
        const apiError: CustomApiError = {
          name: 'Export custom field CSV error',
          message: `Error exporting custom field CSV`,
          status,
        };
        throw apiError;
      }

      return {data, status}
    } catch (error) {
      throw error
    }
  },

  async updateCustomFieldFanData(
    promoterOid: number,
    fanOid: number,
    customFieldOid: number,
    value: any,
  ):Promise<any> {
    try {
      const { data, status } = await axios.post(`promoter/${promoterOid}/fan/${fanOid}/custom-field/${customFieldOid}/value`, {
        value
      })

      if (isErrorStatus(status)) {
        const apiError: CustomApiError = {
          name: 'Update fan custom field input data error',
          message: `Error updating fan's custom field input data`,
          status,
        };
        throw apiError;
      }

      return {data, status}
    } catch (error) {
      throw error
    }
  },

  async deleteCustomFieldFanData(
    promoterOid: number,
    fanOid: number,
    customFieldOid: number,
  ):Promise<any> {
    try {
      const { data, status } = await axios.delete(`promoter/${promoterOid}/fan/${fanOid}/custom-field/${customFieldOid}/value/${customFieldOid}`)

      if (isErrorStatus(status)) {
        const apiError: CustomApiError = {
          name: 'Delete fan custom field input data error',
          message: `Error deleting fan's custom field input data`,
          status,
        };
        throw apiError;
      }

      return {data, status}
    } catch (error) {
      throw error
    }
  }
});
