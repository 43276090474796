<template>
  <section
    class="integrations"
    :style="{
      padding: $arMediaQuery.pageContent.minWidth('sm') ? '28px 30px 0' : '28px 12px 0',
    }"
  >
    <am2-ask-moshtix-access-token-modal
      :is-show="shouldShowModal('moshtix')"
      :loading="isCreatingMoshtixIntegration"
      @confirm="handleModalConfirm('moshtix', ...arguments)"
      @cancel="handleModalCancel('moshtix')"
    />
    <am2-ask-memberful-client-credentials-modal
      :is-show="shouldShowModal('memberful')"
      :loading="isCreatingMemberfulIntegration"
      @confirm="handleModalConfirm('memberful', ...arguments)"
      @cancel="handleModalCancel('memberful')"
    />
    <am2-ask-ticketek-s3-credentials-modal
      :is-show="shouldShowModal('ticketek')"
      :loading="isCreatingTicketekIntegration"
      @confirm="handleModalConfirm('ticketek', ...arguments)"
      @cancel="handleModalCancel('ticketek')"
    />
    <am2-ask-event-genius-access-token-modal
      :is-show="shouldShowModal('event-genius')"
      :loading="isCreatingEventGeniusIntegration"
      @confirm="handleModalConfirm('event-genius', ...arguments)"
      @cancel="handleModalCancel('event-genius')"
    />
    <am2-ask-dice-access-token-modal
      :is-show="shouldShowModal('dice')"
      :loading="isCreatingDiceIntegration"
      @confirm="handleModalConfirm('dice', ...arguments)"
      @cancel="handleModalCancel('dice')"
    />
    <am2-ask-shopify-access-token-modal
      :is-show="shouldShowModal('shopify')"
      :loading="isCreatingShopifyIntegration"
      @confirm="handleModalConfirm('shopify', ...arguments)"
      @cancel="handleModalCancel('shopify')"
    />
    <am2-see-tickets-uk-modal
      :should-show="shouldShowModal('see-tickets-uk')"
      :is-waiting="isWorkatoWaiting"
      :integration="workatoIntegration"
      :provider-account="workatoProviderAccount"
      @complete="handleWorkatoComplete('see-tickets-uk')"
    />
    <am2-axs-modal
      :is-show="shouldShowModal('axs')"
      :is-waiting="isCreatingAxsIntegration"
      @confirm="handleModalConfirm('axs', ...arguments)"
      @cancel="handleModalCancel('axs')"
    />
    <am2-mailchimp-modal
      :should-show="shouldShowModal('mailchimp')"
      :is-waiting="isWorkatoWaiting"
      :integration="workatoIntegration"
      :provider-account="workatoProviderAccount"
      @complete="handleWorkatoComplete('mailchimp')"
      @cancel="handleModalCancel('mailchimp')"
    />
    <am2-tixr-modal
      :should-show="shouldShowModal('tixr')"
      :is-waiting="isWorkatoWaiting"
      :integration="workatoIntegration"
      :provider-account="workatoProviderAccount"
      @complete="handleWorkatoComplete('tixr')"
      @cancel="handleModalCancel('tixr')"
    />
    <am2-tixr-modal-new
      :is-show="shouldShowModal('tixr-new')"
      :loading="isCreatingTixrIntegration"
      @confirm="handleModalConfirm('tixr-new', ...arguments)"
      @cancel="handleModalCancel('tixr-new')"
    />
    <am2-humanitix-modal
      :is-show="shouldShowModal('humanitix')"
      :loading="isCreatingHumanitixIntegration"
      @confirm="handleModalConfirm('humanitix', ...arguments)"
      @cancel="handleModalCancel('humanitix')"
    />
    <am2-ask-try-booking-credentials-modal
      :is-show="shouldShowModal('try-booking')"
      :loading="isCreatingTryBookingIntegration"
      @confirm="handleModalConfirm('try-booking', ...arguments)"
      @cancel="handleModalCancel('try-booking')"
    />
    <am2-ticketmaster-modal
      :is-show="shouldShowModal('ticketmaster')"
      :loading="isCreatingTicketmasterIntegration"
      @confirm="handleModalConfirm('ticketmaster', ...arguments)"
      @cancel="handleModalCancel('ticketmaster')"
    />
    <am2-moshtix-exports-modal
      :is-show="shouldShowModal('moshtix-exports-new')"
      :loading="isCreatingMoshtixExportsIntegration"
      @confirm="handleModalConfirm('moshtix-exports-new', ...arguments)"
      @cancel="handleModalCancel('moshtix-exports-new')"
    />
    <am2-see-tickets-benelux-modal
      :is-show="shouldShowModal('see-tickets-benelux')"
      :loading="isCreatingSeeTicketsBeneluxIntegration"
      @confirm="handleModalConfirm('see-tickets-benelux', ...arguments)"
      @cancel="handleModalCancel('see-tickets-benelux')"
    />
    <am2-ticketbooth-modal
      :is-show="shouldShowModal('ticketbooth')"
      :loading="isCreatingTicketboothIntegration"
      @confirm="handleModalConfirm('ticketbooth', ...arguments)"
      @cancel="handleModalCancel('ticketbooth')"
    />
    <am2-ticketspice-modal
      :is-show="shouldShowModal('ticketspice')"
      :loading="isCreatingTicketspiceIntegration"
      @confirm="handleModalConfirm('ticketspice', ...arguments)"
      @cancel="handleModalCancel('ticketspice')"
    />
    <am2-showclix-modal
      :is-show="shouldShowModal('showclix')"
      :loading="isCreatingShowclixIntegration"
      @confirm="handleModalConfirm('showclix', ...arguments)"
      @cancel="handleModalCancel('showclix')"
    />
    <am2-ticket-tailor-modal
      :is-show="shouldShowModal('ticket-tailor')"
      :loading="isCreatingTicketTailorIntegration"
      @confirm="handleModalConfirm('ticket-tailor', ...arguments)"
      @cancel="handleModalCancel('ticket-tailor')"
    />

    <am2-category-tabs
      v-model="selectedCategory"
      :items="categoryTabs"
      :style="{
        marginBottom: '30px',
      }"
    />

    <section
      v-for="(integrationItem, integrationItemIdx) of integrationItems"
      :key="integrationItemIdx"
    >
      <am2-integration-card
        v-if="integrationItem.enabled && integrationItem.categories.indexOf(selectedCategory) > -1"
        class="integration-card"
        :title="integrationItem.title"
        :description="integrationItem.description"
        :logo-icon-name="integrationItem.icon"
        :logo-color="integrationItem.iconColor"
        :loading="integrationItem.loading"
        :disabled="integrationItem.disabled"
        :connected="integrationItem.connected"
        :failed="integrationItem.failed"
        @manage="integrationItem.onManage"
        @connect="integrationItem.onConnect"
        :data-test-id="`${integrationItem.key}-card`"
      />
    </section>
  </section>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { isIntegrationEnabled, integrationKey } from "~/store/modules/integration/utils";
import { convertToCamelCase } from '@/utils/helpers';

export default {
  components: {
  },
  name: 'Integrations',
  layout: 'default',

  data() {
    return {
      connectingOauthService: null,
      showModal: {
        'moshtix': false,
        'memberful': false,
        'ticketek': false,
        'event-genius': false,
        'dice': false,
        'shopify': false,
        'see-tickets-uk': false,
        'axs': false,
        'mailchimp': false,
        'tixr': false,
        'tixr-new': false,
        'moshtix-exports-new': false,
        'humanitix': false,
        'try-booking': false,
        'ticketmaster': false,
        'see-tickets-benelux': false,
        'ticketbooth': false,
        'ticketspice': false,
        'showclix': false,
        'ticket-tailor': false
      },
      selectedCategory: 'all',
      workatoIntegration: null
    };
  },
  computed: {

    ...mapState({
      promoter: state => state.auth.account,
      path: state => state.route.path,
      isFetchingIntegrations: state => state.integration.isFetchingIntegrations,
      integrations: state => state.integration.integrations,
      promoterIntegrations: state => state.integration.promoterIntegrations,

      isCreatingMoshtixIntegration: state => state.moshtixIntegration.isCreatingIntegration,
      isCreatingMemberfulIntegration: state => state.memberfulIntegration.isCreatingIntegration,
      isCreatingTicketekIntegration: state => state.ticketekIntegration.isCreatingIntegration,
      isCreatingEventGeniusIntegration: state => state.eventGeniusIntegration.isCreatingIntegration,
      isCreatingAxsIntegration: state => state.axsIntegration.isCreatingIntegration,
      isCreatingDiceIntegration: state => state.diceIntegration.isCreatingIntegration,
      isCreatingShopifyIntegration: state => state.shopifyIntegration.isCreatingIntegration,
      isCreatingHumanitixIntegration: state => state.humanitixIntegration.isCreatingIntegration,
      isCreatingTryBookingIntegration: state => state.tryBookingIntegration.isCreatingIntegration,
      isCreatingTicketmasterIntegration: state => state.ticketmasterIntegration.isCreatingIntegration,
      isCreatingSeeTicketsBeneluxIntegration: state => state.seeTicketsBeneluxIntegration.isCreatingIntegration,
      isCreatingTicketboothIntegration: state => state.ticketboothIntegration.isCreatingIntegration,
      isCreatingTicketspiceIntegration: state => state.ticketspiceIntegration.isCreatingIntegration,
      isCreatingShowclixIntegration: state => state.showclixIntegration.isCreatingIntegration,
      isCreatingTicketTailorIntegration: state => state.ticketTailorIntegration.isCreatingIntegration,
      isCreatingTixrIntegration: state => state.tixrIntegration.isCreatingIntegration,
      isCreatingMoshtixExportsIntegration: state => state.moshtixExportsIntegration.isCreatingIntegration,

      workatoProviderAccount: state => state.workatoIntegration.providerAccount,
      isWorkatoWaiting: state => state.workatoIntegration.isWaiting,
    }),

    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled',
      firstPromoterIntegration: 'integration/firstPromoterIntegration',
      hasPromoterIntegration: 'integration/hasPromoterIntegration'
    }),

    categoryTabs() {
      return [
        { text: 'All', key: 'all' },
        { text: 'Ticketing', key: 'ticketing' },
        { text: 'Virtual Events', key: 'virtual_events' },
        { text: 'Ecommerce', key: 'ecommerce' },
        { text: 'Membership', key: 'membership' },
        { text: 'Advertising', key: 'advertising' },
        { text: 'Messaging', key: 'messaging' },
      ];
    },

    customProviderActions() {
      return {
        'dice': 'diceIntegration/CREATE_DICE_INTEGRATION',
        'event-genius': 'eventGeniusIntegration/CREATE_EVENT_GENIUS_INTEGRATION',
        'memberful': 'memberfulIntegration/CREATE_MEMBERFUL_INTEGRATION',
        'moshtix': 'moshtixIntegration/CREATE_MOSHTIX_INTEGRATION',
        'shopify': 'shopifyIntegration/ADD_INTEGRATION',
        'ticketek': 'ticketekIntegration/CREATE_TICKETEK_INTEGRATION',
        'humanitix': 'humanitixIntegration/CREATE_HUMANITIX_INTEGRATION',
        'try-booking': 'tryBookingIntegration/CREATE_TRY_BOOKING_INTEGRATION',
        'ticketmaster': 'ticketmasterIntegration/CREATE_TICKETMASTER_INTEGRATION',
        'moshtix-exports-new': 'moshtixExportsIntegratin/CREATE_MOSHTIX_EXPORTS_INTEGRATION',
        'see-tickets-benelux': 'seeTicketsBeneluxIntegration/CREATE_SEE_TICKETS_BENELUX_INTEGRATION',
        'ticketbooth': 'ticketboothIntegration/CREATE_TICKETBOOTH_INTEGRATION',
        'ticketspice': 'ticketspiceIntegration/CREATE_TICKETSPICE_INTEGRATION',
        'showclix': 'showclixIntegration/CREATE_SHOWCLIX_INTEGRATION',
        'ticket-tailor': 'ticketTailorIntegration/CREATE_TICKET_TAILOR_INTEGRATION',
        'tixr-new': 'tixrIntegration/CREATE_TIXR_INTEGRATION'
      }
    },

    integrationItems() {
      return this.integrations.map(integration => {
        return {
          key: integrationKey(integration),
          enabled: isIntegrationEnabled(integration, this.promoter),
          categories: integration.categories.split(',').map(str => str.trim()),
          icon: integration.provider,
          iconColor: this.$arStyle.color[convertToCamelCase(integration.provider)] || null,
          title: integration.enabled ? integration.name : `${integration.name} | @arep.co only`,
          description: integration.description,
          loading: this.isIntegrationLoading(integration),
          disabled: this.isIntegrationDisabled(integration),
          connected: this.isIntegrationConnected(integration),
          failed: this.hasIntegrationFailed(integration),
          onManage: this.onManageHandler(integration),
          onConnect: this.onConnectHandler(integration)
        };
      }).sort((a, b) => {
        if (a.connected === b.connected) {
          return a.title.localeCompare(b.title);
        } else if (a.connected) {
          return -1;
        } else {
          return 1;
        }
      });
    }
  },

  mounted() {
    this['integration/FETCH_ALL_INTEGRATIONS']();
  },

  methods: {
    ...mapActions([
      'CONNECT_TO_INTEGRATION',
      'DELETE_INTEGRATION',
      'integration/FETCH_ALL_INTEGRATIONS',
      'moshtixIntegration/CREATE_MOSHTIX_INTEGRATION',
      'moshtixExportsIntegration/CREATE_MOSHTIX_EXPORTS_INTEGRATION',
      'ticketekIntegration/CREATE_TICKETEK_INTEGRATION',
      'memberfulIntegration/CREATE_MEMBERFUL_INTEGRATION',
      'eventGeniusIntegration/CREATE_EVENT_GENIUS_INTEGRATION',
      'axsIntegration/CREATE_AXS_INTEGRATION',
      'diceIntegration/CREATE_DICE_INTEGRATION',
      'shopifyIntegration/ADD_INTEGRATION',
      'workatoIntegration/CREATE_PROVIDER_ACCOUNT',
      'tixrIntegration/CREATE_TIXR_INTEGRATION',
      'humanitixIntegration/CREATE_HUMANITIX_INTEGRATION',
      'tryBookingIntegration/CREATE_TRY_BOOKING_INTEGRATION',
      'ticketmasterIntegration/CREATE_TICKETMASTER_INTEGRATION',
      'seeTicketsBeneluxIntegration/CREATE_SEE_TICKETS_BENELUX_INTEGRATION',
      'ticketboothIntegration/CREATE_TICKETBOOTH_INTEGRATION',
      'ticketspiceIntegration/CREATE_TICKETSPICE_INTEGRATION',
      'showclixIntegration/CREATE_SHOWCLIX_INTEGRATION',
      'ticketTailorIntegration/CREATE_TICKET_TAILOR_INTEGRATION'
    ]),

    isIntegrationLoading(integration) {
      return this.isFetchingIntegrations || this.connectingOauthService === integrationKey(integration);
    },

    isIntegrationDisabled(integration) {
      return this.isFetchingIntegrations || (this.connectingOauthService !== null && this.connectingOauthService !== integrationKey(integration));
    },

    isIntegrationConnected(integration) {
      return this.hasPromoterIntegration(integration.provider, integration.app);
    },

    hasIntegrationFailed(integration) {
      const promoterIntegration = this.firstPromoterIntegration(integration.provider, integration.app);
      if (!!promoterIntegration) {
        return promoterIntegration.status === 'failed';
      }

      return false;
    },

    shouldShowModal(provider) {
      return this.showModal[provider] === true;
    },

    onManageHandler(integration) {
      return () => {
        const key = integrationKey(integration);
        this.$router.push({ path: `/settings/integrations/${key}` });
      }
    },

    onConnectHandler(integration) {
      return () => {
        switch (integration.connection) {
          case 'oauth':
            this.handleConnectOauth(integration);
            break;

          case 'simple-auth':
            this.handleConnectSimpleAuth(integration);
            break;

          case 'workato':
            this.handleConnectWorkato(integration);
            break;

          default:
            break;
        }
      }
    },

    async handleConnectOauth(integration) {
      // remove existing integration
      const existingIntegration = this.firstPromoterIntegration(integration.provider, integration.app);
      if (!!existingIntegration) {
        await this.DELETE_INTEGRATION(existingIntegration.oid);
      }

      // signal that we are connecting
      const key = integrationKey(integration);
      this.connectingOauthService = key;

      try {

        // perform oauth connect and re-route to settings
        await this.CONNECT_TO_INTEGRATION({ provider: integration.provider, app: integration.app, additionalInfo: null });
        await this.$router.push({ path: `/settings/integrations/${key}` });

        // signal finished connect
        this.connectingOauthService = null;
        await this['integration/FETCH_ALL_INTEGRATIONS']();

      } catch (e) {

        // show error if not closed by user
        if (e.name !== 'USER_CLOSE') {
          this.$arNotification.push({ type: 'error', message: `Failed to connect ${integration.name}: ${e.message}` });
          console.error(e);
        }

      } finally {
        this.connectingOauthService = null;
      }
    },

    async handleConnectSimpleAuth(integration) {
      this.showModal[integration.provider] = true;
    },

    async handleConnectWorkato(integration) {
      this.workatoIntegration = integration;
      this.showModal[integration.provider] = true;
    },

    async handleModalConfirm(provider, payload) {
      const action = this.customProviderActions[provider] || null;
      const success = await this[action](payload);
      if (success) {
        await this.$router.push({ path: `/settings/integrations/${provider}` });
        this.showModal[provider] = false;
      }
    },

    async handleModalCancel(provider) {
      this.showModal[provider] = false;
    },

    async handleWorkatoComplete(provider) {
      this.showModal[provider] = false;
      await this.$router.push({ path: `/settings/integrations/${this.workatoIntegration.provider}` });
    },
  },
};
</script>

<style lang="scss" scoped>
.integrations {
  max-width: 1200px;
  margin: 0 auto;
  .integration-card {
    margin-bottom: 10px;
  }
}
</style>
