<template>
  <!-- When you scroll down to the bottom of the page loadMoreRecipients will be triggered -->
  <section v-if="showPage" class="message-view">
    <template>
      <am2-message-preview-modal
        :opened="displaySmsMessagePreviewModal"
        :message="currentSelectedMessage"
        :message-list="currentMessageList"
        :provider="currentSelectedMessageProvider"
        :email-template="savedEmail"
        :message-body="currentSelectedMessageMessageBody"
        :subject="currentSelectedMessageSubject"
        :preview-text="currentSelectedMessagePreviewText"
        :scheduled-at="currentSelectedMessageScheduledAt"
        :sender-name="currentMessageSenderName"
        :facebook-page-name="currentSelectedMessageFacebookPageName"
        :facebook-page-image-src="currentSelectedMessageFacebookPageImageSrc"
        @close="displaySmsMessagePreviewModal = false"
      />
      <am2-email-preview-modal
        :opened="displayEmailMessagePreviewModal"
        :subject="currentSelectedMessageSubject"
        :preview-text="currentSelectedMessagePreviewText"
        :scheduled-at="currentSelectedMessageScheduledAt"
        :sender-name="currentMessageSenderName"
        :email-html="templateHTML"
        :message="currentSelectedMessage"
        :is-loading="isFetchingTemplate"
        @close="displayEmailMessagePreviewModal = false"
        @variant-select="handleVariantSelect"
        show-share-link
      />
    </template>

    <MessageViewHero
      @back-click="handleBackClick"
      :back-path="sanitizedReferringPage"
      @list-click="handleListClick"
      :provider-icon="providerIcon"
      :message="currentSelectedMessage"
      :title="messageTitle"
      :scheduled-at-text="scheduledAtText"
      :message-provider-text="messageProviderText"
      :message-list-name="messageListName"
      :message-list-fans-total="messageListFansTotal"
      :cost-of-message="costOfMessage"
      :targeting-string="targetingString"
      :is-sms="isSms"
      :is-firefox="isFirefox"
    />

    <MessageViewNav :menu="menu" @display-message-modal="handleViewMessage" @on-refresh="handleRefresh" />

    <nuxt-child
      :refresh-recipients="refreshRecipients"
      :ab-preview-winner="abPreviewWinner"
      @refreshed-recipients="handleRefreshedRecipients"
    />
  </section>
</template>
<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import { getFormattedDateTimeWithTimezone } from '@/utils/date/';
import MessageViewHero from './message-view-hero.vue';
import MessageViewNav from './message-view-nav.vue';

export default {
  name: 'MessageView',
  layout: 'default',
  components: {
    MessageViewHero,
    MessageViewNav
  },
  data() {
    return {
      displayEmailMessagePreviewModal: false,
      displaySmsMessagePreviewModal: false,
      isFirefox: false,
      showPage: false,
      refreshRecipients: false,
      abWinner: '',
      generatedHtml: null,
      scratchEmailWithInfo: '',
      isFetchingTemplate: false,
    };
  },

  watch: {
    currentSelectedMessage(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.showPage = true;
      }
    },
    routePath(val) {
      this.initializeData();
    }
  },

  computed: {
    ...mapGetters({
      currentSelectedMessageCurrency: 'message/currentSelectedMessageCurrency',
      historicalPreviousPagePath: 'application/historicalPreviousPagePath',
      isFeatureEnabled: 'auth/isFeatureEnabled',
      getBeefreePreviewAndFooterHtmlForInjection: 'message/getBeefreePreviewAndFooterHtmlForInjection',
      injectInfoToScratchEmail: 'message/injectInfoToScratchEmail',
      isPermissionEnabled: 'auth/isPermissionEnabled',
    }),
    ...mapState({
      oid: state => state.route.params.oid,
      routePath: state => state.route.path,
      currentMessageList: state => state.messageList.currentMessageList,
      currentSelectedMessage: state => state.message.currentSelectedMessage,
      savedEmail: state => state.message.savedEmail,
      messageSenders: state => state.messageSender.messageSenders,
      currentMessageSender: state => state.messageSender.currentMessageSender,
      currentCampaign: state => state.campaign.currentCampaign,
      selectedEmailTemplate: state => state.emailTemplate.selectedEmailTemplate,
      statsAB: state => state.message.statsAB,
      scratchEmailMessage: state => state.message.scratchEmailMessage,
    }),

    templateHTML() {
      if (this.currentSelectedMessage?.abEmail) 
        return this.scratchEmailWithInfo;
      else if (this.currentSelectedMessage?.meta?.presentation?.templateType === 'html')
        return this.currentSelectedMessage?.meta?.presentation?.template
      return this.savedEmail
    },

    sanitizedReferringPage() {
      return this.historicalPreviousPagePath('/message-center/messages');
    },
    currentSelectedMessageProvider() {
      return this.currentSelectedMessage?.provider;
    },
    currentSelectedMessageFacebookPageName() {
      if (this.currentSelectedMessageProvider !== 'facebook') {
        return null;
      }
      if (this.currentSelectedMessage?.meta?.facebookMessenger?.pageName) {
        return this.currentSelectedMessage?.meta?.facebookMessenger?.pageName;
      } else {
        return this.currentMessageList?.meta?.facebookMessenger?.pageName;
      }
    },
    currentSelectedMessageFacebookPageImageSrc() {
      if (this.currentSelectedMessageProvider !== 'facebook') {
        return null;
      }
      if (this.currentSelectedMessage?.meta?.facebookMessenger?.imageSrc) {
        return this.currentSelectedMessage?.meta?.facebookMessenger?.imageSrc;
      } else {
        return this.currentMessageList?.meta?.facebookMessenger?.imageSrc;
      }
    },
    currentSelectedMessageMessageBody() {
      if (this.currentSelectedMessageProvider === 'email') {
        return null;
      }
      return this.currentSelectedMessage?.meta.messageBody;
    },
    currentSelectedMessageSubject() {
      if (this.currentSelectedMessage?.abEmail && this.currentSelectedMessage?.abEmailType === 'subject') {
        return this.scratchEmailMessage.meta?.messageBody?.subject || ''
      }

      return this.currentSelectedMessage?.meta.messageBody.subject;
    },
    currentSelectedMessagePreviewText() {
      if (this.currentSelectedMessage?.abEmail && this.currentSelectedMessage?.abEmailType === 'subject') {
        return this.scratchEmailMessage.meta?.messageBody?.previewText || ''
      }
      return this.currentSelectedMessage?.meta.messageBody.previewText;
    },
    currentSelectedMessageScheduledAt() {
      return this.currentSelectedMessage?.scheduledAt;
    },
    currentMessageSenderName() {
      if (
        this.currentMessageSender &&
        this.currentMessageSender.additionalInfo &&
        this.currentMessageSender.additionalInfo.senderName
      ) {
        return this.currentMessageSender.additionalInfo.senderName;
      } else if (
        this.currentMessageSender &&
        this.currentMessageSender.meta &&
        this.currentMessageSender.meta.email &&
        this.currentMessageSender.meta.email.senderName
      ) {
        return this.currentMessageSender.meta.email.senderName;
      } else if (this.currentMessageList) {
        const messageListPromoterPropertyOid =
          this.currentMessageList
            ? this.currentMessageList.meta?.email?.promoterPropertyOid
            : null;
        const sender = this.messageSenders.find(item => item.oid === messageListPromoterPropertyOid);
        return sender && sender.additionalInfo ? sender.additionalInfo.senderName : '';
      } else {
        return null;
      }
    },

    menu() {
      const menuItems = {
        overview: {
          name: 'Overview',
          to: `/message-center/messages/${this.$route.params.oid}/view/overview`
        },
        recipients: {
          name: 'Recipients',
          to: `/message-center/messages/${this.$route.params.oid}/view/recipients`,
          featureKeys: null
        },
        insights: {
          name: 'Link Activity',
          to: `/message-center/messages/${this.$route.params.oid}/view/link-activity`,
          featureKeys: null
        },
        deliverability: {
          name: 'Deliverability',
          to: `/message-center/messages/${this.$route.params.oid}/view/deliverability`,
          featureKeys: null
        }
      };

      if (this.isFeatureEnabled(['messages', 'conversions']) && this.isPermissionEnabled(['view-sales'])) {
        menuItems.conversions = {
          name: 'Conversions',
          to: `/message-center/messages/${this.$route.params.oid}/view/conversions`,
          featureKeys: null
        };
      }

      if (!this.isSms && this.isFeatureEnabled(['messages', 'abEmails']) && this.isABEmail) {
        menuItems.ab = {
          name: 'A/B Test',
          to: `/message-center/messages/${this.$route.params.oid}/view/ab`,
          featureKeys: null
        };
      }

      return menuItems;
    },

    messageListName() {
      if (!!this.currentCampaign && this.currentMessageList?.meta?.name) {
        return `${this.currentMessageList?.meta?.name} (${this.currentCampaign?.name})`;
      } else if (!!this.currentCampaign) {
        return this.currentCampaign?.name;
      }
      if (!this.currentMessageList) return '';
      if (this.currentMessageList.meta.name) return this.currentMessageList.meta.name;
      return this.currentMessageList.name;
    },

    messageListFansTotal() {
      if (!!this.currentMessageList) {
        return this.currentMessageList?.statsSnapshot?.total || 0;
      }
      return 0;
    },

    costOfMessage() {
      const currency = this.currentSelectedMessageCurrency;
      if (!currency) return null;
      if (!this.currentSelectedMessage.statusDetails) return null;
      if (parseFloat(this.currentSelectedMessage.statusDetails.costs.total) === 0) return null;

      let currencyValue = parseFloat(this.currentSelectedMessage.statusDetails.costs.total);
      currencyValue = currencyValue.toFixed(currency.decimal_digits);
      return `${currency.symbol_native}${currencyValue} ${currency.code}`;
    },

    targetingString() {
      if (!this.currentSelectedMessage?.meta?.recipientFilter?.conditions) return null;
      const conditions = this.currentSelectedMessage.meta.recipientFilter.conditions;
      if (conditions?.length < 1) return null;

      if (conditions[0].name === 'tags') {
        if (conditions[0].data.condition === 'true_to_all' || conditions[0].data.condition === 'true_to_any') {
          return `fans with tag${conditions[0].data.values?.length === 1 ? '' : 's'}`;
        } else {
          return `fans without tag${conditions[0].data.values?.length === 1 ? '' : 's'}`;
        }
      }

      if (conditions[0].name === 'messages_opened') {
        if (conditions[0].data.condition === 'true_to_all' || conditions[0].data.condition === 'true_to_any') {
          return 'fans who opened a specific message';
        } else {
          return "fans who didn't open a specific message";
        }
      }

      if (conditions[0].name === 'messages_clicked') {
        if (conditions[0].data.condition === 'true_to_all' || conditions[0].data.condition === 'true_to_any') {
          return 'fans who clicked a specific message';
        } else {
          return "fans who didn't click a specific message";
        }
      }

      return null;
    },

    senderEmailAddress() {
      // If we have a currentMessageSender, then use that property
      if (this.currentMessageSender && this.currentMessageSender.property) {
        if (this.currentMessageSender.additionalInfo) {
          return `${this.currentMessageSender.additionalInfo.senderName} • ${this.currentMessageSender.property}`;
        }
        return this.currentMessageSender.property;
      }

      // Otherwise, let's get the message sender from messageList
      if (this.messageSenders?.length > 0) {
        const messageListPromoterPropertyOid =
          this.currentMessageList && this.currentMessageList.meta.email
            ? this.currentMessageList.meta.email.promoterPropertyOid
            : null;
        const sender = this.messageSenders.find(item => item.oid === messageListPromoterPropertyOid);
        return sender ? sender.property : '';
      }

      return '';
    },

    scheduledAtText() {
      if (!this?.currentSelectedMessage?.sysMtime && !this.isSms) {
        return null;
      }

      let msgSentDate;
      if (this.currentSelectedMessage?.started) {
        msgSentDate = new Date(this.currentSelectedMessage.started);
      } else if (this.currentSelectedMessage?.scheduledAt) {
        msgSentDate = new Date(this.currentSelectedMessage.scheduledAt);
      } else {
        msgSentDate = new Date(this.currentSelectedMessage.sysMtime);
      }

      return getFormattedDateTimeWithTimezone(msgSentDate);
    },

    providerIcon() {
      if (!this.currentSelectedMessage) {
        return null;
      }
      switch (this.currentSelectedMessage.provider) {
        case 'sms':
          return {
            name: 'sms',
            color: this.$arStyle.color.sms,
            width: '24px',
            height: '24px',
            padding: '14px'
          };
        case 'facebook':
          return {
            name: 'messenger',
            color: this.$arStyle.color.messenger,
            width: '28px',
            height: '28px',
            padding: '12px'
          };
        case 'email':
          return {
            name: 'email',
            color: this.$arStyle.color.email,
            width: '26px',
            height: '24px',
            padding: '14px 13px'
          };
        default:
          return null;
      }
    },

    messageTitle() {
      if (!this.currentSelectedMessage) {
        return null;
      }

      const defaultTitle = this.currentSelectedMessage.provider === 'email' ? 'Untitled email' : null;
      return (
        this.currentSelectedMessage.customerName ||
        this.currentSelectedMessage.meta.messageBody.subject ||
        defaultTitle || ''
      );
    },

    messageListFacebook() {
      if (
        !this.currentMessageList ||
        !this.currentMessageList.meta ||
        !this.currentMessageList.meta.facebookMessenger
      ) {
        return null;
      }
      return this.currentMessageList.meta.facebookMessenger;
    },

    isSms() {
      return this.currentSelectedMessage?.provider === 'sms';
    },

    messageProviderText() {
      if (!this.currentSelectedMessage) {
        return null;
      }
      switch (this.currentSelectedMessage.provider) {
        case 'sms':
          return null;
        case 'email':
          return this.senderEmailAddress;
        case 'facebook':
          return this.messageListFacebook ? this.messageListFacebook.pageName : null;
        default:
          console.log('an unknown message provider type was encountered: ', this.currentSelectedMessage.provider);
          return '';
      }
    },

    isABEmail() {
      return !!this.statsAB && this.statsAB.length > 0;
    },

    abPreviewWinner() {
      if (!this.statsAB || this.statsAB.length < 2) {
        return '';
      }

      const statsA = this.statsAB[0].statusDetails;
      const statsB = this.statsAB[1].statusDetails;

      if (this.statsAB[0].treatment === 'email-subject') {
        const openRateA = statsA.totalMessages > 0 ? (statsA.opened + statsA.clicked) / statsA.totalMessages : 0.0;
        const openRateB = statsB.totalMessages > 0 ? (statsB.opened + statsB.clicked) / statsB.totalMessages : 0.0;
        if (openRateA > openRateB) {
          return this.statsAB[0].variant;
        }
        if (openRateA < openRateB) {
          return this.statsAB[1].variant;
        }
      } else {
        const clickRateA = statsA.totalMessages > 0 ? statsA.clicked / statsA.totalMessages : 0.0;
        const clickRateB = statsB.totalMessages > 0 ? statsB.clicked / statsB.totalMessages : 0.0;
        if (clickRateA > clickRateB) {
          return this.statsAB[0].variant;
        }
        if (clickRateA < clickRateB) {
          return this.statsAB[1].variant;
        }
      }

      // if click or open rates are the same, then don't return a winner
      return '';
    },
    abType() {
      return this.currentSelectedMessage?.abEmailType ?? '';
    }
  },

  created() {
    this.initializeData();
    this['auth/FETCH_PERMISSIONS']();
  },

  beforeDestroy() {
    this['message/RESET_CURRENT_SELECTED_MESSAGE']();
    this['campaign/RESET_CURRENT_CAMPAIGN']();
    this['recipient/RESET_RECIPIENTS']();
    this['message/SET_STATS_AB'](null);
    this['message/RESET_SCRATCH_EMAIL_MESSAGE']();
  },

  methods: {
    ...mapActions([
      'message/FETCH_MESSAGE',
      'message/FETCH_SAVED_EMAIL_TEMPLATE',
      'messageList/FETCH_CURRENT_MESSAGE_LIST',
      'messageSender/FETCH_CURRENT_MESSAGE_SENDER',
      'messageSender/FETCH_MESSAGE_SENDERS',
      'campaign/FETCH_CURRENT_CAMPAIGN',
      'emailTemplate/FETCH_BEEFREE_TEMPLATE',
      'message/FETCH_SAVED_BEEFREE_EMAIL_TEMPLATE',
      'message/FETCH_AB_STATS',
      'emailTemplate/DOWNLOAD_BEEFREE_HTML_FROM_TEMPLATE',
      'auth/FETCH_PERMISSIONS',
    ]),

    ...mapMutations([
      'message/RESET_CURRENT_SELECTED_MESSAGE',
      'campaign/RESET_CURRENT_CAMPAIGN',
      'recipient/RESET_RECIPIENTS',
      'message/SET_STATS_AB',
      'message/SELECT_AB_EMAIL_MESSAGE',
      'message/SET_SCRATCH_EMAIL_TEMPLATE',
      'message/RESET_SCRATCH_EMAIL_MESSAGE',
    ]),

    handleBackClick() {
      const referringPage = this.sanitizedReferringPage;
      this.$router.pop(referringPage);
    },
    async fetchMessageList(oid) {
      await this['messageList/FETCH_CURRENT_MESSAGE_LIST']({ oid });
      return true;
    },
    async fetchMessageTierList(oid) {
      await this['messageList/FETCH_CURRENT_MESSAGE_LIST']({ oid, fetchDeleted: true });
      return true;
    },
    fetchMessageSender(oid) {
      this['messageSender/FETCH_CURRENT_MESSAGE_SENDER']({
        oid,
        allowDeleted: true
      });
    },

    handleViewMessage() {
      if (this.currentSelectedMessage.provider === 'email') {
        if (this.currentSelectedMessage?.abEmail) {
          this['message/SELECT_AB_EMAIL_MESSAGE']('A')
        }
        this.getScratchEmailWithInfo()
        this.displayEmailMessagePreviewModal = true;
      } else {
        this.displaySmsMessagePreviewModal = true;
      }
    },

    handleListClick() {
      if (!!this.currentSelectedMessage?.meta?.initiator?.campaignOid) {
        this.$router.push({
          path: `/campaigns/${this.currentSelectedMessage.meta.initiator.campaignOid}/view/dashboard`
        });
        return;
      }
      if (!!this.currentSelectedMessage?.meta?.initiator?.eventOid) {
        this.$router.push({
          path: `/events/${this.currentSelectedMessage.meta.initiator.eventOid}/view/sales`
        });
        return;
      }

      if (!this.currentMessageList) return;

      this.$router.push({
        path: `/message-center/lists/${this.currentMessageList.oid}/contacts`
      });
    },

    handleRefresh() {
      this.refreshRecipients = true;
    },

    handleRefreshedRecipients() {
      this.refreshRecipients = false;
    },

    async initializeData() {
      this['message/RESET_SCRATCH_EMAIL_MESSAGE']();
      this['message/SET_STATS_AB'](null);
      this['message/RESET_CURRENT_SELECTED_MESSAGE']();

      this['campaign/RESET_CURRENT_CAMPAIGN']();
      const succeed = await this['message/FETCH_MESSAGE'](this.oid);
      if (succeed && !!this.currentSelectedMessage) {
        if (this.currentSelectedMessage?.meta?.initiator?.campaignOid) {
          await this.fetchMessageTierList(this.currentSelectedMessage.meta.messageListOid);
          this['campaign/FETCH_CURRENT_CAMPAIGN'](this.currentSelectedMessage.meta.initiator.campaignOid);
        } else {
          await this.fetchMessageList(this.currentSelectedMessage.meta.messageListOid);
        }

        if (this.currentSelectedMessage.meta?.email?.promoterPropertyOid) {
          await this.fetchMessageSender(this.currentSelectedMessage.meta.email.promoterPropertyOid);
        } else if (this.currentMessageList && this.currentMessageList.meta?.email?.promoterPropertyOid) {
          await this.fetchMessageSender(this.currentMessageList.meta.email.promoterPropertyOid);
        }
        if (!this.messageSenders || (!this.currentMessageSender && this.messageSenders.length === 0)) {
          this['messageSender/FETCH_MESSAGE_SENDERS']();
        }
        if (this.currentSelectedMessage.provider === 'email') {
          if (this.currentSelectedMessage.meta.presentation.templateType === 'beefree') {
            const beefree_template_succeed = await this['emailTemplate/FETCH_BEEFREE_TEMPLATE'](
              this.currentSelectedMessage.meta.templateResourceOid
            );

            if (!!beefree_template_succeed) {
              this['message/FETCH_SAVED_BEEFREE_EMAIL_TEMPLATE'](this.selectedEmailTemplate.url);
            }
          } else if (this.currentSelectedMessage.meta.presentation.templateType === 'html') {
            // Do nothing
          } else {
            this['message/FETCH_SAVED_EMAIL_TEMPLATE'](this.currentSelectedMessage.meta.templateResourceOid);
          }
        }
        if (this.currentSelectedMessage?.abEmail) {
          await this['message/FETCH_AB_STATS'](this.oid);
        }
      }

      if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
        this.isFirefox = true;
      }
    },

    async updateInjectedHtml() {
      this.generatedHtml = await this.getBeefreePreviewAndFooterHtmlForInjection(this.currentMessageSender);
    },

    async getScratchEmailWithInfo() {
      this.isFetchingTemplate = true;
      if (this.scratchEmailMessage?.meta?.presentation?.templateType === 'beefree') {
        await this.updateInjectedHtml();
        const html = await this['emailTemplate/DOWNLOAD_BEEFREE_HTML_FROM_TEMPLATE']({
          design: this.scratchEmailMessage?.meta?.presentation?.template,
          previewHtml: this.generatedHtml?.previewHtml || null,
          footerHtml: this.generatedHtml?.footerHtml || null
        });
        this['message/SET_SCRATCH_EMAIL_TEMPLATE'](html);

        this.scratchEmailWithInfo = html;
      } else if (this.scratchEmailMessage?.meta?.presentation?.templateType === 'html') {
        const html = this.scratchEmailMessage?.meta?.presentation?.template;
        this['message/SET_SCRATCH_EMAIL_TEMPLATE'](html);
        this.scratchEmailWithInfo = html;
      } else {
        // Unlayer
        this.scratchEmailWithInfo = this.injectInfoToScratchEmail(this.currentMessageSender);
      }
      this.isFetchingTemplate = false;
    },

    handleVariantSelect() {
      this.getScratchEmailWithInfo();
    },
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/styles/base/mixins';

@mixin flex-row {
  display: flex;
  flex-flow: row nowrap;
}

.message-view {
  padding-bottom: 100px;
}

.fade-enter {
  opacity: 0;
  visibility: hidden;
}
.fade-enter-active {
  transition: opacity 0.4s ease;
}
/* .fade-leave {
  } */
.fade-leave-active {
  transition: 0.2s opacity ease;
  opacity: 0;
  visibility: hidden;
}
</style>
