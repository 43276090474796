<template>
  <portal
    to="modal"
    :disabled="!opened"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="opened"
      header="Send a test email"
      width="707px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleModalClose"
      class="send-test-email-modal"
    >
      <div slot="body" class="body">
        <ar-field
          label="Send a test email to"
          :error-messages="[veeErrors.first('email-addresses')]"
        >
          <div class="u-display-flex">
            <ar-input
              class="u-flex-grow-1"
              placeholder="bob@acme.com, jane@acme.com"
              v-model="emailAddresses"
              v-validate="{
                required: true,
                emailAddresses: true,
                maxTestEmailRecipients: { max: 9 },
              }"
              data-vv-name="email-addresses"
              data-vv-as="email addresses"
              autocomplete="email"
              @enter="handleSendTestEmailClick"
            />
            <ar-simple-button
              class="u-margin-left-3"
              text="Send test email"
              v-tooltip.top="scratchEmailMessageCannotBeTested && 'Please go back and add a sender and subject line before testing'"
              outlined
              :loading="isUpdatingEmail || isSendingTestEmail"
              :disabled="scratchEmailMessageCannotBeTested"
              :style="{
                width: '148px',
              }"
              @click="handleSendTestEmailClick"
            />
          </div>
          <ar-state-message
            v-if="emailSendToCopy"
            class="u-margin-top-3"
            type="success"
            has-icon
            disableTextColor
            :style="{
              wordBreak: 'break-word',
            }"
            :text="emailSendToCopy"
          />
          <ar-text
            class="u-margin-top-5"
            size="xs"
            text="Use commas to separate multiple emails"
            :style="{
              color: $arStyle.color.blueGrey700,
            }"
          />
        </ar-field>
      </div>
      <div slot="footer" class="footer">
        <ar-simple-button
          text="Done"
          :style="{
            width: '140px',
          }"
          @click="handleDoneClick"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
  name: 'SendTestEmailModal',
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      emailAddresses: '',
      emailSendToCopy: '',
      scratchEmailWithInfo: "",
      generatedHtml: null,
    };
  },
  computed: {
    ...mapState({
      scratchEmailMessage: state => state.message.scratchEmailMessage,
      isSendingTestEmail: state => state.message.isSendingTestEmail,
      isUpdatingEmail: state => state.message.isUpdatingEmail,
      currentMessageSender: state => state.messageSender.currentMessageSender,
    }),
    ...mapGetters({
      injectInfoToScratchEmail: 'message/injectInfoToScratchEmail',
      getBeefreePreviewAndFooterHtmlForInjection: 'message/getBeefreePreviewAndFooterHtmlForInjection',
      scratchEmailMessageCannotBeTested: 'message/scratchEmailMessageCannotBeTested',
    }),
  },
  watch: {
    opened(val) {
      if (val) {
        this.emailSendToCopy = '';
        this.emailAddresses = '';
      }
    },
  },
  methods: {
    ...mapActions([
      'message/UPDATE_EMAIL_TEMPLATE_IN_SCRATCH_EMAIL_MESSAGE',
      'message/SEND_TEST_EMAIL',
      'emailTemplate/DOWNLOAD_BEEFREE_HTML_FROM_TEMPLATE',
    ]),
    generateEmailSendToCopy() {
      const recipients = this.emailAddresses.split(',');
      let lastRecipient = null;
      if (recipients.length > 1) {
        lastRecipient = recipients.pop();
      }

      const emailSentString = lastRecipient ? `A test email was sent to <b>${recipients.join(', ')}</b> and <b>${lastRecipient}</b>. Check your inbox.` : `A test email was sent to <b>${recipients[0]}</b>. Check your inbox.`
      this.emailSendToCopy = emailSentString;
    },
    async validateFields() {
      await this.$validator.reset();
      const isValid = await this.$validator.validateAll();
      return isValid;
    },
    handleModalClose() {
      this.$emit('cancel');
    },
    handleDoneClick() {
      this.$emit('cancel');
    },
    async handleSendTestEmailClick() {
      const isValid = await this.validateFields();
      if (!isValid) {
        return;
      }

      // legacy emails need to upload templates to convert to html
      if (!this.scratchEmailMessage.abEmail) {
        await this.getScratchEmailWithInfo()

        if (this.scratchEmailMessage.meta.presentation.templateType === 'unlayer') {
          const emailTemplateUpdated = await this['message/UPDATE_EMAIL_TEMPLATE_IN_SCRATCH_EMAIL_MESSAGE']({
            emailHtml: this.scratchEmailWithInfo
          });
          if (!emailTemplateUpdated) {
            return;
          }
        }
      }

      await this['message/SEND_TEST_EMAIL']({
        type: "email",
        taskOid: this.scratchEmailMessage.oid,
        recipients: this.emailAddresses.replace(/\s/g, '').split(','),
        meta: this.scratchEmailMessage.meta,
        html: this.scratchEmailWithInfo,
      });

      this.generateEmailSendToCopy();
    },
    async getScratchEmailWithInfo() {
      if (this.scratchEmailMessage?.meta?.presentation?.templateType === 'beefree') {
        const beefreeGeneratedHtml = await this.getBeefreePreviewAndFooterHtmlForInjection(this.currentMessageSender)
        const html = await this['emailTemplate/DOWNLOAD_BEEFREE_HTML_FROM_TEMPLATE']({
          design: this.scratchEmailMessage?.meta?.presentation?.template,
          previewHtml: beefreeGeneratedHtml?.previewHtml || null,
          footerHtml: beefreeGeneratedHtml?.footerHtml || null,
        })

        this.generatedHtml = beefreeGeneratedHtml
        this.scratchEmailWithInfo = html
      } else {
        // Unlayer
        this.scratchEmailWithInfo = this.injectInfoToScratchEmail(this.currentMessageSender);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.send-test-email-modal {
  .body {
    padding: 24px 29px 33px;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 23px 29px;
  }
}
</style>
