<template>
  <div
    class="message-strip-wrapper"
    :class="disabled == true ? 'disabled' : ''"
    :style="{
      background: status !== 'complete' ? '#fff' : '#f6f9fc',
      border: isOpen ? `1px solid ${$arStyle.color.purple500}` : `1px solid ${$arStyle.color.skyBlueGrey500}`,
      'box-shadow': isOpen ? `0px 2px 2px rgba(0, 0, 0, 0.1)` : ''
    }"
  >
    <div class="message-strip-inner">
      <div :class="['icon-wrapper', status !== 'complete' ? 'new-icon-wrapper' : 'edit-icon-wrapper']">
        <EditPencilCircle v-if="status !== 'complete'" :fill="`${$arStyle.color.skyBlueGrey300}`" />
        <GreenTickCircle v-else />
      </div>
      <div class="message-content-wrapper">
        <div v-if="status === 'complete'" class="message-content-top complete">
          <ar-text class="content-title" multiple-lines text="To" weight="bold" size="16px" />
          <ar-link-button
            class="content-link"
            text="Edit"
            has-underline
            @click="handleEdit"
            :data-test-id="`message-to-${contentButtonText}-button`"
          />
        </div>
        <div v-else class="message-content-top new">
          <ar-text class="content-title" multiple-lines text="To" weight="bold" size="16px" />
          <ar-simple-button
            v-if="!isOpen"
            class="content-button"
            text="Add"
            outlined
            weight="bold"
            :style="{
              padding: '10px 16px 11px',
              height: 'unset'
            }"
            @click="handleAdd"
            :data-test-id="`message-to-${contentButtonText}-button`"
          />
        </div>
        <div class="message-content-middle">
          <div :class="['message-content-middle-container', status === 'complete' ? 'complete' : '']">
            <ar-text
              v-if="status !== 'complete'"
              class="left-subheading"
              multiple-lines
              text="Who are you sending this email to?"
              weight="normal"
              size="14px"
            />
            <div v-else class="complete-details-wrapper">
              <div class="complete-left">
                <ar-text class="left-subheading" multiple-lines text="Send to" weight="normal" size="14px" />
                <ar-text class="left-copy" :text="selectedListName" weight="normal" size="16px" />
              </div>
              <div class="complete-right">
                <ar-text class="left-subheading" text="Subscribers" align="right" weight="normal" size="14px" />
                <ar-text class="left-copy" :text="finalRecipientCopy" align="right" weight="normal" size="16px" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isOpen" class="inner-details-wrapper">
      <div class="inner-details-container">
        <ar-text class="subheading" multiple-lines text="Send to" weight="bold" size="14px" />
        <div class="input-search-wrapper">
          <div
            v-if="!showInput"
            :class="['fake-input-wrapper', showInvalidListWarning || ( showPlaceholder && isEmptyError) ? 'invalid-warning' : '']"
            @click="handleFakeInputClick"
          >
            <div class="fake-text-area-wapper">
              <ar-text
                v-if="showPlaceholder"
                class="placeholder"
                text="Select a list..."
                weight="normal"
                size="14px"
                :style="{
                  color: `${$arStyle.color.blueGrey600}`
                }"
              />
              <template v-else>
                <ar-text class="list-name" :text="selectedListName" weight="normal" size="14px" />
                <ar-text
                  class="list-name"
                  :text="recipientCountText"
                  weight="normal"
                  size="14px"
                  :style="{
                    color: `${$arStyle.color.blueGrey700}`,
                    marginLeft: '4px'
                  }"
                />
              </template>
            </div>
            <ArrowHead
              class="send-to-input-dropdown-arrowhead"
              :class="listOpen && 'rotate'"
              height="8px"
              width="10px"
              :fill="$arStyle.color.skyBlueGrey700"
            />
          </div>
          <div v-else :class="['input-arrowhead-wrapper', listOpen ? 'active' : '']">
            <div class="input-selection-container" v-click-outside="handleClickOutside">
              <div v-if="listOpen" class="search-icon-wrapper"><SearchIcon class="search-icon" /></div>
              <ar-input
                ref="sendToInput"
                class="message-input send-to-input"
                :style="{
                  paddingLeft: listOpen ? '23px' : '0px'
                }"
                :value="listSearchText"
                @input="handleListSearchInput"
                :placeholder="sendToPlaceholder"
                @focus="handleFocus"
                autocomplete="off"
              />
              <div class="arrowhead-wrapper" @click="handleArrowClick">
                <ArrowHead
                  class="send-to-input-dropdown-arrowhead"
                  :class="listOpen && 'rotate'"
                  height="8px"
                  width="10px"
                  :fill="$arStyle.color.skyBlueGrey700"
                />
              </div>
            </div>
          </div>
          <div class="filter-button-wrapper">
            <ar-simple-button
              icon-name="filter-alt"
              side-length="40px"
              :icon-props="{
                height: '10px'
              }"
              :style="{
                padding: '0 18px'
              }"
              text="Filter"
              :type="hasFilterConditions ? 'purple' : 'grey'"
              outlined
              :disabled="filterButtonShouldBeDisabled"
              v-tooltip.top="{
                content: filterButtonShouldBeDisabled ? filterButtonTooltip : null
              }"
              class="filter-btn u-margin-left-3"
              @click="handleFilterClick"
              data-test-id="message-to-filter-button"
            />
            <div v-if="hasFilterConditions" class="filter-count-circle-wrapper">
              <span class="filter-recipient-count">{{ filterCount }}</span>
            </div>
          </div>
        </div>
        <ar-text
          v-if="showInvalidListWarning"
          class="no-valid-email-copy"
          text="There are no valid email addresses in this list"
          weight="normal"
          size="12px"
        />
        <ar-text
          v-if="showPlaceholder && isEmptyError"
          class="no-valid-email-copy"
          text="A list is required, please select one"
          weight="normal"
          size="12px"
        />
        <am2-message-list-advanced-targeting-section
          v-if="showAdvancedTargetingModule"
          channel="email"
          show-toggle
          @updateAdvancedTargeting="handleAdvancedTargetingUpdate"
        />
        <div
          v-if="hasFilterConditions"
          :class="['filtered-recipient-wrapper', filteredRecipientListCount === 0 ? 'no-recipients' : '']"
        >
          <div class="filtered-recipient-container">
            <div class="recipients-copy-inner">
              <PeopleIcon
                class="people-icon"
                :color="
                  filteredRecipientListCount > 0 ? `${$arStyle.color.blueGrey700}` : `${$arStyle.color.orange500}`
                "
              />
              <ar-text
                class="recipients-copy"
                :text="recipientsCopy"
                :weight="filteredRecipientListCount === 0 ? 'bold' : 'normal'"
                size="14px"
              />
            </div>
            <ar-link-button
              :class="['filter-reset-link']"
              text="Remove all filters"
              has-underline
              @click="handleFiltersReset"
              :data-test-id="`message-to-filter-reset-link-button`"
            />
          </div>
        </div>
        <div v-if="listOpen" :class="['list-items-wrapper']">
          <div v-for="list in filteredMessageList" :key="list.oid" class="list-item" @click="handleListSelect(list)">
            <ar-text
              class="list-name"
              multiple-lines
              :text="list.meta && list.meta.name ? list.meta.name : list.name"
              :weight="selectedListName === list.name ? 'bold' : 'normal'"
              size="14px"
            />
            <ar-text
              class="list-name"
              :text="
                list.statsSnapshot.total === 1
                  ? `(${list.statsSnapshot.total} recipient)`
                  : `(${list.statsSnapshot.total} recipients)`
              "
              weight="normal"
              size="14px"
              :style="{
                color: `${$arStyle.color.blueGrey700}`,
                marginLeft: '4px'
              }"
            />
          </div>
          <div v-if="noMatchingLists" class="list-item no-match">
            <ar-text
              class="list-name"
              :style="{ marginRight: '4px' }"
              size="xs"
              text="No matching list found"
              multiple-lines
              :max-lines="1"
              line-height="20px"
              weight="normal"
            />
          </div>
        </div>
        <div class="action-buttons-wrapper">
          <ar-link-button
            class="cancel-link"
            text="Cancel"
            has-underline
            @click="handleCancel"
            data-test-id="`message-to-cancel-link`"
          />
          <ar-simple-button
            class="save-button"
            text="Save"
            weight="bold"
            :style="{
              padding: '10px 16px 11px',
              height: 'unset'
            }"
            @click="handleSave"
            data-test-id="`message-to-save-button`"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import GreenTickCircle from '~/assets/icons/green-tick-circle.svg?inline';
import EditPencilCircle from '~/assets/icons/edit-pencil-circle.svg?inline';
import ArrowHead from '~/assets/icons/arrow-head.svg?inline';
import SearchIcon from '~/assets/icons/search.svg?inline';
import PeopleIcon from '~/assets/icons/people-icon.svg?inline';
import accounting from 'accounting';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import { clone } from '@/utils/helpers/';
export default {
  name: 'MessageDetailsToBlock',
  components: {
    EditPencilCircle,
    GreenTickCircle,
    ArrowHead,
    SearchIcon,
    PeopleIcon
  },
  props: {
    isFieldExpanded: Boolean,
    showAdvancedTargetingModule: Boolean
  },
  data() {
    return {
      isOpen: false,
      listOpen: false,
      listSearchText: '',
      filteredMessageList: null,
      selectedList: null,
      recipientCount: 0,
      ignoreSuppressions: false,
      filterCriteria: null,
      showInput: false,
      disabled: false,
      isEmptyError: false,
    };
  },
  watch: {
    prunedScratchSegment() {
      this.fetchFitleredRecipientListCount({ channel: 'email' });
      this.handleAdvancedTargetingUpdate();
    },
    async selectedList() {
      if (!this.selectedList) return;
      this.listSearchText =
        this.selectedList.meta && this.selectedList.meta.name ? this.selectedList.meta.name : this.selectedList.name;
      this.recipientCount = this.selectedList?.statsSnapshot?.total;
      await this.$nextTick(() => {
        this.fetchFitleredRecipientListCount({ channel: 'email' });
      });
    },
    messageLists: {
      immediate: true,
      handler() {
        this.filteredMessageList = this.messageLists;
      }
    },
    currentMessageList: {
      immediate: true,
      async handler() {
        this.fetchFitleredRecipientListCount({ channel: 'email' });
        await this.$nextTick(() => {
          if (this.currentMessageList) {
            this.selectedList = this.currentMessageList;
            this.recipientCount = this.selectedList?.statsSnapshot?.total;
          }
        });
      }
    },
    isFieldExpanded: {
      handler() {
        this.disabled = this.isFieldExpanded == false || this.isOpen ? false : true;
      },
      immediate: true
    }
  },
  computed: {
    ...mapState({
      scratchSegment: state => state.segment.scratchSegment,
      filteredRecipientListCount: state => state.messageList.filteredRecipientListCount,
      currentMessageList: state => state.messageList.currentMessageList,
      advancedMessageListTargeting: state => state.messageList.advancedMessageListTargeting,
      scratchEmailMessage: state => state.message.scratchEmailMessage
    }),
    ...mapGetters({
      messageLists: 'messageList/userDefinedMessageLists',
      prunedScratchSegment: 'segment/prunedScratchSegment',
      getCurrentFilterExpression: 'messageList/getCurrentFilterExpression',
      campaignOid: 'message/scratchEmailMessageCampaignOid',
      eventOid: 'message/scratchEmailMessageEventOid'
    }),
    showInvalidListWarning() {
      return this.selectedList && this.recipientCount === 0;
    },
    finalRecipientCopy() {
      if (this.hasFilterConditions) {
        return `${accounting.formatNumber(this.filteredRecipientListCount)} (filtered)`;
      } else {
        return accounting.formatNumber(this.recipientCount);
      }
    },
    filterButtonShouldBeDisabled() {
      // currently can't apply filters to campaign message lists
      return !this.selectedList || this.recipientCount === 0 || !!this.selectedList.campaignOid;
    },
    filterButtonTooltip() {
      if (!!this.selectedList?.campaignOid) {
        return "Filtering disabled for campaign registrants"
      }
      return null;
    },
    recipientsCopy() {
      if (this.filteredRecipientListCount === 0) {
        return 'No matching recipients';
      } else if (this.filteredRecipientListCount === 1) {
        return `${this.filteredRecipientListCount} recipient will receive your message`;
      } else {
        return `${this.filteredRecipientListCount} recipients will receive your message`;
      }
    },
    status() {
      if (!!this.selectedList && !!this.filteredRecipientListCount && !this.isOpen) {
        return 'complete';
      } else if (!!this.selectedList && !this.filteredRecipientListCount) {
        return 'partial';
      } else {
        return 'new';
      }
    },
    filterCount() {
      return this.scratchSegment?.filter?.conditions.length;
    },
    hasFilterConditions() {
      return (
        this.filterIsNotEmpty ||
        !(!!this.advancedMessageListTargeting.condition && this.advancedMessageListTargeting.type == 'all')
      );
    },
    filterIsNotEmpty() {
      return this.prunedScratchSegment && this.prunedScratchSegment.filter.conditions.length !== 0;
    },

    recipientCountText() {
      if (!this.selectedList) return;
      return this.recipientCount === 1 ? `(${this.recipientCount} recipient)` : `(${this.recipientCount} recipients)`;
    },
    showPlaceholder() {
      return !this.selectedList;
    },
    selectedListName() {
      if (this.eventOid && this.eventOid == this.selectedList?.meta?.eventOid) {
        return 'All attendees';
      }
      if (this.campaignOid && this.campaignOid == this.selectedList?.meta?.campaignOid) {
        return this.selectedList.meta && this.selectedList.meta.name
          ? this.selectedList.meta.name
          : this.selectedList.name;
      } else {
        return this.selectedList?.name;
      }
    },
    noMatchingLists() {
      return !this.filteredMessageList.length;
    },
    sendToPlaceholder() {
      return this.listOpen ? 'Search Lists' : 'Select a list...';
    },
    contentButtonText() {
      return this.status === 'new' ? 'Add' : 'Edit';
    },
    recipientsCount() {
      return this.filteredRecipientListCount || 0;
    },
  },
  methods: {
    ...mapActions({
      fetchMessageLists: 'messageList/FETCH_MESSAGE_LISTS_AND_ATTENDEES',
      fetchFitleredRecipientListCount: 'messageList/FETCH_FILTERED_RECIPIENT_LIST_COUNT'
    }),
    ...mapMutations({
      toggleFilterSidebar: 'layout/TOGGLE_SEGMENT_DRAWER',
      setCurrentMessageList: 'messageList/SET_CURRENT_MESSAGE_LIST',
      resetFilters: 'segment/RESET_SCRATCH_SEGMENT_FROM_MESSAGE_SEGMENT',
      updateFilter: 'message/PUT_FILTERING_IN_SCRATCH_EMAIL_MESSAGE',
      resetAdvancedFilters: 'messageList/RESET_ADVANCED_MESSAGE_LIST_TARGETING'
    }),
    async handleFiltersReset() {
      this.resetFilters();
      await this.resetAdvancedFilters();
      this.handleAdvancedTargetingUpdate();
    },
    handleFilterClick() {
      this.toggleFilterSidebar({ hideCreateSegmentButton: true });
    },
    async handleFakeInputClick() {
      this.showInput = true;
      this.listOpen = true;
      await this.$nextTick();
      this.$refs.sendToInput.focus();
    },
    handleListSearchInput(val) {
      this.listSearchText = val;

      this.filterLists(this.listSearchText);
    },
    async handleListSelect(list) {
      // reset filters if switching to a campaign list
      if (!!list.campaignOid) {
        await this.handleFiltersReset();
      }
      this.selectedList = list;
      this.listOpen = false;
      this.showInput = false;
      this.filteredMessageList = this.messageLists;
      this.setCurrentMessageList(list);
    },
    filterLists(text) {
      let filteredMessageList = [];
      let i = 0;
      while (i < this.messageLists.length) {
        let name =
          this.messageLists[i].meta && this.messageLists[i].meta.name
            ? this.messageLists[i].meta.name
            : this.messageLists[i].name;
        if (this.$arFuzzySearch(name, text)) {
          filteredMessageList.push(this.messageLists[i]);
        }

        i++;
      }

      this.filteredMessageList = filteredMessageList;
    },
    handleClickOutside(e) {
      if (!this.isOpen) return;

      let safeElementClasses = ['list-item', 'arrowhead-wrapper', 'input', 'list-item no-match'];
      if (!safeElementClasses.includes(e.target.className)) {
        this.listOpen = false;
        this.showInput = false;

        let name = ''
        if (!this.selectedList) {
          this.listSearchText = '';
        } else {
          name = this.selectedList.meta && this.selectedList.meta.name ? this.selectedList.meta.name : this.selectedList.name;
        }

        if (!!this.selectedList && this.listSearchText !== name) {
          this.selectedList = null;
          this.listSearchText = '';
          this.showInput = false;
        }
        this.filteredMessageList = this.messageLists;
      }
    },
    handleFocus() {
      this.listOpen = true;
    },
    handleArrowClick() {
      this.listOpen = !this.listOpen;
      if (!this.listOpen) {
        this.showInput = false;
      }
    },
    handleSave() {
      if (!this.selectedList || this.showInvalidListWarning) {
        this.isEmptyError = true;
        return
      };
      this.$emit('update', {
        list: this.selectedList,
        filter: this.scratchSegment?.filter,
        ignoreSuppressions: this.ignoreSuppressions
      });
      this.isOpen = false;
      this.$emit('close');
    },
    handleCancel() {
      if (this.selectedList?.oid !== this.scratchEmailMessage?.meta?.messageListOid
        && typeof this.selectedList?.oid === 'number' && typeof this.scratchEmailMessage?.meta?.messageListOid  === 'number') {
        this.$emit('closeCallback', this.restoreSavedList)
      } else {
        this.restoreSavedList()
      }
    },
    restoreSavedList() {
      if (this.scratchEmailMessage?.meta?.messageListOid) {
        this.selectedList = this.messageLists.find(list => list.oid === this.scratchEmailMessage.meta.messageListOid);
        this.setCurrentMessageList(this.selectedList)
      }
      this.isOpen = false;
      this.filteredMessageList = this.messageLists;
      this.$emit('close');
    },
    handleAdd() {
      this.isOpen = !this.isOpen;
      this.$emit('open');
    },
    handleEdit() {
      this.isOpen = true;
      this.$emit('open');
    },
    async handleAdvancedTargetingUpdate() {
      const targetingFilter = this.appendSidebarFiltersToQuickFilters(clone(this.getCurrentFilterExpression));
      this.updateFilter(targetingFilter);
      await this.$nextTick(() => {
        this.fetchFitleredRecipientListCount({ channel: 'email' });
      });
    },
    appendSidebarFiltersToQuickFilters(targetingFilter) {
      if (!targetingFilter || targetingFilter?.conditions?.length === 0) {
        return this.prunedScratchSegment.filter;
      }

      if (!!this.prunedScratchSegment.filter.conditions && this.prunedScratchSegment.filter.conditions.length > 0) {
        targetingFilter.conditions = this.prunedScratchSegment.filter.conditions.concat(targetingFilter.conditions);
        if (this.prunedScratchSegment.filter.logic.length > 0) {
          targetingFilter.logic = ['(']
            .concat(this.prunedScratchSegment.filter.logic)
            .concat([')'])
            .concat(['and'])
            .concat(targetingFilter.logic);
        } else {
          targetingFilter.logic = ['and'].concat(targetingFilter.logic);
        }
      }

      return targetingFilter;
    },

    // triggered by ref in modify/email/index
    handleEmptyError() {
      this.isOpen = true;
      this.isEmptyError = true;
    },
  },
  async fetch() {
    await this.fetchMessageLists({ eventId: this.eventOid, campaignId: this.campaignOid });
  }
};
</script>
<style lang="scss" scoped>
.message-strip-wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 24px;
  width: 100%;
  margin-top: 30px;
  border: 1px solid $skyBlueGrey500;
  border-radius: 4px;
  &.disabled {
    pointer-events: none; // disable all pointer events
    opacity: 0.5;
  }
  .message-strip-inner {
    min-height: 40px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: auto;

    .icon-wrapper {
      margin-right: 24px;

      &.new-icon-wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
      }
      &.edit-icon-wrapper {
        margin-bottom: auto;
      }
    }

    .message-content-wrapper {
      width: 100%;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
    }

    .message-content-top {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      position: relative;

      &.complete {
        margin-bottom: 21px;
      }

      .content-title {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }

      .content-button {
        padding: 10px 16px 11px;
        border: 1px solid $purple500;
        border-radius: 4px;
        position: absolute;
        right: 0;
        top: 5px;
      }
    }

    .message-content-middle {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      width: 100%;

      .message-content-middle-container {
        &.complete {
          width: 100%;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;

          .complete-details-wrapper {
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;

            .complete-left {
              width: 60%;
              padding-right: 15px;
            }

            .complete-right {
              margin-left: auto;

              .left-copy {
                color: $blueGrey800;
              }
            }

            .left-subheading {
              margin-bottom: 6px;
            }
          }
        }
        .left-subheading {
          color: $skyBlueGrey700;
        }
      }
    }
  }

  .inner-details-wrapper {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    .inner-details-container {
      width: 100%;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      margin-top: 26px;
      position: relative;

      .input-search-wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 12px;

        .fake-input-wrapper {
          width: 100%;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: space-between;
          padding: 17px 19px 17px 15px;
          border: 1px solid $blueGrey500;
          border-radius: 4px;
          cursor: pointer;
          position: relative;

          &.invalid-warning {
            border: 1px solid $red500;
            box-shadow: 0px 0px 0px 3px $red400;
          }

          .fake-text-area-wapper {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;
          }

          .arrowhead-wrapper {
            position: absolute;
            right: 0;
            height: 50px;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            padding: 20px;
            top: 0;

            & > * {
              pointer-events: none;
            }

            .send-to-input-dropdown-arrowhead {
              transition: all 0.2s ease;

              &.rotate {
                transform: rotate(180deg);
              }
            }
          }
        }

        .input-arrowhead-wrapper {
          width: 100%;
          position: relative;
          cursor: pointer;

          .search-icon-wrapper {
            z-index: 1;
            height: 100%;
            position: absolute;
            left: 13px;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;

            .search-icon {
              opacity: 0.4;
            }
          }

          .arrowhead-wrapper {
            position: absolute;
            right: 0;
            height: 50px;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            padding: 20px;
            top: 0;

            & > * {
              pointer-events: none;
            }

            .send-to-input-dropdown-arrowhead {
              transition: all 0.2s ease;

              &.rotate {
                transform: rotate(180deg);
              }
            }
          }
        }

        .filter-button-wrapper {
          position: relative;

          .filter-count-circle-wrapper {
            position: absolute;
            top: 1px;
            right: 1px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 15px;
            height: 15px;
            border-radius: 7.5px;
            background: $purple500;
            transform: translateX(50%) translateY(-50%);
            color: white;
            z-index: $zIndexHigh;

            .filter-recipient-count {
              position: relative;
              font-size: 10px;
              line-height: 10px;
              top: -1.5px;
              left: 0.2px;
            }
          }
        }
      }

      .no-valid-email-copy {
        color: $red500;
        margin-top: 8px;
      }

      .ignore-suppressions-wrapper {
        width: 100%;
        margin-top: 12.5px;
      }

      .filtered-recipient-wrapper {
        background: $skyBlueGrey300;
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        padding: 13px 16px;
        margin-top: 16px;
        border-radius: 4px;

        &.no-recipients {
          background: $orange200;

          .filtered-recipient-container {
            .filter-reset-link {
              color: $orange500;
            }
          }
        }

        .filtered-recipient-container {
          width: 100%;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: space-between;

          .recipients-copy-inner {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;

            .people-icon {
              margin-right: 12px;
            }
          }
        }
      }

      .list-items-wrapper {
        border: 1px solid $blueGrey500;
        border-radius: 4px;
        box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.07);
        width: calc(100% - 100px);
        background: white;
        position: absolute;
        top: 88px;
        z-index: 1;
        max-height: 181px;
        overflow: auto;

        .list-item {
          padding: 12px 20px;
          border-bottom: 1px solid $blueGrey500;
          width: 100%;
          cursor: pointer;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-start;

          &:hover {
            background: #f5f1ff;
          }

          &.no-match {
            cursor: default;
          }

          &:last-child {
            border-bottom: unset;
          }

          & > * {
            pointer-events: none;
          }
        }
      }
      .action-buttons-wrapper {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-end;
        margin-top: 32px;

        .cancel-link {
          margin-right: 16px;
        }
      }
    }
  }
}
.message-list-advanced-targeting-section {
  width: 100%;
}
</style>
