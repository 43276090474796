<template>
  <section 
    data-test-id="company-page-accounts"
    :class="[
      'company-accounts',
      $arMediaQuery.pageContent.minWidth('md') && 'md-max']"
  >
    <div class="accounts-wrapper">

      <ar-simple-button
        text="Create New Account"
        icon-name="circle-plus"
        icon-side="left"
        v-tooltip.bottom="{
          content: `Create a new promoter account connected to this promoter.`,
        }"
        icon-distance="12px"
        :style="{ margin: '15px 0 30px' }"
        @click="handleCreateClick"
      />

      <ar-simple-select
        class="select"
        :items="decoratedPromoterAccounts"
        :default-select-index="selectedPromoterIndex"
        placeholder="Please select or search for an account"
        @select="handlePromoterSelect"
      />

      <div v-if="selectedPromoterAccountOid" class="promoter-account-wrapper">
        <ar-snackbar
          v-if="successUpdate"
          class="u-width-100-percent u-margin-top-4 u-margin-bottom-4"
          type="success"
          message="Successfully updated promoter account"
        />
        <ar-snackbar
          v-if="errorUpdate"
          class="u-width-100-percent u-margin-top-4 u-margin-bottom-4"
          type="error"
          message="Failed updating promoter account"
        />

        <div class="inline-block between">
          <div name="account-created" class="account-created">
            <ar-icon
              name="user-circle"
              height="20px"
              :color="$arStyle.color.blueGrey700"
              class="u-margin-right-3"
            />
            <ar-text
              text="Promoter Account Oid:"
              size="xxs"
              weight="bold"
              class="u-margin-right-2"
            />
            <am2-tag
              type="green"
              shape="rectangle"
              :text="!!selectedPromoter ? selectedPromoter.oid : ''"
              text-size="xxs"
              :style="{ marginRight: '10px' }"
            />
            <ar-text
              text="Created:"
              size="xxs"
              weight="bold"
              class="u-margin-right-2"
            />
            <ar-text
              :text="formattedData.created"
              size="xxs"
              class="u-margin-right-2"
            />
          </div>

          <div class="account-actions">
            <ar-simple-button
              text="Delete Account"
              type="red"
              :style="{ margin: '15px 0 30px' }"
              @click="handleDeleteClick"
            />
    
            <ar-simple-button
              text="Update Account"
              :style="{ margin: '15px 0 30px' }"
              @click="handleUpdateClick"
            />
          </div>
        </div>

        <div class="inline-block">
          <am2-field name="promoter-emailAddress" label="Email Address">
            <div>
              <ar-input
                v-validate.disable="'required|email'"
                id="promoter-emailAddress"
                ref="promoter-emailAddress"
                v-model="editPromoterAccount.emailAddress"
                name="promoter-emailAddress"
                data-vv-as="emailAddress"
                placeholder="Email"
                class="input--block"
                type="text"
              />
              <ar-state-message
                v-if="veeErrors.has('promoter-emailAddress')"
                type="error"
                :text="veeErrors.first('promoter-emailAddress')"
                :style="{ marginTop: '8px' }"
              />
            </div>
          </am2-field>
          <am2-field name="promoter-mobileNumber" label="Mobile number">
            <div>
              <ar-input
                v-validate.disable="'required|email'"
                id="promoter-mobileNumber"
                ref="promoter-mobileNumber"
                v-model="editPromoterAccount.mobileNumber"
                name="promoter-mobileNumber"
                data-vv-as="mobileNumber"
                placeholder="Mobile"
                class="input--block"
                type="text"
              />
              <ar-state-message
                v-if="veeErrors.has('promoter-mobileNumber')"
                type="error"
                :text="veeErrors.first('promoter-mobileNumber')"
                :style="{ marginTop: '8px' }"
              />
            </div>
          </am2-field>
        </div>

        <div class="inline-block">
          <am2-field name="promoter-firstName" label="First name">
            <div>
              <ar-input
                v-validate.disable="'required'"
                id="promoter-firstName"
                v-model="editPromoterAccount.firstName"
                name="promoter-firstName"
                placeholder="First name"
                class="input--block"
                type="text"
              />
              <ar-state-message
                v-if="veeErrors.has('promoter-firstName')"
                type="error"
                :text="veeErrors.first('promoter-firstName')"
                :style="{ marginTop: '8px' }"
              />
            </div>
          </am2-field>
          <am2-field name="promoter-lastname" label="Last name">
            <div>
              <ar-input
                v-validate.disable="'required'"
                id="promoter-lastname"
                v-model="editPromoterAccount.lastName"
                name="promoter-lastname"
                placeholder="Last name"
                class="input--block"
                type="text"
              />
              <ar-state-message
                v-if="veeErrors.has('promoter-lastname')"
                type="error"
                :text="veeErrors.first('promoter-lastname')"
                :style="{ marginTop: '8px' }"
              />
            </div>
          </am2-field>
        </div>

        <am2-field name="promoter-description" label="Description">
          <div>
            <ar-textarea
              v-validate.disable="'required'"
              :rows="4"
              id="promoter-description"
              ref="promoter-description"
              v-model="editPromoterAccount.description"
              name="promoter-description"
              data-vv-as="description"
              placeholder="Description"
              class="input--block"
              type="text"
            />
            <ar-state-message
              v-if="veeErrors.has('promoter-description')"
              type="error"
              :text="veeErrors.first('promoter-description')"
              :style="{ marginTop: '8px' }"
            />
          </div>
        </am2-field>

        <am2-field name="user-role" label="User role (Optional)">
          <am2-user-role-select
            class="u-margin-bottom-1"
            :selectedUserRoles="{
              defaultUserRoleOid: editPromoterAccount.defaultUserRoleOid,
              promoterUserRoleOid: editPromoterAccount.promoterUserRoleOid,
            }"
            data-test-id="user-role-select"
            data-vv-name="user-role-select"
            data-vv-as="user role"
            :default-user-roles="defaultUserRoles"
            :promoter-user-roles="promoterUserRoles"
            @handleFilterInput="handleUserRoleFilterInput"
            @select="handleUserRoleSelect" 
          />
        </am2-field>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { generateMessageCenterDateCopy } from '@/utils/date';
import { companyCategoryTypes } from '@/utils/constants';
import { sanitizeHtmlChild } from '@/utils/html-element/';

export default {
  name: 'CompanyAccounts',

  data() {
    return {
      selectedPromoterAccountOid: null,
      editPromoterAccount: {
        emailAddress: null,
        firstName: null,
        lastName: null,
        mobileNumber: null,
        description: null,
        defaultUserRoleOid: null,
        promoterUserRoleOid: null,
      },
      successUpdate: false,
      errorUpdate: false,
    }
  },

  watch: {
    currentPromoter(val) {
      if (val) {
        this['admin/FETCH_PROMOTER_USER_ROLES']({ oid: val.oid });

        this.setSelectedPromoterAccount()
      }
    },
  },

  computed: {
    ...mapState({
      currentPromoter: state => state.admin.currentPromoter,
      defaultUserRoles: state => state.admin.defaultUserRoles,
      promoterUserRoles: state => state.admin.promoterUserRoles,
    }),

    promoterAccounts() {
      return (this.currentPromoter?.promoterAccounts || []).filter(acc => acc.sysActivep)
    },

    decoratedPromoterAccounts() {
      return this.promoterAccounts.map(promoter => ({
        name: `${promoter.emailAddress} (${promoter.oid})`, 
        oid: promoter.oid,
      }));
    },

    selectedPromoterIndex() {
      return this.promoterAccounts.findIndex(promoter => promoter.oid === this.selectedPromoterAccountOid);
    },

    selectedPromoter() {
      return this.promoterAccounts[this.selectedPromoterIndex]
    },

    formattedData() {
      const { sysCtime } = this.selectedPromoter || {}

      return {
        created: sysCtime ? generateMessageCenterDateCopy(sysCtime) : '',
      }
    },
  },

  async mounted() {
    this['admin/FETCH_DEFAULT_USER_ROLES']({});
    
    if (!this.currentPromoter) {
      await this.fetchPromoter(this.$route.params.oid)
    }

    this.setSelectedPromoterAccount()
  },

  methods: {
    ...mapActions([
      'admin/FETCH_PROMOTER',
      'admin/UPDATE_PROMOTER_ACCOUNT',
      'admin/DELETE_PROMOTER_ACCOUNT',
      'admin/FETCH_DEFAULT_USER_ROLES',
      'admin/FETCH_PROMOTER_USER_ROLES',
      'SHOW_CONFIRM',
    ]),

    handleCreateClick() {
      this.$router.push({
        path: `/admin/accounts/new/`,
        query: { 
          promoterOid: this.currentPromoter.oid,
          ref: 'accounts',
        },
      });
    },

    setSelectedPromoterAccount() {
      if (this.$route.query.selected && !this.selectedPromoterAccountOid) {
          this.selectedPromoterAccountOid = parseInt(this.$route.query.selected, 10)
          this.setPromoterData()
        }
    },

    async fetchPromoter(oid) {
      await this['admin/FETCH_PROMOTER']({ oid });
    },

    async deletePromoterAccount() {
      await this['admin/DELETE_PROMOTER_ACCOUNT']({ 
        oid: this.currentPromoter.oid,
        accountOid: this.selectedPromoter.oid,
      })
      this.selectedPromoterAccountOid = null
      this.fetchPromoter(this.$route.params.oid)
    },

    async handleDeleteClick() {
      await this.SHOW_CONFIRM({
        title: 'Delete this promoter?',
        messageHtml: `You’re about to delete <b>${sanitizeHtmlChild(this.currentPromoter.name)}</b>. Are you sure you want to proceed?`,
        confirmButtonText: 'Delete',
        validation: {
          question: `Type ${this.selectedPromoter.emailAddress} to confirm`,
          answer: this.selectedPromoter.emailAddress,
        },
        asyncCompleteFunction: () => this.deletePromoterAccount(),
      })
    },

    handleUpdateClick() {
      const updatedCorrectly = this['admin/UPDATE_PROMOTER_ACCOUNT']({
        oid: this.currentPromoter.oid,
        accountOid: this.selectedPromoter.oid,
        promoterAccountObj: this.editPromoterAccount,
      })

      if (updatedCorrectly) {
        this.successUpdate = true
        this.errorUpdate = false
      } else {
        this.errorUpdate = true
        this.successUpdate = false
      }
    },

    safelyGetSelectedPromoter() {
      // In case selectedPromoter is not available yet
      return this.selectedPromoter || 
        this.promoterAccounts.find(promoter => promoter.oid === this.selectedPromoterAccountOid)
    },

    setPromoterData() {
      const promoter = this.safelyGetSelectedPromoter()

      if (promoter) {
        this.editPromoterAccount.description = promoter.description
        this.editPromoterAccount.firstName = promoter.firstName
        this.editPromoterAccount.lastName = promoter.lastName
        this.editPromoterAccount.mobileNumber = promoter.mobileNumber
        this.editPromoterAccount.emailAddress = promoter.emailAddress
        this.editPromoterAccount.defaultUserRoleOid = promoter.defaultUserRoleOid
        this.editPromoterAccount.promoterUserRoleOid = promoter.promoterUserRoleOid
      }
    },

    handlePromoterSelect(promoter) {
      this.selectedPromoterAccountOid = promoter.oid
      this.setPromoterData()
    },

    handleUserRoleFilterInput(searchString) {
      this['admin/FETCH_DEFAULT_USER_ROLES']({ searchString });
      this['admin/FETCH_PROMOTER_USER_ROLES']({ oid: this.currentPromoter.oid, searchString });
    },

    handleUserRoleSelect(item) {
      if (item.type === 'default') {
        this.editPromoterAccount.defaultUserRoleOid = item.oid
        this.editPromoterAccount.promoterUserRoleOid = null
      } else {
        this.editPromoterAccount.defaultUserRoleOid = null
        this.editPromoterAccount.promoterUserRoleOid = item.oid
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.account-created {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.account-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.inline-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  &.between {
    justify-content: space-between;
  }

  .ar-field {
    flex: 1;
  }
}
</style>
