<template>
  <div
    :class="[
      'tag',
      type,
      shape,
      isClickable && 'action'
    ]"
    @click="handleTagClick"
  >
    <ar-icon
      v-if="iconName"
      :class="['icon', iconAnimation]"
      :name="iconName"
      v-bind="iconPropsDecorated"
    />
    <div
      :class="[
        'text-section',
        hasCross && 'has-cross'
      ]">
      <ar-text
        :size="textSize"
        :text="text"
        class="text"
        :weight="textWeight"
      />
      <ar-text
        v-if="sideText"
        size="xxxs"
        :text="sideText"
        class="side-text u-margin-left-1"
      />
    </div>
    <div
      v-if="hasCross"
      @click="handleCrossClick"
      class="remove-button"
      :style="{
        ...removeButtonStyle
      }"
    >
      <ar-icon
        name="cross"
        width="10px"
        stroke-width="4"
        :color="removeIconColor"
      />
    </div>
  </div>
</template>

<script>
import { isValueInPixel } from '@/utils/helpers'

export default {
  name: 'Tag',
  props: {
    type: {
      type: String,
      default: 'green',
      validator: (value) =>
        ['green', 'dark-green', 'red', 'purple', 'purple-hard', 'purple500', 'orange', 'grey', 'light-grey', 'white-purple', 'white', 'gold'].indexOf(value) !== -1,
    },
    shape: {
      type: String,
      default: 'round',
      validator: (value) =>
        ['round', 'rectangle'].indexOf(value) !== -1,
    },
    text: {
      type: [String, Number],
      required: true,
    },
    textSize: {
      type: String,
      default: 'xs',
      validator: (value) =>
        ['xxxs', 'xxs', 'xs', 'sm', 'md', 'lg'].indexOf(value) !== -1 || isValueInPixel(value)
    },
    sideText: {
      type: String,
      default: null,
    },
    textWeight: {
      type: String,
      default: 'bold',
    },
    iconName: {
      type: String,
      default: null,
    },
    iconAnimation: {
      type: String,
      default: null,
      validator: (value) => !value || ['blink'].indexOf(value) !== -1,
    },
    iconProps: {
      type: Object,
      default: () => ({
        height: '6px',
      }),
    },
    hasCross: {
      type: Boolean,
      default: false,
    },
    removeButtonStyle: {
      type: Object,
      default: () => null,
    },
    isClickable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconPropsDecorated() {
      return {
        ...this.iconProps,
        name: this.iconName,
      };
    },
    removeIconColor() {
      switch(this.type) {
        case 'grey':
        case 'light-grey':
          return this.$arStyle.color.skyBlueGrey600;
          break;
        case 'red':
          return this.$arStyle.color.red500;
          break;
        case 'purple':
          return this.$arStyle.color.purple500;
          break;
        case 'purple-hard':
          return this.$arStyle.color.purple500;
          break;
        case 'purple500':
          return this.$arStyle.color.purple500;
          break;
        case 'green':
          return this.$arStyle.color.green500;
          break;
        case 'dark-green':
          return this.$arStyle.color.green500;
          break;
        case 'orange':
          return this.$arStyle.color.orange200;
          break;
        case 'white-purple':
          return this.$arStyle.color.purple500;
          break;
        case 'white':
        default:
          return this.$arStyle.color.blueGrey800;
          break;
      }
    },
  },
  methods: {
    handleCrossClick() {
      this.$emit('remove');
    },
    handleTagClick() {
      if (!this.isClickable) return;
      this.$emit('click');
    },
  }
};
</script>


<style lang="scss" scoped>
@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.tag {
  display: inline-flex;
  justify-content: center;
  align-items: center;


  .text-section {
    max-width: 100%;
    &.has-cross {
      max-width: calc(100% - 28px);
    }
  }

  .icon {
    &.blink {
      -webkit-animation: fadeInOut 1.6s infinite;
      animation: fadeInOut 1.6s infinite;
    }
  }

  &.rectangle {
    border-radius: 4px;
    padding: 3px 6px;

    .icon {
      margin-right: 2px;
    }
  }

  &.round {
    border-radius: 15px;
    height: 30px;
    padding: 0 10px;

    .icon {
      margin-right: 5px;
    }
  }

  &.green {
    background: $green100;

    .icon {
      color: $green500;
    }

    .text-section {
      .text {
        color: $green500;
      }

      .side-text {
        color: $green500;
      }
    }
  }

  &.dark-green {
    background: $green200;

    .icon {
      color: $green500;
    }

    .text-section {
      .text {
        color: $green500;
      }

      .side-text {
        color: $green500;
      }
    }
  }

  &.grey {
    background: $skyBlueGrey400;

    .icon {
      color: $blueGrey700;
    }

    .text-section {
      .text {
        color: $blueGrey700;
      }

      .side-text {
        color: $blueGrey700;
      }
    }
  }

  &.light-grey {
    background: $skyBlueGrey300;

    .icon {
      color: $blueGrey700;
    }

    .text-section {
      .text {
        color: $blueGrey800;
      }

      .side-text {
        color: $blueGrey800;
      }
    }
  }

  &.red {
    background: $red400;

    .icon {
      color: $red500;
    }

    .text-section {
      .text {
        color: $red500;
      }

      .side-text {
        color: $red500;
      }
    }
  }

  &.purple {
    background: $purple100;

    .icon {
      color: $purple500;
    }

    .text-section {
      .text {
        color: $purple500;
      }

      .side-text {
        color: $purple400
      }
    }
  }

  &.purple-hard {
    background: $purple600;

    .icon {
      color: white;
    }

    .text-section {
      .text {
        color: white;
      }

      .side-text {
        color: white;
      }
    }
  }

  &.purple500 {
    background: $purple500;

    .icon {
      color: white;
    }

    .text-section {
      .text {
        color: white;
      }

      .side-text {
        color: white;
      }
    }
  }

  &.white {
    border: 1px solid $blueGrey500;
    background: white;

    .icon {
      color: $blueGrey800;
    }

    .text-section {
      .text {
        color: $blueGrey800;
      }

      .side-text {
        color: $skyBlueGrey700
      }
    }
  }

  &.white-purple {
    border: 1px solid $blueGrey500;
    background: white;

    .icon {
      color: $purple500;
    }

    .text-section {
      .text {
        color: $purple500;
      }

      .side-text {
        color: $purple500;
      }
    }
  }

  &.orange {
    background: $orange200;

    .icon {
      color: $orange500;
    }

    .text-section {
      .text {
        color: $orange500;
      }

      .side-text {
        color: $orange500
      }
    }
  }

  &.gold {
    background: #E39425;

    .icon {
      color: white;
    }

    .text-section {
      .text {
        color: white;
      }

      .side-text {
        color: white
      }
    }
  }



  &.action {
    cursor: pointer;
  }

  .text-section {
    display: inline-flex;
    align-items: flex-end;

    .text {
      color: inherit;
    }
  }

  .remove-button {
    background-color: #FFF;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    min-width:20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    cursor: pointer;
    position: relative;
    right: -4px;
    font-weight:bold;
    transition: background-color 0.3s;

    &:hover {
      background-color: $skyBlueGrey300;
    }
  }
}
</style>
