<template>
  <portal
    to="modal"
    :disabled="!isShow"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="isShow"
      class="multi-select-with-menu-modal"
      :header="title"
      width="600px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
    >
      <div slot="body" class="body">
        <div class="navigation">
          <ar-text
            size="xs"
            text="Categories"
            weight="bold"
          />
          <div class="categories u-margin-top-3">
            <div
              v-for="item in interactiveListItems"
              :key="item.key"
              :class="[
                'category-item',
                activeSection === item.key && 'active'
              ]"
              @click="item.action"
              v-show="item.key === 'custom-fields' ? filterSidebar(item.key) : true"

            >
              <ar-text
                size="xs"
                :text="item.title"
                :weight="activeSection === item.key ? 'bold' : 'normal'"
                :style="{
                  color: activeSection === item.key ? $arStyle.color.purple500 : null,
                }"
              />
            </div>
          </div>
        </div>
        <div class="select-menu">
          <div
            v-if="!!description || !!subtitle"
            class="description-subtitle"
          >
            <p v-if="description" :class="[
              'description',
              $arMediaQuery.window.maxWidth('xs') && 'u-padding-y-3',
            ]">
              {{ description }}
            </p>
            <am2-heading
              v-if="subtitle"
              class="subtitle"
              type="h2"
              size="xs"
              :title="subtitle"
              weight="bold"
            />

          </div>
          <div
            :class="[
            'modal-content',
              $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
            ]"
            ref="scrollableContent"
          >
            <am2-search
              v-model="searchString"
              placeholder="Search"
              class="search-input"
            />
            <div
              class="currentSection checkbox-section"
              ref="currentSection"
            >
              <div class="checkbox">
                <ar-text
                  size="xs"
                  text="Current"
                  weight="bold"
                />
              </div>
              <div
                class="checkbox"
                v-if="!currentFields || currentFields.length === 0"
              >
                <ar-text
                  size="xs"
                  text="No matching fields"
                  weight="normal"
                  :style="{
                    color: $arStyle.color.skyBlueGrey700,
                  }"
                />
              </div>
              
              <div
                v-for="item of currentFields"
                :key="item.value"
                class="checkbox"
              >
                <ar-checkbox
                  v-model="selectValueMap[item.value]"
                  :disabled="item.disabled || false"
                  :label="item.name"
                />
                <am2-tag
                  v-if="!!item.error"
                  type="red"
                  text="Error"
                  v-tooltip.top="{
                    content: item.error && item.error.userErrorString ? item.error.userErrorString : null
                  }"
                  text-size="xxxs"
                  class="u-margin-left-3"
                />
              </div>
            </div>

            <div
              class="defaultFieldsSection checkbox-section"
              ref="defaultFieldsSection"
            >
              <div class="checkbox">
                <ar-text
                  size="xs"
                  text="Default fields"
                  weight="bold"
                />
              </div>
              <div
                class="checkbox"
                v-if="!filteredDefaultFields || filteredDefaultFields.length === 0"
              >
                <ar-text
                  size="xs"
                  text="No matching fields"
                  weight="normal"
                  :style="{
                    color: $arStyle.color.skyBlueGrey700,
                  }"
                />
              </div>
              <div
                v-for="item of filteredDefaultFields"
                :key="item.value"
                class="checkbox"
              >
                <ar-checkbox
                  v-model="selectValueMap[item.value]"
                  :disabled="item.disabled || false"
                  :label="item.name"
                />
                <am2-tag
                  v-if="!!item.error"
                  type="red"
                  text="Error"
                  v-tooltip.top="{
                    content: item.error && item.error.userErrorString ? item.error.userErrorString : null
                  }"
                  text-size="xxxs"
                  class="u-margin-left-3"
                />
              </div>
            </div>

            <div
              class="customFieldsSection checkbox-section"
              v-show="!!filteredCustomFields && filteredCustomFields.length > 0"
              ref="customFieldsSection"
            >
              <div class="checkbox">
                <ar-text
                  size="xs"
                  text="Custom fields"
                  weight="bold"
                />
              </div>
              <div
                v-for="item of filteredCustomFields"
                :key="item.value"
                class="checkbox"
              >
                <ar-checkbox
                  v-model="selectValueMap[item.value]"
                  :disabled="item.disabled || false"
                  :label="item.name"
                />
                <am2-tag
                  v-if="!!item.error"
                  type="red"
                  text="Error"
                  v-tooltip.top="{
                    content: item.error && item.error.userErrorString ? item.error.userErrorString : null
                  }"
                  text-size="xxxs"
                  class="u-margin-left-3"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" :class="[
        'footer',
        $arMediaQuery.window.maxWidth('xs') && 'u-padding-3',
      ]">
        <ar-simple-button
          v-if="enableResetButton && resetDefaultKeys.length && showResetButton"
          text="Reset to default"
          type="purple"
          :outlined="true"
          icon-name="refresh"
          @click="handleResetClick"
          :style="{ marginRight: 'auto', border: 'none' }"
        />
        <ar-simple-button
          text="Save"
          @click="handleConfirmClick"
          :style="{ width: '130px' }"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapGetters } from 'vuex';
import { debounce } from "debounce";

export default {
  name: 'MultiSelectWithMenuModal',
  components: {},
  props: {
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    isShow: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Array,
      default: () => [],
    },
    enableResetButton: {
      type: Boolean,
      default: false,
    },
    resetDefaultKeys: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      selectValueMap: {},
      showResetButton: false,
      searchString: '',
      interactiveListItems: [
        {
          key: 'current',
          title: 'Current',
          typeKeys: ['current'],
          action: () => {
            this.resetScroll('currentSection')
          },
        },
        {
          key: 'default-fields',
          title: 'Default fields',
          typeKeys: ['default-fields'],
          action: () => {
            this.resetScroll('defaultFieldsSection')
          },
        },
        {
          key: 'custom-fields',
          title: 'Custom fields',
          typeKeys: ['custom-fields'],
          notTypeKeys: [],
          action: () => {
            this.resetScroll('customFieldsSection')
          },
        },
      ],
      currentYCoordinate: 0,
    };
  },

  computed: {
    currentFields() {
      const selectValueKeys = Object.keys(this.selectValueMap)
      const selectedDefaultFields = !!this.items.defaultFields ? this.items.defaultFields.filter((item) => {
        return selectValueKeys.includes(item.value) && this.filterFields(item)
      }) : []
      const selectedCustomFields = !!this.items.customFields ? this.items.customFields.filter((item) => {
        return selectValueKeys.includes(item.value) && this.filterFields(item)
      }): []
      return [
        ...selectedDefaultFields,
        ...selectedCustomFields,
      ]
    },
    filteredDefaultFields() {
      if (!this.items.defaultFields) {
        return [];
      }

      const selectValueKeys = Object.keys(this.selectValueMap)
      return this.items.defaultFields.filter((item) => {
        return this.filterFields(item)
      })
    },
    filteredCustomFields() {
      if (!this.items.customFields) {
        return [];
      }

      const selectValueKeys = Object.keys(this.selectValueMap)
      return this.items.customFields.filter((item) => {
        return this.filterFields(item)
      })
    },
    activeSection() {
      let currentField = this.$refs.currentSection
      let defaultField = this.$refs.defaultFieldsSection
      let customField = this.$refs.customFieldsSection
      let arModalHeader = document.getElementsByClassName('ar-modal-header')

      let wrapperElements = document.getElementsByClassName('ar-modal-body-inner')
      let offset = 0
      const gap = 24

      if (arModalHeader && arModalHeader[0]) {
        const modalHeaderDimensions = arModalHeader[0].getBoundingClientRect()
        offset = modalHeaderDimensions.height + modalHeaderDimensions.y + gap
      }
      const scrollAreaBefore = (!!wrapperElements && !!wrapperElements[0]) ? wrapperElements[0].scrollTop : 0
      const offsetY = this.currentYCoordinate
      const fieldOffsetY = scrollAreaBefore - offset

      
      if (currentField) {
        const currentFieldDimensions = currentField.getBoundingClientRect()
          
        if (offsetY >= 0 && offsetY < (currentFieldDimensions.top + currentFieldDimensions.height + fieldOffsetY)){
          return 'current'
        }
      }
      
      if (defaultField) {
        const defaultFieldDimensions = defaultField.getBoundingClientRect()
          
        if (offsetY > (defaultFieldDimensions.top + fieldOffsetY - gap) && offsetY < (defaultFieldDimensions.top + defaultFieldDimensions.height + fieldOffsetY)){
          return 'default-fields'
        }
      }
      
      
      if (customField) {
        const customFieldDimensions = customField.getBoundingClientRect()
          
        if (offsetY > (customFieldDimensions.top + fieldOffsetY - gap)){
          return 'custom-fields'
        }
      }

      return 'current'
    }
  },

  mounted() {
    this.initializeSelectValueMap();
  },

  watch: {
    value() {
      this.initializeSelectValueMap();
    },
    selectValueMap: {
      handler(newValue) {
        for (let i = 0; i < this.items.length; i += 1) {
          const correspondingItem = this.items[i];
          const isDefaultKey = this.resetDefaultKeys.includes(correspondingItem.value);

          if ((isDefaultKey && !newValue[correspondingItem.value])
            || (!isDefaultKey && newValue[correspondingItem.value] && !correspondingItem.disabled)) {
            this.showResetButton = true
            return
          }
        }

        this.showResetButton = false
      },
      deep: true,
    },
    isShow(val) {
      if (!!val) {
        // Initialize
        let interval = setInterval(() => {
          let wrapperElements = document.getElementsByClassName('ar-modal-body-inner')
          this.debouncedScrollListener = debounce(this.scrollListener, 10)

          if (!!wrapperElements && wrapperElements[0]) {
            wrapperElements[0].addEventListener('scroll', this.debouncedScrollListener)
            clearInterval(interval)
          }

        }, 500)
      } else {
        // Destroy
        let wrapperElements = document.getElementsByClassName('ar-modal-body-inner')
        if (!!wrapperElements && wrapperElements[0]) {
          wrapperElements[0].removeEventListener('scroll', this.debouncedScrollListener)
        }

        this.currentYCoordinate = 0
      }
    }
  },

  methods: {
    initializeSelectValueMap() {
      const newSelectValueMap = {};
      for (let i = 0; i < this.value.length; i += 1) {
        const correspondingItem = this.items?.defaultFields?.find(item => item.value === this.value[i]) || null;
        const itemHasError = correspondingItem && !!correspondingItem.error;
        newSelectValueMap[this.value[i]] = !itemHasError;
      }
      this.selectValueMap = newSelectValueMap;
    },
    handleConfirmClick() {
      this.$emit('input', this.getSelectValues());
    },
    handleResetClick() {
      const newSelectValueMap = {};

      for (let i = 0; i < this.items.length; i += 1) {
        const correspondingItem = this.items[i];

        if (correspondingItem && (correspondingItem.disabled || this.resetDefaultKeys.includes(correspondingItem.value))) {
          newSelectValueMap[correspondingItem.value] = true;
        }
      }
      this.selectValueMap = newSelectValueMap;
    },
    handleCloseClick() {
      this.$emit('close');
    },
    getSelectValues() {
      const selectValueMapKeys = Object.keys(this.selectValueMap);
      return selectValueMapKeys.filter(key => {
        return this.selectValueMap[key]
      });
    },

    filterFields(field) {
      return this.$arFuzzySearch(field.name, this.searchString || '');
    },
    filterSidebar(fieldKey) {
      if (fieldKey === 'current') {
        return !!this.currentFields && this.currentFields.length > 0
      } else if (fieldKey === 'default-fields') {
        return !!this.filteredDefaultFields && this.filteredDefaultFields.length > 0
      } else if (fieldKey === 'custom-fields') {
        return !!this.filteredCustomFields && this.filteredCustomFields.length > 0
      }

      return false;
    },
    resetScroll(section) {
      let sectionElements = document.getElementsByClassName(section)
      let wrapperElements = document.getElementsByClassName('ar-modal-body-inner')
      let arModalHeader = document.getElementsByClassName('ar-modal-header')
      
      if (sectionElements && sectionElements[0]) {
        if (section === 'currentSection') {
          wrapperElements[0].scrollTo(0, 0)
        } else {
          const modalHeaderDimensions = arModalHeader[0].getBoundingClientRect()
          const sectionElementCoordinates = sectionElements[0].getBoundingClientRect()
          const gap = 24
          const offset = modalHeaderDimensions.height + modalHeaderDimensions.y + gap
          const moveTo = wrapperElements[0].scrollTop + sectionElementCoordinates.top - offset
          wrapperElements[0].scrollTo({ top: moveTo })
        }
      }
    },

    isSectionActive(section) {
      return this.activeSection === section
    },

    scrollListener(scrollData) {
      this.currentYCoordinate = scrollData.srcElement.scrollTop
    }
  },
};
</script>

<style lang="scss" scoped>
.multi-select-with-menu-modal {
  .header {
    font-size: 20px;
  }

  .body {
    display: flex;
    flex-direction: row;
    padding: 24px;
    gap: 24px;
    max-height: 800px;
    justify-content: flex-end;
    min-height: 200px;

    .navigation {
      width: 188px;
      position: fixed;
      height: auto;
      top: 98px;
      left: 24px;

      .categories {
        .category-item {
          padding: 8px;

          &:hover {
            cursor: pointer;
            background-color: $skyBlueGrey300;
            border-radius: 4px;
          }

          &.active {
            background-color: $purple100;
            border-radius: 4px;
            font-weight: bold;
          }
        }
      }
    }

    .select-menu {
      width: calc(100% - 188px - 24px);

      .description-subtitle {
        margin-bottom: 24px;
      }

      .description {
        background: $skyBlueGrey300;
        border-bottom: 1px solid $skyBlueGrey500;
        color: $blueGrey800;
        text-align: center;
      }

      .modal-content {
        display: flex;
        flex-direction: column;
        padding-bottom: 24px;
        .checkbox-section {
          margin-top: 24px;
          border: 1px solid $skyBlueGrey500;
          border-radius: 4px;
          .checkbox {
            display: flex;
            align-items: center;
            height: 51px;

            &:not(:last-child){
              border-bottom: 1px solid $skyBlueGrey500;
            }
            padding: 0 15px;

            .label {
              line-height: 1;
              margin-left: 10px;
            }
          }
        }
      }

    }

  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px 25px;
  }
}
</style>
