import { NuxtAxiosInstance } from "@nuxtjs/axios";
import { isErrorStatus } from '../utils';
import { CustomApiError } from '../types';
import { PromoterIntegration, SyncTask } from './types';

export default (axios: NuxtAxiosInstance) => ({
  /**
   * Fetch integrations
   */
  async fetchAll(promoterOid: number): Promise<PromoterIntegration[]> {
    const { data, status } = await axios.sg.get(`/promoter/${promoterOid}/integration`);
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Fetch integrations error',
        message: `Error doing fetch integrations`,
        status,
      };
      throw apiError;
    }
    return data;
  },
  /**
   * Fetch integrations by app + provider
   */
  async fetchByAppProvider(promoterOid: number, app: string, provider: string): Promise<PromoterIntegration[]> {
    const { data, status } = await axios.sg.get(`/promoter/${promoterOid}/integration`, {
      params: {
        $filter: `app=${app} and provider=${provider}`,
      },
    });
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Fetch integrations by app + provider error',
        message: `Error doing fetch integrations by app + provider`,
        status,
      };
      throw apiError;
    }
    return data;
  },
  /**
   * Delete integration
   */
  async delete(promoterOid: number, oid: number): Promise<void> {
    const { status } = await axios.sg.delete(`/promoter/${promoterOid}/integration/${oid}`);
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Delete integrations error',
        message: `Error doing delete integration with oid ${oid}`,
        status,
      };
      throw apiError;
    }
  },
  /**
   * Start sync
   */
  async startSync(promoterOid: number, integrationOid: number): Promise<SyncTask | null> {
    const { data, status } = await axios.sg.post(`/promoter/${promoterOid}/integration/${integrationOid}/sync`);
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Start sync error',
        message: `Error doing start sync`,
        status,
      };
      throw apiError;
    }
    return data;
  },
  /**
   * Stop sync
   */
  async stopSync(promoterOid: number, integrationOid: number): Promise<SyncTask | null> {
    const { data, status } = await axios.sg.post(`/promoter/${promoterOid}/integration/${integrationOid}/sync-stop`);
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Stop sync error',
        message: `Error doing stop sync`,
        status,
      };
      throw apiError;
    }
    return data;
  },
  /**
   * Fetch sync task
   */
  async fetchSyncTask(promoterOid: number, integrationOid: number): Promise<SyncTask | null> {
    const { data, status } = await axios.sg.get(`/promoter/${promoterOid}/integration/${integrationOid}/task`, {
      params: {
        $orderby: 'sysMtime desc',
        $top: 1,
        $filter: `promoterIntegrationOid=${integrationOid}`,
      }
    });
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Fetch sync task error',
        message: `Error doing fetch sync task`,
        status,
      };
      throw apiError;
    }
    return data[0] || null;
  },
  /**
   * A manual way of creating Memberful integration
   * @returns SyncTask - Once auth is created, Sync task is kicked off and returned
   */
  async createMemberfulIntegration(siteName: string, apiKey: string): Promise<SyncTask> {
    const { data, status } = await axios.sg.post('/simple-auth', {
      provider: 'memberful',
      siteName,
      apiKey,
    });
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Create Memberful Integration error',
        message: `Error creating Memberful integration`,
        status,
      };
      throw apiError;
    }
    return data;
  },
  /**
   * A manual way of creating Ticketek integration
   * @returns SyncTask - Once auth is created, Sync task is kicked off and returned
   */
  async createTicketekIntegration(
    s3BucketName: string,
    s3BucketRegion: string,
    s3BucketAccessKey: string,
    s3BucketSecretKey: string,
    messageList: string,
    tagFans: boolean,
  ): Promise<SyncTask> {
    const { data, status } = await axios.sg.post('/simple-auth', {
      provider: 'ticketek',
      bucketName: s3BucketName,
      bucketRegion: s3BucketRegion,
      bucketAccessKey: s3BucketAccessKey,
      bucketSecretKey: s3BucketSecretKey,
      messageListOid: messageList,
      tagFans: tagFans,
    });
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Create Ticketek Integration error',
        message: `Error creating Ticketek integration`,
        status,
      };
      throw apiError;
    }
    return data;
  },
  /**
   * A direct way to integration 3rd-party services
   * @returns SyncTask - Once auth is created, Sync task is kicked off and returned
   */
  async simpleAuth(username: string, password: string): Promise<SyncTask> {
    const { data, status } = await axios.sg.post('/simple-auth', {
      username,
      password,
    });
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Simple Auth error',
        message: `Error doing simple auth`,
        status,
      };
      throw apiError;
    }
    return data;
  },
  /**
   * A direct way to integration of Event Genius
   * @returns SyncTask - Once auth is created, Sync task is kicked off and returned
   */
  async eventGeniusSimpleAuth(username: string, password: string): Promise<SyncTask> {
    const { data, status } = await axios.sg.post('/simple-auth', {
      provider: 'event-genius',
      app: 'event-genius',
      username,
      password,
    });
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Event Genius Simple Auth error',
        message: `Error doing Event Genius simple auth`,
        status,
      };
      throw apiError;
    }
    return data;
  },
  /**
   * A direct way to integration of DICE
   * @returns SyncTask - Once auth is created, Sync task is kicked off and returned
   */
  async diceSimpleAuth(
    token: string,
    messageList: string,
    tagFans: object,
  ): Promise<SyncTask> {

    const params = {
      provider: 'dice',
      app: 'dice',
      token,
      messageListOid: messageList,
    }

    const { data, status } = await axios.sg.post('/simple-auth', !!tagFans ? {
      ...params,
      tagFans: tagFans
    } : params);

    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'DICE Simple Auth error',
        message: `Error doing DICE simple auth`,
        status,
      };
      throw apiError;
    }
    return data;
  },
  /**
   * A direct way to integration of Moshtix
   * @returns SyncTask - Once auth is created, Sync task is kicked off and returned
   */
  async moshtixSimpleAuth(username: string, password: string, accessToken: string): Promise<SyncTask> {
    const { data, status } = await axios.sg.post('/simple-auth', {
      provider: 'moshtix',
      app: 'moshtix',
      username,
      password,
      accessToken
    });
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Moshtix Simple Auth error',
        message: `Error doing Moshtix simple auth`,
        status,
      };
      throw apiError;
    }
    return data;
  },

  async moshtixExportsSimpleAuth(providerName: string): Promise<SyncTask> {
    const { data, status } = await axios.sg.post('/simple-auth', {
      provider: 'moshtix-exports-new',
      app: 'moshtix-exports-new',
      providerName: providerName
    });
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Moshtix Exports Simple Auth error',
        message: `Error doing Moshtix Exports simple auth`,
        status,
      };
      throw apiError;
    }
    return data;
  },

  /**
   * A direct way to integration of Shopify
   * @returns SyncTask - Once auth is created, Sync task is kicked off and returned
   */
  async shopifySimpleAuth(shopPrefix: string, accessToken: string): Promise<SyncTask> {
    const { data, status } = await axios.sg.post('/simple-auth', {
      provider: 'shopify',
      app: 'shopify',
      shopPrefix: shopPrefix,
      accessToken: accessToken
    });
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Shopify Simple Auth error',
        message: `Error doing Shopify simple auth`,
        status,
      };
      throw apiError;
    }
    return data;
  },

  async tixrSimpleAuth(
    clientPartnerKey: string,
    privateKey: string,
    accountName: string
  ): Promise<SyncTask> {

    const params = {
      provider: 'tixr-new',
      app: 'tixr-new',
      publicKey: clientPartnerKey,
      secretKey: privateKey,
      accountName
    }

    const { data, status } = await axios.sg.post('/simple-auth', params);

    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Tixr Simple Auth error',
        message: `Error setting up the Tixr integration (check login credentials)`,
        status,
      };
      throw apiError;
    }
    return data;
  },

  async humanitixSimpleAuth(
    apiKey: string,
    accountName: string
  ): Promise<SyncTask> {

    const params = {
      provider: 'humanitix',
      app: 'humanitix',
      apiKey,
      accountName
    }

    const { data, status } = await axios.sg.post('/simple-auth', params);

    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Humanitix Simple Auth error',
        message: `Error doing Humanitix simple auth`,
        status,
      };
      throw apiError;
    }
    return data;
  },

  async seeTicketsBeneluxSimpleAuth(
    username: string,
    password: string,
    merchantId: string
  ): Promise<SyncTask> {

    const params = {
      provider: 'see-tickets-benelux',
      app: 'see-tickets-benelux',
      username,
      password,
      merchantId
    }

    const { data, status } = await axios.sg.post('/simple-auth', params);

    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'See Tickets Benelux Simple Auth error',
        message: `Error doing See Tickets Benelux simple auth`,
        status,
      };
      throw apiError;
    }
    return data;
  },

  /**
   * A direct way to integration of try booking
   * @returns SyncTask - Once auth is created, Sync task is kicked off and returned
   */
  async tryBookingSimpleAuth(apiKey: string, secret: string): Promise<SyncTask> {
    const { data, status } = await axios.sg.post('/simple-auth', {
      provider: 'try-booking',
      app: 'try-booking',
      apiKey: apiKey,
      secret: secret
    });
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'TryBooking Simple Auth error',
        message: `Error doing try-booking simple auth`,
        status,
      };
      throw apiError;
    }
    return data;
  },

  /**
   * A direct way to integration of ticketmaster
   * @returns SyncTask - Once auth is created, Sync task is kicked off and returned
   */
  async ticketmasterSimpleAuth(providerName: string): Promise<SyncTask> {
    const { data, status } = await axios.sg.post('/simple-auth', {
      provider: 'ticketmaster',
      app: 'ticketmaster',
      providerName: providerName
    });
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Ticketmaster Simple Auth error',
        message: `Error doing ticketmaster simple auth`,
        status,
      };
      throw apiError;
    }
    return data;
  },
  /*
   * A direct way to integration of ticketbooth
   * @returns SyncTask - Once auth is created, Sync task is kicked off and returned
   */
  async ticketboothSimpleAuth(accountName: string, apiToken: string, email: string, password: string): Promise<SyncTask> {
    const { data, status } = await axios.sg.post('/simple-auth', {
      provider: 'ticketbooth',
      app: 'ticketbooth',
      accountName: accountName,
      apiToken: apiToken,
      email: email,
      password: password
    });
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Ticketbooth Simple Auth error',
        message: `Error doing ticketbooth simple auth`,
        status,
      };
      throw apiError;
    }
    return data;
  },

  /*
   * A direct way to integration of Ticketspice
   * @returns SyncTask - Once auth is created, Sync task is kicked off and returned
   */
  async ticketspiceSimpleAuth(apiKey: string, accountName: string): Promise<SyncTask> {
    const { data, status } = await axios.sg.post('/simple-auth', {
      provider: 'ticketspice',
      app: 'ticketspice',
      apiKey,
      accountName
    });
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Ticketspice Simple Auth error',
        message: `Error doing Ticketspice simple auth`,
        status,
      };
      throw apiError;
    }
    return data;
  },

  /**
   * A direct way to integration of showclix
   * @returns SyncTask - Once auth is created, Sync task is kicked off and returned
   */
  async showclixSimpleAuth(accountName: string, apiToken: string, email: string, password: string): Promise<SyncTask> {
    const { data, status } = await axios.sg.post('/simple-auth', {
      provider: 'showclix',
      app: 'showclix',
      accountName: accountName,
      apiToken: apiToken,
      email: email,
      password: password
    });
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Showclix Simple Auth error',
        message: `Error doing showclix simple auth`,
        status,
      };
      throw apiError;
    }
    return data;
  },

    /**
   * A direct way to integration of ticket tailor
   * @returns SyncTask - Once auth is created, Sync task is kicked off and returned
   */
    async ticketTailorSimpleAuth(apiKey: string, accountName: string): Promise<SyncTask> {
      const { data, status } = await axios.sg.post('/simple-auth', {
        provider: 'ticket-tailor',
        app: 'ticket-tailor',
        apiKey: apiKey,
        accountName: accountName
      });
      if (isErrorStatus(status)) {
        const apiError: CustomApiError = {
          name: 'Ticket tailor simple Auth error',
          message: `Error finishing Ticket tailor simple auth`,
          status,
        };
        throw apiError;
      }
      return data;
    }
});
