<template>
  <!-- This page supports infinite scroll -->
  <section
    class="message-lists-contacts-wrapper"
    v-infinite-scroll="fetchMoreSubscribers"
    infinite-scroll-disabled="disableFetchMoreSubscribers"
    :infinite-scroll-distance="10"
  >
    <ar-snackbar
      v-if="emailNotVerified"
      class="u-width-100-percent"
      type="general"
      message="Please verify this email address. <a>Resend verification email</a>."
      @anchorClick="retryEmailVerification"
    />
    <am2-no-content-section
      v-if="noContacts && !currentMessageList.filterGroupOid && (currentMessageList.email || currentMessageList.sms)"
      class="u-margin-top-7"
      icon-name="ar-subscriber-in-circle"
      :icon-props="{
        height: '79px',
      }"
      header="Import subscribers"
      body="To get started add subscribers to your list. You can either <a>select existing contacts</a>, <a>import a csv file</a> or <a>create a signup form.</a>"
      button-text="Import CSV file"
      :button-props="{
        iconName: null,
      }"
      @buttonClick="handleImportContactsClick"
      @bodyLinkClick="handleNoContactsAnchorClick"
      data-test-id="message-center-lists-contacts-import-csv-file-section"
    />
    <am2-no-content-section
      v-else-if="noContacts && !currentMessageList.filterGroupOid && currentMessageList.facebookMessenger"
      class="u-margin-top-7"
      icon-name="ar-subscriber-in-circle"
      :icon-props="{
        height: '79px',
      }"
      header="Import subscribers"
      body="To get started add subscribers to your list. You can either <a>select existing contacts to add</a> or <a>create a signup form.</a>"
      @bodyLinkClick="handleNoContactsForMessengerAnchorClick"
    />
    <div v-else>
      <am2-fans-control-section
        :fans-count="totalSubscribersCount"
        :order-by="orderBy"
        :import-contacts="importContactsDecorated"
        :loading="isFetchingSubscribers && subscribers.length === 0"
        :csvExport="canExportCsv ? {
          action: handleExportCsvClick
        } : null"
        :style="{
          margin: '22px 0 32px',
        }"
      />
      <am2-subscribers-table
        :head="headings"
        :body="subscribers"
        :loading="isFetchingSubscribers && subscribers.length === 0"
        :empty-text="isAutoList ? 'No contacts match applied filter' : 'No data'"
        has-sticky-header
        @sortBy="handleSortChange"
      />
    </div>
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';

// We're experience performance issue, so we hide "lastMessageTime" at the moment
// export const fetchMessageListFansSearch = 'emailAddress,mobileNumber,firstName,lastName,optedIntoEmail,optedIntoSms,lastMessageTime,fanOid';
export const fetchMessageListFansSearch = 'emailAddress,mobileNumber,firstName,lastName,optedIntoEmail,optedIntoSms,fanOid';

export default {
  name: 'MessageListContacts',
  data: () => ({
    searchString: '',
    orderBy: { key: 'fanOid', order: 'desc' },
    headings: [
      {
        name: 'Name',
        key: 'name',
        format: 'name',
        sortKey: 'firstName',
      },
      {
        name: 'Email',
        key: 'emailAddress',
        format: 'emailAddress',
        sortKey: 'emailAddress',
      },
      {
        name: 'Channel',
        key: 'channel',
        format: 'channel',
      },
      // {
      //   name: 'Last message sent',
      //   key: 'lastMessageTime',
      //   format: 'lastMessageTime',
      // },
    ],
    showExportingCSVtimeout: null,
  }),
  computed: {
    ...mapGetters({
      isPermissionEnabled: 'auth/isPermissionEnabled',
    }),
    ...mapState({
      currentMessageList: state => state.messageList.currentMessageList,
      messageSenders: state => state.messageSender.messageSenders,
      isExportingListSubscribers: state => state.subscriber.isExportingListSubscribers,
      subscribers: state => state.subscriber.subscribers,
      totalSubscribersCount: state => state.subscriber.totalSubscribersCount,
      isFetchingSubscribers: state => state.subscriber.isFetchingSubscribers,
      isNoMoreSubscribers: state => state.subscriber.isNoMoreSubscribers,
      hasFetchSubscribersFailed: state => state.subscriber.hasFetchSubscribersFailed,
    }),
    noContacts() {
      return !this.isFetchingSubscribers && this.subscribers.length === 0;
    },
    disableFetchMoreSubscribers() {
      return this.isFetchingSubscribers || this.isNoMoreSubscribers || this.hasFetchSubscribersFailed;
    },
    promoterPropertyOid() {
      return this.currentMessageList && this.currentMessageList.meta && this.currentMessageList.meta.email && this.currentMessageList.meta.email.promoterPropertyOid ? this.currentMessageList.meta.email.promoterPropertyOid : null;
    },
    currentlySelectedEmailMessageSender() {
      if (!this.promoterPropertyOid) return null;
      return this.messageSenders.find( sender => sender.oid === this.promoterPropertyOid) || null;
    },
    emailNotVerified() {
      if (!this.promoterPropertyOid) { return false; }
      if (!this.currentlySelectedEmailMessageSender) { return false; }
      return !this.currentlySelectedEmailMessageSender.verified;
    },
    importContactsDecorated() {
      if (!this.currentMessageList) return null;
      if (!this.currentMessageList.oid) return null;
      if (this.currentMessageList.filterGroupOid) return null;
      return {
        action: this.handleImportContactsClick,
      }
    },
    isAutoList() {
      return this.currentMessageList && this.currentMessageList.filterGroupOid;
    },
    canExportCsv() {
      return this.isPermissionEnabled(['export-all']);
    },
  },
  watch: {
    totalSubscribersCount(val) {
      this.$emit('contactsCountUpdate', val);
    },
  },
  created() {
    this.reloadSubscribers();
    this['auth/FETCH_PERMISSIONS']();
  },
  beforeDestroy() {
    this['subscriber/RESET_SUBSCRIBERS']();
  },
  methods: {
    ...mapActions([
      'subscriber/FETCH_MORE_SUBSCRIBERS',
      'subscriber/EXPORT_LIST_SUBSCRIBERS_CSV',
      'OPEN_IMPORT_CONTACTS_MODAL',
      'messageSender/RESEND_EMAIL_VERIFICATION',
      'promoterTasks/START_POLLING_PENDING_TASKS',
      'auth/FETCH_PERMISSIONS',
    ]),
    ...mapMutations([
      'subscriber/RESET_SUBSCRIBERS',
    ]),
    async handleExportCsvClick() {
      if (this.isExportingListSubscribers) {
        return;
      }
      this['subscriber/EXPORT_LIST_SUBSCRIBERS_CSV']({
        orderBy: this.orderBy,
        messageListOid: this.currentMessageList.oid,
      });
      setTimeout( () => {
        this['promoterTasks/START_POLLING_PENDING_TASKS']({
          reload: true,
        });
      }, 750);
    },

    handleImportContactsClick() {
      this.OPEN_IMPORT_CONTACTS_MODAL({
        messageList: this.currentMessageList,
        canChangeMessageList: false,
      });
    },

    retryEmailVerification() {
      if (!this.currentlySelectedEmailMessageSender) return;
      this['messageSender/RESEND_EMAIL_VERIFICATION'](this.currentlySelectedEmailMessageSender.oid);
    },

    async reloadSubscribers() {
      const { rows, count } = await this['subscriber/FETCH_MORE_SUBSCRIBERS']({
        select: fetchMessageListFansSearch,
        orderBy: this.orderBy,
        messageListOid: this.currentMessageList.oid,
        reload: true,
      });
    },
    async fetchMoreSubscribers() {
      await this['subscriber/FETCH_MORE_SUBSCRIBERS']({
        select: fetchMessageListFansSearch,
        orderBy: this.orderBy,
        messageListOid: this.currentMessageList.oid,
      });
    },

    handleSortChange(orderObj) {
      let key = orderObj.key;
      const order = orderObj.order;

      this.orderBy = {
        key,
        order,
      };

      this.reloadSubscribers();
    },
    handleNoContactsAnchorClick(anchorIndex) {
      if (anchorIndex === 0) {
        this.$router.push({
          path: '/audience',
        });
      } else if (anchorIndex === 1) {
        this.handleImportContactsClick();
      } else if (anchorIndex === 2) {
        this.$router.push({
          path: '/message-center/signup-forms/new/basic',
        });
      }
    },
    handleNoContactsForMessengerAnchorClick(anchorIndex) {
      if (anchorIndex === 0) {
        this.$router.push({
          path: '/audience',
        });
      } else if (anchorIndex === 1) {
        this.$router.push({
          path: '/message-center/signup-forms/new/basic',
        });
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.message-lists-contacts-wrapper {
  .has-contacts-section {
    .fan-control-panel {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
