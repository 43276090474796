var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"event-details"},[_c('ar-field',{attrs:{"label":"Event name","error-messages":[_vm.veeErrors.first('event-name')]}},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|max:150'),expression:"'required|max:150'"}],attrs:{"value":_vm.scratchEvent ? _vm.scratchEvent.name : "","autocomplete":"event-name","data-vv-name":"event-name","data-vv-as":"event name","placeholder":"Event name","type":"text","data-test-id":"event-name"},on:{"input":_vm.handleEventNameInput}})],1),_vm._v(" "),(_vm.scratchEventIsZoomEvent)?_c('ar-field',{staticClass:"u-margin-top-10",attrs:{"label":"Location"}},[_c('am2-new-slug-input',{attrs:{"value":_vm.zoomUrlFromCurrentEvent,"readonly":"","enable-copy":""}})],1):_c('div',[_c('ar-field',{staticClass:"u-margin-top-10",attrs:{"label":"Location","error-messages":[
        _vm.veeErrors.first('live-stream-url'),
        _vm.veeErrors.first('location') ]}},[_c('am2-elegant-tabs',{attrs:{"items":_vm.locationTabs,"tab-key":_vm.selectedLocationKey,"layout":"wide","data-test-id":"location-tabs"},on:{"select":_vm.handleLocationSelect}}),_vm._v(" "),(_vm.selectedLocationKey === 'physical')?_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"u-margin-top-2",attrs:{"value":_vm.scratchEvent ? _vm.scratchEvent.location : "","autocomplete":"event-location","data-vv-name":"location","data-vv-as":"location","placeholder":"Include a venue, place or address","data-test-id":"event-location"},on:{"input":_vm.handleEventLocationInput}}):_vm._e(),_vm._v(" "),(_vm.selectedLocationKey === 'online' && !_vm.isEventRSVPEnabled && !_vm.currentEventRSVPForm)?_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|url'),expression:"'required|url'"}],staticClass:"u-margin-top-2",attrs:{"value":_vm.scratchEvent.presentation.liveStreamUrl,"autocomplete":"url","data-vv-name":"live-stream-url","data-vv-as":"live stream URL","placeholder":"Enter live stream URL"},on:{"input":_vm.handleEventLiveStreamUrlInput}}):_vm._e()],1),_vm._v(" "),(_vm.selectedLocationKey === 'physical')?_c('ar-field',{staticClass:"u-margin-top-10",attrs:{"label":"Event capacity","error-messages":[_vm.veeErrors.first('event-capacity')]}},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|integer|isPositiveInt'),expression:"'required|integer|isPositiveInt'"}],attrs:{"value":_vm.scratchEvent ? _vm.scratchEvent.capacity : "","autocomplete":"event-capacity","data-vv-name":"event-capacity","data-vv-as":"event capacity","placeholder":"Enter event capacity","type":"number","data-test-id":"event-capacity"},on:{"input":_vm.handleEventCapacityInput}})],1):_vm._e()],1),_vm._v(" "),_c('div',{class:[
      'u-display-flex',
      'u-margin-top-10',
      _vm.$arMediaQuery.window.maxWidth('md') && 'u-flex-flow-column',
      'u-justify-content-space-between'
    ]},[_c('ar-field',{attrs:{"label":"Event starts","error-messages":[
        _vm.veeErrors.first('startDate'),
        _vm.veeErrors.first('timezone') ]}},[_c('div',{class:[
          'u-display-flex',
          'u-align-items-center' ]},[_c('am2-date-time-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.startDateValidate),expression:"startDateValidate"}],attrs:{"time-zone":_vm.scratchEvent ? _vm.scratchEvent.timeZone : null,"value":_vm.scratchEvent? _vm.scratchEvent.startDate : null,"size":_vm.$arMediaQuery.window.maxWidth('xs') ? 'small' : 'medium',"data-vv-name":"startDate","data-vv-as":"event start time","data-test-id":"event-start-time"},on:{"input":_vm.handleEventStartDateInput}}),_vm._v(" "),(!_vm.displayEndTime && !_vm.$arMediaQuery.window.maxWidth('xs'))?_c('ar-link-button',{staticClass:"u-margin-left-3",attrs:{"text":"Add end time","data-test-id":"add-event-end-time-link"},on:{"click":_vm.handleAddEndTimeClick}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"u-margin-top-2 u-display-flex u-flex-flow-column",style:({ marginBottom: '5px' })},[_c('am2-timezone-dropdown',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-name":"timezone","value":_vm.selectedTimezone,"data-test-id":"event-timezone-link"},on:{"input":_vm.handleEventTimeZoneInput}}),_vm._v(" "),(!_vm.displayEndTime && _vm.$arMediaQuery.window.maxWidth('xs'))?_c('ar-link-button',{staticClass:"u-margin-top-2",attrs:{"text":"Add end time","data-test-id":"add-event-end-time-link"},on:{"click":_vm.handleAddEndTimeClick}}):_vm._e()],1)]),_vm._v(" "),(_vm.$arMediaQuery.window.minWidth('lg') && _vm.displayEndTime)?_c('ar-divider',{style:({
        position: 'relative',
        top: '57px',
        width: 'calc(100% - 652px)',
        margin: '0 18px',
      })}):_vm._e(),_vm._v(" "),(_vm.displayEndTime)?_c('ar-field',{class:[
        'u-display-flex',
        'u-flex-flow-column',
        _vm.$arMediaQuery.window.maxWidth('md') ? 'u-margin-top-10' : null ],attrs:{"label":"Event ends","error-messages":[_vm.veeErrors.first('endDate')]}},[_c('am2-date-time-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.endDateValidate),expression:"endDateValidate"}],attrs:{"time-zone":_vm.scratchEvent ? _vm.scratchEvent.timeZone : null,"value":_vm.scratchEvent ? _vm.scratchEvent.endDate : null,"disabled-until":_vm.scratchEvent.startDate,"size":_vm.$arMediaQuery.window.maxWidth('xs') ? 'small' : 'medium',"data-vv-name":"endDate","data-vv-as":"event end time","data-test-id":"event-end-time"},on:{"input":_vm.handleEventEndDateInput}}),_vm._v(" "),_c('ar-link-button',{style:({
          marginTop: '8px',
        }),attrs:{"text":"Remove end time","data-test-id":"remove-event-end-time-link"},on:{"click":_vm.handleRemoveEndTimeClick}})],1):_vm._e()],1),_vm._v(" "),_c('ar-field',{staticClass:"u-margin-top-10",attrs:{"label":_vm.currentEventRSVPForm ? 'Event image' : 'Event image (optional)',"error-messages":[
      _vm.veeErrors.first('eventImage'),
      _vm.veeErrors.first('eventImageUploading')
    ]}},[_c('div',{style:({
        height: (_vm.scratchEventImageUrl || _vm.isUploadingEventImage) ? null : '0',
        overflow: 'hidden',
      })},[(_vm.isUploadingEventImage)?_c('div',{directives:[{name:"validate",rawName:"v-validate:isUploadingEventImage",value:('uploadingAssets'),expression:"'uploadingAssets'",arg:"isUploadingEventImage"}],attrs:{"data-vv-name":"eventImageUploading","data-vv-as":"event image"}}):_vm._e(),_vm._v(" "),(_vm.currentEventRSVPForm)?_c('div',{directives:[{name:"validate",rawName:"v-validate:scratchEventImageUrl",value:('required'),expression:"'required'",arg:"scratchEventImageUrl"}],attrs:{"data-vv-name":"eventImage","data-vv-as":"event image"}}):_vm._e(),_vm._v(" "),_c('am2-dropzone',{ref:"img-dropzone",attrs:{"file-type":['image/jpeg', 'image/jpg', 'image/png'],"file-type-alias":"image","placeholder-icon":{
          name: 'image',
        },"image-options":{
          dimensions: {
            width: 1200,
            height: 628
          }
        },"ratio":0.525,"feedback-type":"image","feedback":_vm.scratchEventImageUrl,"enable-ar-s3-bucket-upload":"","ar-s3-bucket-upload-options-list":_vm.arS3BucketUploadOptionsList},on:{"delete":_vm.handleImageDelete,"uploadStart":function($event){_vm.isUploadingEventImage = true},"uploadEnd":function($event){_vm.isUploadingEventImage = false},"upload":_vm.handleImageUpload}})],1),_vm._v(" "),(!_vm.scratchEventImageUrl && !_vm.isUploadingEventImage)?_c('div',{staticClass:"u-display-flex"},[_c('ar-icon',{staticClass:"u-margin-right-2",attrs:{"name":"image","height":"21px","color":_vm.$arStyle.color.blueGrey600}}),_vm._v(" "),_c('ar-link-button',{attrs:{"text":"Upload image","data-test-id":"upload-image-link"},on:{"click":_vm.handleUploadImageClick}})],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }