var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:[
    'wrapper',
    'content',
    'content--centered',
    _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
    _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ]},[_c('am2-step-link',{staticClass:"step-back-link",attrs:{"text":"Back to integrations"},on:{"click":_vm.handleBackLinkClick}}),_vm._v(" "),_c('am2-axs-modal',{attrs:{"is-show":_vm.showAxsModal,"loading":_vm.isCreatingAxsIntegration},on:{"confirm":_vm.handleAxsConfirm,"cancel":function($event){_vm.showAxsModal = false}}}),_vm._v(" "),_c('am2-opt-in-settings-modal',{attrs:{"is-show":_vm.showOptinSettingsModal,"providerName":"AXS","message-list-oid":_vm.currentMessageListOid,"tag-fans":_vm.tagFans},on:{"cancel":function($event){_vm.showOptinSettingsModal = false}}}),_vm._v(" "),_c('div',{class:[
      'title-section',
      _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max' ]},[_c('div',{staticClass:"title-section-left"},[_c('am2-icon-title-section',{attrs:{"title":"Axs","description":"Automatically sync your members, events, orders from Axs","icon-props":{
          name: 'axs',
          color: _vm.$arStyle.color.axs,
          height: '46px',
          width: '40px',
        }}})],1),_vm._v(" "),(!_vm.isFetchingAxsIntegration)?_c('div',{staticClass:"title-section-right"},[_c('am2-expand-button-dropdown',{attrs:{"align":"left","button-props":{ iconName: 'settings' },"items":[
          {
            name: 'Remove Account',
            value: 'removeIntegration',
          } ]},on:{"select":_vm.handleSettingOptionSelect}})],1):_vm._e()]),_vm._v(" "),(_vm.isAddingAxsIntegration || _vm.isFetchingAxsIntegration)?_c('section',{staticClass:"integration-loading-container"},[_c('am2-loading-bubble')],1):_c('section',[(_vm.integrationFailed)?_c('section',{staticClass:"integration-failed-message"},[_c('ar-snackbar',{style:({ width: '100%' }),attrs:{"type":"warning","message":"Your Axs connection is no longer valid. This means we can't sync your member data. <a>Please reconnect your account</a>"},on:{"anchorClick":_vm.handleReconnectIntegration}})],1):_c('SyncBlockContainer',{staticClass:"sync-block-container",attrs:{"copySize":"sm","sync-status":"default","sync-time":_vm.syncTime,"sync-copies":{
        'default': _vm.message
      }},on:{"startSync":_vm.handleStartSync,"stopSync":_vm.handleStopSync}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }