<template>
  <portal
    to="modal"
    :disabled="!isShow"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="isShow"
      class="axs-modal"
      header="Connect To Axs"
      width="590px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
    >
      <div
        slot="body"
        :class="[
          'axs-modal-body',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]"
      >
        <p>Press the &ldquo;Connect&rdquo; button below to establish connection with <a href="https://www.axs.com/">AXS</a>.</p>
      </div>
      </div>
      <div
        slot="footer"
        :class="[
          'axs-modal-footer',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]">
        <ar-simple-button
          text="Connect"
          :loading="loading"
          :disabled="confirmButtonDisabled"
          :style="{ width: '108px' }"
          @click="handleConfirmClick"
          id="axs-modal-confirm-button"
          data-test-id="axs-modal-confirm-button"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'AxsModal',
  components: {},
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    confirmButtonDisabled() {
        return !!this.integration;
    }
  },
  methods: {
    ...mapActions([
      'axsIntegration/CREATE_AXS_INTEGRATION',
      'axsIntegration/FETCH_INTEGRATION'
    ]),
    async fetchIntegration() {
      const success = await this['axsIntegration/FETCH_INTEGRATION']()
      return success;
    },
    async handleConfirmClick() {

      const response = await this['axsIntegration/CREATE_AXS_INTEGRATION']();

      if (response) {

        if (this.$route.path != '/settings/integrations/axs') {
          await this.$router.push({ path: '/settings/integrations/axs' });
        }
        else {
          this.fetchIntegration();
        }
        this.$emit('cancel');
      }
      else {
        this.errorMessage = response.message;
        this.httpStatus = response.httpStatusCode;
      }
    },
    handleCloseClick() {
      this.$emit('cancel');
    }
  }
};
</script>

<style lang="scss" scoped>
.axs-modal {
  .axs-modal-body {
    padding: 20px 30px 30px;
    &:nth-child(2) {
      border-top: 1px solid $blueGrey500;
    }
  }
  .axs-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 25px 30px;
    height: 100px;
  }
}
</style>
