<template>
  <section :class="[
    'events-sales',
    $arMediaQuery.pageContent.minWidth('md') && 'md-max',
  ]">
    <am2-custom-date-range-modal
      v-if="arEnableTicketSalesFilter"
      title="Custom date range"
      :is-show="showCustomDateRangeModal"
      @close="closeCustomDateRangeClick"
      @input="handleCustomDateRangeModalConfirm"
    />

    <div class="events-sales__top-nav">
      <div class="events-sales__import">
        <am2-typography-skeleton
          size="sm"
          v-if="currentEvent == null"
          :style="{
            width: '124px',
            marginTop: '8px',
            marginBottom: '6px',
          }"
        />
        <am2-integration-indicator
          v-if="hasZoomIntegration"
          integration="zoom"
          :icon-color="$arStyle.color.zoom"
        />
        <am2-integration-indicator
          v-if="hasEventbriteIntegration"
          integration="eventbrite"
          :icon-color="$arStyle.color.eventbrite"
        />
        <am2-integration-indicator
          v-if="hasUniverseIntegration"
          integration="universe"
          :icon-color="$arStyle.color.universe"
        />
        <am2-integration-indicator
          v-if="hasMoshtixIntegration"
          integration="moshtix"
          :icon-color="$arStyle.color.moshtix"
        />
        <am2-integration-indicator
          v-if="hasEventixIntegration"
          integration="eventix"
          :icon-color="$arStyle.color.eventix"
        />
        <am2-integration-indicator
          v-if="hasEventGeniusIntegration"
          integration="event-genius"
          :icon-color="$arStyle.color.eventGenius"
        />
        <am2-integration-indicator
          v-if="hasTicketekIntegration"
          integration="ticketek"
          :icon-color="$arStyle.color.ticketek"
        />
        <am2-integration-indicator
          v-if="hasDiceIntegration"
          integration="dice"
          :icon-color="$arStyle.color.dice"
        />
        <div :class="['sync-banner', $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max']" v-if="(hasAnyIntegration) && lastDataImport">
          <ar-text
            size="xs"
            :text="lastSyncMessage"
            :style="{
              display: 'inline-block',
              color: $arStyle.color.blueGrey700,
            }"
          />
          <div class="inner-sync" v-if="isEventSyncing">
            <ar-icon class="syncing-icon" name="refresh"/>
          </div>
          <ar-link-button
            v-else-if="!hasTicketekIntegration"
            data-test-id="sync-ticket-sales-button"
            text="Sync ticket sales"
            @click="syncEvent"
            :style="{
              margin: '0 0 0 5px',
            }"
          />
        </div>


        <div v-else-if="lastDataImport" class="sync-banner"
            :style="{
              marginTop: '8px',
              marginBottom: '8px',
            }">
          <ar-text
            size="xs"
            :text="`Last ticket sales data import was on ${lastDataImport.format('Do MMM YYYY')}.`"
            :style="{
              color: $arStyle.color.blueGrey700,
            }"
          />
          <ar-link-button
            text="Import ticket sales"
            @click="openImportModal"
            :style="{
              margin: '0 0 0 5px',
            }"
          />
        </div>


        <div v-else-if="currentEvent != null" class="sync-banner">
          <ar-text
            size="xs"
            :text="'No data has been imported.'"
            :style="{
              display: 'inline-block',
              marginTop: '8px',
              marginBottom: '8px',
              color: $arStyle.color.blueGrey700,
            }"
          />
          <ar-link-button
            text="Import ticket orders"
            @click="openImportModal"
            :style="{
              margin: '0 0 0 5px',
            }"
          />
        </div>
      </div>
      <div
        class="filter-sidebar"
        v-if="arEnableTicketSalesFilter"
      >
        <div
          class="event-filter-dropdowns"
        >
          <FilterSalesDropdown
            data-test-id="event-sales-filter-button"
            has-search
            placeholder="Provider"
            :dropdown-style="{
              width: '500px',
            }"
            align="left"
            @submit="handleFilterSales"
          />

          <ar-simple-select
            data-test-id="event-sales-date-dropdown"
            :items="dropdown.items"
            placeholder="Date range"
            align="right"
            @select="(item, key) => handleSelectDate(item, key)"
            :style="{
              minWidth: '200px',
            }"
            :value="selectedDateItem"
            :default-select-index="selectedDateItem"
          />
        </div>
      </div>
    </div>
    <div
      data-test-id="event-filter-text-container"
      class="event-filter-text-container"
    >
      <ar-text
        v-if="getFilterText"
        size="xs"
        class="filter-text"
        :style="{
          marginRight: '5px',
        }"
        :text="getFilterText"
        v-tooltip.top="{
          content: getFilterText,
        }"
      />

      <ar-link-button
        v-if="getFilterText"
        class="comparing-to-clear-button"
        text="clear"
        :has-underline="true"
        :color="$arStyle.color.$purple500"
        @click="handleClearFilter"
        data-test-id="event-filter-clear"
      />
    </div>

    <am2-card-container
      layout="soft"
      :style="{
        marginBottom: $arMediaQuery.pageContent.minWidth('md') ? '30px' : '12px',
      }"
    >
      <am2-overview-bar
        data-test-id="event-ticket-stats-bar"
        :values="[
          {
            label: 'Total ticket sales',
            type: eventTicketStats ? 'CURRENCY' : 'LOADING',
            amount: eventTicketStats && eventTicketStats.totalTicketSales,
            percentChange: undefined,
          },
          {
            label: 'Average order value',
            type: eventTicketStats ? 'CURRENCY' : 'LOADING',
            amount: eventTicketStats && eventTicketStats.averageOrderSize,
            percentChange: undefined,
          },
          {
            label: 'Average tickets per order',
            type: eventTicketStats ? 'FLOAT' : 'LOADING',
            amount: eventTicketStats && eventTicketStats.averageTicketsPerOrder,
            percentChange: undefined,
          },
          {
            label: 'Total quantity sold',
            type: eventTicketStats ? 'PROGRESSION' : 'LOADING',
            amount: eventTicketStats && eventTicketStats.totalTicketsSold,
            maximum: parseInt(eventTicketStats && eventTicketStats.capacity),
            layout: 'status-bar',
          },
        ]"
        :loading="isFetchingEventTicketStats"
        layout="spacious"
      />
    </am2-card-container>

    <div
      :class="[
        'graph-section',
        $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
        $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
      ]">

      <TicketSalesChartSection :filter="currentFilter" />

      <am2-card-container
        layout="soft"
        v-if="hasMultipleTicketPlatforms && $arMediaQuery.pageContent.minWidth('sm')"
        class="full-width-cell"
        :style="{ padding: $arMediaQuery.pageContent.minWidth('sm') ? '30px' : '30px 10px' }">

        <am2-multiple-bar-charts
          data-test-id="event-ticket-platforms-graph"
          v-if="ticketPlatformsData"
          title="Ticket platforms"
          :topic-index="ticketPlatformChartIndex"
          :loading="isFetchingEventTicketStats"
          :topics="ticketPlatformsData.topics"
          :chart-datas="ticketPlatformsData.chartDatas"
          @topicChange="(topic) => { ticketPlatformsTopic = topic.name }"
          custom-tab-width="200px"
        />

      </am2-card-container>

      <am2-card-container
        layout="soft"
        v-if="$arMediaQuery.pageContent.minWidth('sm')"
        class="full-width-cell" :style="{ padding: $arMediaQuery.pageContent.minWidth('sm') ? '30px' : '30px 10px', minHeight: '300px' }">
        <am2-multiple-bar-charts
          data-test-id="event-ticket-types-graph"
          v-if="isFetchingEventTicketStats || ticketTypesData"
          title="Ticket types"
          :topic-index="ticketTypesChartIndex"
          :loading="isFetchingEventTicketStats"
          :topics="ticketTypesData? ticketTypesData.topics : null"
          :chart-datas="ticketTypesData? ticketTypesData.chartDatas : null"
          @topicChange="(topic) => { ticketTypesTopic = topic.name }"
          :style="{ height: !eventTicketStats ? '200px' : null }"
          custom-tab-width="200px"
        />

        <div
          class="u-display-flex u-align-items-center u-justify-content-center"
          :style="{
            width: '100%',
            marginTop: '-15px',
            minHeight: '30px'
          }">
          <ar-link-button
            data-test-id="event-ticket-types-see-more-toggle"
            v-if="filteredAndSortedTicketTypesData && filteredAndSortedTicketTypesData && filteredAndSortedTicketTypesData.length > 7"
            :text="!ticketTypeSeeMore ? 'See more' : 'See less'"
            @click="toggleTicketTypesSeeAll"
          />
        </div>

      </am2-card-container>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import accounting from 'accounting';
import dayjs from 'dayjs'

import {
  eventLastImport,
  isSyncing,
} from '@/utils/event';

import TicketSalesChartSection from './TicketSalesChartSection.vue'
import FilterSalesDropdown from './components/FilterSalesDropdown'

export default {
  name: 'EventSales',
  components: {
    TicketSalesChartSection,
    FilterSalesDropdown,
  },

  data: () => ({
    syncPoller: null,
    ticketSalesTopic: 'Sales',
    ticketPlatformsTopic: 'Sales',
    ticketTypesTopic: 'Sales',
    locationChartTopic: 'City',
    ticketSalesChartTopic: 'Sales',
    showCustomDateRangeModal: false,
    arEnableTicketSalesFilter: process.env.arEnableTicketSalesFilter,
    ticketTypeSeeMore: false,
    dateRangeStart: null,
    dateRangeEnd: null,
    selectedDateItem: null,
    dateFilterIndex: null,
    currentFilter: null,
  }),

  computed: {
    ...mapState({
      oid: state => state.route.params.oid,
      currentEvent: state => state.event.currentEvent,
      isFetchingEventTicketStats: state => state.event.isFetchingEventTicketStats,
      eventTicketStats: state => state.event.eventTicketStats,
      eventTicketSales: state => state.event.eventTicketSales,
      scratchSegment: state => state.segment.scratchSegment,
      scratchSegmentInfo: state => state.segment.scratchSegmentInfo,
      savedSegmentList: state => state.segment.savedSegmentList,
      segmentCriteriaMap: state => state.segment.segmentCriteriaMap,
      tags: state => state.tag.tags,
    }),

    ...mapGetters({
      eventHasIntegration: 'event/eventHasIntegration',
      prunedScratchSegment: 'segment/prunedScratchSegmentFilter',
      regionSettingsCurrencySymbol: 'auth/regionSettingsCurrencySymbol',
      isFeatureEnabled: 'auth/isFeatureEnabled',
      isPermissionEnabled: 'auth/isPermissionEnabled',
    }),

    isOneSchemaEnabled() {
      return this.isFeatureEnabled(['oneschema']);
    },

    hasZoomIntegration() {
      return this.eventHasIntegration(this.currentEvent, 'zoom');
    },

    hasEventbriteIntegration() {
      return this.eventHasIntegration(this.currentEvent, 'eventbrite');
    },

    hasUniverseIntegration() {
      return this.eventHasIntegration(this.currentEvent, 'universe');
    },

    hasMoshtixIntegration() {
      return this.eventHasIntegration(this.currentEvent, 'moshtix');
    },

    hasEventixIntegration() {
      return this.eventHasIntegration(this.currentEvent, 'eventix');
    },

    hasEventGeniusIntegration() {
      return this.eventHasIntegration(this.currentEvent, 'event-genius');
    },

    hasTicketekIntegration() {
      return this.eventHasIntegration(this.currentEvent, 'ticketek');
    },

    hasDiceIntegration() {
      return this.eventHasIntegration(this.currentEvent, 'dice');
    },

    hasAnyIntegration() {
      return this.hasZoomIntegration || this.hasEventbriteIntegration || this.hasUniverseIntegration || this.hasMoshtixIntegration || this.hasEventixIntegration || this.hasEventGeniusIntegration || this.hasTicketekIntegration || this.hasDiceIntegration;
    },

    lastDataImport() {
      if (!this.currentEvent) {
        return null;
      }
      return eventLastImport(this.currentEvent);
    },

    sinceLastImport() {
      return this.lastDataImport.fromNow();
    },

    lastSyncMessage() {
      if (this.isEventSyncing) {
        return 'Syncing';
      } else {
        return `Ticket sales last synced ${this.sinceLastImport}.`;
      }
    },

    ticketPlatformChartIndex() {
      return this.ticketPlatformsTopic === 'Sales' ? 0 : 1
    },

    ticketTypesChartIndex() {
      return this.ticketTypesTopic === 'Sales' ? 0 : 1
    },

    filteredAndSortedTicketTypesData() {
      if (!this.eventTicketStats) {
        return null;
      }
      const hasChartDatas = this.eventTicketStats.revenuePerTicketType.length !== 0;

      let chartDatas = hasChartDatas ? this.eventTicketStats.revenuePerTicketType : null;

      if (chartDatas) {
        chartDatas = chartDatas.map((x, y) => {
            return {
              ...x,
              ticketTypeCopy: x.ticketType,
              ticketType: `${x.ticketType} (${x.ticketCost ? x.ticketCost.displayValue : ``})`
            }
          })
      }

      // We have to sort datas here instead of in charts, cuz that's customisation
      if (this.ticketTypesTopic === 'Sales' && chartDatas) {
        chartDatas.sort((a, b) => b.percentageTicketSales - a.percentageTicketSales);
      } else if (this.ticketTypesTopic === 'Quantity' && chartDatas) {
        chartDatas.sort((a, b) => b.percentageTotalTickets - a.percentageTotalTickets);
      }

      if (chartDatas) {
        // Filter out 0% when ticketType already exists in chart
        chartDatas = chartDatas.filter((item, index) => {
          const firstExistenceIndex = chartDatas.findIndex((chartItem) => chartItem.ticketType == item.ticketType)
          const isDuplicate = firstExistenceIndex != index

          return !(isDuplicate && item.percentageTicketSales == 0)
        })
      }

      return chartDatas
    },

    ticketTypesData() {
      if (!this.eventTicketStats) {
        return null;
      }

      const themeColorPalette = this.$arStyle.color;
      const isLarge = this.$arMediaQuery.pageContent.minWidth('lg');

      // Limit to 7 entries if see more is not open

      let chartDatas = null

      if (this.filteredAndSortedTicketTypesData) {
        chartDatas = (this.ticketTypeSeeMore ? this.filteredAndSortedTicketTypesData : this.filteredAndSortedTicketTypesData.slice(0, 7))
      }

      const currencySymbol = this.regionSettingsCurrencySymbol

      let data = {
        topics: [
          {
            name: 'Sales',
            // Value can be from 'percentageTicketSales' or 'percentageTotalTickets', be careful
            valueGetters: [{
              name: 'Default',
              getter: ({ ticketType, percentageTicketSales }) => (
                [ticketType || 'unknown', percentageTicketSales || 0]
              ),
            }],
            format: '{value}%',
            xAxisLabelFormatter: function (xLabel, { ticketSales, ticketCost, ticketTypeCopy }) {
              let valueText;
              if (typeof ticketSales === 'number') {
                valueText = accounting.formatMoney(ticketSales / 100, { symbol: currencySymbol, precision: 0 });
              } else {
                valueText = '$0';
              }

              let displayValue = ticketCost ? ticketCost.displayValue : null;

              return `
                <span title="${ticketTypeCopy}"
                style="position: relative; display: flex; justify-content: space-between; align-items: flex-start; width: 100%; max-width: 100%; flex-direction:${isLarge ? 'row' : 'column'}; font-size: 14px; height: 20px; ">
                  <span style="position: absolute; top: 8px; left: 0px; display: block; width: 100%; height: 0px; border-top: 1px dotted ${themeColorPalette.blueGrey500};"></span>
                  <span style="position: relative; max-width: 75%; color: #43516b; white-space: normal; background: white; padding-right: 10px; display: flex; flex-direction: row;">
                    <span style="width: ${displayValue ? '80%' : '100%'}; overflow: hidden; text-overflow: ellipsis; height: 20px; white-space:nowrap; line-height: 15px;">${ticketTypeCopy}</span>
                    ${ displayValue ? `<span style="color: #8E97A6; line-height: 15px;">&nbsp;(${displayValue})</span>` : ``}
                  </span>
                  <span style="position: relative; color: #43516b;  background: white; padding-left: ${isLarge ? '10px' : '0'}; height: 20px; line-height: 15px;">
                    ${valueText}
                  </span>
                </span>
              `;
            },
            barWidth: 25,
            hasTooltip: false,
            hasYLabel: false,
            yAxisMinRange: 15,
            hasDataLabels: true,

            xAxisLabelAlign: 'left'
          },
          {
            name: 'Quantity',
            valueGetters: [{
              name: 'Default',
              getter: ({ ticketType, percentageTotalTickets }) => (
                [ticketType || 'unknown', percentageTotalTickets || 0]
              ),
            }],
            format: '{value}%',
            xAxisLabelFormatter: function (xLabel, { totalTickets, ticketCost, ticketTypeCopy }) {
              let valueText = totalTickets || 0;

              let displayValue = ticketCost ? ticketCost.displayValue : null;

              return `
                <span title="${ticketTypeCopy}"
                style="position: relative; display: flex; justify-content: space-between; align-items: flex-start; width: 100%; max-width: 100%;">
                  <span style="position: absolute; top: 8px; left: 0px; display: block; width: 100%; height: 0px; border-top: 1px dotted ${themeColorPalette.blueGrey500};"></span>
                  <span style="position: relative; max-width: 75%; color: #43516b; white-space: normal; background: white; padding-right: 10px; display: flex; flex-direction: row;">
                    <span style="width: ${displayValue ? '80%' : '100%'}; overflow: hidden; text-overflow: ellipsis; height: 20px; white-space:nowrap; line-height: 15px;">${ticketTypeCopy}</span>
                    ${ displayValue ? `<span style="color: #8E97A6; line-height: 15px;">&nbsp;(${displayValue})</span>` : ``}
                  </span>
                  <span style="position: relative; color: #43516b; font-size: 14px; line-height: 1; background: white; padding-left: 10px;">${valueText} ${parseInt(valueText) > 1 || parseInt(valueText) == 0 ? `tickets` : `ticket`}</span>
                </span>
              `;
            },
            barWidth: 25,
            hasTooltip: false,
            hasYLabel: false,
            yAxisMinRange: 15,
            hasDataLabels: true,
          }
        ],
        chartDatas,
      };

      return data;
    },

    ticketPlatformsData() {
      if (!this.eventTicketStats) {
        return {};
      }

      const hasChartDatas = this.eventTicketStats.revenuePerProvider.length !== 0;
      const chartDatas = hasChartDatas ?
          this.eventTicketStats.revenuePerProvider :
          [{ ticketType: 'No data', ticketSales: 0, totalTickets: 0 }];

      // We have to sort datas here instead of in charts, cuz that's customisation
      if (this.ticketPlatformsTopic === 'Sales' && chartDatas) {
        chartDatas.sort((a, b) => b.percentageTicketSales - a.percentageTicketSales);
      } else if (this.ticketPlatformsTopic === 'Quantity' && chartDatas) {
        chartDatas.sort((a, b) => b.percentageTotalTickets - a.percentageTotalTickets);
      }

      const themeColorPalette = this.$arStyle.color;
      const currencySymbol = this.regionSettingsCurrencySymbol

      var data = {
        topics: [
          {
            name: 'Sales',
            // Value can be from 'percentageTicketSales' or 'percentageTotalTickets', be careful
            valueGetters: [{
              name: 'Default',
              getter: ({ provider, percentageTicketSales }) => (
                [provider || 'unknown', percentageTicketSales || 0]
              ),
            }],
            format: '{value}%',
            xAxisLabelFormatter: function (xLabel, { ticketSales }) {
              if (!hasChartDatas) {
                return '';
              }

              let valueText;
              if (typeof ticketSales === 'number') {
                valueText = accounting.formatMoney(ticketSales / 100, { precision: 0 });
              } else {
                valueText = '$0';
              }
              // eslint-disable-next-line multiline-ternary

              return `
                <span style="position: relative; display: flex; justify-content: space-between; align-items: flex-start; width: 100%; max-width: 100%;">
                  <span style="position: absolute; top: 8px; left: 0px; display: block; width: 100%; height: 0px; border-top: 1px dotted ${themeColorPalette.blueGrey500};"></span>
                  <span style="position: relative; top: -2px; max-width: 70%; color: #43516b; font-size: 14px; line-height: 1.2; white-space: normal; background: white; padding-right: 10px;">${xLabel}</span>
                  <span style="position: relative; color: #43516b; font-size: 14px; line-height: 1; background: white; padding-left: 10px;">${valueText}</span>
                </span>`;
            },
            barWidth: 25,
            yAxisMinRange: hasChartDatas ? 15 : 100,
            hasTooltip: false,
            hasYLabel: false,
            hasDataLabels: hasChartDatas,
          },
          {
            name: 'Quantity',
            valueGetters: [{
              name: 'Default',
              getter: ({ provider, percentageTotalTickets }) => (
                [provider || 'unknown', percentageTotalTickets || 0]
              ),
            }],
            format: '{value}%',
            xAxisLabelFormatter: function (xLabel, { totalTickets }) {
              if (!hasChartDatas) {
                return '';
              }

              let valueText = totalTickets || '$0';

              return `
                <span style="position: relative; display: flex; justify-content: space-between; align-items: flex-start; width: 100%; max-width: 100%;">
                  <span style="position: absolute; top: 8px; left: 0px; display: block; width: 100%; height: 0px; border-top: 1px dotted ${themeColorPalette.blueGrey500};"></span>
                  <span style="position: relative; top: -2px; max-width: 70%; color: #43516b; font-size: 14px; line-height: 1.2; white-space: normal; background: white; padding-right: 10px;">${xLabel}</span>
                  <span style="position: relative; color: #43516b; font-size: 14px; line-height: 1; background: white; padding-left: 10px;">${valueText} ${parseInt(valueText) > 1 || parseInt(valueText) == 0 ? `tickets` : `ticket`}</span>
                </span>
                ` ;
            },
            barWidth: 25,
            yAxisMinRange: hasChartDatas ? 15 : 100,
            hasTooltip: false,
            hasYLabel: false,
            hasDataLabels: hasChartDatas,
          }
        ],
        chartDatas,
      };

      return data;
    },

    hasMultipleTicketPlatforms() {
      return this.eventTicketStats && this.eventTicketStats.revenuePerProvider ? this.eventTicketStats.revenuePerProvider.length > 1 : false;
    },

    isEventSyncing(){
      if (!this.currentEvent) {
        return false;
      }
      return isSyncing(this.currentEvent);
    },

    getFilterText() {
      if (!this.prunedScratchSegment || this.prunedScratchSegment.filter.conditions.length == 0) {
        return null;
      }

      const {
        conditions,
        logic
      } = this.prunedScratchSegment.filter

      const filteredBy = this.getFilteredBy(conditions, logic)

      return `Filter: ${filteredBy}`
    },

    dropdown() {
      return {
        title: 'Timeframe',
        filterKey: this.eventTicketStatsTimePeriod,
        items: [
          {
            name: 'All time',
            key: 'all',
            action: () => this.setPromoterStatsFilter("all"),
          },
          {
            name: 'Today',
            key: 'today',
            action: () => this.setPromoterStatsFilter("today"),
          },
          {
            name: 'Yesterday',
            key: 'yesterday',
            action: () => this.setPromoterStatsFilter("yesterday"),
          },
          {
            name: 'This week',
            key: 'this-week',
            action: () => this.setPromoterStatsFilter("this-week"),
          },
          {
            name: 'Last week',
            key: 'last-week',
            action: () => this.setPromoterStatsFilter("last-week"),
          },
          {
            name: 'This month',
            key: 'this-month',
            action: () => this.setPromoterStatsFilter("this-month"),
          },
          {
            name: 'Last month',
            key: 'last-month',
            action: () => this.setPromoterStatsFilter("last-month"),
          },
          {
            name: 'Custom date range',
            key: 'custom-date-range',
            action: () => this.setPromoterStatsFilter("custom-date-range"),
          },
        ],
      };
    },

  },

  methods: {
    ...mapActions({
      fetchEventTicketStats: 'event/FETCH_EVENT_TICKET_STATS',
      fetchEventTicketSales: 'event/FETCH_EVENT_TICKET_SALES',
      clearSalesData: 'event/CLEAR_SALES_DATA',
      clearTimescaleData: 'event/CLEAR_COMPARE_TIMESCALE_DATA',
      clearAnnotations: 'event/CLEAR_ANNOTATIONS',
      openImportModalAction: 'OPEN_IMPORT_EVENT_DATA_MODAL',
      setDragListenerState: 'event/SET_DRAG_LISTENER_STATE',
      fetchTags: 'tag/FETCH_MORE_TAGS',
      openOneschemaImportModal: 'OPEN_ONESCHEMA_EVENT_IMPORT_MODAL',
      fetchPermissions: 'auth/FETCH_PERMISSIONS',
     }),
    ...mapMutations([
      'segment/RESET_SCRATCH_SEGMENT',
      'segment/SET_SCRATCH_SEGMENT_CONDITION',
      'segment/DELETE_SCRATCH_SEGMENT_CONDITION',
      'segment/ADD_SCRATCH_SEGMENT_CONDITION',
      'event/RESET_EVENT_TICKET_SALES',
      'event/RESET_EVENT_TICKET_STATS',
    ]),

    checkIfEventIsSyncing() {
      if (this.isEventSyncing) {
        this.startEventSyncPoller();
      }
    },

    startEventSyncPoller() {
      if (!this.syncPoller) {
        this.syncPoller = setInterval(this.pollNewStats, 4000);
      }
    },
    openImportModal() {

      if (this.isOneSchemaEnabled) {
        this.openOneschemaImportModal({
          typeOfImport: 'ticket_orders_single_event',
          eventOid: this.$route.params.oid
        });
      } else {
        this.openImportModalAction({
          eventOid: this.oid,
          timezone: this.currentEvent.timezone, // tmp use
        });
      }
    },
    syncEvent() {
      this.$emit('navbarAction', 'SYNC_EVENT');
      this.startEventSyncPoller();
    },

    async fetchAndInitEventTicketStats(oid) {
      await this.fetchEventTicketStats({
        eventOid: oid,
        filter: this.prunedScratchSegment && this.prunedScratchSegment.filter ? this.prunedScratchSegment.filter : null
      })

      // If this is a known free event, set the topics to Quantity instead of Sales
      if (!this.eventTicketStats || typeof this.eventTicketStats.totalTicketsSold !== 'number' || typeof this.eventTicketStats.averageOrderSize !== 'number' ) {
        this.ticketSalesTopic = 'Sales';
        this.ticketPlatformsTopic = 'Sales';
        this.ticketTypesTopic = 'Sales';
      } else {
        const newTopic = this.eventTicketStats.totalTicketsSold > 0 && this.eventTicketStats.averageOrderSize === 0 ? 'Quantity' : 'Sales';
        this.ticketSalesTopic = newTopic;
        this.ticketPlatformsTopic = newTopic;
        this.ticketTypesTopic = newTopic;
      }
    },

    async pollNewStats() {
      if (this.prunedScratchSegment.filter) {
        this.currentFilter = this.prunedScratchSegment.filter

        this.fetchAndInitEventTicketStats(this.oid)

        this.fetchEventTicketSales({
          eventOid: this.oid,
          filter: this.prunedScratchSegment.filter
        });

        if (!isSyncing(this.currentEvent)) {
          clearInterval(this.syncPoller);
        }
      }
    },

    async setPromoterStatsFilter(timePeriod) {
      this.eventTicketStatsTimePeriod = timePeriod;
    },

    handleSelectDate({ key }, itemKey) {
      let startDay = null
      let endDay = null

      this.selectedDateItem = itemKey

      if (key == 'custom-date-range') {
        this.showCustomDateRangeModal = true;
      } else {
        if (key == 'today') {
          startDay = dayjs().startOf('day').format('YYYY-MM-DD')
          endDay = dayjs().endOf('day').format('YYYY-MM-DD')
        } else if (key == 'this-week') {
          startDay = dayjs().startOf('week').format('YYYY-MM-DD')
          endDay = dayjs().endOf('week').format('YYYY-MM-DD')
        } else if (key == 'yesterday') {
          const yesterday = dayjs().add(-1, 'day')
          startDay = yesterday.startOf('day').format('YYYY-MM-DD')
          endDay = yesterday.endOf('day').format('YYYY-MM-DD')
        } else if (key == 'this-month') {
          startDay = dayjs().startOf('month').format('YYYY-MM-DD')
          endDay = dayjs().endOf('month').format('YYYY-MM-DD')
        } else if (key == 'last-month') {
          const lastMonth = dayjs().add(-1, 'month')
          startDay = lastMonth.startOf('month').format('YYYY-MM-DD')
          endDay = lastMonth.endOf('month').format('YYYY-MM-DD')
        } else if (key == 'last-week') {
          const lastMonth = dayjs().add(-1, 'week')
          startDay = lastMonth.startOf('week').format('YYYY-MM-DD')
          endDay = lastMonth.endOf('week').format('YYYY-MM-DD')
        }

        this.pushDatesToScratchSegment(startDay, endDay);
      }
    },

    handleCustomFieldClick() {
      this.showCustomDateRangeModal = true;
    },

    closeCustomDateRangeClick() {
      this.showCustomDateRangeModal = false;
    },

    async pushDatesToScratchSegment(start, end) {
      let index = this.dateFilterIndex !== null ? this.dateFilterIndex : 0;

      let condition = null;

      if (start && end) {
        condition = {
          data: {
            condition: "is_between",
            values: [start, end],
          },
          name: "purchaseDatePerEvent",
          type: "date",
        }

        if (this.dateFilterIndex === null) {
          await this['segment/ADD_SCRATCH_SEGMENT_CONDITION']({ segmentCriteriaResource: 'purchaseDatePerEvent', logic: ['and'] });
          index = this.scratchSegment.filter.conditions.length - 1;
        }

        this['segment/SET_SCRATCH_SEGMENT_CONDITION']({
          conditionIndex: index,
          filterCondition: condition
        });
      }

      this.dateFilterIndex = index;

      this.handleFilterSales()
    },

    handleCustomDateRangeModalConfirm(item) {
      this.showCustomDateRangeModal = false;
      this.pushDatesToScratchSegment(item.start, item.end);
    },

    toggleTicketTypesSeeAll() {
      this.ticketTypeSeeMore = !this.ticketTypeSeeMore
    },

    handleClearFilter() {
      this['segment/RESET_SCRATCH_SEGMENT']();
      this.selectedDateItem = null;
      this.dateFilterIndex = null;
      this.pollNewStats()
    },

    handleFilterSales(dateFilterIndex) {
      if (dateFilterIndex) {
        this.dateFilterIndex = dateFilterIndex;
      }

      this.pollNewStats()
    },

    getFilteredBy(conditions, logic) {
      let logicIndex = 0;

      return conditions.reduce((text, value, index) => {
        let condition

        const isSelectedPredefinedDate = (value.name == "purchaseDatePerEvent" && this.dateFilterIndex === index)

        if (isSelectedPredefinedDate) {
          condition = { title: "" }
        } else if (this.segmentCriteriaMap[value.name].data.predicates) {
          condition = this.segmentCriteriaMap[value.name].data.predicates.find((predicate) => {
            return value.data.condition == predicate.value
          })
        } else {
          condition = this.segmentCriteriaMap[value.name].data.find((predicate) => {
            return value.data == predicate.value
          })
        }

        if (condition.title === "is equal to") {
          condition.title = "on"
        }

        let filterValue

        if (value.name == 'tags') {
          const tempTags = value.data.values.map((filterTag, index) => {
            const tag = this.tags.find((tag) => {
              return tag.oid == filterTag
            })

            if (!!tag) {
              return (index > 0 && value.data.values.length - 1 == index) ? `and ${tag?.name}` : tag?.name
            } else {
              return null
            }
          })

          filterValue = `"` + (tempTags.length > 2 ? tempTags.join(", ") : tempTags.join(" ")) + `"`
        } else if (isSelectedPredefinedDate && this.dropdown.items[this.selectedDateItem].key !== "custom-date-range") {
          filterValue = this.dropdown.items[this.selectedDateItem].name.toLowerCase()
        } else {
          let values = value.data.values || value.data.value

          if (value.type === "date") {
            values = values.map((val) => dayjs.utc(val).format("MMM Do, YYYY")).join(" - ")
          } else if (typeof(values) === "array") {
            values = `"` + values.join(" and ") + `"`
          } else if (values) {
            values = `"${values}"`
          }

          filterValue = values || ''
        }


        let logicText = ""
        let finalText = ""

        if ((logic[logicIndex] == '(' || logic[logicIndex] == ')')) {
          logicText = logic[logicIndex]
          logicIndex++;
        }

        if (index > 0) {
          logicText = `${logicText} ${logic[logicIndex]} `
          logicIndex++;
        }

        let title = ""

        if (this.segmentCriteriaMap[value.name].type === "date") {
          title = "Purchased"
        } else {
          title = this.segmentCriteriaMap[value.name].title
        }


        finalText = `${text}${logicText}${title} ${condition.title}`

        if (filterValue && filterValue != "") {
          finalText = `${finalText} ${filterValue}`
        }

        return finalText
      }, "")
    },

    async fetchPermissionsAndMaybeReroute() {
      await this.fetchPermissions();
      if (!this.isPermissionEnabled(['view-sales'])) {
        this.$router.push({
          path: `/events/${this.oid}/view/campaigns`,
        });
      }
    }

  },

  async mounted() {
    this.fetchPermissionsAndMaybeReroute();
    this.fetchAndInitEventTicketStats(this.oid)
    this.fetchEventTicketSales({
      eventOid: this.oid,
    })

    this.checkIfEventIsSyncing()
  },

  created() {
    this.fetchTags({
      top: 50,
      reload: true,
    });

    this.$store.registerModule("event-view-child", {
      actions: {
        FORCE_REFETCH_DATA: (state) => {
          this.pollNewStats();
        },
      }
    })
  },

  beforeRouteLeave(_, __, next) {
    this['event/RESET_EVENT_TICKET_SALES']();
    this['event/RESET_EVENT_TICKET_STATS']();
    this['segment/RESET_SCRATCH_SEGMENT']();
    this.clearSalesData()
    this.clearAnnotations()
    this.clearTimescaleData()
    this.setDragListenerState(false)
    next()
  },

  beforeDestroy() {
    if (this.syncPoller) {
      clearInterval(this.syncPoller);
    }
  },
};
</script>

<style lang="scss" scoped>

.graph-section {
  display: grid;
  grid-template-columns: calc(50% - 15px) calc(50% - 15px);
  grid-gap: 30px;

  // .grid-insight {
  //   height: 100%;
  //   margin: $content-gutter;
  // }

  .full-width-cell {
    grid-column: 1 / span 2;
  }

  .cell {
    grid-column: 1 / span;
  }

  &.sm-max {
    grid-gap: 12px;
    grid-template-columns: calc(50% - 6px) calc(50% - 6px);
  }

  &.md-max {
    .cell {
      grid-column: 1 / span 2;
    }
  }
}

>>>.highcharts-xaxis-labels {
  width: 50% !important;
  > span {
    width: 100% !important;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.sync-banner {
  display: flex;
  flex-direction: row;
    align-items: center;

  .link {
    margin-left: 4px;
  }

  &.xs-max {
    flex-direction: column;
    .link {
      margin-left: 0;
    }
  }



  .inner-sync {
    margin-left: 8px;
    margin-top: 2px;

    .syncing-icon {
      animation: spin 1s infinite linear;
    }
  }

}

.filter-sidebar {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  .event-filter-dropdowns {
    width: 250px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

}

.event-filter-text-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 20px;
  margin-top: 10px;
  margin-bottom: 20px;

  .filter-text {
    color: $blueGrey700;
  }
}


.link {
  cursor: pointer;
  text-decoration: underline;
}

a:hover {
  text-decoration:underline;
}

.events-sales {
  &__top-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__import {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 0px;

    .integration-halo {
      margin-right: 10px;
    }
  }

  .progress-pill {
    width: 100%;
    height: 10px;
    position: relative;
    background-color: #e4e9ee;
    border-radius: 5px;
    margin-top: 12px;
    overflow: hidden;

    .sold {
      background-color: #2dc26a;
      position: absolute;
      height: 100%;
    }

    .over-capacity {
      background-color: #fc4349;
      position: absolute;
      height: 100%;
      right: 0;
    }
  }
}

</style>
