var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ar-hero-wrapper"},[_c('div',{staticClass:"ar-content"},[_c('a',{staticClass:"back-link",on:{"click":_vm.handleBackClick}},[_c('ar-icon',{attrs:{"name":"arrow","rotate":90}}),_vm._v(" "),_c('span',{staticClass:"text",style:({ top: _vm.isFirefox ? '0' : '-1px' })},[_vm._v(_vm._s(_vm.stepBackText))])],1),_vm._v(" "),_c('div',{staticClass:"message-title-wrapper"},[(_vm.providerIcon)?_c('ar-icon',{attrs:{"name":_vm.providerIcon.name,"color":_vm.providerIcon.color,"height":_vm.providerIcon.height,"width":_vm.providerIcon.width,"wrapper-style":{
          backgroundColor: '#FFF',
          padding: _vm.providerIcon.padding,
          borderRadius: '50%',
          marginRight: '12px',
          boxShadow: ("0px 0px 6px 2px " + (_vm.rgbaColor(_vm.$arStyle.color.blueGrey400, 0.2)))
        }}}):_vm._e(),_vm._v(" "),_c('am2-heading',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
              content: _vm.title,
              classes: ['longTooltip'],
            }),expression:"{\n              content: title,\n              classes: ['longTooltip'],\n            }",modifiers:{"top":true}}],staticClass:"message-heading",style:({ color: 'white' }),attrs:{"type":"h1","size":"xl","title":_vm.title}})],1),_vm._v(" "),_c('div',{staticClass:"message-hero-stats-wrapper",class:_vm.statsLoadedClass},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
          content: 'Date sent'
        }),expression:"{\n          content: 'Date sent'\n        }",modifiers:{"top":true}}],staticClass:"message-hero-stat",style:({
          cursor: 'default',
        })},[_c('ar-icon',{style:({
            marginRight: '7px',
          }),attrs:{"height":"16px","name":"clock","color":_vm.$arStyle.color.skyBlueGrey500}}),_vm._v(" "),_c('ar-text',{style:({
            color: ("" + (_vm.$arStyle.color.skyBlueGrey500)),
          }),attrs:{"text":_vm.scheduledAtText,"size":"xs"}})],1),_vm._v(" "),(!_vm.isSms)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
          content: 'Sent from',
        }),expression:"{\n          content: 'Sent from',\n        }",modifiers:{"top":true}}],staticClass:"message-hero-stat",style:({
          cursor: 'default',
        })},[_c('ar-icon',{style:({
            marginRight: '7px'
          }),attrs:{"height":"16px","name":"flag","color":_vm.$arStyle.color.skyBlueGrey500}}),_vm._v(" "),_c('ar-text',{style:({
            color: ("" + (_vm.$arStyle.color.skyBlueGrey500)),
          }),attrs:{"text":_vm.messageProviderText,"size":"xs"}})],1):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
          content: _vm.messageListTooltip,
        }),expression:"{\n          content: messageListTooltip,\n        }",modifiers:{"top":true}}],staticClass:"message-hero-stat",style:({
          cursor: 'pointer',
        }),on:{"click":_vm.handleListClick}},[_c('ar-icon',{style:({
            marginRight: '7px'
          }),attrs:{"height":"16px","name":"user","color":_vm.$arStyle.color.skyBlueGrey500}}),_vm._v(" "),_c('ar-text',{style:({
            color: ("" + (_vm.$arStyle.color.skyBlueGrey500)),
          }),attrs:{"text":(_vm.messageListName + " • " + _vm.fansTotal),"size":"xs"}})],1),_vm._v(" "),(_vm.costOfMessage)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
          content: 'This is how much it cost to send your SMS message.',
        }),expression:"{\n          content: 'This is how much it cost to send your SMS message.',\n        }",modifiers:{"top":true}}],staticClass:"message-hero-stat",style:({
          cursor: 'default',
        })},[_c('ar-icon',{attrs:{"name":"money","height":"17px","color":_vm.$arStyle.color.skyBlueGrey500}}),_vm._v(" "),_c('ar-text',{style:({
            marginLeft: '7px',
            color: ("" + (_vm.$arStyle.color.skyBlueGrey500))
          }),attrs:{"size":"xs","text":_vm.costOfMessage}})],1):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }