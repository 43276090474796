<template>
  <am2-card-container layout="regular">
    <div class="conversion-chart">

      <!-- Header -->

      <div class="conversion-chart__header">
        <SalesChartHeadingDropdown
          has-data
          v-model="selectedHeading"
          :options="headingOptions"
        />

        <am2-elegant-tabs
          :items="ocChartTabItems"
          tab-key="sales"
          @select="handleTimeRangeChange"
          :style="{
            width: '250px',
          }"
          layout="wide"
          data-test-id="overview-open-vs-click-tabs"
        />
      </div>

      <!-- Line chart -->

      <div class="conversion-chart__chart-wrapper">
        <multi-events-chart
          :is-loading="isChartLoading || !isCalculationsFinished"
          :series="chartSeries"
          :options="chartOptions()"
        />
      </div>
    </div>
  </am2-card-container>
</template>

<script>
import { mapMutations, mapState, mapGetters } from "vuex";
import MultiEventsChart from "@/components/charts/MultiEventsChart.vue";
import SalesChartHeadingDropdown from "@/pages/events/view/sales/components/SalesChartHeadingDropdown.vue";

import { setInitialSalesChartData } from "~/store/modules/event/utils";
import { amountInDollars, centsRemaining, clone } from "~/utils/helpers";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import accounting from "accounting";

dayjs.extend(utc)

export default {
  name: "ConversionsChart",
  components: {
    MultiEventsChart,
    SalesChartHeadingDropdown,
  },
  data() {
    return {
      ocChartKeyValue: 'sales',
      timeZone: '',
      compareFrom: 'start',
      ocChartTabItems: [
        {
          key: 'sales',
          name: 'Sales'
        },
        {
          key: 'quantity',
          name: 'Quantity'
        }
      ],
      rawChartDataArray: [],
      worker: null,
      selectedHeading: { name: 'Total ticket sales', value: 'accumulative' },
      headingOptions: [
        {
          name: 'Total ticket sales',
          value: 'accumulative',
        },
        {
          name: 'Incremental ticket sales',
          value: 'incremental',
        },
      ],
    };
  },
  computed: {
    ...mapState({
      oid: state => state.route.params.oid,

      conversionGraphTimeseries: state => state.message.conversionGraphTimeseries,
      isChartLoading: state => state.message.isFetchingConversionGraph,
      currentSelectedMessage: state => state.message.currentSelectedMessage,
      isCalculationsFinished: state => state.message.isCalculationsFinished,
    }),

    chartSeries() {
      return this.rawChartDataArray.map((data) => {
        return {
          name: data.name,
          data: data[this.ocChartKeyValue][this.selectedHeading.value][0].data
        }
      })
    }
  },

  watch: {
    conversionGraphTimeseries() {
      this.setSeriesData();
    },
  },

  methods: {
    ...mapMutations({
      setChartIsLoading: 'message/SET_IS_FETCHING_CONVERSIONS_GRAPH',
    }),
    ...mapGetters({
      regionSettingsCurrencySymbol: 'auth/regionSettingsCurrencySymbol',
    }),

    handleTimeRangeChange(range) {
      this.ocChartKeyValue = range.key;
    },

    prependZeroValsToChartData(data) {
      if (!data.length) {
        return data
      }
      let zeroedData = data

      if(zeroedData[0] && zeroedData[0].ts) {
        zeroedData.unshift({
          sales: 0,
          tickets: 0,
          ts: dayjs(zeroedData[0].ts + 'Z').isAfter(dayjs(this.currentSelectedMessage.started))
            ? this.currentSelectedMessage.started
            : dayjs.utc(zeroedData[0].ts).subtract(1, 'day').valueOf(),
        })
      }

      return zeroedData
    },

    patchChartData(data) {
      let dataSet = this.prependZeroValsToChartData(data)
      return dataSet
    },

    noSalesDefaultData() {
      let minDate = ''
      let maxDate = ''

      this.conversionGraphTimeseries.forEach((data) => {
        if (data.stats.cumulativeSales && data.stats.cumulativeSales.length) {
          let firstDate = data.stats.cumulativeSales[0].ts
          let lastDate = data.stats.cumulativeSales[data.stats.cumulativeSales.length - 1].ts
          if (!minDate || firstDate < minDate) {
            minDate = firstDate
          }
          if (!maxDate || lastDate > maxDate) {
            maxDate = lastDate
          }
        }
      })

      if (!minDate) minDate = dayjs().format()
      if (!maxDate) maxDate = dayjs().format()

      return [{ ts: minDate, sales: 0, tickets: 0 }, { ts: maxDate, sales: 0, tickets: 0 }]
    },

    setSeriesData() {
      if (!this.conversionGraphTimeseries.length) return;

      this.rawChartDataArray = []
      this.clearWorker()
      this.worker = this.$arWorker.createInitialEventSalesDataWorker()

      this.worker.addEventListener('message', (event) => {
        let initialData = setInitialSalesChartData(event.data.oid, event.data)
        initialData.name = event.data.name
        this.rawChartDataArray.push(initialData)
      })

      try {
        for (let i = 0; i < this.conversionGraphTimeseries.length; i++) {
          const data = clone(this.conversionGraphTimeseries[i].stats)

          let initialData = {}

          let salesData = this.cumulativeSalesOrBackwardsCompatibleSales(data)
          let totalSalesRawData = !!salesData.length ? salesData : this.noSalesDefaultData()
          let incrementalSalesRawData = !!salesData.length && !!data?.salesPerHour.length ? data.salesPerHour : this.noSalesDefaultData()

          initialData['totalSales'] = !!totalSalesRawData.length ? this.patchChartData(totalSalesRawData) : totalSalesRawData
          initialData['incrementalSales'] = !!incrementalSalesRawData.length ? this.patchChartData(incrementalSalesRawData) : incrementalSalesRawData


          this.worker.postMessage({
            chartData: initialData,
            oid: this.conversionGraphTimeseries[i].targetOid,
            name: this.conversionGraphTimeseries[i].name,
          })
        }
      } catch (error) {
        this.$arNotification.push({ type: 'error', message: 'Failed to show graph' });
      }
    },

    clearWorker() {
      if (!this.worker) return
      this.worker.terminate()
      this.worker = null
    },

    cumulativeSalesOrBackwardsCompatibleSales(data) {
      if (!!data?.cumulativeSales?.length) {
        return data.cumulativeSales
      } else if (!!data?.sales?.length) {
        return data.sales
      } else {
        return []
      }
    },

    getFullNameForTopic() {
      let copy
      switch(this.ocChartKeyValue) {
        case 'sales':
          copy =  'Total ticket sales'
          break
        case 'quantity':
          copy =  'Total ticket quantity'
          break
        default:
          console.log('An unrecognised topic was received')
      }

      return copy
    },

    getTooltipValue(val) {
      if (this.ocChartKeyValue === 'quantity') {
        return val
      }

      return `${this.getDollars(val)}`
    },

    getDollars(val) {
      return `${this.regionSettingsCurrencySymbol}${amountInDollars(val)}`
    },

    getCents(val) {
      return `${centsRemaining(val)}`
    },

    yAxisQuantityFormatter(value) {
      return accounting.formatNumber(value);
    },

    yAxisSalesFormatter(value) {
      if (value < 100) { // Might get small value if it's not in unit of cent
        return `${accounting.formatMoney(value, { precision: 0 })}`;
      }
      let dollars = value / 100;
      if (dollars >= 1000000) {
        return `${accounting.formatMoney(dollars / 1000000, { precision: dollars % 1000000 === 0 ? 0 : 1 })}m`;
      } else if (dollars >= 1000) {
        return `${accounting.formatMoney(dollars / 1000, { precision: dollars % 1000 === 0 ? 0 : 1  })}k`;
      }
      return accounting.formatMoney(dollars, { precision: 0 })
    },

    chartOptions() {
      return {
        yaxis: {
          minWidth: 50,
          labels: {
            formatter: (val) => {
              return this.ocChartKeyValue === 'sales' ? this.yAxisSalesFormatter(val) : this.yAxisQuantityFormatter(val)
            },
          }
        },
        tooltip: {
          custom: ({_, seriesIndex, dataPointIndex, w}) => {
            let toolTipData = w.config.series[seriesIndex].data[dataPointIndex]
            let dataDate = dayjs(toolTipData[0][0]).format('DD MMMM YYYY HH:mm')

            return `
              <div class="sales-tooltip-wrapper">
                <span class="tooltip-date">${dataDate}</span>
                <span class="tooltip-tab">${this.getFullNameForTopic()}</span>
                <span class="tooltip-value-wrapper">
                  <span class="tooltip-value">${this.getTooltipValue(toolTipData[1])}</span>
                  <span class="tooltip-cents">${this.ocChartKeyValue === 'sales' ? this.getCents(toolTipData[1]) : ''}</span>
                </span>
              </div>
            `
          }
        }
      }
    },
  },
}
</script>

<style lang="scss">
.conversion-chart{
  padding: calc(30em / 16);

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
}

.sales-tooltip-wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;
  width: 180px;
  background: white;
  padding: ui-px2em(12) ui-px2em(15) ui-px2em(18);
  box-sizing: border-box;
  font-family: 'Graphik';
  font-weight: 400;
  color: $blueGrey800;
  z-index: 1;
  position: relative;

  .tooltip-date {
    font-size: 14px;
    margin-bottom: 0;
  }

  .tooltip-tab {
    font-size: 14px;
    color: $blueGrey700;
    margin-top: -5px;
    margin-bottom: 10px;
  }

  .tooltip-value-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: baseline;
    justify-content: flex-start;

    .tooltip-value {
      font-size: 24px;
    }

    .tooltip-cents {
      font-size: 14px;
    }
  }
}
</style>
