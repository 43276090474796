<template>
    <section :class="[
      'customer-profile-activity',
      $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
    ]">
      <div class="left-side">
        <am2-card-container
          layout="soft"
          v-ar-sticky-top="{
            priority: 1,
            gap: 20,
          }">
          <am2-interactive-list
            :items="interactiveListItems"
            :active-index="interactiveListIndex"
            :disabled="customerState.isFetchingActivity"
          />
        </am2-card-container>
      </div>
      <div
        class="right-side"
        v-infinite-scroll="loadMoreActivity"
        infinite-scroll-disabled="infiniteScrollDisabled"
        infinite-scroll-distance="50"
        v-ar-feature-mask="{
          show: !isCustomerProfileFeatureEnabled,
          showUpgradeOn: 'hover',
          iconName: 'audience',
          title: lockedTitle.activity,
          message: 'Unlock customer profiles to get access to a timeline for each fan showing previous activity.',
        }"
        :style="{
          borderRadius: !isCustomerProfileFeatureEnabled ? '5px' : null,
          overflow: !isCustomerProfileFeatureEnabled ? 'hidden' : null,
        }"
      >
        <div
          :style="{
            filter: isCustomerProfileFeatureEnabled ? null : 'blur(5px)',
          }"
        >
          <div
            v-if="computedActivity.length === 0 && customerState.isFetchingActivity === true"
          >
            <am2-card-skeleton
              v-for="n in 3"
              class="base-plan-card"
              type="activity"
              :key="n"
            />
          </div>
          <div
            v-else
          >
            <am2-card-container
              layout="soft"
              v-if="computedActivity.length === 0 && customerState.isFetchingActivity === false"
              class="no-content-container ar-transition-group-item">
              <ar-text
                :text="noContentProps.body"
                :style="{
                color: $arStyle.color.blueGrey700,
              }"
                size="xs"
              />
            </am2-card-container >
            <transition-group
              v-else
              name="ar-transition-group"
              tag="div">
              <am2-activity-card
                class="ar-transition-group-item u-margin-bottom-3"
                v-for="item in computedActivity"
                :key="item.oid"
                :image-path="item.imagePath"
                :title="item.title"
                :subtitle="item.subtitle"
                :datetime="item.datetime"
                :text="item.text"
                :path="item.path"
                :link="item.link"
                :icon="item.icon"
                :children="item.children ? item.children : []"
                :types="item.type"
              />
            </transition-group>
          </div>
        </div>
      </div>
    </section>
</template>

<script>

  import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
  import { dummyFanActivity } from '@/utils/audience';

  export default {
    name: 'CustomerProfileActivity',

    data() {
      return {
        interactiveListIndex: 'all',
      }
    },

    watch: {
      interactiveListIndex: {
        handler(val) {
          const selectedActivityType = this.interactiveListItems.find( item => item.key === val);
          if (!selectedActivityType) return;

          this.$nextTick(() => {
            this.fetchActivity({
              types: selectedActivityType.typeKeys,
              notTypes: selectedActivityType.notTypeKeys,
            });
          });
        },
      },
      customer: {
        handler(val, oldVal) {
          if (val && val.oid && !oldVal && !this.isFetchingActivity) {
            const selectedActivityType = this.interactiveListItems.find( item => item.key === this.interactiveListIndex);
            this.fetchActivity({
              types: selectedActivityType.typeKeys || this.allowedTypeKeys,
              notTypes: selectedActivityType.notTypeKeys,
            });
          }
        },
        immediate: true,
      }
    },

    created() {
      this.fetchPermissionsAndWait();
      this['customer/RESET_CUSTOMER_ACTIVITY']();
      this.interactiveListIndex = 'all';
    },

    beforeDestroy() {
      this['customer/RESET_CUSTOMER_ACTIVITY']();
    },

    computed: {
      ...mapGetters({
        isFeatureEnabled: 'auth/isFeatureEnabled',
        isPermissionEnabled: 'auth/isPermissionEnabled',
      }),
      ...mapState({
        customerState: state => state.customer,
        customer: state => state.customer.customer,
        isFetchingActivity: state => state.customer.isFetchingActivity,
      }),

      allowedTypeKeys() {
        if (this.isPermissionEnabled(['view-sales'])) {
          return ['fan-message', 'campaign-registration', 'event-purchase', 'ecommerce-purchase'];
        }
        return ['fan-message', 'campaign-registration'];
      },

      interactiveListItems() {
        const allTypes = this.isPermissionEnabled(['view-sales']) ?
          [] :
          ['fan-message', 'campaign-registration']
        const items = [
          {
            key: 'all',
            title: 'All Activity',
            typeKeys: allTypes,
            action: () => {
              this.interactiveListIndex = 'all';
            },
          },
          {
            key: 'fan-message',
            title: 'Messages',
            typeKeys: ['fan-message'],
            notTypeKeys: [],
            action: () => {
              this.interactiveListIndex = 'fan-message';
            },
          },
          {
            key: 'campaign-registration',
            title: 'Campaigns',
            typeKeys: ['campaign-registration'],
            notTypeKeys: [],
            action: () => {
              this.interactiveListIndex = 'campaign-registration';
            },
          },
        ];

        if (this.isPermissionEnabled(['view-sales'])) {
          items.push({
            key: 'event-purchase',
            title: 'Events',
            typeKeys: ['event-purchase'],
            notTypeKeys: [],
            action: () => {
              this.interactiveListIndex = 'event-purchase';
            },
          })
          items.push({
            key: 'ecommerce-purchase',
            title: 'Purchases',
            typeKeys: ['ecommerce-purchase'],
            notTypeKeys: [],
            action: () => {
              this.interactiveListIndex = 'ecommerce-purchase';
            },
          })
        }
        return items;
      },

      lockedTitle() {
        return {
          "activity": this.customerState?.customer?.firstName ? `${this.customerState?.customer?.name}'s Activity Feed` : `Activity Feed`,
        }
      },

      infiniteScrollDisabled() {
        return this.customerState.noMoreActivity || this.customerState.isFetchingActivity || this.customerState.hasFetchCustomerActivityFailed;
      },

      isFetchingActivity() {
        return this.customerState.isFetchingActivity;
      },

      isCustomerProfileFeatureEnabled() {
        return this.isFeatureEnabled(['audience', 'customerProfiles']);
      },

      computedActivity() {
        if (!this.isCustomerProfileFeatureEnabled) {
          return dummyFanActivity(this.interactiveListIndex);
        }
        return this.customerState.fanActivity;
      },

      noContentProps() {
        const noContentProps = {};

        switch(this.interactiveListIndex) {
          case 'important':
            noContentProps.body = 'No important activity';
            break;
          case 'fan-message':
            noContentProps.body = `${this.customerState?.customer?.name} has not been sent any messages`;
            break;
          case 'event-purchase':
            noContentProps.body = `${this.customerState?.customer?.name} has not made any purchases`;
            break;
          case 'campaign-registration':
            noContentProps.body = `${this.customerState?.customer?.name} has not participated in any campaigns`;
            break;
          case 'event-attendance':
            noContentProps.body = `${this.customerState?.customer?.name} has not purchased tickets to any events`;
            break;
          case 'ecommerce-purchase':
            noContentProps.body = `${this.customerState?.customer?.name} has not made any ecommerce purchases`;
            break;
          default:
            noContentProps.body = 'No activity';
            break;
        }
        return noContentProps;
      }
    },

    methods: {
      ...mapActions([
        'customer/FETCH_CUSTOMER_ACTIVITY',
        'auth/FETCH_PERMISSIONS',
      ]),

      ...mapMutations([
        'customer/RESET_CUSTOMER_ACTIVITY',
      ]),
      async fetchPermissionsAndWait() {
        await this['auth/FETCH_PERMISSIONS']();
      },

      async loadMoreActivity() {
        if (this.customerState.noMoreActivity) return;
        if (this.customerState.isFetchingActivity) return;
        const selectedActivityType = this.interactiveListItems.find( item => item.key === this.interactiveListIndex);
        if (!selectedActivityType) return;
        await this.fetchActivity({
          skip: this.computedActivity.length,
          types: selectedActivityType.typeKeys,
          notTypes: selectedActivityType.notTypeKeys,
          append: true,
        });
      },

      async fetchActivity({
          top = 50,
          skip = 0,
          types = this.allowedTypeKeys,
          notTypes = [],
          append = false
      }) {
        if(this.customerState.isFetchingActivity) return;
        this['customer/FETCH_CUSTOMER_ACTIVITY']({
          top,
          skip,
          types,
          notTypes,
          append,
          fanOid: this.$route.params.oid,
        });
      },
    }

  };
</script>

<style lang="scss" scoped>
  .customer-profile-activity {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;

    .left-side {
      grid-column: 1;
      grid-row: 1;
      height: 100%;
    }

    .right-side {
      grid-column: 2 / 4;
      grid-row: 1;
      position:relative;
    }

    .no-content-container {
      background-color: #FFF;
      padding: 32px;
      width: 100%;
    }

    &.sm-max {
      display: flex;
      flex-direction: column;
      padding: 12px;

      .left-side {
        margin-bottom: 24px;
      }
    }
  }
</style>
