var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('am2-card-container',{staticClass:"sync-block-container"},[(_vm.syncStatus === 'in-progress')?_c('div',{staticClass:"sync-block"},[_c('am2-loading-bubble',{staticClass:"sync-animation"}),_vm._v(" "),_c('div',{staticClass:"text-block"},[_c('am2-heading',{staticClass:"header",attrs:{"size":_vm.$arMediaQuery.pageContent.minWidth('sm') ? 'lg' : 'md',"type":"h2","title":_vm.syncCopies[_vm.syncStatus || 'default']}}),_vm._v(" "),_c('div',{staticClass:"button-block"},[_c('ar-simple-button',{staticClass:"button",attrs:{"text":"Stop sync","outlined":""},on:{"click":_vm.handleStopSyncClick}})],1)],1)],1):_c('div',{staticClass:"sync-block"},[(_vm.isFetchingSyncTask)?_c('ar-skeleton',{style:({
        width: '44px',
        height: '44px',
        borderRadius: '50%',
      })}):_c('div',{staticClass:"tick-container"},[(_vm.syncStatus === 'completed' || _vm.syncStatus === 'connected')?_c('div',{staticClass:"tick-container",style:({
          backgroundColor: _vm.$arStyle.color.green500,
        })},[_c('ar-icon',{staticClass:"tick-icon",attrs:{"name":"check","width":"18","height":"17"}})],1):_c('div',{staticClass:"tick-container",style:({
          backgroundColor: _vm.$arStyle.color.purple500,
        })},[_c('ar-icon',{staticClass:"tick-icon",attrs:{"name":"exclamation-mark","height":"25"}})],1)]),_vm._v(" "),_c('div',{staticClass:"text-block u-margin-top-8"},[(_vm.isFetchingSyncTask)?_c('ar-skeleton',{style:({
          width: '100%',
          height: '26px',
          borderRadius: '3px',
        })}):_c('am2-heading',{staticClass:"header",attrs:{"size":_vm.copySize,"type":"h2","title":_vm.syncCopies[_vm.syncStatus || 'default']}}),_vm._v(" "),_c('div',{staticClass:"button-block u-margin-top-9"},[(_vm.isFetchingSyncTask)?_c('ar-skeleton',{style:({
            width: '300px',
            height: '50px',
            borderRadius: '3px',
          })}):(_vm.syncStatus === 'completed')?_c('ar-simple-button',{staticClass:"button",attrs:{"text":"Sync now","loading":_vm.isFetchingSyncTask},on:{"click":_vm.handleSyncNowClick}}):_vm._e()],1),_vm._v(" "),(_vm.timeSinceLastSync)?_c('div',{staticClass:"last-sync-time"},[_c('ar-text',{style:({
            color: _vm.$arStyle.color.blueGrey700,
          }),attrs:{"text":("Last synced " + _vm.timeSinceLastSync),"size":"xxs"}})],1):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }