<template>
  <portal
    v-if="open"
    to="modal"
    :style="{ position: 'absolute' }"
    class="portal-wrapper"
  >
    <ar-modal
      :is-open="open"
      class="custom-fields-modal"
      :header="header"
      width="672px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
      :has-back-link="hasBackLink"
      @back="handleBackPress"
    >
      <div slot="body" class="cf-modal-body">
        <div class="modal-body-wrapper">
          <template v-if="step === -1">
            <div
              :style="{width: '100%'}">
              <ar-text
                class="select-field-subheading"
                size="14px"
                text="Choose from an existing custom field or create a new one."
                :color="$arStyle.color.blueGrey800"
                :max-lines="1"
                line-height="25px"
              />
              <ar-text
                class="select-field-subheading u-margin-top-6"
                size="14px"
                text="Select custom field"
                weight="bold"
                :color="$arStyle.color.blueGrey800"
                :max-lines="1"
                line-height="25px"
              />
              <ar-select
                class="custom-field-select u-margin-top-4"
                :items="promoterCustomFields"
                placeholder="Select existing custom field"
                :hide-arrow="false"
                showEmptyState
                data-test-id="existing-custom-fields"
                @select="handleExistingCustomFieldSelect"
              >
                <template slot="selected-item" slot-scope="selectedItemScope">
                  <div
                    v-if="selectedItemScope.item"
                    :style="{
                    display: 'flex',
                    height: '100%',
                    alignItems: 'center',
                  }"
                    :data-test-id="`custom-field-item-${selectedItemScope.item}`"
                  >
                    <ar-text
                      size="xs"
                      v-if="selectedItemScope.item"
                      :text="selectedItemScope.item.name"
                      :style="{
                      width: 'auto',
                      marginLeft: '10px',
                      color: $arStyle.color.blueGrey800,
                    }"
                      weight="normal"
                    />
                  </div>
                  <div
                    v-else
                    :style="{
                    display: 'flex',
                    height: '100%',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    padding: '0 14px',
                  }"
                  >
                    <ar-text
                      size="xs"
                      text="Select custom field"
                      :style="{
                      color: $arStyle.color.blueGrey600,
                    }"
                    />
                  </div>
                </template>
                <template slot="item" slot-scope="itemScope">
                  <div
                    :style="{
                    display: 'flex',
                    alignItems: 'center',
                    height: '55px',
                    padding: '0 15px',
                    cursor: 'pointer',
                    background: itemScope.active ? $arStyle.color.purple100 : null,
                    borderBottom: '1px solid #dcdee4',
                  }"
                    :data-test-id="`custom-field-item-${itemScope.item.name}`"
                  >
                    <ar-text
                      size="xs"
                      :text="itemScope.item.name"
                      :style="{
                      width: 'auto',
                      color: itemScope.active ? '#7344c0' : '#43516b',
                    }"
                      :weight="itemScope.selected ? 'bold' : 'normal'"
                    />
                  </div>
                </template>
              </ar-select>
            </div>

            <div class="existing-field-data" v-if="!!existingField">
              <div class="grid-item">
                <ar-text
                  class="custom-field-type"
                  size="14px"
                  text="Custom field type"
                  weight="bold"
                  :color="$arStyle.color.blueGrey800"
                  :max-lines="1"
                  line-height="25px"
                />
              </div>
              <div class="grid-item">
                <ar-text
                  class="custom-field-type"
                  size="14px"
                  :text="prettyVisibility"
                  weight="normal"
                  :color="$arStyle.color.blueGrey700"
                  :max-lines="1"
                  line-height="25px"
                  :style="{
                    textTransform: 'capitalize',
                    color: $arStyle.color.blueGrey700
                  }"
                />
              </div>
              <div class="grid-item">
                <ar-text
                  class="custom-field-type"
                  size="14px"
                  text="Field input type"
                  weight="bold"
                  :color="$arStyle.color.blueGrey800"
                  :max-lines="1"
                  line-height="25px"
                />
              </div>
              <div class="grid-item">
                <ar-text
                  class="custom-field-type"
                  size="14px"
                  :text="prettyFieldInputType"
                  weight="normal"
                  :color="$arStyle.color.blueGrey700"
                  :max-lines="1"
                  line-height="25px"
                  :style="{
                    color: $arStyle.color.blueGrey700
                  }"
                />
              </div>
              <div class="grid-item" v-if="prettyOptionsText.length > 0">
                <ar-text
                  class="custom-field-type"
                  size="14px"
                  text="Options"
                  weight="bold"
                  :color="$arStyle.color.blueGrey800"
                  :max-lines="1"
                  line-height="25px"
                />
              </div>
              <div class="grid-item" v-if="prettyOptionsText.length > 0">
                <div
                  v-for="(optionsText, idx) in prettyOptionsText"
                  :key="idx"
                >
                  <ar-text
                    class="custom-field-type u-margin-bottom-3"
                    size="14px"
                    :text="optionsText"
                    weight="normal"
                    :color="$arStyle.color.blueGrey700"
                    :max-lines="1"
                    line-height="25px"
                    :style="{
                      color: $arStyle.color.blueGrey700
                    }"
                  />
                </div>
              </div>
            </div>

          </template>
          <template v-if="step === null || step === 0">
            <ar-text
              class="create-custom-field-subheading"
              size="14px"
              text="What type of custom field will this be?"
              weight="bold"
              :color="$arStyle.color.blueGrey800"
              :max-lines="1"
              line-height="25px"
            />
            <div class="cf-inner-wrapper">
              <div
                :class="[
                  'inner-container',
                  customFieldType === 'profile' ? 'selected' : ''
                ]"
                @click="handleSelectCustomFieldType('profile')"
              >
                <profileImg />
                <div class="cf-option-wrapper">
                  <ar-text
                    class="custom-field-inner-heading"
                    size="16px"
                    text="Profile field"
                    weight="bold"
                    :color="$arStyle.color.blueGrey800"
                    :max-lines="1"
                    line-height="25px"
                  />
                  <ar-text
                    class="custom-field-inner-copy"
                    size="14px"
                    text="Information you want to see on a fans profile, filter or use as a merge field. e.g. presale code."
                    weight="normal"
                    multiple-lines
                    line-height="18px"
                    />
                </div>
              </div>
              <div
                :class="[
                  'inner-container',
                  customFieldType === 'form' ? 'selected' : ''
                ]"
                @click="handleSelectCustomFieldType('form')"
              >
                <formImg />
                <div class="cf-option-wrapper">
                  <ar-text
                    class="custom-field-inner-heading"
                    size="16px"
                    text="Form field"
                    weight="bold"
                    :max-lines="1"
                    :color="$arStyle.color.blueGrey800"
                    line-height="25px"
                  />
                  <ar-text
                    class="custom-field-inner-copy"
                    size="14px"
                    text="Ideal for collecting responses to questions like a survey (e.g. favorite artist). Not visible on profiles."
                    weight="normal"
                    multiple-lines
                    line-height="18px"
                  />
                </div>
              </div>
            </div>
          </template>
          <template v-if="step === 1">
            <div class="step-wrapper">
              <ar-field
                class="form-element-wrapper field-label"
                label="Field Name"
                :error-messages="[veeErrors.first('name')]"
              >
                <ar-input
                  v-validate="'required'"
                  autocomplete="name"
                  data-vv-name="name"
                  data-vv-as="name"
                  type="text"
                  placeholder="e.g - FanID or Who is your favourite artist?"
                  :value="customFieldData ? customFieldData.name : ``"
                  :disabled="editingProfileFieldInRestrictedMode"
                  @input="(val) => handleFieldUpdate({field: 'name', val})"
                />
              </ar-field>
              <div class="form-element-wrapper">
                <ar-field
                  class="field-label"
                  label="Field Description (optional)"
                  :error-messages="[veeErrors.first('description')]"
                >
                  <ar-input
                    name="description"
                    id="description"
                    class="field-description-input"
                    :value="customFieldData.description"
                    :disabled="editingProfileFieldInRestrictedMode"
                    @input="(val) => handleFieldUpdate({field: 'description', val})"
                    placeholder="e.g - Select an option from the drop down"
                    data-vv-name="description"
                    data-vv-as="description"
                  />
                </ar-field>
              </div>
              <div class="form-element-wrapper" v-click-outside="handleClickOutsideTypeDropdown">
                <ar-field
                  class="field-type-dropdown"
                  label="Field Type"
                  :error-messages="[veeErrors.first('hasFieldType')]"
                  v-validate:hasFieldType="'required:true'"
                  data-vv-name="hasFieldType"
                  data-vv-as="type"
                >
                  <ar-select
                    class="field-type-select"
                    :items="dropdownTypeOptions"
                    placeholder="Select field type"
                    :hide-arrow="false"
                    showEmptyState
                    data-test-id="existing-custom-fields"
                    @select="handleTypeDropdownOptionClick"
                    :disabled="editingProfileFieldInRestrictedMode || (createState === 'edit' && !!(selectedFieldType && selectedFieldType.display))"
                    :defaultSelectIndex="selectedFieldTypeIndex"
                  >
                    <template slot="selected-item" slot-scope="selectedItemScope">
                      <div
                        v-if="selectedItemScope.item"
                        :style="{
                          display: 'flex',
                          height: '100%',
                          alignItems: 'center',
                        }"
                        :data-test-id="`custom-field-item-${selectedItemScope.item}`"
                      >
                        <ar-text
                          size="xs"
                          v-if="selectedItemScope.item"
                          :text="selectedItemScope.item.display"
                          :style="{
                            width: 'auto',
                            marginLeft: '10px',
                            color: $arStyle.color.blueGrey800,
                          }"
                          weight="normal"
                        />
                      </div>
                      <div
                        v-else
                        :style="{
                          display: 'flex',
                          height: '100%',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          padding: '0 14px',
                        }"
                      >
                        <ar-text
                          size="xs"
                          text="Select list type"
                          :style="{
                            color: $arStyle.color.blueGrey600,
                          }"
                        />
                      </div>
                    </template>
                    <template slot="item" slot-scope="itemScope">
                      <div
                        :style="{
                          display: 'flex',
                          alignItems: 'center',
                          height: '55px',
                          padding: '0 15px',
                          cursor: 'pointer',
                          background: itemScope.active ? $arStyle.color.purple100 : null,
                        }"
                        :data-test-id="`custom-field-item-${itemScope.item.display}`"
                      >
                        <ar-text
                          size="xs"
                          :text="itemScope.item.display"
                          :style="{
                            width: 'auto',
                            color: itemScope.active ? '#7344c0' : '#43516b',
                          }"
                          :weight="itemScope.selected ? 'bold' : 'normal'"
                        />
                      </div>
                    </template>
                  </ar-select>
                </ar-field>
              </div>
              <div v-if="dropdownOptionsToggle" class="form-element-wrapper">
                <ar-field
                  class="cf-dropdown-options"
                  label="Dropdown options"
                  :error-messages="[veeErrors.first('hasContent')]"
                  v-validate:hasContent="'required:true'"
                  data-vv-name="hasContent"
                  data-vv-as="dropdown option"
                >
                  <draggable
                    v-model="dropdownOptions"
                    handle=".handle"
                  >
                    <div
                      v-for="(dropdownOption, index) in dropdownOptions"
                      :key="`dropdown-option-${index}`"
                      class="cf-dropdown-option"
                    >
                      <ar-field>
                        <div
                          class="cf-dropdown-option-row"
                        >
                          <ar-icon
                            class="u-margin-right-3 option-button handle"
                            name="tile-view"
                            height="13px"
                            :color="$arStyle.color.blueGrey600"
                          />
                          <div
                            class="cf-dropdown-option-row-input"
                          >
                            <ar-input
                              class="u-margin-right-3"
                              placeholder="Field option"
                              :value="dropdownOption.name"
                              :style="{
                                'width': '50%'
                              }"
                              @input="(val) => handleNameInputDropdown(val, dropdownOption, index)"
                              :disabled="editingProfileFieldInRestrictedMode"
                              v-validate="'required|isUnique'"
                              :data-vv-name="`cf-option-input-${index}`"
                              data-vv-as="'field option'"
                              :has-error="veeErrors.has(`cf-option-input-${index}`)"
                            />
                            <ar-auto-complete-input
                              ref="input"
                              placeholder="Add tag (optional)"
                              class="add-tag-input u-margin-right-3"
                              data-test-id="tag-list-auto-complete-input"
                              new-suggestion-template="Create new tag {suggestion}"
                              :disabled="editingProfileFieldInRestrictedMode"
                              :suggestions="suggestedTags"
                              :value="dropdownOption.tag ? dropdownOption.tag : ''"
                              @input="(value) => handleTagSearchStringInput(value, index)"
                              @blur="handleTagInputBlur"
                              @suggestSelect="(value) => handleSuggestionClick(value, index)"
                              :style="{
                                'width': '50%'
                              }"
                            />
                          </div>
                          <ar-icon
                            class="option-button"
                            name="discard"
                            height="13px"
                            :color="$arStyle.color.blueGrey600"
                            @click="(optionRow) => handleDeleteOptionRow(optionRow, index)"
                          />

                        </div>
                      </ar-field>
                      <ar-state-message
                        v-if="veeErrors.has(`cf-option-input-${index}`)"
                        :text="veeErrors.first(`cf-option-input-${index}`)"
                        type="error"
                        class="u-margin-top-2"
                      />
                    </div>
                  </draggable>

                </ar-field>
                <ar-simple-button
                  outlined
                  text="Add option"
                  class="u-margin-top-3"
                  :disabled="editingProfileFieldInRestrictedMode"
                  :style="{
                    width: '100%',
                  }"
                  @click="handleAddDropdownOption"
                />
              </div>
              <div>
              </div>
              <div class="cf-dropdown-options">
              </div>
              <div
                class="form-element-wrapper"
                v-if="customFieldType === 'profile' && !restrictedMode"
              >
                <ar-checkbox
                  class="read-only-checkbox"
                  :value="readOnly"
                  @input="updateReadOnly"
                  :disabled="editingProfileFieldInRestrictedMode"
                  label="This field is read only"
                  data-test-id="custom-field-readonly-checkbox"
                />
              </div>
            </div>
          </template>
        </div>
      </div>
      <div slot="footer" class="cf-modal-footer">
        <div class="lhs">
          <ar-simple-button
            v-if="step === -1"
            class="modal-new-button"
            text="New custom field"
            outlined
            @click="handleNewFieldClick"
          />
        </div>
        <div class="rhs">
          <ar-link-button
            class="modal-cancel-button"
            text="Cancel"
            @click="handleCancel"
          />
          <ar-simple-button
            class="modal-next-button"
            :text="nextButtonName"
            :disabled="!nextButtonEnabled"
            v-tooltip.top="{
              content: editingProfileFieldInRestrictedMode ? `Profile fields cannot be edited from within campaigns or signup forms` : null
            }"
            @click="handleCreateNextClick"
          />
        </div>
      </div>
    </ar-modal>
  </portal>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { debounce } from "debounce";
import profileImg from '~/assets/images/other/cf-profile-img.svg?inline'
import formImg from '~/assets/images/other/cf-form-img.svg?inline'
import ArrowHead from '~/assets/icons/arrow-head.svg?inline'
import { dropdownTypeOptions as fieldTypeOptions } from '~/utils/constants';

import { clone } from '@/utils/helpers/';

export default {
  name: 'CustomFieldSelectEditCreateModal',
  components: {
    profileImg,
    formImg,
    ArrowHead
  },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    selectedCustomField: {
      type: Object,
      default: null,
    },
    createState: {
      type: String,
      default: 'new'
    },
    restrictedMode: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      step: null,
      customFieldData: {},
      typeDropdownOpen: false,
      dropdownTypeOptions: fieldTypeOptions,
      readOnly: false,
      finalStep: 1,
      selectedFieldType: null,
      dropdownOptionsToggle: false,
      dropdownOptions: [],
      tagRequestController: null,
      promoterCustomFields: [],
      existingField: null,
    }
  },
  mounted() {
    this.debounceFetchMoreTags = debounce((args) => {
      this['tag/FETCH_MORE_TAGS'](args);
    }, 500);
  },
  watch: {
    selectedCustomField(val) {
      if (!val) {
        return;
      }

      this.loadSelectedCustomField(val)
    },
    open(val) {
      this.resetCustomFieldState()
      
      if (!!val) {
        this.existingField = null;
        if (this.createState === 'edit') {
          this.step = 1
          this.loadSelectedCustomField(this.selectedCustomField)
        } else if (this.createState === 'select') {
          this.fetchPromoterCustomFields();
          this.step = -1;
        }
      }
    },
  },

  computed: {
    ...mapState({
      tags: state => state.tag.tags,
    }),
    prettyVisibility() {
      if (!this.existingField) return null;
      if (this.readOnly) return `${this.existingField.visibility} (read only)`
      return this.existingField.visibility
    },
    prettyFieldInputType() {
      if (!this.existingField) return null;
      switch(this.existingField.fieldType) {
        case "text":
          return "Text";
        case "number":
          return "Number";
        case "date":
          return "Date";
        case "dropdown-multi":
          return "Multi Select";
        case "dropdown-single":
          return "Single Select";
        default:
          return "Unknown";
      }
    },
    prettyOptionsText() {
      if (!this.existingField?.options || !this.existingField.options.length) return [];
      const options = this.existingField.options.map( item => {
        return item.name;
      });
      if (options.length <= 8) {
        return options;
      }
      const remainingOptions = options.length - 7;
      options.length = 7;
      options.push(`(${remainingOptions} other options)`)
      return options;
    },
    editingProfileFieldInRestrictedMode() {
      if (!this.restrictedMode) return false;
      if (!this.selectedCustomField) return false;
      return this.selectedCustomField.visibility === 'profile';
    },
    customFieldType() {
      return this.customFieldData?.type
    },
    nextButtonName() {
      if (this.step === -1) {
        return "Save"
      }
      return this.step === this.finalStep ? "Save" : "Next"
    },
    nextButtonEnabled() {
      if (this.step === -1 && !!this.existingField) {
        return true;
      }
      if (this.editingProfileFieldInRestrictedMode) {
        return false;
      }
      return !!this.customFieldType;
    },
    header() {
      return this.createState === 'new' ? `New ${this.customFieldType || "custom" } field` : `Edit ${this.customFieldType || "custom" } field`
    },
    hasBackLink() {
      if (this.createState === 'select' && this.step > -1) return true;
      return this.createState === "edit" ? false : this.step >= 1
    },
    suggestedTags() {
      return this.tags.map(item => item.name);
    },
    hasContent() {
      return this.dropdownOptions.length > 0
    },
    hasFieldType() {
      return !!this.selectedFieldType
    },
    selectedFieldTypeIndex() {
      if (!this.selectedFieldType) {
        return;
      }
      const index = fieldTypeOptions.findIndex((option) => {
        return option.key === this.selectedFieldType.key
      })

      return index
    }
  },
  created() {
    this.initializeValidator()
  },

  methods: {
    ...mapActions([
      'tag/FETCH_MORE_TAGS',
      'tag/ADD_TAG',
      'FETCH_PROMOTER_CUSTOM_FIELDS',
    ]),
    ...mapMutations([
      'tag/RESET_TAGS',
    ]),
    async fetchPromoterCustomFields() {
      this.promoterCustomFields = await this['FETCH_PROMOTER_CUSTOM_FIELDS']();
    },
    updateReadOnly(val) {
      if (this.editingProfileFieldInRestrictedMode) return;
      this.readOnly = val
      this.handleFieldUpdate({field: 'readOnly', val})
    },
    handleExistingCustomFieldSelect(val) {
      if (this.editingProfileFieldInRestrictedMode) return;
      this.existingField = val;
    },
    handleTypeDropdownArrowClick() {
      // We're not allowed to modify the field type when editing
      if (this.createState === 'edit') {
        return;
      }

      this.typeDropdownOpen = !this.typeDropdownOpen
    },
    handleTypeDropdownFocus() {
      this.typeDropdownOpen = true
    },
    handleClickOutsideTypeDropdown() {
      if (!this.typeDropdownOpen) return
      this.typeDropdownOpen = false;
    },
    handleFieldUpdate(data) {
      if (this.editingProfileFieldInRestrictedMode) return;
      this.$set(this.customFieldData, data.field, data.val);
    },
    handleSelectCustomFieldType(val) {
      this.customFieldData = {type: val}
    },
    handleNewFieldClick() {
      this.step = 0;
    },
    async handleCreateNextClick() {
      if (this.step === -1 && this.existingField) {
        this.$emit('fieldSelected', this.existingField)
        return;
      }
      if (this.editingProfileFieldInRestrictedMode) return;
      if (!('readOnly' in this.customFieldData)) {
        this.customFieldData['readOnly'] = false
      }

      // Update customFieldData with data from dropdownOptions if it's present
      if (this.dropdownOptions.length > 0) {
        this.customFieldData['options'] = this.dropdownOptions
      }
      // eventually add else if for final step (if needed)
      if (!this.step) {
        this.step = 1
      } else if (this.step === this.finalStep) {
        // Validation
        const isValid = await this.$validator.validateAll();

        if (isValid) {
          if (this.createState === 'edit') {
            let changedCustomField = {
              oid: this.customFieldData.oid
            }

            if (this.customFieldData.name != this.selectedCustomField.name) {
              changedCustomField['name'] = this.customFieldData.name
            }

            if (this.customFieldData.description != this.selectedCustomField.description) {
              changedCustomField['description'] = this.customFieldData.description
            }

            if (!!('readOnly' in this.customFieldData) && this.customFieldData.readOnly != this.selectedCustomField.readOnly) {
              changedCustomField['readOnly'] = this.customFieldData.readOnly
            }

            if (!!('options' in this.customFieldData) && (JSON.stringify(this.customFieldData.options) != JSON.stringify(Array.isArray(this.selectedCustomField?.options) ? this.selectedCustomField?.options : []))) {
              changedCustomField['options'] = this.customFieldData.options
            }

            if (Object.keys(changedCustomField).length > 0) {
              this.$emit('save', this.createState, changedCustomField)
            } else {
              this.$arNotification.push({ type: 'error', message: "We couldn't save the edited custom field. Please review the data and try again." });
            }
          } else {
            this.$emit('save', this.createState, this.customFieldData)
          }
        } else {
          return;
        };
      } else {
        this.step++
      }
    },
    resetCustomFieldState() {
      this.customFieldData = {}
      this.step = null
      this.dropdownOptionsToggle = false;
      this.dropdownOptions = []
      this.selectedFieldType = null;
      this.tagRequestController = null;
      this.readOnly = false;
    },
    handleCancel() {
      this.resetCustomFieldState()
      this.$emit('close')
    },
    handleCloseClick() {
      this.resetCustomFieldState()
      this.$emit('close')
    },
    handleTypeDropdownOptionClick(value) {
      if (this.editingProfileFieldInRestrictedMode) return;
      this.customFieldData.fieldType = value.key
      this.selectedFieldType = value
      this.handleTypeDropdownArrowClick()

      if (value.key === 'dropdown-single' || value.key === 'dropdown-multi') {
        this.dropdownOptionsToggle = true;
      } else if (this.dropdownOptionsToggle) { // If it's not a dropdown type any more and it's still on, we need to remove the dropdown options section
        this.dropdownOptionsToggle = false;
      }
    },
    handleBackPress() {
      this.step -= 1
    },
    handleAddDropdownOption() {
      if (this.editingProfileFieldInRestrictedMode) return;
      this.fetchTags('');
      this.dropdownOptions.push({
        name: '',
        tag: null,
      })
    },
    handleTagSearchStringInput(newSearchString, idx) {
      if (this.editingProfileFieldInRestrictedMode) return;
      this.dropdownOptions[idx].tag = newSearchString.trim();

      this.fetchTags(newSearchString);
    },
    fetchTags(searchString) {
      const source = this.$axios.CancelToken.source();
      this.debounceFetchMoreTags({
        top: 20,
        reload: true,
        searchString: searchString,
        cancelToken: source.token
      });
      this.tagRequestController = source;
    },
    handleTagInputBlur() {
      if (this.tagRequestController) {
        // Cancel axios tags request
        this.tagRequestController.cancel();
        // Cancel debounce
        this.debounceFetchMoreTags.clear();
      }
      // Reset all tags
      this['tag/RESET_TAGS']();
      // Refetch tags
      this.fetchTags('');
    },
    async handleSuggestionClick(value, idx) {
      if (this.editingProfileFieldInRestrictedMode) return;
      const existingTag = (this.tags || []).find( tag => tag.name === value)
      this.dropdownOptions[idx].tag = value.trim();
      if (existingTag) {
        this.dropdownOptions[idx].tagOid = existingTag?.oid;
      } else {
        // Add tag here
        let tagResp = await this['tag/ADD_TAG']({name: value})

        if (!tagResp) return
        this.dropdownOptions[idx].tagOid = tagResp?.oid;
      }
      this.fetchTags('');
    },
    handleNameInputDropdown(val, dropdownOption, index) {
      if (this.editingProfileFieldInRestrictedMode) return;
      this.dropdownOptions[index].name = val;
    },
    handleDeleteOptionRow(val, index) {
      if (this.editingProfileFieldInRestrictedMode) return;
      this.dropdownOptions.splice(index, 1);
    },
    loadSelectedCustomField(selectedCustomField) {
      this.customFieldData = {
        ...selectedCustomField,
        type: selectedCustomField.visibility,
      }

      this.selectedFieldType = this.dropdownTypeOptions.find((option) => { return option.key === selectedCustomField.fieldType } )

      this.dropdownOptions = (!!selectedCustomField.options && Array.isArray(selectedCustomField.options)) ? clone(selectedCustomField.options) : []
      if (selectedCustomField.fieldType === 'dropdown-single' || selectedCustomField.fieldType === 'dropdown-multi') {
        this.dropdownOptionsToggle = true;
      } else if (this.dropdownOptionsToggle) { // If it's not a dropdown type any more and it's still on, we need to remove the dropdown options section
        this.dropdownOptionsToggle = false;
      }

      this.readOnly = selectedCustomField.readOnly
    },
    initializeValidator() {
      this.$validator.extend('isUnique', {
        getMessage: field => `Name must be unique`,
        validate: value => {
          return !(this.dropdownOptions?.filter((option) => option.name === value).length > 1)
        },
      });
    },
    insertErrorForDuplicatedName() {
      this.veeErrors.add({
        field: 'name',
        msg: 'Name already exists. Name needs to be unique.'
      })
    }
  },
}
</script>
<style lang="scss">
.modal-body-wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 48px 32px;
  min-height: 537px;

  .create-custom-field-subheading {
    align-self: flex-start;
  }

  .existing-field-data {
    display: grid;
    width: 100%;
    grid-template-columns: 30% auto;
    margin-top:20px;

    .grid-item {
      padding: 16px 0;
    }
  }

  .cf-inner-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 16px;

    .inner-container {
      border: 1px solid #DCDEE4;
      border-radius: 4px;
      padding: 24px;
      width: 292px;
      cursor: pointer;

      .cf-option-wrapper {
        margin-top: 16px;

        .custom-field-inner-heading {
          margin-bottom: 8px;
        }

        .custom-field-inner-copy {
          color: #8492a6;
        }
      }

      &:hover, &.selected {
        color: $purple500;
        border: 1px solid $purple500;
        
        .custom-field-inner-heading {
          color: $purple500;
        }
        .custom-field-inner-copy {
          color: $blueGrey800;
        }
      }

      &:hover {
        box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.07);
      }
    }
  }

  .step-wrapper {
    width: 100%;
    position: relative;

    .form-element-wrapper {
      width: 100%;
      margin-top: 24px;
      position: relative;

      &:first-child {
        margin-top: unset;
      }

      .read-only-checkbox {
        margin-bottom: 40px;

        .label-section {
          .text-section__inner {
            font-weight: bold !important;
          }
        }
      }
    }

    .cf-type-dropdown-wrapper {
      position: relative;

      .type-dropdown-input {
        padding-right: 20px;
      }

      .sender-arrowhead-wrapper {
        position: absolute;
        right: 0;
        top: 0;
        padding: 14px;
        height: 100%;

        .send-to-input-dropdown-arrowhead {
          cursor: pointer;
          transition: all 0.2s ease;

          &.rotate {
            transform: rotate(180deg);
          }
        }
      }
    }

    .cf-type-dropdown {
      border: 1px solid $blueGrey500;
      border-radius: 4px;
      margin-top: 5px;
      box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.07);
      z-index: 99999;
      position: absolute;
      width: 100%;
      background: white;

      .dropdown-item-wrapper {
        padding: 16px 16px 16px 16px;

        &:first-child {
          border-top: unset;
        }
      }
    }

    .cf-dropdown-option {
      margin-bottom: 16px;

      .cf-dropdown-option-row {
        display: flex;
        flex-direction: row;
        width: 100%;

        .cf-dropdown-option-row-input {
          display: flex;
          flex-direction: row;
          width: 100%;
        }

        .option-button {
          cursor: pointer;
        }
      }

    }
  }
}
.cf-modal-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .lhs {
    padding: 24px 0 24px 32px;
  }
  .rhs {
    padding: 24px 32px 24px 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
  }

  .modal-cancel-button {
    margin-right: 24px;
  }

  .modal-next-button {
    padding: 14px 24px;
  }
}
</style>
