var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{style:({ position: 'absolute' }),attrs:{"to":"modal","disabled":!_vm.isShow}},[_c('ar-modal',{staticClass:"purchase-details-modal",attrs:{"is-open":_vm.isShow,"header":"Order Details","width":"600px","mobile":_vm.$arMediaQuery.window.maxWidth('xs')},on:{"close":_vm.handleCloseClick}},[_c('div',{attrs:{"slot":"body"},slot:"body"},[(_vm.purchaseData)?_c('div',{class:[
        'purchase-details-modal__wrapper',
        'generic',
        'header'
      ]},[_c('div',{class:[
            'event-details',
            _vm.$arMediaQuery.window.maxWidth('sm') && 'sm-max'
          ]},[_c('div',{staticClass:"icon-avatar"},[_c('ar-icon',{attrs:{"type":"primary","name":_vm.purchaseData.source.icon,"color":_vm.purchaseData.source.iconColor,"width":"15px","height":"15px"}})],1),_vm._v(" "),_c('div',{class:[
              'left',
              _vm.$arMediaQuery.window.maxWidth('sm') && 'sm-max' ]},[_c('div',{staticClass:"purchase-data"},[_c('ar-text',{style:({
                  marginBottom: '4px',
                  marginTop: '3px'
                }),attrs:{"size":"xs","text":_vm.purchaseData.source.platform}}),_vm._v(" "),_c('ar-text',{style:({
                  color: _vm.$arStyle.color.skyBlueGrey700,
                }),attrs:{"size":"xs","text":_vm.purchaseData.description,"multiple-lines":""}})],1)]),_vm._v(" "),_c('div',{class:[
              'right',
              _vm.$arMediaQuery.window.maxWidth('sm') && 'sm-max' ]},[_c('ar-text',{style:({
              marginBottom: '8px'
            }),attrs:{"size":"xs","text":("Order " + (_vm.purchaseData.orderId)),"align":_vm.$arMediaQuery.window.maxWidth('sm') ? 'left' : 'right'}}),_vm._v(" "),_c('ar-text',{style:({
                color: _vm.$arStyle.color.skyBlueGrey700,
              }),attrs:{"size":"xxxs","text":_vm.prettyTime,"align":_vm.$arMediaQuery.window.maxWidth('sm') ? 'left' : 'right'}})],1)])]):_vm._e(),_vm._v(" "),(_vm.purchaseData)?_c('div',{class:[
      'purchase-details-modal__wrapper',
      'generic',
      'body'
    ]},[(_vm.decoratedPurchaseDataItems)?_c('div',{staticClass:"tickets-data"},[_c('table',{staticClass:"ticket-table"},[_c('tr',{staticClass:"header"},[_c('th',{staticClass:"description-header"},[_vm._v("Item")]),_vm._v(" "),_c('th',{staticClass:"price-header"},[_vm._v("Price")]),_vm._v(" "),_c('th',{staticClass:"total-header"},[_vm._v("Total")])]),_vm._v(" "),_vm._l((_vm.decoratedPurchaseDataItems),function(item,key){return _c('tr',{key:key,staticClass:"ticket"},[_c('td',[(item.image || _vm.purchaseData.fallbackImage)?_c('div',{staticClass:"ticket-image"},[(item.image || _vm.purchaseData.fallbackImage)?_c('img',{attrs:{"src":item.image || _vm.purchaseData.fallbackImage},on:{"click":_vm.handleImageClick}}):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"ticket-name"},[_c('ar-text',{style:({
                      marginBottom: '4px',
                    }),attrs:{"size":"xs","weight":"bold","multiple-lines":"","text":((item.quantity) + " x " + (item.ticketType))}}),_vm._v(" "),_c('ar-text',{style:({
                      color: _vm.$arStyle.color.skyBlueGrey700,
                    }),attrs:{"size":"xs","weight":"normal","multiple-lines":"","text":_vm.purchaseData.description}})],1)]),_vm._v(" "),_c('td',{style:({
                    color: _vm.itemIsRefunded(item) ? _vm.$arStyle.color.red500 : null,
                    textAlign: 'right',
                  })},[_vm._v(_vm._s(item.formattedSinglePrice))]),_vm._v(" "),_c('td',{style:({
                  color: _vm.itemIsRefunded(item) ? _vm.$arStyle.color.red500 : null,
                  textAlign: 'right',
              })},[_vm._v(_vm._s(item.formattedTotalPrice))])])})],2)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"totals"},[_c('div',{staticClass:"total grand-total"},[_c('ar-text',{attrs:{"size":"sm","weight":"bold","text":"Total"}}),_vm._v(" "),_c('ar-text',{attrs:{"size":"sm","weight":"bold","text":((_vm.purchaseData.currencyCode) + " " + (_vm.formatCurrencyLocal(_vm.purchaseData.grossTotal / 100)))}})],1),_vm._v(" "),_c('div',{staticClass:"total"},[_c('ar-text',{style:({
                color: _vm.$arStyle.color.skyBlueGrey700,
              }),attrs:{"size":"xs","align":"right","text":"Fees"}}),_vm._v(" "),_c('ar-text',{style:({
                color: _vm.$arStyle.color.skyBlueGrey700,
              }),attrs:{"size":"xs","align":"right","text":_vm.formatCurrencyLocal(_vm.purchaseData.fees ? _vm.purchaseData.fees / 100: 0)}})],1),_vm._v(" "),_c('div',{staticClass:"total"},[_c('ar-text',{style:({
                color: _vm.$arStyle.color.skyBlueGrey700,
              }),attrs:{"size":"xs","align":"right","text":"Taxes"}}),_vm._v(" "),_c('ar-text',{style:({
                color: _vm.$arStyle.color.skyBlueGrey700,
              }),attrs:{"size":"xs","align":"right","text":_vm.formatCurrencyLocal(_vm.purchaseData.tax ? _vm.purchaseData.tax / 100 : 0)}})],1)])]):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }