<template>
  <portal
    to="modal"
    :disabled="!isShow"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="isShow"
      class="invite-account-modal"
      header="New user"
      width="590px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
    >
      <div
        slot="body"
        :class="[
          'invite-account-modal-body',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]"
      >

        <ar-field
          label="Email address"
          class="invite-account-modal-body-row"
        >
          <ar-input
            v-model="emailAddress"
            name="email-address"
            placeholder="e.g. jess@domain.com"
          />
        </ar-field>
        <ar-field
          label="User type"
          class="invite-account-modal-body-row"
        >
          <am2-user-role-select
            class="u-margin-bottom-1"
            data-test-id="user-role-select"
            data-vv-name="user-role-select"
            data-vv-as="user role"
            :default-user-roles="defaultUserRoles"
            :promoter-user-roles="promoterUserRoles"
            :selectedUserRoles="{
              defaultUserRoleOid: defaultUserRoleOid,
              promoterUserRoleOid: promoterUserRoleOid,
            }"
            is-user-facing
            @select="handleUserRoleSelect"
            @handleFilterInput="handleUserRoleFilterInput"
          />
        </ar-field>
      </div>
      <div
        slot="footer"
        :class="[
          'invite-account-modal-footer',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]">
        <ar-link-button
          text="Cancel"
          @click="handleCloseClick"
          :style="{
            height: '40px',
            padding: '0 20px'
          }"
        />
        <ar-simple-button
          text="Create"
          :disabled="confirmButtonDisabled"
          :style="{ width: '108px' }"
          @click="handleConfirmClick"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'InviteAccountModal',
  components: {},
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      emailAddress: null,
      defaultUserRoleOid: null,
      promoterUserRoleOid: null
    }
  },

  computed: {
    ...mapState({
      promoterUserRoles: state => state.auth.promoterUserRoles,
      defaultUserRoles: state => state.auth.defaultUserRoles,
    }),
    confirmButtonDisabled() {
      if (!this.emailAddress) return false;
      return !this.defaultUserRoleOid &&
             !this.promoterUserRoleOid;
    },
  },

  methods: {
    async handleConfirmClick() {
      const isValidated = await this.$validator.validateAll();

      if (isValidated) {
        this.$emit('confirm', {
          emailAddress: this.emailAddress,
          defaultUserRoleOid: this.defaultUserRoleOid,
          promoterUserRoleOid: this.promoterUserRoleOid,
        });
        this.resetData();
      }
    },
    handleCloseClick() {
      this.$emit('cancel');
      this.resetData();
    },
    resetData() {
      this.emailAddress = null;
      this.defaultUserRoleOid = null;
      this.promoterUserRoleOid = null;
    },
    handleUserRoleSelect(item) {
      if (item.type === 'default') {
        this.defaultUserRoleOid = item.oid
        this.promoterUserRoleOid = null
      } else {
        this.defaultUserRoleOid = null
        this.promoterUserRoleOid = item.oid
      }
      console.log(item)
    },
    handleUserRoleFilterInput(searchString) {
      this.$emit('handleUserRoleFilterInput', searchString)
    },
  },
};
</script>

<style lang="scss" scoped>
.invite-account-modal {
  .invite-account-modal-body {
    display: flex;
    gap: 24px;
    padding: 48px 32px;
    flex-direction: column;

    &-row {
      width: 100%;
      gap: 24px;

      [ar-widget-id=ar-field] {
        width: 100%;
      }
    }
  }

  .invite-account-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 25px 30px;
    height: 100px;
  }
}
</style>
