<template>
  <section class="messenger-edit-details">
    <am2-import-dynamic-tags-modal
      :is-show="showImportDynamicTagsModal"
      :message-list-oid="scratchSimpleMessage.meta.messageListOid"
      channel="facebookMessenger"
      @import="handleDynamicTagsImport"
      @cancel="showImportDynamicTagsModal = false"
    />

    <!-- Message To -->
    <ar-field
      label="To"
    >
      <am2-message-list-select
        ref="message-list-select"
        :value="scratchSimpleMessage.meta.messageListOid"
        :campaign-oid="scratchSimpleMessageCampaignOid"
        :event-oid="scratchSimpleMessageEventOid"
        :facebook-page-id="selectedFacebookMessengerPageId"
        @select="handleMessageListChange"
        :channels="['facebookMessenger']"
        v-tooltip.top="{
          content: dynamicTagsExist ? 'Changing lists will clear all dynamic tags from your message!' : null
        }"
        data-test-id="message-list-select"
      />

      <ar-snackbar
        v-if="noContacts && !!selectedFacebookMessengerPageId && !isFetchingFacebookPages && hasFetchedInitialFacebookPages"
        type="error"
        message="There are no valid messenger subscribers"
        :style="{ marginTop: '10px' }"
      />
      <ar-snackbar
        v-if="noContacts && !selectedFacebookMessengerPageId && !isFetchingFacebookPages && hasFetchedInitialFacebookPages"
        type="warning"
        :message="`Please select a Facebook page to see the number of recipients in your ${!!currentCampaign ? 'tier' : 'list'}`"
        :style="{ marginTop: '10px' }"
      />
      <ar-snackbar
        v-if="noIntegration && !isFetchingFacebookPages && hasFetchedInitialFacebookPages"
        type="error"
        message="Integration error - <a>please reconnect your Facebook account</a>"
        :style="{ marginTop: '10px' }"
        @anchorClick="handleIntegrationClick"
      />

      <ar-snackbar
        v-if="currentSelectedMessage && currentMessageListIsForAllCampaignRegistrants"
        class="u-margin-top-5"
        type="warning"
        message="Changing from All Recipients to a Tier will disable scheduling."
      />
      <ar-state-message
        v-if="currentCampaignIsActive && currentMessageListIsForCampaignTier"
        class="u-margin-top-5"
        :text-props="{
          weight: 'bold',
        }"
        has-icon
        disable-text-color
        type="information"
        text="Note: It's recommended to only message individual tiers once the campaign is ended."
      />
    </ar-field>

    <am2-message-list-advanced-targeting-section
      v-if="showAdvancedTargetingModule"
      channel="facebookMessenger"
      show-toggle
      @updateAdvancedTargeting="handleAdvancedTargetingUpdate"
    />

    <ar-field
      v-if="messageList"
      label="From"
      :style="{
        margin: $arMediaQuery.pageContent.maxWidth('sm') ? '32px 0' : '44px 0 60px',
      }"
    >
      <am2-facebook-page-select
        class="select"
        :value="selectedFacebookMessengerPageId"
        @select="handleFacebookPageSelect"
      />
      <ar-snackbar
        v-if="selectedFacebookMessengerPageId && !selectedFacebookMessengerPage && !isFetchingFacebookPages && hasFetchedInitialFacebookPages"
        type="error"
        message="The page you selected is currently unavailable - <a>please reconnect your Facebook account</a>"
        allow-html
        :style="{ marginTop: '10px' }"
        @anchorClick="handleIntegrationClick"
      />
      <ar-snackbar
        v-if="facebookPageSelectionHasChanged && !isFetchingFacebookPages && hasFetchedInitialFacebookPages"
        type="warning"
        message="Changing Facebook Page will reduce the number of valid recipients in your message"
        :style="{ marginTop: '10px' }"
      />
      <ar-snackbar
        v-if="facebookPageIsNotAuthorized && !isFetchingFacebookPages && hasFetchedInitialFacebookPages"
        type="error"
        message="This page is not authorized to send messages. Please <a target='__blank' href='https://audiencerepublic.com/contact-us?type=organizer'>contact sales</a> or <a href='/settings/subscriptions'>purchase a subscription</a> to continue."
        allow-html
        :style="{ marginTop: '10px' }"
      />
      <ar-snackbar
        v-if="integrationDisconnected"
        type="error"
        message="Your Facebook Page has been disconnected.<br/><a>Re-connect Facebook Account</a>"
        @anchorClick="handleIntegrationClick"
        :style="{ marginTop: '10px' }"
      />
    </ar-field>


    <!-- Message Body Wrapper -->
    <div
      class="u-position-relative"
      :style="{
        marginTop: '40px',
      }"
    >
      <div
        class="u-position-absolute u-display-flex u-justify-content-flex-end"
        :style="{ top: 0, right: 0 }"
      >
        <am2-link-button-dropdown
          :items="dynamicTagSelectItems"
          :button-props="{
            hasArrow: false,
            hasUnderline: true,
          }"
          :disabled="isListValid || !scratchSimpleMessage.meta.messageListOid"
          :item-style="{
            height: '44px',
            padding: '0 20px',
          }"
          :divider-style="{
            margin: '0',
          }"
          placeholder="Insert dynamic tag"
          :dropdown-style="{
            padding: '0',
          }"
          v-tooltip.top="{
            content: isListValid ? 'When you select a list with valid Facebook Messenger subscribers you’ll be able to use dynamic tags.' : null
          }"
          @select="handleInsertDynamicTagsSelect"
          data-test-id="insert-dynamic-tag-link"
        />
      </div>
    </div>
    <ar-field
      label="Message"
      :error-messages="[veeErrors.first('messageBody')]"
    >
      <SimpleMessageEditor
        ref="simple-message-editor"
        :dynamic-tags="dynamicTags"
        :value="scratchSimpleMessage.meta.messageBody"
        @input="handleMessageBodyInput"
        v-validate="{
          required: true,
          max: scratchSimpleMessage.meta.messageBody.length + charactersLeft,
        }"
        data-vv-name="messageBody"
      />
      <ar-snackbar
        v-if="nonExistingTagsInSimpleMessageErrorCopy"
        type="error"
        :message="nonExistingTagsInSimpleMessageErrorCopy"
        :style="{
          marginTop: '10px',
        }"
      />
      <am2-characters-left-copy-section
        :current-length="scratchSimpleMessage.meta.messageBody.length"
        tooltip="This number includes the largest possible values represented by your dynamic tags. It may not match the number of characters in your message preview"
        :max-length="scratchSimpleMessage.meta.messageBody.length + charactersLeft"
        :style="{
          marginTop: '10px',
        }"
      />
    </ar-field>
    <ar-field
      label="Schedule?"
      :style="{
        marginTop: '40px',
      }"
    >
      <ar-checkbox
        :value="enableSchedule"
        @input="handleEnableScheduleToggle"
        label="Schedule a time to send this message"
        :disabled="currentSelectedMessageUiStatus === 'scheduled' || !currentMessageListCanBeSchedued"
        v-tooltip.top="{
          content: !currentMessageListCanBeSchedued && !enableSchedule ? 'You cannot schedule messages from campaigns' : null,
        }"
      />
      <div
        v-if="enableSchedule"
        :style="{
          display: 'flex',
          alignItems: 'baseline',
          marginTop: '20px',
          flexDirection: $arMediaQuery.pageContent.minWidth('md') ? 'row' : 'column',
        }"
      >
        <ar-field
          label="Start"
          :class="[
            'u-color-blueGrey700',
            'u-flex-shrink-0',
          ]"
          :error-messages="[veeErrors.first('scheduledAt')]"
        >
          <am2-date-time-input
            :value="scratchSimpleMessage.scheduledAt"
            @input="handleScheduledAtInput"
            :time-zone="scratchSimpleMessage.meta.presentation.timeZone"
            v-validate="'required|tzFormat|afterNow'"
            data-vv-name="scheduledAt"
            data-vv-as="scheduled time"
          />
        </ar-field>
        <ar-divider
          v-if="$arMediaQuery.pageContent.minWidth('md')"
          :style="{
            position: 'relative',
            top: '38px',
            width: '50px',
            margin: '0 10px',
          }"
        />
        <ar-field
          label="Timezone"
          :class="[
            'u-flex-shrink-0',
          ]"
          :style="{
            marginTop: $arMediaQuery.pageContent.maxWidth('sm') ? '24px' : null,
            width: $arMediaQuery.pageContent.minWidth('md') ? 'calc(100% - 340px)' : '100%',
            color: $arStyle.color.blueGrey700,
          }"
          :error-messages="[veeErrors.first('timeZone')]"
        >
          <am2-timezone-select
            :value="selectedTimezone"
            @input="handleTimezoneInput"
            v-validate="'required'"
            data-vv-name="timeZone"
          />
        </ar-field>
      </div>
    </ar-field>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import moment from 'moment';
import checkoutPageAccessibility from '@/mixins/checkoutPageAccessibility';
import { MAX_CHARACTERS_FACEBOOK_MESSAGE, AR_SHORT_URL_PATTERN } from '@/utils/constants';
import { urlsRegex } from '@/utils/regex';
import SimpleMessageEditor from '@/components/editors/simple-message-editor';

export default {
  name: 'FacebookMessenger',

  mixins: [
    checkoutPageAccessibility({ featureKeys: ['messages', 'facebookMessenger'], featureName: 'Facebook Messenger' }),
  ],

  components: {
    SimpleMessageEditor,
  },

  props: {
    messageList: {
      type: Object,
      default: null,
    },
    noContacts: {
      type: Boolean,
      default: false,
    },
    facebookPageIsPurchased: {
      type: Boolean,
      default: null,
    }
  },

  data() {
    return {
      showImportDynamicTagsModal: false,
      enableSchedule: false,
      facebookPages: [],
      tagMaxWidthMap: {},
      arEnableMessageAdvancedTargeting: process.env.arEnableMessageAdvancedTargeting,
    };
  },

  computed: {
    ...mapState({
      currentCampaign: state => state.campaign.currentCampaign,
      currentSelectedMessage: state => state.message.currentSelectedMessage,
      scratchSimpleMessage: state => state.message.scratchSimpleMessage,
      isFetchingFacebookPages: state => state.facebookMessengerIntegration.isFetchingFacebookPages,
      hasFetchedInitialFacebookPages: state => state.facebookMessengerIntegration.hasFetchedInitialFacebookPages,
      currentMessageList: state => state.messageList.currentMessageList,
      integration: state => state.facebookMessengerIntegration.integration,
      isFetchingIntegration: state => state.facebookMessengerIntegration.isFetchingIntegration,
      hasFetchedIntegration: state => state.facebookMessengerIntegration.hasFetchedIntegration,
      currentRegionSettings: state => state.auth.regionSettings,
    }),
    ...mapGetters({
      scratchSimpleMessageCampaignOid: 'message/scratchSimpleMessageCampaignOid',
      scratchSimpleMessageEventOid: 'message/scratchSimpleMessageEventOid',
      currentCampaignIsActive: 'campaign/currentCampaignIsActive',
      nonExistingTagsInSimpleMessageErrorCopy: 'message/nonExistingTagsInSimpleMessageErrorCopy',
      currentSelectedMessageUiStatus: 'message/currentSelectedMessageUiStatus',
      currentMessageListCanBeSchedued: 'messageList/currentMessageListCanBeSchedued',
      currentMessageListIsForAllCampaignRegistrants: 'messageList/currentMessageListIsForAllCampaignRegistrants',
      currentMessageListIsForCampaignTier: 'messageList/currentMessageListIsForCampaignTier',
      integrationDisconnected: 'facebookMessengerIntegration/integrationDisconnected',
      getCurrentFilterExpression: 'messageList/getCurrentFilterExpression',
    }),
    // We don't want to show the adv targeting module on campaign sends, if no message list is selected
    // or while a draft message is loading
    showAdvancedTargetingModule() {
      if (!this.arEnableMessageAdvancedTargeting) return false;
      if (this.currentMessageListIsForCampaignTier) return false;
      if (this.currentMessageListIsForAllCampaignRegistrants) return false;
      if (this.messageOid && !this.currentSelectedMessage) return false;
      if (this.isFetchingMessage) return false;
      if (!this.scratchSimpleMessage) return false;
      if (!this.scratchSimpleMessage.meta.messageListOid) return false;
      return true;
    },
    selectedFacebookMessengerPageId() {
      if (this.scratchSimpleMessage && this.scratchSimpleMessage.meta && this.scratchSimpleMessage.meta.facebookMessenger && this.scratchSimpleMessage.meta.facebookMessenger.pageId) return this.scratchSimpleMessage.meta.facebookMessenger.pageId;
      if (!this.messageList || !this.messageList.meta || !this.messageList.meta.facebookMessenger || !this.messageList.meta.facebookMessenger.pageId) return null;
      return this.messageList.meta.facebookMessenger.pageId;
    },
    selectedFacebookMessengerPage() {
      const selectedFbPage = this.facebookPages.find(item => item.puid === this.selectedFacebookMessengerPageId);
      return selectedFbPage;
    },
    facebookPageSelectionHasChanged() {
      let messageListFacebookPageId = this.scratchSimpleMessage?.meta?.facebookMessenger?.pageId;
      let scratchMessageListId = this.messageList?.meta?.facebookMessenger?.pageId;
      if (!messageListFacebookPageId || !scratchMessageListId) return false;
      return messageListFacebookPageId !== scratchMessageListId;
    },
    facebookPageIsNotAuthorized() {
      return this.selectedFacebookMessengerPage && !this.facebookPageIsPurchased;
    },
    charactersLeft() {
      const originalCharactersLeft = MAX_CHARACTERS_FACEBOOK_MESSAGE; // The max number of characters in a Facebook message
      let dynamicTagsActionedMessage = this.scratchSimpleMessage.meta.messageBody;

      // Replace all URLs with dummy short URLs
      dynamicTagsActionedMessage = dynamicTagsActionedMessage.replace(urlsRegex, AR_SHORT_URL_PATTERN);

      // Replace all dynamic tags with dummy dynamic tag values
      if (this.scratchSimpleMessage.meta.tagMaxWidthMap) {
        Object.keys(this.scratchSimpleMessage.meta.tagMaxWidthMap).forEach( item => {
          const maxLength = this.scratchSimpleMessage.meta.tagMaxWidthMap[item] || 0;
          const dummyString = [...Array(maxLength)].map(_ => 'x').join('');
          const regexString = new RegExp('\\{\\{' + item + '\\}\\}', 'g');
          dynamicTagsActionedMessage = dynamicTagsActionedMessage.replace(regexString, dummyString);
        });
      }

      // Return the length of the dummy-filled message
      return originalCharactersLeft - dynamicTagsActionedMessage.length;
    },
    dynamicTags() {
      return this.scratchSimpleMessage.meta.dynamicTagHeaders || [];
    },
    dynamicTagSelectItems() {
      if (this.dynamicTags.length === 0) {
        return [
          {
            type: 'action',
            name: 'Add dynamic tags',
            value: 'addDynamicTags'
          },
        ];
      } else {
        return [
          ...this.dynamicTags.map(tag => ({
            typography: {
              weight: 'bold',
            },
            name: tag,
            value: tag,
          })),
          {
            type: 'divider',
          },
          {
            type: 'action',
            name: 'Add dynamic tags',
            value: 'addDynamicTags'
          },
        ];
      }
    },
    dynamicTagsExist() {
      return this.scratchSimpleMessage.meta.dynamicTagHeaders && this.scratchSimpleMessage.meta.dynamicTagHeaders.length > 0;
    },
    // If all fetched pages are not authorized, returns true
    // If the selected list's page is not authorized, returns true
    // If the selected list's page is not in the list, returns true
    // Otherwise, will return false
    noIntegration() {
      if (this.facebookPages && this.facebookPages.length > 0 && !this.facebookPages.some( item => item.authorized)) return true;
      if (!this.messageList || !this.messageList.meta || !this.messageList.meta.facebookMessenger || !this.messageList.meta.facebookMessenger.pageId) return false;
      if (!this.facebookPages || this.facebookPages.length < 1) return false;
      const targetPage = this.facebookPages.find( item => item.puid === this.messageList.meta.facebookMessenger.pageId);
      if (!targetPage) return true;
      return targetPage.authorized === false;
    },
    isListValid() {
      return (this.noContacts && !!this.selectedFacebookMessengerPageId && !this.isFetchingFacebookPages && this.hasFetchedInitialFacebookPages) || this.currentMessageList?.statsSnapshot?.total === 0
    },
    selectedTimezone() {
      if (this.scratchSimpleMessage?.meta?.presentation?.timeZone) {
        return this.scratchSimpleMessage.meta.presentation.timeZone
      } else if (this.currentRegionSettings) {
        return this.currentRegionSettings?.additionalInfo?.defaultTimezone || null
      } else {
        return null
      }
    }
  },

  watch: {
    scratchSimpleMessage: {
      handler(val) {
        if (val.meta.presentation.timeZone || val.scheduledAt) {
          this.enableSchedule = true;
        }
      },
      immediate: true,
    },
    messageList: {
      handler(val) {
        if (val) {
          this['message/PUT_SCRATCH_SIMPLE_MESSAGE']({
            meta: { messageListOid: val.oid },
          });
        }
      },
    },
  },

  created() {
    this.$validator.dictionary.merge({
      en: {
        custom: {
          messageListoid: {
            required: 'Please select a list.',
          },
        },
      },
    });

    this.$validator.extend('afterNow', {
      getMessage: field => `Scheduled time is invalid - time must be in the future`,
      validate: value => {
        return moment(value).isAfter(new Date());
      },
    });

    this.$validator.localize('en', {
      custom: {
        scheduledAt: {
          required: () => 'Please enter scheduled time',
        },
        timeZone: {
          required: () => 'Please select a timezone',
        },
        messageBody: {
          max: () => 'Your message exceeds the maximum allowed character limit',
        },
      },
    });
  },

  async mounted() {
    this.fetchIntegrations();
    this['facebookMessengerIntegration/FETCH_INTEGRATION']();
    this['auth/FETCH_REGION_SETTINGS']();
  },

  methods: {
    ...mapActions([
      'FETCH_FACEBOOK_PAGES',
      'facebookMessengerIntegration/FETCH_INTEGRATION',
      'messageList/FETCH_FILTERED_RECIPIENT_LIST_COUNT',
      'auth/FETCH_REGION_SETTINGS',
    ]),
    ...mapMutations([
      'message/REMOVE_DYNAMIC_TAGS_IN_SCRATCH_SIMPLE_MESSAGE',
      'message/PUT_SCRATCH_SIMPLE_MESSAGE',
      'message/SET_CSV_PREVIEW_CONTACTS',
      'messageList/SET_CURRENT_MESSAGE_LIST',
      'message/PUT_FILTERING_IN_SCRATCH_SIMPLE_MESSAGE',
    ]),
    async fetchIntegrations() {
      const pages = await this.FETCH_FACEBOOK_PAGES();
      if (pages) {
        this.facebookPages = pages;
      }
    },
    clearScheduleTime() {
      this['message/PUT_SCRATCH_SIMPLE_MESSAGE']({
        meta: {
          presentation: {
            timeZone: null,
          },
        },
        scheduledAt: null,
      });
    },
    handleEnableScheduleToggle(toggle) {
      this.enableSchedule = toggle;
      if (!toggle) {
        this.clearScheduleTime();
      }
    },
    handleMessageBodyInput(val) {
      this['message/PUT_SCRATCH_SIMPLE_MESSAGE']({
        meta: { messageBody: val },
      });
    },
    handleScheduledAtInput(val) {
      this['message/PUT_SCRATCH_SIMPLE_MESSAGE']({
        scheduledAt: val,
      });
    },
    handleTimezoneInput(val) {
      this['message/PUT_SCRATCH_SIMPLE_MESSAGE']({
        meta: {
          presentation: {
            timeZone: val,
          },
        },
      });
    },
    handleMessageListChange(messageList) {
      this['message/PUT_SCRATCH_SIMPLE_MESSAGE']({
        meta: {
          messageListOid: messageList.oid,
        },
      });
      this['message/REMOVE_DYNAMIC_TAGS_IN_SCRATCH_SIMPLE_MESSAGE']();
      this['messageList/SET_CURRENT_MESSAGE_LIST'](messageList);
      if (!this.currentMessageListCanBeSchedued) {
        this.enableSchedule = false;
        this.clearScheduleTime();
      }
    },
    handleFacebookPageSelect(facebookPage) {
      this['message/PUT_SCRATCH_SIMPLE_MESSAGE']({
        meta: {
          facebookMessenger: {
            pageId: facebookPage ? facebookPage.id : null,
            pageName: facebookPage ? facebookPage.name : null,
            imageSrc: facebookPage ? facebookPage.imageSrc : null,
          }
        },
      });
      this.$emit('facebookPageUpdate', facebookPage);
      this['messageList/FETCH_FILTERED_RECIPIENT_LIST_COUNT']({
        channel: 'facebookMessenger',
        facebookPageId: facebookPage.id,
      });
    },
    handleIntegrationClick() {
      this.$router.push({
        path: `/settings/integrations`
      });
    },
    handleDynamicTagsImport({ dynamicTagHeaders, dynamicTagRows, tagsResourceOid, tagMaxWidthMap }) {
      this['message/SET_CSV_PREVIEW_CONTACTS']({
        headers: dynamicTagHeaders,
        rows: dynamicTagRows,
      });
      this['message/PUT_SCRATCH_SIMPLE_MESSAGE']({
        meta: {
          dynamicTagHeaders,
          tagsResourceOid,
          tagMaxWidthMap,
        },
      });
      this.showImportDynamicTagsModal = false;
    },
    async handleInsertDynamicTagsSelect(item) {
      if (item.value === 'addDynamicTags') {
        this.showImportDynamicTagsModal = true;
      } else {
        this.$refs['simple-message-editor'].insertDynamicTag(item.value);
      }
    },

    // Used by parents to force a refresh of message lists, in the event that the selected list no longer exists
    triggerMessageListRefresh() {
      this.$refs['message-list-select'].fetchMessageLists();
    },

    handleAdvancedTargetingUpdate() {
      const targetingFilter = this.getCurrentFilterExpression;
      const facebookPageId = this.scratchSimpleMessage.meta.facebookMessenger.pageId;
      this['message/PUT_FILTERING_IN_SCRATCH_SIMPLE_MESSAGE'](targetingFilter);
      this['messageList/FETCH_FILTERED_RECIPIENT_LIST_COUNT']({
        channel: 'facebookMessenger',
        skipStatsSnapshot: true,
        facebookPageId,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.messenger-edit-details {
  .messenger-container {
    display: flex;
    align-items: center;

    .icon {
      width: 36px;
      height: 36px;
      border-radius: 50%;
    }
  }
  .cost-container {
    padding: 15px;
    border-radius: 4px;
    background-color: #F1EDFE;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .cost {
      display: flex;
      flex-direction: row;

      .value {
        font-weight: bold;
        margin-right: 1em;
      }

      .tooltip {
        color: $purple500;
      }
    }
  }
}
</style>
