<template>
    <section
      :class="[
        'wrapper',
        'content',
        'content--centered',
        $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
        $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
      ]">
      <am2-step-link
        class="step-back-link"
        text="Back to integrations"
        @click="handleBackLinkClick" />
      <am2-axs-modal
        :is-show="showAxsModal"
        :loading="isCreatingAxsIntegration"
        @confirm="handleAxsConfirm"
        @cancel="showAxsModal = false"
      />

      <am2-opt-in-settings-modal
        :is-show="showOptinSettingsModal"
        @cancel="showOptinSettingsModal = false"
        providerName="AXS"
        :message-list-oid="currentMessageListOid"
        :tag-fans="tagFans"
      />
      <div
        :class="[
          'title-section',
          $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
        ]">
        <div class="title-section-left">
          <am2-icon-title-section
            title="Axs"
            description="Automatically sync your members, events, orders from Axs"
            :icon-props="{
              name: 'axs',
              color: $arStyle.color.axs,
              height: '46px',
              width: '40px',
            }"
          />
        </div>
        <div class="title-section-right" v-if="!isFetchingAxsIntegration">
          <am2-expand-button-dropdown
            align="left"
            :button-props="{ iconName: 'settings' }"
            :items="[
              {
                name: 'Remove Account',
                value: 'removeIntegration',
              },
            ]"
            @select="handleSettingOptionSelect" />
        </div>
      </div>

      <section v-if="isAddingAxsIntegration || isFetchingAxsIntegration" class="integration-loading-container">
        <am2-loading-bubble />
      </section>
      <section v-else>
        <section v-if="integrationFailed" class="integration-failed-message">
          <ar-snackbar
            type="warning"
            message="Your Axs connection is no longer valid. This means we can't sync your member data. <a>Please reconnect your account</a>"
            @anchorClick="handleReconnectIntegration"
            :style="{ width: '100%' }"
          />
        </section>

        <SyncBlockContainer
          v-else
          class="sync-block-container"
          copySize="sm"

          sync-status="default"
          :sync-time="syncTime"
          :sync-copies="{
            'default': message
          }"
          @startSync="handleStartSync"
          @stopSync="handleStopSync"
        />
      </section>

    </section>
  </template>

  <script>
  import { mapState, mapActions, mapMutations } from 'vuex';
  import SyncBlockContainer from '../components/sync-block-container';

  export default {
    name: 'AxsIntegration',
    layout: 'default',
    components: {
      SyncBlockContainer,
    },

    data: () => ({
      currentlySelectedIntegrationIndex: 0,
      syncStatusPoller: null,
      showAxsModal: false,
      showOptinSettingsModal: false,
      currentMessageListOid: null,
      tagFans: null,
      message: "Please contact Audience Republic via support@audiencerepublic.com to finish connecting your AXS account."
    }),

    computed: {
      ...mapState({
        axsIntegration: state => state.axsIntegration.integration,
        isFetchingAxsIntegration: state => state.axsIntegration.isFetchingIntegration,
        isAddingAxsIntegration: state => state.axsIntegration.isAddingIntegration,
        isCreatingAxsIntegration: state => state.axsIntegration.isCreatingIntegration,
        isDeletingAxsIntegration: state => state.axsIntegration.isDeletingIntegration,
        isFetchingSyncTask: state => state.axsIntegration.isFetchingSyncTask,
        syncTask: state => state.axsIntegration.syncTask,
        syncStatus: state => String(state.axsIntegration.syncStatus),
      }),

      axsIntegrationEnabled() {
        return process.env.arEnableAxsIntegration;
      },

      syncTime() {
        return this.syncTask?.sysMtime || null;
      },

      currentIntegration() {
        if (this.currentlySelectedIntegrationIndex === null) return null;
        if (!this.axsIntegration || this.axsIntegration.length < 1 || !this.axsIntegration[this.currentlySelectedIntegrationIndex]) return null;
        return this.axsIntegration[this.currentlySelectedIntegrationIndex];
      },

      integrationFailed() {
        if (!this.currentIntegration) return false;
        return this.currentIntegration.status === 'failed';
      },
    },

    async mounted() {
      if (!this.axsIntegrationEnabled) {
        this.handleBackLinkClick();
      }
      await this.fetchIntegration();
      this.startFetchTasksPolling();
    },

    beforeDestroy() {
      this['axsIntegration/RESET_INTEGRATION']();

      if (this.syncStatusPoller) {
        clearInterval(this.syncStatusPoller);
      }
    },

    methods: {
      ...mapActions([
        'SHOW_CONFIRM',
        'axsIntegration/FETCH_INTEGRATION',
        'axsIntegration/DELETE_INTEGRATION',
        'axsIntegration/SYNC_START',
        'axsIntegration/SYNC_STOP',
        'axsIntegration/FETCH_SYNC_TASK',
        'axsIntegration/CREATE_AXS_INTEGRATION',
        'promoterTasks/START_POLLING_PENDING_TASKS',
      ]),
      ...mapMutations([
        'axsIntegration/RESET_INTEGRATION',
      ]),
      startFetchTasksPolling() {
        if (this.currentIntegration?.oid && !this.isFetchingSyncTask) {
          this.fetchTasks();
          this.syncStatusPoller = setInterval(this.fetchTasks, 3000);
        }
      },
      async fetchIntegration() {
        const success = await this['axsIntegration/FETCH_INTEGRATION']()
        if ((!success || !this.axsIntegration || this.axsIntegration.length === 0) && this.$route.path != '/settings/integrations') {
          this.$router.push({
            path: '/settings/integrations',
          });
        }
      },

      async fetchTasks() {
        await this['axsIntegration/FETCH_SYNC_TASK'](this.currentIntegration.oid);

        if (this.currentIntegration && this.hasExistingTask && this.syncStatus !== "in-progress") {
          window.clearInterval(this.syncStatusPoller);
          this.syncStatusPoller = null;
          this['promoterTasks/START_POLLING_PENDING_TASKS']({});
        }
      },

      async handleAxsConfirm({ apiKey, accountName }) {
        const succeed = await this['axsIntegration/CREATE_AXS_INTEGRATION']({
          apiKey,
          accountName
        });
        if (succeed) {
          this.fetchIntegration();
          this.showAxsModal = false;
        }
      },

      hasExistingTask() {
        return this.syncTask !== null;
      },

      handleBackLinkClick() {
        this.$router.push({ path: '/settings/integrations' });
      },

      handleIntegrationChange(integration, index) {
        this.currentlySelectedIntegrationIndex = index;
        this.fetchTasks();
      },

      async handleAddNewIntegration() {
        this.showAxsModal = true;
      },

      async handleStartSync() {
        setTimeout(() => {
          this['promoterTasks/START_POLLING_PENDING_TASKS']({});
        }, 750);
        await this['axsIntegration/SYNC_START']({ oid: this.currentIntegration.oid });
        if (this.syncStatus !== 'completed') {
          this.syncStatusPoller = setInterval(this.fetchTasks, 3000);
        }
      },

      handleStopSync() {
        this['axsIntegration/SYNC_STOP']({oid: this.currentIntegration.oid});
        setTimeout(() => {
          this['promoterTasks/START_POLLING_PENDING_TASKS']({});
        }, 750);
      },

      async handleReconnectIntegration() {
        const agreed = await this.SHOW_CONFIRM({
          messageHtml: `Make sure you are logging in with the Axs account <strong>${this.currentIntegration.accountId}</strong> on Axs before reconnecting it.`,
          confirmButtonText: 'Reconnect account',
        });
        if (!agreed) {
          return;
        }

        const succeed = await this['axsIntegration/DELETE_INTEGRATION'](this.currentIntegration.oid);
        if (!succeed) {
          return;
        }

        this.handleAddNewIntegration();
      },

      async handleSettingOptionSelect(item) {
        if (item.value === 'removeIntegration') {
          const answer = await this.SHOW_CONFIRM({
            messageHtml: 'Removing the Axs integration will stop syncing your orders and customer data from Axs.',
            confirmButtonText: 'Remove integration',
            iconName: 'alert-question-mark',
            title: 'Are you sure?',
          });
          if (answer) {
            try {
              await this['axsIntegration/DELETE_INTEGRATION'](this.currentIntegration.oid);
              if (this.axsIntegration.length > 0) {
                this.currentlySelectedIntegrationIndex = 0;
                await this.fetchIntegration();
                this.startFetchTasksPolling();
              } else {
                this.$router.push({ path: '/settings/integrations' });
              }
            } catch (e) {
              this.$arNotification.push({ type: 'error', message: 'Failed to remove integration' });
            }
          }
        } else if (item.value === 'reconnectIntegration') {
          this.handleReconnectIntegration();
        } else if (item.value == 'openSettingsModal') {
          if (!this.currentIntegration) return;

          this.showOptinSettingsModal = true;
        }
      },
    }
  };
  </script>

  <style lang="scss" scoped>
  .wrapper {

    &.sm-max {
      padding: 0 24px;
    }
    &.xs-max {
      padding: 0 12px;
    }

    .integration-loading-container {
      display: flex;
      justify-content: center;
      margin-top: 65px;
    }

    .step-back-link {
      margin-top: 48px;
    }

    .title-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 48px;

      .title-section-left {
        display: inline-flex;
        align-items: flex-start;

        .tag {
          position: relative;
          margin-left: 10px;
          top: 4px;
        }
      }

      .title-section-right {
        display: inline-flex;
        align-items: center;

        .select {
          width: 300px;
          margin-right: 10px;
        }
      }

      &.md-max {
        flex-direction: column;
        align-items: flex-start;

        .title-section-right {
          margin-top:16px;
        }
      }
    }

    .sync-block-container {
      margin-top:64px;
    }
  }
  </style>
