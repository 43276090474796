<template>
    <section
      :class="[
        'wrapper',
        'content',
        'content--centered',
        $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
        $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
      ]">
      <am2-step-link
        class="step-back-link"
        text="Back to integrations"
        @click="handleBackLinkClick" />
      <am2-ticketbooth-modal
        :is-show="showTicketboothCredentialsModal"
        :loading="isCreatingTicketboothIntegration"
        @confirm="handleTicketboothCredentialsConfirm"
        @cancel="showTicketboothCredentialsModal = false"
      />

      <div
        :class="[
          'title-section',
          $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
        ]">
        <div class="title-section-left">
          <am2-icon-title-section
            title="Ticketbooth"
            description="Sync your orders, events and attendees from Ticketbooth"
            :icon-props="{
              name: 'ticketbooth',
              color: $arStyle.color.ticketbooth,
              height: '46px',
              width: '40px',
            }"
          />
        </div>
        <div class="title-section-right" v-if="!isFetchingTicketboothIntegration && integrationsSummary">
          <am2-integration-account-select
            v-if="!isFetchingTicketboothIntegration && ticketboothIntegration && currentIntegration"
            :default-selected-integration-oid="currentIntegration.oid"
            :available-integrations="integrationsSummary"
            @select="handleIntegrationChange"
            @new-account="handleAddNewIntegration"
            :style="{ marginRight: '10px', maxWidth: 'calc(100vw - 80px)', }"
          />
          <am2-expand-button-dropdown
            align="left"
            :button-props="{ iconName: 'settings' }"
            :items="[
              {
                name: 'Remove Account',
                value: 'removeIntegration',
              },
              {
                name: 'Reconnect Integration',
                value: 'reconnectIntegration',
              }
            ]"
            @select="handleSettingOptionSelect" />
        </div>
      </div>

      <section v-if="isAddingTicketboothIntegration || isFetchingTicketboothIntegration" class="integration-loading-container">
        <am2-loading-bubble />
      </section>
      <section v-else>
        <section v-if="integrationFailed" class="integration-failed-message">
          <ar-snackbar
            type="warning"
            message="Your Ticketbooth connection is no longer valid. This means we can't sync your member data. <a>Please reconnect your account</a>"
            @anchorClick="handleReconnectIntegration"
            :style="{ width: '100%' }"
          />
        </section>

        <SyncBlockContainer
          v-else
          class="sync-block-container"
          :is-fetching-sync-task="isFetchingSyncTask"
          :sync-status="syncStatus"
          :sync-time="syncTime"
          :sync-copies="{
            'in-progress': 'Syncing data from Ticketbooth',
            'pending': 'The data sync is still pending',
            'completed': 'Your Ticketbooth account is currently connected',
            'cancelled': 'Your Ticketbooth account connection is cancelled',
            'failed': this.syncErrorMessage,
            'default': 'Syncing tasks not found',
          }"
          @startSync="handleStartSync"
          @stopSync="handleStopSync"
        />
      </section>

    </section>
  </template>

  <script>
  import { mapState, mapActions, mapMutations } from 'vuex';
  import SyncBlockContainer from '../components/sync-block-container';

  export default {
    name: 'TicketboothIntegration',
    layout: 'default',
    components: {
      SyncBlockContainer,
    },

    data: () => ({
      currentlySelectedIntegrationIndex: 0,
      syncStatusPoller: null,
      showTicketboothCredentialsModal: false
    }),

    computed: {
      ...mapState({
        ticketboothIntegration: state => state.ticketboothIntegration.integration,
        isFetchingTicketboothIntegration: state => state.ticketboothIntegration.isFetchingIntegration,
        isAddingTicketboothIntegration: state => state.ticketboothIntegration.isAddingIntegration,
        isCreatingTicketboothIntegration: state => state.ticketboothIntegration.isCreatingIntegration,
        isDeletingTicketboothIntegration: state => state.ticketboothIntegration.isDeletingIntegration,
        isFetchingSyncTask: state => state.ticketboothIntegration.isFetchingSyncTask,
        syncTask: state => state.ticketboothIntegration.syncTask,
        syncStatus: state => state.ticketboothIntegration.syncStatus,
      }),

      ticketboothIntegrationEnabled() {
        return true;
      },

      syncErrorMessage() {
        return this.syncTask?.statusDetails?.lastError || this.syncTask?.statusDetails?.reason;
      },

      syncTime() {
        return this.syncTask?.sysMtime || null;
      },

      integrationsSummary() {
        if (!this.ticketboothIntegration) return null;
        const summary = this.ticketboothIntegration
          .map(i => ({
            oid: i.oid,
            name: i.accountId,
            email: i.accountId
          }));

        return summary
      },

      currentIntegration() {
        if (!this.ticketboothIntegration) return null;
        return this.ticketboothIntegration[this.currentlySelectedIntegrationIndex];
      },

      integrationFailed() {
        if (!this.currentIntegration) return false;
        return this.currentIntegration.status === 'failed';
      },
    },

    async mounted() {
      if (!this.ticketboothIntegrationEnabled) {
        this.handleBackLinkClick();
      }
      await this.fetchIntegration();
      this.startFetchTasksPolling();
    },

    beforeDestroy() {
      this['ticketboothIntegration/RESET_INTEGRATION']();

      if (this.syncStatusPoller) {
        clearInterval(this.syncStatusPoller);
      }
    },

    methods: {
      ...mapActions([
        'SHOW_CONFIRM',
        'ticketboothIntegration/FETCH_INTEGRATION',
        'ticketboothIntegration/DELETE_INTEGRATION',
        'ticketboothIntegration/SYNC_START',
        'ticketboothIntegration/SYNC_STOP',
        'ticketboothIntegration/FETCH_SYNC_TASK',
        'ticketboothIntegration/CREATE_TICKETBOOTH_INTEGRATION',
        'promoterTasks/START_POLLING_PENDING_TASKS',
      ]),
      ...mapMutations([
        'ticketboothIntegration/RESET_INTEGRATION',
      ]),
      startFetchTasksPolling() {
        if (this.currentIntegration?.oid && !this.isFetchingSyncTask) {
          this.fetchTasks();
          this.syncStatusPoller = setInterval(this.fetchTasks, 3000);
        }
      },
      async fetchIntegration() {
        await this['ticketboothIntegration/FETCH_INTEGRATION']();
      },

      async fetchTasks() {
        await this['ticketboothIntegration/FETCH_SYNC_TASK'](this.currentIntegration.oid);

        if (this.currentIntegration && this.hasExistingTask && this.syncStatus !== "in-progress") {
          window.clearInterval(this.syncStatusPoller);
          this.syncStatusPoller = null;
          this['promoterTasks/START_POLLING_PENDING_TASKS']({});
        }
      },

      async handleTicketboothCredentialsConfirm({ accountName, apiToken, email, password }) {
        const succeed = await this['ticketboothIntegration/CREATE_TICKETBOOTH_INTEGRATION']({
            accountName,
            apiToken,
            email,
            password
        });
        if (succeed) {
          this.fetchIntegration();

          this.showTicketboothCredentialsModal = false;
        }
      },


      hasExistingTask() {
        return this.syncTask !== null;
      },

      handleBackLinkClick() {
        this.$router.push({ path: '/settings/integrations' });
      },

      handleIntegrationChange(integration, index) {
        this.currentlySelectedIntegrationIndex = index;

        this.updateCurrentMessageListOid();
        this.fetchTasks();
      },

      updateCurrentMessageListOid() {
        if (this.currentlySelectedIntegrationIndex === null || !this.integrationsSummary) {
          return null;
        }

        this.currentMessageListOid = !!this.integrationsSummary[this.currentlySelectedIntegrationIndex] ? this.integrationsSummary[this.currentlySelectedIntegrationIndex].messageListOid : null
      },

      async handleAddNewIntegration() {
        this.showTicketboothCredentialsModal = true;
      },

      async handleStartSync() {
        setTimeout(() => {
          this['promoterTasks/START_POLLING_PENDING_TASKS']({});
        }, 750);
        await this['ticketboothIntegration/SYNC_START']({ oid: this.currentIntegration.oid });
        if (this.syncStatus !== 'completed') {
          this.syncStatusPoller = setInterval(this.fetchTasks, 3000);
        }
      },

      handleStopSync() {
        this['ticketboothIntegration/SYNC_STOP']({oid: this.currentIntegration.oid});
        setTimeout(() => {
          this['promoterTasks/START_POLLING_PENDING_TASKS']({});
        }, 750);
      },

      async handleReconnectIntegration() {
        const agreed = await this.SHOW_CONFIRM({
          messageHtml: `Make sure you are logging in with the Ticketbooth account <strong>${this.currentIntegration.accountId}</strong> on Ticketbooth before reconnecting it.`,
          confirmButtonText: 'Reconnect account',
        });
        if (!agreed) {
          return;
        }

        const succeed = await this['ticketboothIntegration/DELETE_INTEGRATION'](this.currentIntegration.oid);
        if (!succeed) {
          return;
        }

        this.handleAddNewIntegration();
      },

      async handleSettingOptionSelect(item) {
        if (item.value === 'removeIntegration') {
          const answer = await this.SHOW_CONFIRM({
            messageHtml: 'Removing the Ticketbooth integration will stop syncing your data from Ticketbooth.',
            confirmButtonText: 'Remove integration',
            iconName: 'alert-question-mark',
            title: 'Are you sure?',
          });
          if (answer) {
            try {
              await this['ticketboothIntegration/DELETE_INTEGRATION'](this.currentIntegration.oid);
              this.$router.push({ path: '/settings/integrations' });
            } catch (e) {
              this.$arNotification.push({ type: 'error', message: 'Failed to remove integration' });
            }
          }
        } else if (item.value === 'reconnectIntegration') {
          this.handleReconnectIntegration();
        }
      },
    }
  };
  </script>

  <style lang="scss" scoped>
  .wrapper {

    &.sm-max {
      padding: 0 24px;
    }
    &.xs-max {
      padding: 0 12px;
    }

    .integration-loading-container {
      display: flex;
      justify-content: center;
      margin-top: 65px;
    }


    .step-back-link {
      margin-top: 48px;
    }

    .title-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 48px;

      .title-section-left {
        display: inline-flex;
        align-items: flex-start;

        .tag {
          position: relative;
          margin-left: 10px;
          top: 4px;
        }
      }

      .title-section-right {
        display: inline-flex;
        align-items: center;

        .select {
          width: 300px;
          margin-right: 10px;
        }
      }

      &.md-max {
        flex-direction: column;
        align-items: flex-start;

        .title-section-right {
          margin-top:16px;
        }
      }
    }


    .sync-block-container {
      margin-top:64px;
    }
  }
  </style>
