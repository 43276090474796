<template>
  <ar-modal
    :is-open="isVisible"
    class="import-data-modal"
    :header="modalTitle"
    :has-back-link="step > 1"
    :hide-footer="step < 2"
    width="590px"
    :mobile="$arMediaQuery.window.maxWidth('xs')"
    @close="onClose"
    @back="goBack"
  >
    <!-- BODY -->
    <!-- File upload -->
    <div
      v-if="step === 1"
      slot="body"
      :class="['import-data-modal__wrapper', $arMediaQuery.window.maxWidth('xs') && 'xs-max']"
    >
      <am2-dropzone
        :file-type="['text/csv']"
        :file-size="100000000"
        file-type-alias="CSV file"
        :placeholder-icon="{
          name: 'upload',
          width: '16px',
          height: '16px',
          color: $arStyle.color.purple500,
          wrapperStyle: {
            background: mouseOverCsvDropdown ? `white` : $arStyle.color.skyBlueGrey400,
            width: '60px',
            height: '60px',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: mouseOverCsvDropdown ? '2px solid white' : null,
          },
        }"
        :ratio="0.525"
        @upload="handleCSVUploaded"
        @mouseover.native="mouseOverCsvDropdown = true"
        @mouseleave.native="mouseOverCsvDropdown = false"
      />
    </div>
    <!-- CSV Column Values -> Contacts Field mapping -->
    <div v-else-if="step === 2" slot="body">
      <div class="mapping-instructions">
        <p>Map the fields to import into Audience Republic from your CSV file</p>
        <p class="mapping-instructions__subtitle">* indicates a compulsory field</p>
      </div>
      <div :class="['import-data-modal__wrapper', $arMediaQuery.window.maxWidth('xs') && 'xs-max']">
        <p class="column-text"><span>Audience Republic</span><span>CSV File</span></p>

        <div class="form">
          <div v-for="(arTableHeader, index) in defaultFieldMap" :key="index" class="form-row">
            <p class="col-xs-12 col-sm-5 form-row__label">
              {{ `${arTableHeader.name}${arTableHeader.required ? '*' : ''}` }}
              <ar-icon
                v-if="arTableHeader.hint && $arMediaQuery.window.minWidth('sm')"
                v-tooltip.top="{ content: arTableHeader.hint }"
                name="tooltip-question-mark"
                class="icon"
                height="15px"
                width="15px"
              />
            </p>

            <div class="col-xs-12 col-sm-7 form-row__input">
              <ar-simple-select
                v-if="arTableHeader.name === 'Date Format'"
                class="selector"
                :items="dateFormatOptions"
                :default-select-index="selectedDateFormatIndex"
                :data-test-id="`import-option-${arTableHeader.value}`"
                @select="selectDateformat"
              />
              <ar-simple-select
                v-else
                class="selector"
                enable-filter
                :items="csvHeadersOptions(arTableHeader.mappedTo)"
                placeholder="Please select..."
                enable-clear
                :default-select-index="actualSelectedIndex(arTableHeader.mappedTo)"
                :data-test-id="`csv-colum-${arTableHeader.serverHeaderName}`"
                @select="(val) => setMapping(val, arTableHeader)"
                @clear="() => setMapping({ index: null }, arTableHeader)"
              />

              <div v-if="arTableHeader.error" class="error">
                {{ arTableHeader.error }}
              </div>
            </div>
          </div>
          <div v-if="arEnableCustomFields && isCustomFieldsFeatureEnabled" class="custom-field-map">
            <div
              v-for="(customField, customFieldIndex) in customFieldMap"
              class="form-row"
              :key="'custom-field-row-' + customFieldIndex"
            >

              <p class="col-xs-12 col-sm-5 form-row__label">
                {{  customField.name }}
                <ar-icon
                  v-if="customField.hint && $arMediaQuery.window.minWidth('sm')"
                  v-tooltip.top="{ content: customField.hint }"
                  name="tooltip-question-mark"
                  class="icon"
                  height="15px"
                  width="15px"
                />
              </p>
              <div class="col-xs-12 col-sm-7 form-row__input">
                <ar-simple-select
                  :items="csvHeadersOptions(customField.mappedTo)"
                  :default-select-index="actualSelectedIndex(customField.mappedTo)"
                  class="selector"
                  enable-filter
                  enable-clear
                  placeholder="Please select..."
                  :data-test-id="`csv-colum-custom-field-${customField.oid}`"
                  @select="(val) => setMapping(val, customField)"
                  @clear="() => setMapping({ index: null }, customField)"
                />
                <ar-icon
                  class="delete-button u-margin-left-4"
                  name="discard"
                  height="16px"
                  :color="$arStyle.color.blueGrey600"
                  @click="(optionRow) => handleDeleteCustomField(customField)" 
                />
              </div>
            </div>
          </div>

          <div v-if="arEnableCustomFields && isCustomFieldsFeatureEnabled" class="simple-button-filtered-dropdown-wrapper">
            <am2-simple-button-filtered-dropdown
              class="simple-button-filtered-button"
              text="Add custom field"
              icon-name="plus"
              item-name="custom fields"
              :items="filteredCustomFields"
              @select="addCustomFieldToFieldMap"
            />
          </div>
        </div>
      </div>
    </div>
    <!--
      Additional actions to perform once fans
      have been imported:
        1. Add fans to a list
        2. Add tags to fans

     -->
    <div
      v-else-if="step === 3"
      slot="body"
      :class="['import-data-modal__wrapper', $arMediaQuery.window.maxWidth('xs') && 'xs-max']"
    >
      <ar-text :text="`<b>${numberOfNewFans}</b> contacts have been mapped`" size="xs" allow-html />
      <ar-divider
        :style="{
          margin: '15px 0 20px 0',
          height: '0.5px',
          background: $arStyle.color.$bluegrey500,
        }"
      />
      <div class="form-row">
        <ar-text
          text="Add to list"
          size="xs"
          weight="bold"
          :style="{
            marginBottom: '4px',
            height: '50px',
            display: 'flex',
            alignItems: 'center',
          }"
          class="col-xs-12 col-sm-4 form-row__label"
        />

        <div class="message-list-select col-xs-12 col-sm-8">
          <am2-message-list-select
            v-model="messageListSettings.oid"
            @select="handleMessageListChange"
            :channels="mobileNumberMapped ? ['email', 'sms'] : ['email']"
            message-list-type="manual"
            :show-stats="false"
            :disabled="!canChangeMessageList"
          />

          <ar-text
            :text="`Imported contacts can only be subscribed to lists with Email${
              mobileNumberMapped ? ' or SMS' : ''
            } enabled`"
            size="xxxs"
            multiple-lines
            class="message-list-subtitle"
            line-height="20px"
          />

          <div v-show="messageListSettings.oid !== null" class="channel-choices">
            <ar-checkbox
              v-show="messageListSettings.listContainsEmail"
              v-model="messageListSettings.emailEnabled"
              class="tick-section-checkbox"
              :disabled="!messageListSettings.emailCanBeEnabled"
              v-tooltip.top="{
                content: messageListSettings.emailCanBeEnabled
                  ? null
                  : `One or more of your imported contacts does not have a valid email address`,
              }"
              label="Email"
              icon-name="email"
              :icon-props="{
                width: '18px',
                color: $arStyle.color.email,
              }"
            />
            <ar-checkbox
              v-show="messageListSettings.listContainsSms"
              v-model="messageListSettings.smsEnabled"
              :disabled="!messageListSettings.smsCanBeEnabled"
              v-tooltip.top="{
                content: messageListSettings.smsCanBeEnabled
                  ? null
                  : `One or more of your imported contacts does not have a valid phone number`,
              }"
              class="tick-section-checkbox"
              label="SMS"
              icon-name="sms"
              :icon-props="{
                width: '18px',
                color: $arStyle.color.sms,
              }"
            />
          </div>
        </div>
      </div>
      <ar-divider
        :style="{
          margin: '26px 0 21px 0',
          height: '0.5px',
          background: $arStyle.color.$bluegrey500,
        }"
      />
      <div class="form-row">
        <ar-text
          text="Add Tags"
          size="xs"
          weight="bold"
          :style="{
            marginBottom: '4px',
            height: '50px',
          }"
          class="col-xs-12 col-sm-4 form-row__label"
        />
        <div class="col-xs-12 col-sm-8">
          <am2-fan-tag-input v-model="newFanTags" />
        </div>
      </div>
    </div>
    <!-- Import complete -->
    <div
      v-else-if="step === 4"
      slot="body"
      :class="['import-data-modal__wrapper', $arMediaQuery.window.maxWidth('xs') && 'xs-max']"
    >
      <p class="column-text"><span>Audience Republic</span><span>CSV File</span></p>

      <p class="import-data-modal__wrapper__import-message">
        {{ importCompleteMessage }}
      </p>
    </div>

    <!-- FOOTER -->
    <div
      v-if="step >= 2"
      slot="footer"
      :class="['import-data-modal__footer', $arMediaQuery.window.maxWidth('xs') && 'u-padding-3']"
    >
      <!--
        NOTE/RK: Uncomment this when integrations is in production
        <div v-if="step===1">
          <p class="header">Want to easily add your contacts?</p>
          <p class="body">Our integrations help you seamlessly adds your contacts.
            <a
              target="_blank"
              href="https://audiencerepublic.com/audience-manager/audience/">
              Learn more
            </a>
          </p>
        </div>
      -->
      <div v-if="error" class="error-message" v-html="error" />
      <div>
        <div>
          <ar-simple-button
            :text="importButtonText"
            :loading="importButtonDisabled"
            :disabled="nextButtonDisabled"
            @click="handleNextStep"
            :type="canImport ? 'purple' : 'grey'"
            :style="{ float: 'right' }"
          />
        </div>
      </div>
    </div>
  </ar-modal>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { initialContactsCSVMap } from '~/utils/constants';
import { clone, csvValidationCheck, generateUniqueIntegersArray, sanitizeFilename, fileContentZipToB64 } from '@/utils/helpers';

export default {
  name: 'ImportContactsModal',

  data() {
    return {
      isVisible: false,
      step: 1,
      type: 'fan',
      arToPromoterMap: initialContactsCSVMap(),
      selectedDateFormat: {name: 'International', val: 'international'},
      dateFormatOptions: [{name: 'International', val: 'international'}, {name: 'US', val: 'us'}],
      csvHeaders: [],
      csvRows: [],
      error: '',
      rowErrors: false,
      acceptErrors: false,
      newFanTags: [],
      messageListSettings: {
        oid: null,
        smsCanBeEnabled: false, // Do all items in the contact import satisfy requirements to allow SMS?
        emailCanBeEnabled: false, // Do all items in the contact import satisfy requirements to allow Email?
        smsEnabled: false, // Has SMS been checked?
        emailEnabled: false, // Has Email been checked?
        listContainsSms: false, // Does the list even contain SMS?
        listContainsEmail: false, // Does the list even contain Email?
      },
      mouseOverCsvDropdown: false,
      canChangeMessageList: true,
      importFile: null,
      awaitingServerResponse: false,
      indexesToValidate: [], // Keep it consistent so that we don't recompute this every time the user presses Next
      goBack: () => {},
      onConfirm: () => {},
      onClose: () => {},
      arEnableCustomFields: process.env.arEnableCustomFields,
    };
  },
  computed: {
    ...mapState({
      isImportingAudienceCsv: (state) => state.audience.isImportingAudienceCsv,
      promoterOid: (state) => state.auth.account.promoterOid,
      customFields: (state) => state.customField.customFields,
    }),
    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled',
    }),

    importButtonText() {
      if (this.step === 3) {
        if (this.acceptErrors) return "Import with warnings";
        return "Import"
      } else {
        if (this.rowErrors) return "Continue with warnings";
        return "Continue";
      }
    },
    importButtonDisabled() {
      return this.step === 3 && (!!this.isImportingAudienceCsv || this.awaitingServerResponse);
    },
    nextButtonDisabled() {
      return this.messageListSettings.oid !== null &&
        !this.messageListSettings.emailEnabled &&
        !this.messageListSettings.smsEnabled;
    },
    disabledList() {
      return this.arToPromoterMap.filter((a) => a.mappedTo !== null);
    },
    importCompleteMessage() {
      return this.$store.state.importMessage;
    },
    modalTitle() {
      let title = 'Upload a CSV';

      switch (this.step) {
        case 3:
          title = 'Confirm import';
          break;
        default:
          break;
      }

      return title;
    },
    selectedDateFormatIndex() {
      const dateFormat = this.selectedDateFormat.name;
      const index = this.dateFormatOptions.findIndex((c) => c.name === dateFormat);
      return index === -1 ? null : index;
    },
    numberOfNewFans() {
      return this.csvRows.length;
    },
    mobileNumberMapped() {
      const mapping = this.arToPromoterMap.find((m) => m.serverHeaderName === 'mobileNumber');

      const columnIdx = mapping.mappedTo;

      return columnIdx !== null;
    },
    canImport() {
      if (!this.arToPromoterMap) return false
      const unmappedRequired = this.arToPromoterMap.filter(item => item.required && (item.mappedTo === null))
      return unmappedRequired.length === 0;
    },
    defaultFieldMap() {
      return this.arToPromoterMap.filter(item => !item.customField)
    },
    customFieldMap() {
      return this.arToPromoterMap.filter(item => !!item.customField)
    },
    isCustomFieldsFeatureEnabled() {
      return this.isFeatureEnabled(['customFields']);
    },
    filteredCustomFields() {
      return this.customFields.filter(customField => {
        const customFieldMapIndex = this.customFieldMap.findIndex(customFieldMapItem => {
          return customField.oid.toString() === customFieldMapItem.serverHeaderName.replace("cf_", "")
        })

        return customField.fieldType != 'dropdown-multi' && customFieldMapIndex < 0
      })
    }
  },
  watch: {
    canImport(val, oldVal) {
      if (val && !oldVal) {
        this.clearErrors();
      }
    },
  },
  created() {
    this.arToPromoterMap = initialContactsCSVMap();
    window.addEventListener('arModalOpen', this.handleModalOpen);
  },
  
  mounted() {
    this['customField/FETCH_CUSTOM_FIELDS']({type: 'profile'})
  },

  beforeDestroy() {
    window.removeEventListener('arModalOpen', this.handleModalOpen);
  },

  methods: {
    ...mapActions([
      'audience/IMPORT_AUDIENCE_CSV',
      'promoterTasks/START_POLLING_PENDING_TASKS',
      'customField/FETCH_CUSTOM_FIELDS',
    ]),
    handleModalOpen({ detail: { action, payload } }) {
      if (action !== 'OPEN_IMPORT_CONTACTS_MODAL') return;

      // make phone-number required in a sms-only list
      const { messageList = {} } = payload || {};
      const { oid, sms, email } = messageList;
      if (oid && sms && !email) {
        this.arToPromoterMap = this.arToPromoterMap.map(item => item.name === 'Phone Number'
          ? { ...item, required: true }
          : item);
      }

      this.startModalAction(payload);
    },
    respondModalAction(payload) {
      window.dispatchEvent(
        new CustomEvent('arModalResponse', { detail: { action: 'OPEN_IMPORT_CONTACTS_MODAL', payload } })
      );
    },
    setupMessageListSettings(messageList) {
      if (messageList) {
        this.messageListSettings = {
          oid: messageList.oid,
          emailCanBeEnabled: messageList.email,
          emailEnabled: messageList.email,
          listContainsEmail: messageList.email,

          smsCanBeEnabled: messageList.sms,
          smsEnabled: messageList.sms,
          listContainsSms: messageList.sms,
        };
      } else {
        this.messageListSettings = {
          oid: null,
          smsCanBeEnabled: false,
          emailCanBeEnabled: false,
          smsEnabled: false,
          emailEnabled: false,
          listContainsSms: false,
          listContainsEmail: false,
        };
      }
    },
    startModalAction(payload = { messageList: null, canChangeMessageList: true }) {
      this.isVisible = true;
      this.awaitingServerResponse = false;

      const { messageList, canChangeMessageList } = payload;
      this.canChangeMessageList = canChangeMessageList;

      this.setupMessageListSettings(messageList);

      this.onConfirm = () => {
        this.isVisible = false;
        this.respondModalAction(true);
        this.step = 1;
        this.csvHeaders = [];
        this.csvRows = [];
        this.newFanTags = [];
        this.isVisible = false;
        this.arToPromoterMap = initialContactsCSVMap();
        this.error = '';
        this.rowErrors = false;
        this.acceptErrors = false;
        this.indexesToValidate = []
      };
      this.onClose = () => {
        this.step = 1;
        this.csvHeaders = [];
        this.csvRows = [];
        this.newFanTags = [];
        this.isVisible = false;
        this.arToPromoterMap = initialContactsCSVMap();
        this.error = '';
        this.rowErrors = false;
        this.acceptErrors = false;
        this.indexesToValidate = []

        this.respondModalAction(false);
      };
      this.goBack = () => {
        this.step -= 1;
        this.setupMessageListSettings(messageList);
      }
    },
    handleCSVUploaded({ file, additionalInfo: { headers, body } }) {
      this.csvHeaders = headers;
      this.csvRows = body;
      this.importFile = new File([file], sanitizeFilename(file.name), { type: file.type});
      this.indexesToValidate = this.csvRows.length < 1000
        ? Array.from({length: this.csvRows.length}, (_, i) => i)
        : generateUniqueIntegersArray(500, 0, this.csvRows.length - 1)
      this.step = 2;
    },
    setMapping(val, arTableHeader) {
      this.rowErrors = false;
      this.error = '';
      this.acceptErrors = false;
      const arTableHeaderIndex = this.arToPromoterMap.indexOf(arTableHeader);
      if (arTableHeaderIndex > -1 && !!this.arToPromoterMap[arTableHeaderIndex]) {
        this.arToPromoterMap[arTableHeaderIndex].mappedTo = val.index;
        this.arToPromoterMap[arTableHeaderIndex].error = undefined;
      }
    },
    handleMessageListChange(messageList) {
      this.messageListSettings.oid = messageList.oid;
      this.messageListSettings.selectedChannels = [];

      this.messageListSettings.emailCanBeEnabled = messageList.email;
      this.messageListSettings.emailEnabled = messageList.email;
      this.messageListSettings.listContainsEmail = messageList.email;

      this.messageListSettings.smsCanBeEnabled = messageList.sms && this.mobileNumberMapped;
      this.messageListSettings.smsEnabled = false; // Jared specifically asked that we DO NOT automatically pre-check SMS opt-in. But if he ever changes his mind, uncomment the below...
      // this.messageListSettings.smsEnabled = messageList.sms && this.mobileNumberMapped;
      this.messageListSettings.listContainsSms = messageList.sms;
    },
    selectDateformat(val) {
      this.error = '';
      this.rowErrors = false;
      this.acceptErrors = false;
      this.selectedDateFormat = val;
    },
    csvHeadersOptions(selectedHeaderIndex) {
      return this.csvHeaders
        .map((c, index) => ({
          name: c,
          index,
        }))
        .filter((c) => {
          return this.arToPromoterMap.find((item) => item.mappedTo === c.index && c.index !== selectedHeaderIndex) === undefined;
        });
    },
    csvCustomFieldsHeadersOptions(selectedHeaderIndex) {
      return this.csvHeaders
        .map((c, index) => ({
          name: c,
          index,
        }))
        .filter((c) => {
          return this.customFieldMap.find((item) => item.mappedTo === c.index && c.index !== selectedHeaderIndex) === undefined;
        });
    },
    actualSelectedIndex(selectedHeaderIndex) {
      if (selectedHeaderIndex === null) return null;

      const value = this.csvHeaders[selectedHeaderIndex]
      const newIndex = this.csvHeadersOptions(selectedHeaderIndex).findIndex((c) => c.name === value);

      return newIndex || 0
    },
    updatePendingTasksInAudienceAndMessage() {
      this['promoterTasks/START_POLLING_PENDING_TASKS']({
        reload: true,
      });
    },

    clearErrors() {
      // Remove errors from mapping arrays
      this.arToPromoterMap = clone(
        this.arToPromoterMap.map((mapping, index) => {
          mapping.error = null;
          return mapping;
        })
      );
    },

    async handleNextStep() {
      this.clearErrors();
      // csv check here:
      let validationFailures = '';
      // Pre-validation check.

      this.arToPromoterMap.forEach((mapping) => {
        if (mapping.mappedTo !== null && csvValidationCheck(mapping, this.csvRows, false, this.selectedDateFormat.val, this.indexesToValidate) !== null) {
          mapping.error = `Your CSV has an invalid value for this column.`;
          validationFailures += `<strong>Warning: One or more rows have errors.</strong><p>The ${
            mapping.name
          } column has an invalid value:</p><code style="white-space: pre">${csvValidationCheck(
            mapping,
            this.csvRows,
            false,
            this.selectedDateFormat.val,
            this.indexesToValidate
          )} </code> <br/>`;
        }
      });
      // csv check error raise here if there any
      if (this.arToPromoterMap.some((a) => a.error)) {
        this.error = validationFailures;
        if (!this.rowErrors) {
          this.rowErrors = true;
          return;
        } else {
          this.acceptErrors = true;
        }
      }
      /// end csv check
      if (this.step !== 3) {
        if (this.step === 2 && (!this.mobileNumberMapped || !this.messageListSettings.listContainsSms)) {
          // Forcefully disable SMS if it can't be enabled for any particular reason
          this.$set(this.messageListSettings, 'smsCanBeEnabled', false);
        } else if (this.step === 2 && this.mobileNumberMapped && this.messageListSettings.listContainsSms) {
          // If the list contains SMS and all numbers are correctly mapped, then make sure that smsCanBeEnabled is set to true
          this.$set(this.messageListSettings, 'smsCanBeEnabled', true);
        }

        if (
          this.arToPromoterMap.every((mapping) => {
            if (mapping.required) {
              return mapping.mappedTo !== null && mapping.error === null;
            } else {
              return mapping.error === null;
            }
          }) || this.acceptErrors
        ) {
          // if the mappping is required then mappingTo is not null and mapping error is null. otherwise, just check mapping errors
          this.step += 1;
          return;
        }
      }

      if (this.step === 3) {
        this.awaitingServerResponse = true;
      }

      const incompleteRows = [];
      this.arToPromoterMap.forEach((a, index) => {
        if (a.required && (a.mappedTo === null || a.mappedTo === -1)) {
          incompleteRows.push(index);
        }
      });

      let subscriptions = {};
      if (this.messageListSettings.smsEnabled) {
        subscriptions.sms = true;
      }

      if (this.messageListSettings.emailEnabled) {
        subscriptions.email = true;
      }

      if (this.messageListSettings.oid != null && !subscriptions.sms && !subscriptions.email) {
        this.error = 'You must choose at least one channel if you wish to add contacts to a list';
      } else if (incompleteRows.length > 0) {
        const mapWithErrors = this.arToPromoterMap.map((a, index) => {
          if (incompleteRows.includes(index)) {
            a.error = 'This field is required.';
          }

          return a;
        });

        this.arToPromoterMap = mapWithErrors;
        this.error = 'Please go back and map all required fields.';
      } else {
        const cleanedColumnMap = {};

        this.arToPromoterMap
          .filter((a) => a.mappedTo !== null)
          .forEach((a) => {
            cleanedColumnMap[a.mappedTo] = a.serverHeaderName;
          });

        // get the asset type according to the server import type
        const serverImportType = 'audience-contact';
        // add original csv file to payload. then upload csv to s3 through our server.
        const csvContent = await fileContentZipToB64(this.importFile);
        const s3payload = {
          importType: serverImportType, // should be pure contact only
          originalCsvName: this.importFile.name,
          originalCsv: csvContent,
        };

        const serverResponse = await this.$api.buckets.uploadCsv(this.promoterOid, s3payload);

        if (serverResponse === null) {
          this.awaitingServerResponse = false;
          return;
        } else if (serverResponse.error) {
          this.error = serverResponse.error;
          this.awaitingServerResponse = false;
          return;
        } else if (!serverResponse.data['bucket-oid']) {
          this.error = 'The server has not received your csv file. No bucketOid';
          this.awaitingServerResponse = false;
          return;
        }

        const payload = {
          csvHeaders: this.csvHeaders,
          columnMap: cleanedColumnMap,
          tags: this.newFanTags,
          importType: serverImportType, // should be pure contact only
          bucketOid: serverResponse.data['bucket-oid'],
          dateFormat: this.selectedDateFormat.val,
        };

        if (this.messageListSettings.oid) {
          payload.messageList = {
            oid: this.messageListSettings.oid,
            subscriptions,
          };
        }

        await this['audience/IMPORT_AUDIENCE_CSV'](payload);
        this.awaitingServerResponse = false;
        setTimeout(() => {
          this.updatePendingTasksInAudienceAndMessage();
        }, 1250);
        this.onConfirm();
      }
    },

    addCustomFieldToFieldMap(data) {
      /* 16 Aug 2023 ADR
        There are two types of fields: default fields and custom fields.
        Default fields have been predefined by our system. Custom Fields are any field that promoters create on their own.
        Both can be imported into fan data.
        Note: Only Profile Custom Fields are allowed to be imported into a fan data. Form Custom Fields are not allowed.
        
        We identify each custom field input as "cf_$OID". It ensures that the server header name is unique and easily identifiable.
        It is the column name template used in the CSV when exported.
        The $OID value is the OID in the promoterCustomField in the backend.
       */
      this.arToPromoterMap.push({
        value: null,
        name: data.name,
        required: false,
        serverHeaderName: `cf_${data.oid}`,
        columnType: data.fieldType,
        mappedTo: null,
        hint: data.fieldType === 'date' ? "Recommended format DD/MM/YYYY" : null,
        customField: true,
      })
    },

    handleDeleteCustomField(val) {
      const customFieldIndex = this.arToPromoterMap.findIndex((field) => {
        return field.serverHeaderName === val.serverHeaderName
      })
      this.arToPromoterMap.splice(customFieldIndex, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.error {
  color: $red500;
  font-size: small;
  padding-top: 0.5em;
  padding-left: 0.4em;
}
.import-data-modal {
  .mapping-instructions {
    background-color: $skyBlueGrey300;
    padding: 20px;

    &__subtitle {
      font-size: 12px;
      color: $blueGrey700;
    }
  }

  .message-list-select {
    .message-list-subtitle {
      color: $blueGrey700;
      margin-top: 5px;
    }

    .channel-choices {
      margin-top: 10px;
      display: flex;
      flex-direction: row;

      .tick-section-checkbox {
        margin-right: 25px;
      }
    }
  }

  .column-chooser-tabs {
    background-color: $skyBlueGrey300;
    display: flex;
    flex-direction: row;
    padding: 0px 20px;

    &__option {
      margin-right: 20px;
      font-size: 14px;
      padding-right: 5px;
      padding-bottom: 10px;

      &:hover {
        cursor: pointer;
      }

      &.active {
        border-bottom: solid 2px $purple500;
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    min-height: 50px;
    padding: 28px 28px;
    overflow: auto;

    &.xs-max {
      padding: 28px 12px;
    }

    &.generic {
      padding: 0px;
    }

    > .mass-import-option {
      min-height: 86px;
      border-top: 1px solid $blueGrey500;
      padding: 1.5rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;

      > div {
        padding: 0.75rem;

        > p.heading {
          color: #43516b;
          font-size: 16px;
        }

        > p.body {
          color: #8e97a6;
        }

        &:last-child {
          position: absolute;
          right: 2.5rem;
        }
      }

      &:hover {
        cursor: pointer;
        background-color: darken(white, 2%);
      }
    }

    p.column-text {
      color: $blueGrey700;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 13px;
      border-bottom: 1px solid $skyBlueGrey500;
      padding-bottom: 13px;

      span:last-child {
        float: right;
      }
    }

    &__import-message {
      font-size: 1.2em;
      padding-top: 1em;
    }

    &_preamble {
      margin-bottom: 1em;
    }

    .form-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 0.75em;

      &__label {
        width: 40%;
        height: 50px;
        display: flex;
        align-items: center;
        position: relative;

        .ar-icon-wrapper {
          position: absolute;
          right: 8px;
        }
      }

      &__input {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 60%;

        .selector {
          width: 100%;
        }
      }

      > :first-child {
        flex: 1;
      }

      > :nth-child(2) {
        flex: 3;
      }

      .secondary-dropdown {
        margin-left: 15px;
      }

      .delete-button {
        cursor: pointer;
      }
    }

    .custom-field-map {
      border-top: 1px solid $skyBlueGrey400;
      margin-top: 20px;
      padding-top: 10px;

      .form-row__label {
        min-width: 0;

        .form-row__label__display {
          white-space: nowrap;
          overflow-x: hidden;
          text-overflow: ellipsis;
        }
      }

      .form-row__input {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    .simple-button-filtered-dropdown-wrapper {
      margin-top: 20px;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    min-height: 50px;
    padding: 28px 28px;
    background-color: $skyBlueGrey300;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    p {
      text-align: center;
      color: $blueGrey800;

      &.header {
        font-family: Graphik-Medium;
        font-size: 16px;
        line-height: 25px;
        letter-spacing: 0;
      }

      &.body {
        font-size: 14px;
        letter-spacing: 0;
      }
    }

    div.error-message {
      border: 1px solid $orange500;
      background: $orange-lite;
      transition: all 0.3s ease;
      width: 100%;
      color: #42516b;
      border-radius: 5px;
      padding: 12px 20px;
      text-align: left;
      margin-bottom: 10px;
    }

    .tick-container {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background-color: $green500;
      display: flex;
      justify-content: center;
      align-items: center;

      g {
        fill: white;
      }
    }
  }

  &__type,
  &__action,
  &__get-started {
    width: 50%;
  }

  &__type,
  &__action,
  &__get-started {
    padding: 25px 35px;
  }

  &__type {
    background-color: $skyBlueGrey300;
    height: 100%;
  }

  &__button {
    width: 100%;
    margin-top: 42px;
  }

  &__checkbox {
    margin-top: 15px;
  }

  &__footer-wrapper {
    display: flex;
    height: 50px;
    box-shadow: 0 3px 10px;
    padding: 50px;
    align-items: center;
    justify-content: flex-end;
  }

  &__eventbrite {
    text-decoration: underline;
    margin-top: 15px;
  }

  div.ticketing-provider {
    display: flex;
    align-items: center;

    > div {
      margin-right: 5px;
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }

  .upload-area {
    &:hover {
      .icon-container {
        border: 2px solid white;
      }
    }

    .icon-container {
      background: $skyBlueGrey400;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
